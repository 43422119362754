import Keycloak from 'keycloak-js';
import React from 'react';
import { matchPath, useLocation, Link } from 'react-router-dom';
import Icon from '@icon-park/react/es/all';

import Logo from 'components/v3/Elements/Logo/Logo';
import AvatarAction from 'components/v3/Elements/Avatares/AvatarAction/AvatarAction';
import Notifications from 'components/v3/Elements/Notifications/Notifications';
import { BodyL } from 'styles/v3/variables';

import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import UserType from 'models/User/UserTypeModel';
import menu from '../menu.json';
import * as S from './styles';

interface HeaderProps {
  auth?: Keycloak.KeycloakInstance;
  hasMenu?: boolean;
}

const Header: React.FC<HeaderProps> = ({ auth, hasMenu = true }) => {
  const userName = ((auth?.tokenParsed as any)?.name as string) ?? '';
  const { data: currentUser } = useSelector(
    (state: ApplicationState) => state.currentUser
  );
  const location = useLocation();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.location.href = `${window.location.protocol}//${window.location.host}/admin-panel`;
  };

  return (
    <S.Wrapper>
      <S.LogoContainer>
        <Link to='/v3/home-page'>
          <Logo minimal color='grey1000' />
        </Link>
      </S.LogoContainer>
      <S.MenuWrapper>
        {hasMenu && (
          <S.MenuContainer>
            {menu.map((i) => {
              const isAdmin = i.key === 'admin';
              if (
                currentUser?.type === UserType.STAFF ||
                currentUser?.types?.includes(UserType.STAFF)
              ) {
                return (
                  <S.MenuItem
                    $icon={i.icon}
                    key={i.name}
                    className={
                      matchPath(location.pathname, i.path) ? 'active' : ''
                    }
                  >
                    <Link
                      to={i.link}
                      onClick={isAdmin ? handleClick : () => {}}
                    >
                      <Icon type={i.icon} />
                      <BodyL>{i.name}</BodyL>
                    </Link>
                  </S.MenuItem>
                );
              }
              return (
                !isAdmin && (
                  <S.MenuItem
                    $icon={i.icon}
                    key={i.name}
                    className={
                      matchPath(location.pathname, i.path) ? 'active' : ''
                    }
                  >
                    <Link to={i.link}>
                      <Icon type={i.icon} />
                      <BodyL>{i.name}</BodyL>
                    </Link>
                  </S.MenuItem>
                )
              );
            })}
          </S.MenuContainer>
        )}
      </S.MenuWrapper>
      <S.ProfileContainer>
        <Notifications />
        {userName && <AvatarAction />}
      </S.ProfileContainer>
    </S.Wrapper>
  );
};

export default Header;
