import styled from 'styled-components';
import { BodyM } from 'styles/v3/variables';

export const MainWrapper = styled.div`
  padding: 62px 24px 62px 24px;
`;

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  flex: 1;

  gap: 24px;

  h3 {
    margin-bottom: 0 !important;
  }

  ${BodyM} {
    max-width: 493px;
  }
`;

export const ActionsWrapper = styled.div`
  max-width: 300px;

  display: flex;
  flex-direction: column;

  flex: 1;

  gap: 10px;
`;

export const InformationSection = styled.section`
  display: flex;
  flex-direction: column;

  gap: 48px;
`;

export const PriceTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  gap: 8px;

  h2 {
    margin-bottom: 0 !important;
  }

  p {
    margin-bottom: 2px;
  }
`;

export const GeneralInfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
`;

export const MainInformationSection = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 24px;
`;

export const DatesText = styled.div`
  display: flex;
  flex-direction: row;

  gap: 8px;
`;
