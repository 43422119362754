import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.grey950};
  max-width: ${toRem(996)};
  width: 100%;
  gap: 48px;
`;
