import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';

import RequestService from 'services/v3/Request/RequestService';

type UseRequestJoinProps = {
  clubId?: string;
  userId?: string;
};

export const useRequestJoin = ({ clubId, userId }: UseRequestJoinProps) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      await RequestService.requestJoin({ clubId, userId }),
    onSuccess: async () => {
      notification.success({
        message: 'Request successfully sent!',
        description:
          'Your request has been sent successfully, please wait for the club to get back to you',
      });

      await queryClient.invalidateQueries(['request', clubId, userId]);
    },
    onError: (error: any) => {
      notification.error({
        message: 'An error occurs!',
        description: error.message,
      });
    },
  });
};
