import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: ${toRem(40)} 0;
  margin: 0 auto;
`;
export const FooterNav = styled.nav`
  width: 100%;
  margin-top: ${toRem(10)};

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      & + li {
        margin-left: 1.5rem;
      }
    }
  }
`;
