import { OptionsType } from 'components/v3/Forms/Select/Select';

export const ageRuleOptions: OptionsType[] = [
  {
    value: 'EQUAL',
    label: 'Year Only',
  },
  {
    value: 'YOUNGER_WITH_MORE_ONE_YEAR',
    label: 'Year + 1 Year younger',
  },
  {
    value: 'YOUNGER_WITH_MORE_TWO_YEAR',
    label: 'Year + 2 Years younger',
  },
  {
    value: 'YOUNGER',
    label: 'Year + Younger',
  },
];

export const genderOptions: OptionsType[] = [
  {
    value: 'MALE',
    label: 'Boys',
  },
  {
    value: 'FEMALE',
    label: 'Girls',
  },
  {
    value: 'COED',
    label: 'Coed',
  },
];
