import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import UserType from '../../models/User/UserTypeModel';
import { ApplicationState } from '../../redux/store';
import './header-menu.scss';

const HeaderMenu: React.FunctionComponent<{ empty?: boolean }> = (props) => {
  const { empty } = props;
  const user = useSelector((state: ApplicationState) => state.user);

  const handleClickToNewVault = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    window.location.href = `${window.location.protocol}//${window.location.host}/v3/home-page`;
  };

  return (
    <div className='header-menu' style={{ display: empty ? 'block' : '' }}>
      <Link to='/' className='link'>
        <div className='logo' />
      </Link>
      {!empty && (
        <>
          <div className='menu-top'>
            <ul>
              <li>
                <Link to='/' className='link'>
                  Home
                </Link>
              </li>
              {user.data.type !== UserType.CLUB_DIRECTOR && (
                <li>
                  <Link to='/my-club' className='link'>
                    My Club
                  </Link>
                </li>
              )}
              {user.data.type === UserType.CLUB_DIRECTOR && (
                <li>
                  <Link to='/club-director' className='link'>
                    Club Director
                  </Link>
                </li>
              )}
              {(user.data.type === UserType.STAFF ||
                user.data.type === UserType.REFEREE ||
                user.data.type === UserType.TOURNAMENT_DIRECTOR) && (
                <li>
                  <Link to='/admin-panel/tournaments' className='link'>
                    My Tournaments
                  </Link>
                </li>
              )}
              {user.data.type === UserType.STAFF && (
                <>
                  <li>
                    <Link to='/admin-panel' className='link'>
                      Admin Panel
                    </Link>
                  </li>
                  <li>
                    <a
                      href='/v3/home-page'
                      className='link'
                      onClick={handleClickToNewVault}
                    >
                      New Vault
                    </a>
                  </li>
                </>
              )}
              <li>
                <Link to='/ranking' className='link'>
                  Ranking
                </Link>
              </li>

              <li>
                <Link to='/logout' className='link'>
                  logout
                </Link>
              </li>
            </ul>
          </div>
          <div className='manage-icons'>
            <NavLink to='/my-settings' activeClassName='active'>
              <div className='box-action-top icon-manage' />
            </NavLink>
          </div>
        </>
      )}
    </div>
  );
};

export default HeaderMenu;
