import { Whistling, Shield } from '@icon-park/react';

export const allowedRoles = [
  {
    title: 'Referee',
    name: 'REFEREE',
    icon: Whistling,
  },
  {
    title: 'Club',
    name: 'CLUB_DIRECTOR',
    icon: Shield,
  },
];
