import * as React from 'react';
import InputMask from 'react-input-mask';

import InputText, { InputTextProps } from '../InputText/InputText';

interface InputPhoneProps extends InputTextProps {
  mask?: string;
}

const InputPhone: React.FC<InputPhoneProps> = ({
  mask = '99 99 99999 9999',
  value,
  onChange,
  ...props
}) => (
  <InputMask
    mask={mask}
    value={value}
    maskPlaceholder={null}
    onChange={onChange}
  >
    <InputText {...props} type='phone' icon='PhoneTelephone' />
  </InputMask>
);

export default InputPhone;
