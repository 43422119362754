import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Wrapper = styled.div<{ $theme: 'success' | 'error' }>`
  .simple-card-wrapper {
    border-width: 4px;
    padding: ${toRem(30)};
    justify-content: flex-start;
    border-color: ${(props) =>
      props.$theme === 'success' ? COLORS.brandPrimary : COLORS.supportError};

    .card-icon {
      margin-top: ${toRem(-10)};
    }
  }
`;
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${toRem(32)};

  h3,
  p {
    margin-bottom: 1rem;
  }

  a {
    width: fit-content;
  }
`;
