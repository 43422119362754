import * as React from 'react';
import InputText, { InputTextProps } from '../InputText/InputText';

interface InputDateProps extends InputTextProps {}

const InputDate: React.FC<InputDateProps> = ({ ...props }) => (
  <InputText {...props} type='date' />
);

export default InputDate;
