import React from 'react';

import { Pagination } from 'antd';
import { User } from 'models/User/UserModel';
import { AvatarWithName } from '../AvatarWithName/AvatarWithName';
import * as S from './styles';

type StaffProps = {
  staff?: User[];
  total?: number;
  pageSize?: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

export const Staff = ({
  staff,
  total,
  pageSize,
  setCurrentPage,
}: StaffProps) => {
  return (
    <S.Container>
      <S.AvatarWrapper>
        {staff?.map((item) => (
          <AvatarWithName
            key={item.id}
            photo={item.photo}
            name={item.firstName}
          />
        ))}
      </S.AvatarWrapper>
      {staff?.length ? (
        <Pagination
          total={total}
          defaultCurrent={1}
          showSizeChanger={false}
          pageSize={pageSize ?? 10}
          onChange={(page) => setCurrentPage(page)}
        />
      ) : null}
    </S.Container>
  );
};
