import React, { useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useGetMembersWithoutClub } from 'hooks/v3/clubs/useGetMembersWithoutClub/useGetMembersWithoutClub';
import { useInviteMembersByClub } from 'hooks/v3/clubs/useInviteMembersByClub/useInviteMembersByClub';
import { ClubInvitesTypes } from 'models/v3/ClubInvite/ClubInvitesModel';
import { notification } from 'components/v3/Notification/notification';
import { Find, InviteList } from './components';
import * as S from './styles';

const InviteMembersToClub = () => {
  const history = useHistory();
  const params: { clubId?: string } = useParams();

  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);

  const { data } = useGetMembersWithoutClub();
  const { mutateAsync } = useInviteMembersByClub();

  const selectedMembersData = data?.filter((item) =>
    selectedMembers.includes(item.id)
  );

  const handleRemoveCard = (id: string) => {
    setSelectedMembers(selectedMembers?.filter((memberId) => memberId !== id));
  };

  const handleCardSelect = (id: string) => {
    if (selectedMembers.includes(id)) {
      handleRemoveCard(id);
    } else {
      setSelectedMembers([...selectedMembers, id]);
    }
  };

  const handleSendInvites = async () => {
    if (!selectedMembers.length) {
      notification.error({
        message: 'An error occurs!',
        description:
          'Please select at least one member to send the invitation to',
      });
      return;
    }

    if (params.clubId) {
      const payload = {
        clubId: params.clubId,
        receiversIds: selectedMembers,
        type: ClubInvitesTypes.CLUB_TO_USER,
      };

      await mutateAsync(payload);

      history.push(`/v3/clubs/${params.clubId}/dashboard`);
    }
  };

  return (
    <S.Container>
      <Find
        data={data}
        selectedMembers={selectedMembers}
        handleCardSelect={handleCardSelect}
      />
      <InviteList
        handleRemoveCard={handleRemoveCard}
        handleSendInvites={handleSendInvites}
        selectedMembersData={selectedMembersData}
      />
    </S.Container>
  );
};

export default InviteMembersToClub;
