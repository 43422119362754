import { action } from 'typesafe-actions';
import { NewAgeDivision } from 'admin/models/AgeDivision';
import { CouponView } from 'services/v3/Coupons/types';
import { SignupEventActionTypes } from './types';

export const setSignupClub = (clubs: string) =>
  action(SignupEventActionTypes.SET_CLUB, clubs);

export const signupDivisionQtyIncrease = (divisionId: string) =>
  action(SignupEventActionTypes.DIVISION_QTY_INCREASE, divisionId);

export const signupDivisionQtyDecrease = (divisionId: string) =>
  action(SignupEventActionTypes.DIVISION_QTY_DECREASE, divisionId);

export const validateSignupDivisions = (divisionId: NewAgeDivision[]) =>
  action(SignupEventActionTypes.VALIDATE_DIVISIONS, divisionId);

export const setSignupCoupon = (coupon: CouponView | undefined) =>
  action(SignupEventActionTypes.SET_COUPON, coupon);
