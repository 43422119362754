import * as React from 'react';
import { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { color } from '../../styles/styleVariables';

interface IBreadcrumbsProps {
  routes: any[];
}

const Breadcrumbs: React.FunctionComponent<IBreadcrumbsProps> = (props) => {
  const { routes } = props;

  const router: any = useRouteMatch();
  const [crumbs, setCrumbs] = useState<any>([]);

  React.useEffect(() => {
    const crumbs = routes
      // Get all routes that contain the current one.
      .filter(({ path }) => router.path.includes(path))
      // Swap out any dynamic routes with their param values.
      // E.g. "/pizza/:pizzaId" will become "/pizza/1"
      .map(({ path, ...rest }) => ({
        path: Object.keys(router.params).length
          ? Object.keys(router.params).reduce(
              (path, param) => path.replace(`:${param}`, router.params[param]),
              path
            )
          : path,
        ...rest,
      }));
    setCrumbs(crumbs);
  }, []);
  return (
    <BreadcrumbsWrapper>
      {crumbs.map((crumb: any) => (
        <div className='item'>
          <Link className='link' to={crumb.path}>
            {' '}
            {crumb.name}
          </Link>
        </div>
      ))}
    </BreadcrumbsWrapper>
  );
};

const BreadcrumbsWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  font-size: 10px;
  .item {
    margin-right: 25px;
    position: relative;

    &:after {
      right: -15px;
      content: '>';
      position: absolute;
    }
    &:last-child {
      font-weight: 600;
    }
    &:last-child:after {
      content: '';
    }
  }
`;

export default Breadcrumbs;
