import React from 'react';
import { Divider } from 'antd';
import { ArrowLeft, Delete } from '@icon-park/react';
import ConvertUtil from 'util/ConvertUtil';

import { User } from 'models/User/UserModel';

import IconButton from 'components/v3/Buttons/IconButton';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { BodyL, BodyM, TitleH4, COLORS } from 'styles/v3/variables';

import {
  Container,
  GoBackAndAllSelecteds,
  UserSelected,
  UserImageAndInfo,
  UserImage,
  UserSelectedInfoContainer,
  AddPlayersContainer,
} from './styles';

interface ViewUsersSelectedProps {
  users: User[];
  handleUpdateUsers: () => Promise<void>;
  handleSelected: (id: string) => void;
  handleGoBack: () => void;
}

export const ViewUsersSelected = ({
  users,
  handleUpdateUsers,
  handleSelected,
  handleGoBack,
}: ViewUsersSelectedProps) => {
  return (
    <Container>
      <GoBackAndAllSelecteds>
        <IconButton
          icon={<ArrowLeft size={24} fill={COLORS.grey50} />}
          style={{ backgroundColor: 'transparent' }}
          onClick={handleGoBack}
        />
        <TitleH4 $color='grey50' $isUpper style={{ marginLeft: 16 }}>
          {users.length} Members Selected
        </TitleH4>
      </GoBackAndAllSelecteds>
      {users.map((user) => {
        return (
          <UserSelected key={`key_selected_user${user.id}`}>
            <UserImageAndInfo>
              <UserImage>
                <img
                  src={ConvertUtil.getMinioUrl(user.photo)}
                  alt='Event Logo'
                />
              </UserImage>

              <UserSelectedInfoContainer>
                <BodyL $color='grey50'>
                  {user.firstName} {user.lastName}
                </BodyL>
                <BodyM $color='grey400'>{user.age} yo.</BodyM>
                <BodyM $color='grey400'>
                  {user.address?.city} - {user.address?.state}
                </BodyM>
              </UserSelectedInfoContainer>
            </UserImageAndInfo>

            <IconButton
              icon={<Delete size={24} fill={COLORS.grey50} />}
              style={{ backgroundColor: COLORS.grey800 }}
              onClick={() => handleSelected(user.id)}
            />
          </UserSelected>
        );
      })}

      <Divider style={{ margin: 0, marginTop: 100 }} />

      <AddPlayersContainer>
        <FilledButton onClick={handleUpdateUsers} isUpper isBold={false}>
          Add Players To Roster
        </FilledButton>
      </AddPlayersContainer>
    </Container>
  );
};
