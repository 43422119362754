import React, { useState } from 'react';
import { Divider } from 'antd';
import { Table } from 'components/v3/Table/Table';
import { useSubscribedClubs } from 'hooks/v3/event/useSubscribedClubs/useSubscribedClubs';
import { useGetEventInvites } from 'hooks/v3/event/useGetEventInvites/useGetEventInvites';
import { EventInvitesTypes } from 'models/v3/EventInvite/EventInvitesModel';
import { Filters } from '../../../Filters/Filters';
import { columns } from './columns';
import { useClubDataFilter } from '../../../../hooks';

type ClubsTableProps = {
  id: string;
};

export const ClubsTable = ({ id }: ClubsTableProps) => {
  const [filterValue, setFilterValue] = useState('');

  const { data } = useGetEventInvites({
    id,
    type: EventInvitesTypes.EVENT_TO_CLUB,
  });

  const uniqueClubsSet = new Set<string>();

  const newClubs = data?.filter((invite) => {
    if (!invite.club) return true;

    const clubName = invite.club.name;

    if (uniqueClubsSet.has(clubName)) return false;

    uniqueClubsSet.add(clubName);
    return true;
  });

  const { filteredData } = useClubDataFilter({
    filterValue,
    invites: newClubs,
  });

  return (
    <>
      <Divider style={{ margin: 0 }} />
      <Filters filterValue={filterValue} setFilterValue={setFilterValue} />
      <Table columns={columns} dataSource={filteredData} />
    </>
  );
};
