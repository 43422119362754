import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const NewUserWrapper = styled.div`
  max-width: 636px;
  width: 100%;
  margin: 0 auto;

  h1 {
    margin-bottom: 1.5rem;
  }
`;
export const RoundedBoxStyle = `
  background-color: ${COLORS.grey950};
  border-radius: 1.5rem;
  padding: ${toRem(24)};
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const UserCreatedBox = styled.div`
  ${RoundedBoxStyle}

  h2 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: ${toRem(32)};
  }
`;
export const NewUserForm = styled.form`
  ${RoundedBoxStyle}

  legend {
    margin: 0 0 1.5rem 0;
  }

  .new-user-input {
    margin-bottom: 1.5rem;
  }
`;
export const FormFieldset = styled.fieldset`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${toRem(40)};

  & > div {
    & + div {
      margin-left: 1.5rem;
    }
    width: 100%;
  }
`;
