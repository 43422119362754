import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  margin-left: ${toRem(221.17)};
  width: 100%;
`;

export const ContainerUser = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: ${toRem(228.6)};

  background-color: ${COLORS.grey950};

  padding: ${toRem(32)} ${toRem(24)};
  border-radius: ${toRem(24)};
`;

export const ContainerUserProfile = styled.div`
  display: flex;
`;

export const UserProfileImage = styled.div`
  width: ${toRem(160)};
  height: ${toRem(160)};

  overflow: hidden;

  background-color: ${COLORS.brandPrimary};
  border: ${toRem(6)} solid ${COLORS.grey800};
  border-radius: ${toRem(160)};

  img {
    object-fit: cover;
    max-width: 100%;

    width: ${toRem(160)};
    height: ${toRem(160)};
  }
`;

export const UserInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;

  padding-left: ${toRem(32)};
`;

export const UserNameAndTeams = styled.div``;

export const ContainerEvents = styled.div`
  width: 100%;

  margin-top: ${toRem(111)};
`;

export const EventsContent = styled.div`
  display: flex;
  gap: ${toRem(55)};
`;

export const MyEventsContainer = styled.div`
  display: flex;
  align-items: center;

  margin-top: ${toRem(20)};
`;

export const EventMore = styled.div`
  width: ${toRem(56)};
  padding: ${toRem(16)};
  margin-left: ${toRem(16)};

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${toRem(56)};

  background-color: ${COLORS.grey900};
`;

export const OtherEventsTitleAndControllers = styled.div`
  display: flex;
  gap: ${toRem(8)};

  span {
    width: ${toRem(16)};
    height: ${toRem(16)};
  }
`;

export const ButtonCarousel = styled.button<{ disabled: boolean }>`
  background-color: ${COLORS.grey900};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  display: flex;
  align-items: center;
  padding: ${toRem(8)};

  border-radius: ${toRem(8)};
  border: none;

  cursor: pointer;

  transition: ease-in-out 0.5s;

  :hover {
    background-color: ${COLORS.grey800};
  }
`;

export const OtherEventsContainer = styled.div`
  display: flex;
  align-items: center;

  margin-top: ${toRem(24)};
  gap: ${toRem(24)};

  width: 57vw;
  overflow: hidden;

  .alice-carousel__stage-item {
    width: ${toRem(310)} !important;
  }

  .alice-carousel__dots-item {
    display: none;
  }

  .alice-carousel__prev-btn-item {
    display: none;
  }

  .alice-carousel__next-btn-item {
    display: none;
  }
`;

export const UnitedVideosContainer = styled.div`
  margin-top: ${toRem(50)};
`;

export const VideosContent = styled.div`
  display: flex;
  gap: ${toRem(20)};

  width: 85vw;
  overflow: hidden;

  .alice-carousel__dots-item {
    display: none;
  }

  .alice-carousel__prev-btn-item {
    display: none;
  }

  .alice-carousel__next-btn-item {
    display: none;
  }

  .alice-carousel__stage-item {
    width: ${toRem(500)} !important;
  }
`;
