import styled from 'styled-components';

export const AvatarWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  gap: 28px;
`;

export const MembersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 32px;
`;
