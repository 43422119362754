import { Modal } from 'antd';

import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

interface RoleCard {
  isEnabled?: boolean;
}

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: 468px;
    border-radius: 24px;
    background-color: ${COLORS.grey950};
  }

  .ant-modal-header {
    border-radius: 24px;
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: 24px;
    padding: 6px 12px 6px 12px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 32px;
`;

export const RolesWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 32px;
`;

export const RoleCard = styled.div<RoleCard>`
  width: 200px;
  height: 200px;
  border-radius: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  background-color: ${({ isEnabled }) =>
    isEnabled ? COLORS.grey950 : COLORS.grey900};

  opacity: ${({ isEnabled }) => (isEnabled ? 1 : 0.4)};

  cursor: ${({ isEnabled }) => (isEnabled ? 'pointer' : 'not-allowed')};

  border: 1px solid
    ${({ isEnabled }) => (isEnabled ? COLORS.grey50 : COLORS.grey600)};

  p {
    text-transform: capitalize;
  }

  transition: transform 0.2s;

  ${({ isEnabled }) =>
    isEnabled &&
    `
    &:hover {
      transform: scale(1.1);
      color: ${COLORS.brandPrimary};
      background-color: rgba(202, 252, 1, 0.3);
      border: 1px solid ${COLORS.brandPrimary};
    }
  `}
`;

export const CloseButton = styled.button`
  width: 38px;
  height: 38px;

  border: none;
  box-shadow: none;
  padding: 7.4px 0;
  border-radius: 10px;
  background-color: ${COLORS.grey700};

  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    background-color: ${COLORS.grey600};
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin: 0 !important;
  }
`;
