import * as React from 'react';
import { withRouter, useHistory } from 'react-router-dom';

import * as S from './styles';
import { BodyM, TitleH2 } from 'styles/v3/variables';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import TextButton from 'components/v3/Buttons/TextButton';

const UserCreated = () => {
  const history = useHistory();
  return (
    <S.NewUserWrapper>
      <S.UserCreatedBox>
        <TitleH2 $spanColor='brandPrimary'>
          New user created, but it is
          <br />
          <span>not complete</span>.
        </TitleH2>

        <BodyM>
          To participate in any event, create a club or create an event, this
          user will need to complete their profile.
        </BodyM>

        <FilledButtonWithIcon
          isUpper
          onClick={() => history.push('/v3/new-user/user-info')}
        >
          Complete Now
        </FilledButtonWithIcon>
        <TextButton isUpper onClick={() => history.go(-2)}>
          Remind me later
        </TextButton>
      </S.UserCreatedBox>
    </S.NewUserWrapper>
  );
};

export default withRouter(UserCreated);
