import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';
import backgroundEvents from 'assets/imgs/my-events-background.jpeg';

export const EventContainer = styled.div<{ isGreen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: ${toRem(282)};
  height: ${toRem(321)};

  padding: ${toRem(30)};

  border: 1px solid
    ${({ isGreen }) => (isGreen ? COLORS.brandPrimary : COLORS.grey800)};
  border-radius: ${toRem(24)};

  background: linear-gradient(86deg, #252622 40.19%, rgba(37, 38, 34, 0.91)),
    url(${backgroundEvents}) lightgray no-repeat;

  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${COLORS.grey400};
`;

export const EventIcon = styled.div`
  display: flex;
  align-items: flex-start;
  width: ${toRem(69)};
  height: ${toRem(69)};
  border-radius: ${toRem(69)};

  overflow: hidden;

  background-color: ${COLORS.brandPrimary};

  img {
    object-fit: cover;
    max-width: 100%;

    width: ${toRem(69)};
    height: ${toRem(69)};
  }
`;

export const EventInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:first-child {
    margin-top: ${toRem(8)};
  }

  &:nth-child(2) {
    margin-top: ${toRem(4)};
  }
`;

export const EventTitle = styled.div``;
