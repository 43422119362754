import { Gender } from 'admin/models/Enums';
import { Address } from 'admin/models/event/Address';
import { RequestStatus } from 'models/Request/RequestModel';
import { ExtraInfo } from 'models/User/ExtraInfoModel';
import { User } from 'models/User/UserModel';
import { Guardian } from 'models/User/GuardianModel';

export enum BaseUsersActionTypes {
  BASE_USERS_SET_DATA = '@@baseUsers/SET_DATA',
  BASE_USERS_SET_USER = '@@baseUsers/SET_USER',
  BASE_USERS_SET_USER_ID = '@@baseUsers/SET_USER_ID',
  BASE_USERS_UPDATE_DATA = '@@baseUsers/UPDATE_DATA',
}

export enum UserRelationship {
  SON = 'SON',
  NEPHEW = 'NEPHEW',
  CLUB = 'CLUB',
  TEAM = 'TEAM',
}

export enum DocumentStatusLabel {
  APPROVED = 'approved',
  EMPTY = 'empty',
  PENDING = 'waiting approval',
  DECLINED = 'denied',
  EXPIRED = 'expired',
  VIEW = 'VIEW',
}

export type Feedback = {
  age?: string;
  photo?: string;
  document?: string;
};

export enum SafeSportType {
  TRAINED = 'TRAINED',
  REFRESHER_1 = 'REFRESHER_1',
  REFRESHER_2 = 'REFRESHER_2',
  REFRESHER_3 = 'REFRESHER_3',
}

export interface BaseUser extends User {
  relationship?: UserRelationship;
  pendingDocumentation?: boolean;
  active?: boolean;
  guardian?: Guardian;
  creationDate?: Date;
  approvedPhoto?: string;
  renewDate?: Date;
  safesportVerified?: string;
  message?: string;
}

export interface PatchBaseUser {
  id?: string;
  firstName: string;
  middleName?: string;
  ageProof?: string;
  lastName: string;
  suffix?: string;
  extraInfo?: ExtraInfo;
  documentationVerified?: RequestStatus;
  ageVerified?: RequestStatus;
  email?: string;
  photo?: string;
  phoneNumber: string;
  birthDate: string;
  gender: Gender;
  guardian?: Guardian;
  address?: Address;
  photoVerified?: RequestStatus;
  safesportValidUntil?: string;
  safesportType?: SafeSportType;
}

export interface BaseUsersState {
  readonly data: BaseUser[];
  readonly userId: string;
}
