import * as React from 'react'

import InputCheckbox from 'components/v3/Forms/InputCheckbox/InputCheckbox'
import { BodyM, TitleH4 } from 'styles/v3/variables'
import RoundedCard, { RoundedCardProps } from '../RoundedCard/RoundedCard'

import * as S from './styles'

interface BoosterCardProps extends RoundedCardProps {
  id: string
  title: string
  description: string
  theme: 'primary' | 'light'
  withBorder?: boolean
  selected?: boolean
  disabled?: boolean
  bgImage?: string
  className?: string
}

const BoosterCard: React.FC<BoosterCardProps> = ({
  id,
  title,
  theme,
  selected,
  description,
  size,
  disabled,
  orientation,
  withBorder,
  bgImage,
  className,
  ...props
}) => {
  return (
    <S.PlanCardWrapper
      $selected={selected}
      $disabled={disabled}
      $withBorder={withBorder}
      $bgImage={bgImage}
      className={className ?? ''}
    >
      <RoundedCard {...props} size={size} orientation={orientation}>
        <InputCheckbox
          name={id}
          theme={theme}
          id={id}
          checked={selected}
          onChange={() => console.log('check')}
        />
        <S.PlanCardContainer $orientation={orientation} $selected={selected}>
          <TitleH4 className='card-title'>{title}</TitleH4>
          <BodyM className='card-description'>{description}</BodyM>
        </S.PlanCardContainer>
      </RoundedCard>
    </S.PlanCardWrapper>
  )
}

export default BoosterCard
