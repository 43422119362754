import { AxiosRequestConfig } from 'axios';

import { ConfigApi } from '../../config.api';
import APIServices from '../../util/ApiServices';
import ServiceResponse from '../../util/ServiceResponse';
import { Tiebreakers } from 'admin/models/event/Event';

class B2bTiebreakersService {
  public static async list(): Promise<ServiceResponse<Tiebreakers[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: '/tiebreakers',
      method: 'GET',
    };
    return APIServices.request<Tiebreakers[]>(axiosConfig);
  }
}

export default B2bTiebreakersService;
