import React, { useMemo } from 'react';
import { Whistling, HeadsetOne, SoccerOne } from '@icon-park/react';

import { BodyM, COLORS } from 'styles/v3/variables';
import * as S from './styles';
import { PermissionIconProps } from './types';

export const PermissionIcon = ({ title }: PermissionIconProps) => {
  const buttonStyle = useMemo(() => {
    switch (title) {
      case 'Player':
        return {
          $bgColor: COLORS.brandPrimary,
          $textColor: COLORS.grey950,
          Icon: <SoccerOne />,
        };
      case 'Coach':
        return {
          $bgColor: COLORS.brandSecondary,
          $textColor: COLORS.white,
          Icon: <HeadsetOne />,
        };
      case 'Referee':
        return {
          $bgColor: COLORS.brandTertiary,
          $textColor: COLORS.white,
          Icon: <Whistling />,
        };
      default:
        return {
          $bgColor: COLORS.brandPrimary,
          $textColor: COLORS.grey950,
          Icon: <SoccerOne />,
        };
    }
  }, [title]);

  return (
    <S.Container
      $bgColor={buttonStyle.$bgColor}
      $textColor={buttonStyle.$textColor}
    >
      {buttonStyle.Icon}
      <BodyM>{title}</BodyM>
    </S.Container>
  );
};
