import * as React from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Divider } from 'antd';

import { BodyM, BodyLBold } from 'styles/v3/variables';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import InputText from 'components/v3/Forms/InputText/InputText';
import InputDate from 'components/v3/Forms/InputDate/InputDate';
import InputPhone from 'components/v3/Forms/InputPhone/InputPhone';
import InputFile from 'components/v3/Forms/InputFile/InputFile';
import ConvertUtil from 'util/ConvertUtil';
import UploadService from 'services/upload/UploadService';
import Select from 'components/v3/Forms/Select/Select';
import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';
import * as S from './styles';

const EventInfo: React.FunctionComponent = (props) => {
  const params: { eventId: string } = useParams();
  const eventId: string = params?.eventId || '';

  const { isLoading, mutateAsync } = useUpdateEvent({ eventId });

  const logoImgRef = React.useRef<HTMLInputElement>(null);
  const formRef = React.useRef<HTMLFormElement>(null);
  const headerImgRef = React.useRef<HTMLInputElement>(null);

  const { eventData, saving } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );

  const imageUpdatedRef = React.useRef({
    logo: false,
    header: false,
  });

  const [isUploading, setIsUploading] = React.useState<boolean>(false);

  const handleChangeLogo = () => {
    imageUpdatedRef.current.logo = true;
  };

  const handleChangeHeader = () => {
    imageUpdatedRef.current.header = true;
  };

  const eventInfoData = React.useMemo(() => {
    return {
      name: eventData?.name ?? '',
      endDate: eventData?.endDate ?? new Date(),
      startDate: eventData?.startDate ?? new Date(),
      description: eventData?.description ?? '',
      logo: eventData?.logo ?? '',
      data: {
        eventInfo: {
          poolName: eventData?.data?.eventInfo?.poolName ?? '',
          teamIdentifier: eventData?.data?.eventInfo?.teamIdentifier ?? '',
          header: eventData.data?.eventInfo?.header ?? '',
          emailContact: eventData.data?.eventInfo?.emailContact ?? '',
          phoneContact: eventData.data?.eventInfo?.phoneContact ?? '',
        },
      },
    };
  }, [eventData]);

  const handleSave = async () => {
    try {
      if (formRef.current) {
        const formData = new FormData(formRef.current);
        // get current image value
        let {
          logo,
          data: {
            eventInfo: { header },
          },
        } = eventInfoData;
        // if image was updated request the new image
        if (imageUpdatedRef.current.logo) {
          if (logoImgRef.current !== null && logoImgRef.current.files?.length) {
            logo = await handleImageUpload(logoImgRef.current.files);
          } else {
            // remove image if no image selected
            logo = '';
          }
        }

        if (imageUpdatedRef.current.header) {
          if (
            headerImgRef.current !== null &&
            headerImgRef.current.files?.length
          ) {
            header = await handleImageUpload(headerImgRef.current.files);
          } else {
            header = '';
          }
        }

        const payload = {
          name: formData.get('name') as string,
          startDate: formData.get('startDate') as any,
          endDate: formData.get('endDate') as any,
          description: formData.get('description') as string,
          logo,
          data: {
            eventInfo: {
              poolName: (formData.get('poolName') as string) ?? '',
              teamIdentifier: (formData.get('teamIdentifier') as string) ?? '',
              header,
              emailContact: (formData.get('emailContact') as string) ?? '',
              phoneContact: (formData.get('phoneContact') as string) ?? '',
            },
          },
        };

        await mutateAsync({ id: eventId, data: payload });
      }
    } catch (e) {
      console.log('Error updating event', e);
    }
  };

  const handleImageUpload = async (files: FileList) => {
    let imageId = '';
    if (files && files.length > 0) {
      const file = files[0];
      setIsUploading(true);
      try {
        await Promise.all([
          UploadService.upload(
            {
              file,
              prefix: 'event',
              public: true,
            },
            true
          ).then((response) => {
            if (response.success) {
              imageId = response.data.id;
            }
          }),
        ]);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('error uploading file', e);
      }
    }
    setIsUploading(false);

    return imageId;
  };

  return (
    <S.EventInfoWrapper>
      <S.EventInfoFormWrapper>
        <S.EventInfoForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
          <BodyLBold $color='white'>General info</BodyLBold>

          <fieldset>
            <InputText
              id='name'
              label='Event Name'
              placeholder='Event Name'
              defaultValue={eventData?.name ?? ''}
              key={`name_${String(eventInfoData.name)}`}
              className='general-event-input'
            />
          </fieldset>

          <S.EventInfoFieldset>
            <InputDate
              id='startDate'
              placeholder='Start Date'
              key={`startDate_${String(eventInfoData.startDate)}`}
              defaultValue={
                eventInfoData?.startDate
                  ? new Date(eventInfoData.startDate as Date)
                      .toISOString()
                      .split('T')[0]
                  : new Date().toISOString().split('T')[0]
              }
              className='general-event-input-date'
            />
            <InputDate
              id='endDate'
              placeholder='End Date'
              key={`endDate_${String(eventInfoData.endDate)}`}
              defaultValue={
                eventInfoData?.endDate
                  ? new Date(eventInfoData.endDate as Date)
                      .toISOString()
                      .split('T')[0]
                  : new Date().toISOString().split('T')[0]
              }
              className='general-event-input-date'
            />
          </S.EventInfoFieldset>

          <fieldset>
            <InputText
              id='description'
              label='Description'
              placeholder='Add a short description for your event'
              key={`description_${String(eventInfoData.description)}`}
              defaultValue={eventInfoData.description}
            />
          </fieldset>

          <fieldset>
            <Select
              placeholder='Pool Name Format'
              name='poolName'
              key={`poolName_${String(eventInfoData.data.eventInfo.poolName)}`}
              defaultValue={
                eventInfoData.data.eventInfo.poolName
                  ? {
                      value: eventInfoData.data.eventInfo.poolName,
                      label: eventInfoData.data.eventInfo.poolName,
                    }
                  : null
              }
              options={[
                { value: 'Letters (A, B, C…)', label: 'Letters (A, B, C…)' },
                { value: 'Numbers (1, 2, 3…)', label: 'Numbers (1, 2, 3…)' },
              ]}
            />
          </fieldset>

          <fieldset>
            <Select
              placeholder='Team Identifier Format'
              name='teamIdentifier'
              key={`teamIdentifier_${String(
                eventInfoData.data.eventInfo.teamIdentifier
              )}`}
              defaultValue={
                eventInfoData.data.eventInfo.teamIdentifier
                  ? {
                      value: eventInfoData.data.eventInfo.teamIdentifier,
                      label: eventInfoData.data.eventInfo.teamIdentifier,
                    }
                  : null
              }
              options={[
                { value: 'Letters (A, B, C…)', label: 'Letters (A, B, C…)' },
                { value: 'Numbers (1, 2, 3…)', label: 'Numbers (1, 2, 3…)' },
              ]}
            />
          </fieldset>

          <Divider style={{ margin: 0 }} />

          <>
            <BodyLBold $color='white'>Contact Info</BodyLBold>
            <fieldset>
              <InputText
                id='emailContact'
                label='Email'
                placeholder='Email'
                defaultValue={eventData?.data?.eventInfo?.emailContact ?? ''}
                key={`name_${String(
                  eventInfoData.data.eventInfo.emailContact
                )}`}
                className='general-event-input'
              />
            </fieldset>
            <fieldset>
              <InputPhone
                id='phoneContact'
                label='Phone Number'
                placeholder='Phone Number'
                defaultValue={eventData?.data?.eventInfo?.phoneContact ?? ''}
                key={`name_${String(
                  eventInfoData.data.eventInfo.phoneContact
                )}`}
                className='general-event-input'
              />
            </fieldset>
          </>

          <Divider style={{ margin: 0 }} />

          <BodyLBold $color='white'>Events Visual identify</BodyLBold>

          <BodyM className='field-description' $color='white'>
            Upload a Logo for your event. The logo must be{' '}
            <span>512px by 512px</span>
          </BodyM>

          <fieldset>
            <S.EventInfoSummaryHeader>
              <InputFile
                format='circle'
                key={`logoImg_${String(eventInfoData.logo)}`}
                ref={logoImgRef}
                previewUrl={
                  eventInfoData?.logo !== ''
                    ? ConvertUtil.getMinioUrl(eventInfoData.logo)
                    : undefined
                }
                id='logoImg'
                text='Add Logo'
                onChange={handleChangeLogo}
              />
            </S.EventInfoSummaryHeader>
          </fieldset>

          <BodyM className='field-description' $color='white'>
            Upload a Header for your event. The header will show up on your
            event page. It must be <span>1440px by 250px</span>
          </BodyM>

          <fieldset>
            <S.EventInfoSummaryHeader>
              <InputFile
                format='square'
                ref={headerImgRef}
                key={`header_${String(eventInfoData?.data?.eventInfo?.header)}`}
                previewUrl={
                  eventInfoData?.data?.eventInfo?.header
                    ? ConvertUtil.getMinioUrl(
                        eventInfoData.data.eventInfo.header
                      )
                    : undefined
                }
                id='headerImg'
                text='Add Header'
                onChange={handleChangeHeader}
              />
            </S.EventInfoSummaryHeader>
          </fieldset>

          <FilledButton
            isUpper
            color='primary'
            onClick={handleSave}
            isLoading={isLoading || isUploading}
          >
            Save {isUploading && '...'}
          </FilledButton>
        </S.EventInfoForm>
      </S.EventInfoFormWrapper>
    </S.EventInfoWrapper>
  );
};

export default withRouter(EventInfo);
