import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { AllEventInvites } from 'services/v3/Event/types';
import { formatUserName } from 'util/formatUserName';
import { AvatarWithText } from '../AvatarWithText/AvatarWithText';
import { SimpleTag } from '../../../SimpleTag/SimpleTag';
import { ActionMenu } from './components';

export const columns: ColumnsType<AllEventInvites> = [
  {
    title: 'Club',
    dataIndex: ['club', 'name'],
    onFilter: (value, record) => {
      const clubName = record.club?.name;
      return clubName?.includes(value.toString().toLowerCase()) || false;
    },
    render(value, record) {
      return <AvatarWithText imgSrc={record.club?.logo} text={value} />;
    },
  },
  {
    title: 'Club Director',
    dataIndex: ['director', 'firstName'],
    onFilter: (value, record) => {
      const directorName = formatUserName({ ...record.director }).toLowerCase();
      return directorName.includes(value.toString().toLowerCase());
    },
    render(value, record) {
      return <AvatarWithText text={value} imgSrc={record.director?.photo} />;
    },
  },
  {
    title: 'Status',
    dataIndex: ['invite', 'status'],
    render(value) {
      let type = 'info';

      if (value === 'DENIED') type = 'error';
      if (value === 'ACCEPTED') type = 'success';

      return <SimpleTag text={value} type={type as any} />;
    },
  },
  {
    title: '',
    render() {
      return <ActionMenu />;
    },
  },
];
