import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  display: flex;
  padding: 1.25rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  border-radius: 1rem;
  border: 1px solid;
  background: ${COLORS.grey900};
`;

export const ContainerInformationDivision = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const AvatarEventInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${toRem(8)};
`;

export const EventInformation = styled.div`
  display: flex;
  align-items: center;
  gap: ${toRem(8)};
`;

export const RosterTitle = styled.div`
  color: ${COLORS.white};
  font-family: UF Display;
  font-size: 1.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 105%;
`;

export const CalendarInformation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
`;

export const DateInfo = styled.div`
  gap: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  font-family: Boxed;
  font-size: 1rem;
  font-style: normal;
  color: ${COLORS.grey100};
  font-weight: 500;
  line-height: 120%;
`;

export const DateInline = styled.div`
  display: inline-flex;
`;

export const DateTextBold = styled.div`
  color: ${COLORS.grey400};
  font-weight: 700;
  line-height: 120%;
  padding-right: ${toRem(8)};
`;

export const EventImage = styled.div`
  width: ${toRem(24)};
  height: ${toRem(24)};
  border-radius: ${toRem(24)};

  margin: 0 auto;
  overflow: hidden;

  background-color: ${COLORS.brandPrimary};

  img {
    object-fit: cover;
    max-width: 100%;

    width: ${toRem(24)};
    height: ${toRem(24)};
  }
`;
