import * as React from 'react';
import { CloseOne, Search } from '@icon-park/react';
import Icon, { IconType } from '@icon-park/react/es/all';

import { BodySBold } from 'styles/v3/variables';
import * as S from './styles';

export interface InputTextProps {
  id: string;
  placeholder?: string;
  value?: string | number;
  defaultValue?: string | number;
  label?: string;
  type?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  containerClassName?: string;
  min?: number;
  max?: number;
  style?: Record<string, unknown>;
  required?: boolean;
  pattern?: string;
  icon?: IconType;
  maxLength?: number;
  isSearchable?: boolean;
  onClearInput?: (field: any) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputText: React.FC<InputTextProps> = ({
  id,
  label,
  type = 'text',
  error = false,
  errorMessage,
  disabled = false,
  className,
  containerClassName,
  required,
  icon,
  isSearchable,
  onClearInput,
  ...props
}) => {
  const [focus, setFocus] = React.useState<boolean>(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onInputFocus = () => setFocus(!focus);
  const onInputBlur = () => setFocus(false);
  const onInputClear = () => {
    inputRef.current!.value = '';
    if (onClearInput) onClearInput(id);
  };

  return (
    <S.ContainerWrapper className={containerClassName}>
      {error && errorMessage && (
        <BodySBold $color='supportError'>{errorMessage}</BodySBold>
      )}
      <S.Container
        $error={error}
        $focus={focus}
        $disabled={disabled}
        $isSerchable={isSearchable}
        className={className}
      >
        <S.Label
          htmlFor={id}
          $required={!!required && !props.defaultValue && !props.value}
        >
          {label}
        </S.Label>
        {isSearchable && <Search size={24} />}
        <S.Input
          {...props}
          ref={inputRef}
          $error={error}
          $clearable={!!onClearInput}
          $hasIcon={!!icon}
          type={type}
          name={id}
          disabled={disabled}
          required={!!required}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
        />
        {onClearInput && (
          <S.ClearText $error={error}>
            <CloseOne size={24} onClick={onInputClear} />
          </S.ClearText>
        )}
        {icon && <Icon size={24} type={icon} />}
      </S.Container>
    </S.ContainerWrapper>
  );
};

export default InputText;
