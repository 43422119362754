import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ClubView } from '../../../admin/models/club/Club';
import LoadingIcon from '../../../components/base/LoadingIcon/LoadingIcon';
import ClubCardRequest from '../../../components/ClubCard/ClubCardRequest';
import RequestModel from '../../../models/Request/RequestModel';
import ClubService from '../../../services/Club/ClubService';

const MyClubPage: React.FunctionComponent = () => {
  const [clubs, setClubs] = useState<ClubView[]>([]);
  const [clubRequest, setClubRequest] = useState<RequestModel[]>([]);
  const [filterQuery, setFilterQuery] = useState('');
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    ClubService.list(
      new URLSearchParams({
        size: '1000',
      })
    ).then((response) => {
      if (response.success) {
        setClubs(response.data.content);
      }
      setLoadingData(false);
    });
    ClubService.getRequests().then((response) => {
      if (response.success) {
        setClubRequest(response.data.content);
      }
    });
  }, []);

  return (
    <div style={{ padding: ' 20px 50px' }}>
      <h1 style={{ textAlign: 'center' }}>AVAILABLE CLUBS</h1>
      {loadingData && <LoadingIcon />}
      {!loadingData && (
        <>
          <div
            className='form-group'
            style={{
              maxWidth: '400px',
              margin: '20px auto',
              display: 'inherit',
            }}
          >
            <span className='icon icon-search' />
            <input
              name='query'
              value={filterQuery}
              onChange={(val) => setFilterQuery(val.target.value)}
              type='text'
              className='icon'
              placeholder='Find a Club'
            />
          </div>
          <Wrapper>
            {clubs
              .filter((club) =>
                club.name
                  ?.toLocaleLowerCase()
                  .includes(filterQuery.toLocaleLowerCase())
              )
              .map((club) => {
                return (
                  <ClubCardRequest
                    key={club.id}
                    club={club}
                    teamIcon='/assets/imgs/Vault_logo_white.svg'
                    statusRequest={
                      clubRequest.find((e) => e.club.id === club.id)?.status
                    }
                  />
                );
              })}
          </Wrapper>
          {!clubs.filter((club) =>
            club.name
              ?.toLocaleLowerCase()
              .includes(filterQuery.toLocaleLowerCase())
          ).length && <div>No Clubs found</div>}
        </>
      )}
    </div>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 320px);
  grid-gap: 10px;
  justify-content: space-around;
  @media (max-width: 1100px) {
    grid-template-columns: repeat(auto-fill, 250px);
  }
`;

export default MyClubPage;
