import React from 'react';
import { useGetEventById } from 'hooks/v3/event/useGetEventById/useGetEventById';
import { useParams } from 'react-router-dom';
import { Header } from './Header/Header';
import { Tabs, TabsProps } from './Tabs/Tabs';
import * as S from './styles';
import Loading from '../Loading/Loading';

type EventManagerDashboardProps = {
  id?: string;
  title?: string;
} & TabsProps;

export const EventManagerDashboard = ({
  id,
  tabs,
  title,
}: EventManagerDashboardProps) => {
  const params: { eventId: string } = useParams();
  const { data: currentEvent } = useGetEventById(params.eventId);
  if (!currentEvent) return <Loading />;

  return (
    <S.Container>
      <Header id={currentEvent.id} title={title || currentEvent.name} />
      <Tabs tabs={tabs} />
    </S.Container>
  );
};
