import React from 'react';
import { Dropdown, Menu } from 'antd';
import { BodyM } from 'styles/v3/variables';
import { More } from '@icon-park/react';
import { notification } from 'components/v3/Notification/notification';

export const ActionMenu = () => {
  const handleReSendInvite = () => {
    notification.success({
      message: 'Your invitations were sent',
      description: 'Now make sure to follow up with the invites.',
    });
  };

  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item onClick={handleReSendInvite}>
            <BodyM $color='grey200'>Re-send invite</BodyM>
          </Menu.Item>
        </Menu>
      }
    >
      <More size={32} />
    </Dropdown>
  );
};
