import { useQuery } from '@tanstack/react-query';
import ClubService from 'services/v3/Clubs/ClubService';

type UseGetClubMembersByIdProps = {
  id?: string;
  size?: number;
  page?: number;
};

export const useGetClubMembersById = ({
  id,
  page,
  size,
}: UseGetClubMembersByIdProps) => {
  const response = useQuery(['clubs-members', id, page], () =>
    ClubService.getClubsMembersById(id, size, page)
  );

  return {
    ...response,
    data: response.data?.data,
  };
};
