import { User } from 'models/User/UserModel';
import DateUtil from 'util/DateUtil';
import { formatUserName } from 'util/formatUserName';

type UseFilterRefereesProps = {
  referees?: User[];
  refereeName: string;
  selectedAge: number;
  selectedCity: string;
};

export const useFilterReferees = ({
  referees,
  refereeName,
  selectedAge,
  selectedCity,
}: UseFilterRefereesProps) => {
  return referees
    ?.filter(({ firstName, middleName, lastName }) =>
      formatUserName({ firstName, middleName, lastName })
        .toLowerCase()
        .includes(refereeName.toLowerCase())
    )
    .filter(({ address }) =>
      selectedCity
        ? address?.city.toLowerCase().includes(selectedCity.toLowerCase())
        : true
    )
    .filter(({ birthDate }) =>
      selectedAge
        ? DateUtil.getUserAge(birthDate as string) === selectedAge
        : true
    );
};
