import { loadStripe, RedirectToCheckoutOptions } from '@stripe/stripe-js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import sadCloud from '../../assets/imgs/icons/sad_cloud.svg';
import loadingGif from '../../assets/imgs/tail-spin.svg';
import { newUserReset } from '../../redux/newUser/actions';
import { ApplicationState } from '../../redux/store';
import PaymentServices from '../../services/Payment/PaymentServices';
import UserServices from '../../services/User/UserApi';
import TokenUtil from '../../util/TokenUtil';
import { getUserId } from '../../util/UsersUtil';

const MembershipUpgradePayment: React.FunctionComponent = () => {
  const [loadingMessage, setLoadingMessage] = useState('');
  const [error, setError] = useState(false);
  const { newUser } = useSelector(
    (state: ApplicationState) => state.newUser
  ).data;
  const history = useHistory();
  const dispatch = useDispatch();
  const routeParams: { userId?: string } = useParams();

  function redirectStripe(sessionId: RedirectToCheckoutOptions) {
    PaymentServices.getPaymentKey().then(async (res) => {
      const stripe = await loadStripe(res.data.publicKey);
      stripe?.redirectToCheckout(sessionId);
    });
  }

  useEffect(() => {
    const accountId = routeParams.userId || getUserId();
    if (newUser?.type !== undefined && accountId) {
      const { membershipId } = newUser;
      UserServices.updateExtraInfo(newUser, accountId).then((res) => {
        // Clear preferences
        localStorage.removeItem('vault_preferences');

        // Refresh Token to Update UserList
        TokenUtil.refreshToken().then(() => {
          // If is not a free account
          if (newUser.membershipPrice !== 0 && membershipId) {
            setLoadingMessage('Redirecting to payment');
            PaymentServices.fetchCheckoutSession(membershipId, accountId).then(
              (response) => {
                redirectStripe(response.data);
              }
            );
          } else {
            history.push('/');
          }
        });
        dispatch(newUserReset());
      });
    } else {
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '50px',
        paddingTop: '100px',
      }}
    >
      {!error && (
        <>
          <div style={{ marginRight: '30px' }}>
            <img src={loadingGif} alt='' />
          </div>
          <h3>{loadingMessage}...</h3>
        </>
      )}

      {error && (
        <>
          <div style={{ marginRight: '30px' }}>
            <img src={sadCloud} alt='' />
          </div>
          <div>
            There was an error during your user creation.
            <br />
            Click <Link to='/wizard'>here</Link> and try again. If the problem
            persists, try to contact us.
          </div>
        </>
      )}
    </div>
  );
};

export default MembershipUpgradePayment;
