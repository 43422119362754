import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: ${toRem(16)};
  color: ${COLORS.grey500};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${toRem(10)} ${toRem(30)} ${toRem(24)} ${toRem(30)};
`;

export const FooterTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: ${toRem(10)};
`;
