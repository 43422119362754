import { useQuery } from '@tanstack/react-query';
import CouponService from 'services/v3/Coupons/CouponServices';

export function useGetUsedCodesForAccount(accountId: string) {
  const { data, isLoading, error } = useQuery(
    ['get-used-codes-for-account', accountId],
    async () => await CouponService.getUsedCodesForAccount(accountId),
    {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );

  return {
    ...data,
    data: data?.data ?? [],
    isLoading,
    error,
  };
}
