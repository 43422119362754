import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${toRem(16)};

  padding: ${toRem(16)};
  border-radius: ${toRem(24)};
  background-color: ${COLORS.grey900};
`;

export const Flex = styled.div`
  display: flex;
`;

export const BasicInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddressWrapper = styled.div`
  display: flex;
`;

export const InfoWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
