import * as React from 'react';
import styled from 'styled-components';
import { ArrowRight } from '@icon-park/react';

import OutlinedButton, { OutlinedButtonProps } from './OutlinedButton';

const OutlinedButtonWithIcon: React.FC<
  OutlinedButtonProps & { customIcon?: React.ReactNode }
> = ({ customIcon, children, ...props }) => {
  return (
    <OutlinedButtonIcon {...props}>
      {children}
      {customIcon || <ArrowRight />}
    </OutlinedButtonIcon>
  );
};

const OutlinedButtonIcon = styled(OutlinedButton)`
  span {
    display: flex;
    font-size: 1.5rem;
    margin-left: 0.5rem;
    align-items: center;
  }
`;

export default OutlinedButtonWithIcon;
