import * as React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { message as MessageSnackbar } from 'antd';
import { useDispatch } from 'react-redux';

import { BodyXL, TitleH1 } from 'styles/v3/variables';

import InputText from 'components/v3/Forms/InputText/InputText';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import InputDate from 'components/v3/Forms/InputDate/InputDate';
import InputPhone from 'components/v3/Forms/InputPhone/InputPhone';
import FilledButton from 'components/v3/Buttons/FilledButton';

import BaseUserService from 'services/v3/BaseUsers/BaseUsersApi';
import UserServices from 'services/v3/User/UserService';

import { UserRelationship, BaseUser } from 'redux/v3/baseUsers/types';
import { baseUserSetUser, baseUserSetUserId } from 'redux/v3/baseUsers/actions';

import { Gender } from 'admin/models/Enums';
import { setCurrentUser } from 'redux/v3/currentUser/actions';
import { usersFetchSuccess } from 'redux/v3/users/actions';
import * as S from './styles';

const relationshipOptions: OptionsType[] = [
  {
    label: 'Son / Daughter',
    value: 'SON',
  },
  {
    label: 'Nephew / Niece',
    value: 'NEPHEW',
  },
  {
    label: 'Club Participant',
    value: 'CLUB',
  },
  {
    label: 'Team Participant',
    value: 'TEAM',
  },
];
const genderOptions: OptionsType[] = [
  { value: 'MALE', label: 'Male' },
  { value: 'FEMALE', label: 'Female' },
];

type Fields = {
  birthDate: { value: string; error: boolean };
  firstName: { value: string; error: boolean };
  gender: { value: string; error: boolean };
  lastName: { value: string; error: boolean };
  phoneNumber: { value: string; error: boolean };
  relationship: { value: string; error: boolean };
};

const NewUser: React.FC = () => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const [fields, setFields] = React.useState<Fields>({
    birthDate: { value: '', error: false },
    firstName: { value: '', error: false },
    gender: { value: '', error: false },
    lastName: { value: '', error: false },
    phoneNumber: { value: '', error: false },
    relationship: { value: '', error: false },
  });
  const [validating, setValidating] = React.useState<boolean>(false);

  const handleInputClear = (field: keyof Fields) => {
    setFields({
      ...fields,
      [field]: { value: '', error: fields[field].error },
    });
    setValidating(false);
  };
  const handleChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFields({
      ...fields,
      [e.target.name]: { value: e.target.value, error: false },
    });
    setValidating(false);
  };
  const handleChangeSelect = (field: string, e: any) => {
    setFields({ ...fields, [field]: { value: e.value, error: false } });
    setValidating(false);
  };

  const validate = () => {
    setValidating(true);
    for (const [key, values] of Object.entries(fields)) {
      if (values.value === '') {
        setFields((newFields) => ({
          ...newFields,
          [key]: { value: values.value, error: true },
        }));
      }
    }
  };

  console.log('new user 2')

  const saveUser = async () => {
    try {
      if (formRef.current) {
        const formData: FormData = new FormData(formRef.current);

        const payload: BaseUser = {
          id: '',
          clubs: [],
          birthDate: formData.get('birthDate') as string,
          firstName: formData.get('firstName') as string,
          gender: String(formData.get('gender')) as Gender,
          lastName: formData.get('lastName') as string,
          phoneNumber: formData.get('phoneNumber') as string,
          relationship: String(
            formData.get('relationship')
          ) as UserRelationship,
        };

        const { data } = await BaseUserService.saveUser(payload);

        if (data.message) {
          throw new Error(data.message);
        } else {
          dispatch(baseUserSetUser(data));
          if (data.id) dispatch(baseUserSetUserId(data.id));

          console.log('setting new current user', data?.id)

          const { data: updatedProfile } = await UserServices.getProfiles()

          const updatedBaseUser = updatedProfile?.baseUsers?.find(u => u.id === data.id)
         
          console.log('setting new current user', updatedBaseUser)

          if (updatedBaseUser) {
            dispatch(setCurrentUser(updatedBaseUser));
            const newUsers = [...(updatedProfile.baseUsers || []), ...(updatedProfile.users || [])];
            dispatch(usersFetchSuccess(newUsers));
          }



          history.push('/v3/new-user/created');
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        MessageSnackbar.error({ content: error.message, duration: 5 });
      }
    }
  };

  React.useEffect(() => {
    if (validating) {
      const getFields = Object.values(fields);
      const hasError = getFields.find((i) => i.error);

      if (!hasError) saveUser();
    }
  }, [validating, fields]);

  return (
    <S.NewUserWrapper>
      <TitleH1>New User</TitleH1>

      <S.NewUserForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
        <fieldset>
          <legend>
            <BodyXL $color='brandPrimary' $isUpper>
              User Info
            </BodyXL>
          </legend>

          <Select
            name='relationship'
            placeholder='What is your connection with this user?'
            options={relationshipOptions}
            onChange={(e) => handleChangeSelect('relationship', e)}
            required
            error={fields.relationship.error}
            className='new-user-input'
          />
          <InputText
            id='firstName'
            placeholder='First Name'
            label='First Name'
            defaultValue={fields.firstName.value}
            required
            onClearInput={(id) => handleInputClear(id)}
            error={fields.firstName.error}
            onChange={handleChangeInputValue}
            className='new-user-input'
          />
          <InputText
            id='lastName'
            placeholder='Last Name'
            label='Last Name'
            defaultValue={fields.lastName.value}
            required
            onClearInput={(id) => handleInputClear(id)}
            error={fields.lastName.error}
            onChange={handleChangeInputValue}
            className='new-user-input'
          />
          <InputPhone
            id='phoneNumber'
            placeholder='Telephone'
            label='Telephone'
            defaultValue={fields.phoneNumber.value}
            onChange={handleChangeInputValue}
            error={fields.phoneNumber.error}
            required
            className='new-user-input'
          />
        </fieldset>

        <S.FormFieldset>
          <InputDate
            id='birthDate'
            placeholder='Birthdate'
            label='Birthdate'
            defaultValue={fields.birthDate.value}
            error={fields.birthDate.error}
            onChange={handleChangeInputValue}
            required
          />
          <Select
            name='gender'
            placeholder='Gender'
            options={genderOptions}
            required
            error={fields.gender.error}
            onChange={(e) => handleChangeSelect('gender', e)}
          />
        </S.FormFieldset>

        <FilledButton isUpper onClick={validate}>
          Create User
        </FilledButton>
      </S.NewUserForm>
    </S.NewUserWrapper>
  );
};

export default withRouter(NewUser);
