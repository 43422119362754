import { useQuery } from '@tanstack/react-query';
import UserService from 'services/v3/User/UserService';

export function useGetBaseUser(userId: string) {
  const { data, isLoading, error } = useQuery(
    ['/get-user', userId],
    async () => await UserService.getBaseUserInfo(userId)
  );

  const user = data?.data;
  return {
    ...data,
    data: user,
    isLoading,
    error,
  };
}
