import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

interface HeaderContainer {
  image?: string;
}

export const HeaderContainer = styled.div<HeaderContainer>`
  width: 100%;
  height: 150px;

  display: flex;
  flex-direction: column;

  padding: 30px;
  border-radius: 0px 0px 24px 24px;

  background-size: cover;
  background-image: ${({ image }) => `url(${image})`};
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  button {
    color: ${COLORS.grey50};
    border: 0px 0px 4px 0px;
    border-color: ${COLORS.grey700};
    background-color: ${COLORS.grey700};

    &:hover {
      color: ${COLORS.grey50};
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 16px;

  p {
    font-weight: 500;
    border-radius: 7px;
    margin: 0 !important;
    background-color: unset;
    color: ${COLORS.brandPrimary};
  }
`;
