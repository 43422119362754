import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { TitleH4 } from 'styles/v3/variables';

import Logo from 'components/v3/Elements/Logo/Logo';
import { truncateText } from 'util/truncateText';

import { User } from 'models/User/UserModel';
import { setCurrentUser } from 'redux/v3/currentUser/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import ConvertUtil from 'util/ConvertUtil';
import { formatUserName } from 'util/formatUserName';
import * as S from './styles';

export const UserCard = (user: User) => {
  const dispatch = useDispatch();

  const currentUserId = useSelector(
    (state: ApplicationState) => state.currentUser.data.id
  );

  const isSelected = currentUserId === user?.id;

  const fullName = formatUserName({
    lastName: user.lastName,
    firstName: user.firstName,
    middleName: user.middleName,
  });

  const handleClick = () => {
    dispatch(setCurrentUser(user));
  };

  return (
    <S.CardWrapper onClick={handleClick} isSelected={isSelected}>
      <S.FlexDiv>
        <S.UserAvatar
          size={150}
          alt={fullName}
          icon={<UserOutlined />}
          src={ConvertUtil.getMinioUrl(user.photo)}
        />
      </S.FlexDiv>
      <S.TitleWrapper>
        <S.FlexDiv>
          <Logo minimal color='grey950' />
        </S.FlexDiv>
        <TitleH4>{truncateText(fullName, 28)}</TitleH4>
      </S.TitleWrapper>
    </S.CardWrapper>
  );
};
