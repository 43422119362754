import { User } from 'models/User/UserModel';
import { DeepPartial } from 'util/types';

export enum CurrentUserActionTypes {
  SET_CURRENT_USER = '@@currentUser/SET_CURRENT_USER',
}

export interface CurrentUserState {
  readonly data: DeepPartial<User>;
}
