import * as React from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { differenceBy } from 'lodash';

import { ApplicationState } from 'redux/store';

import { PatchEventProps } from 'services/v3/B2bEvent/types';

import { Tiebreakers } from 'admin/models/event/Event';
import { DeepPartial } from 'util/types';
import B2bTiebreakersService from 'services/v3/B2bEvent/B2bTiebreakersService';

import DraggableCard from 'components/v3/Cards/DraggableCard/DraggableCard';
import FilledButton from 'components/v3/Buttons/FilledButton';

import { BodyLBold, BodyM } from 'styles/v3/variables';
import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';
import { Spinner } from 'components/v3/Spinner/Spinner';
import * as S from './styles';
import reorderList from './reorderList';

const TiebreakersPage: React.FC = () => {
  const { eventData } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );
  const params: { eventId: string } = useParams();
  const eventId: string = params?.eventId || '';

  const { isLoading, mutateAsync } = useUpdateEvent({ eventId });

  const tieBreakers: DeepPartial<Tiebreakers[]> | undefined =
    eventData.data?.tieBreakers;

  const [activeItems, setActiveItems] = React.useState<
    DeepPartial<Tiebreakers[]>
  >([]);
  const [inactiveItems, setInactiveItems] = React.useState<Tiebreakers[]>([]);
  const [dragged, setDragged] = React.useState<number | null>(null);
  const [mouse, setMouse] = React.useState<[number, number]>([0, 0]);
  const [dropZone, setDropZone] = React.useState<number>(0);

  React.useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (dragged !== null) {
        e.preventDefault();
        setDragged(null);

        setActiveItems((prev) => reorderList([...prev], dragged, dropZone));
      }
    };

    document.addEventListener('mouseup', handler);
    return () => document.removeEventListener('mouseup', handler);
  });

  React.useEffect(() => {
    const handler = (e: MouseEvent) => setMouse([e.x, e.y]);
    document.addEventListener('mousemove', handler);

    return () => document.removeEventListener('mousemove', handler);
  }, []);

  React.useEffect(() => {
    (async () => {
      const { data } = await B2bTiebreakersService.list();
      if (tieBreakers && tieBreakers !== data) {
        const filterInactiveTieBreakers = differenceBy(data, tieBreakers, 'id');
        setActiveItems(tieBreakers);
        setInactiveItems(filterInactiveTieBreakers);
      } else {
        setActiveItems(data);
      }
    })();
  }, [eventData]);

  React.useEffect(() => {
    if (dragged !== null) {
      const elements = Array.from(document.getElementsByClassName('drop-zone'));
      const positions = elements.map((e) => e.getBoundingClientRect().top);
      const absDifferences = positions.map((v) => Math.abs(v - mouse[1]));

      let result = absDifferences.indexOf(Math.min(...absDifferences));

      if (result > dragged) result += 1;

      setDropZone(result);
    }
  }, [dragged, mouse]);

  const handleMouseDown = (e: any, index: number) => {
    e.preventDefault();
    setDragged(index);
    setDropZone(index);
  };

  const handleMouseUp = (e: any) => {
    e.preventDefault();
    if (dragged !== null) {
      setDragged(null);
    }
  };

  const handleDelete = (v: any) => {
    const clearListActiveItems = activeItems.filter((i) => i !== v);
    setActiveItems(clearListActiveItems);
    setInactiveItems([...inactiveItems, v]);
  };

  const handleAdd = (v: any) => {
    const clearListInactiveItems = inactiveItems.filter((i) => i !== v);
    setActiveItems([...activeItems, v]);
    setInactiveItems(clearListInactiveItems);
  };

  const handleSave = async () => {
    const payload: DeepPartial<PatchEventProps> = {
      data: {
        tieBreakers: activeItems,
      },
    };

    await mutateAsync({ id: eventId, data: payload });
  };

  return activeItems.length === 0 ? (
    <S.SpinnerWrapper>
      <Spinner />
    </S.SpinnerWrapper>
  ) : (
    <S.TiebreakersWrapper>
      <S.TiebreakersContainer>
        <BodyLBold className='card-title'>Active Tiebreakers</BodyLBold>
        <BodyM>
          Rearrange the order of importance for the tiebreaker in your event.
        </BodyM>
        {dragged !== null && (
          <DraggableCard
            active
            size='small'
            orientation='row'
            className='floating'
            title={dragged ? activeItems[dragged]?.name : activeItems[0]?.name}
            style={{
              left: `${mouse[0]}px`,
              top: `${mouse[1]}px`,
            }}
          />
        )}
        <S.List>
          <DraggableCard
            size='small'
            orientation='row'
            key='0-drop-zone-a'
            className={`drop-zone ${
              dragged === null || dropZone !== 0 ? 'hidden' : ''
            }`}
          />
          {activeItems.map((value, index) => (
            <>
              {dragged !== index && (
                <>
                  <DraggableCard
                    active
                    key={value?.id}
                    size='small'
                    title={value?.name}
                    orientation='row'
                    info='Card Information'
                    handleDelete={() => handleDelete(value)}
                    onMouseDown={(e) => handleMouseDown(e, index)}
                  />
                  <DraggableCard
                    size='small'
                    orientation='row'
                    key={`${value?.id}-drop-zone`}
                    className={`drop-zone ${
                      dragged === null || dropZone !== index + 1 ? 'hidden' : ''
                    }`}
                    onMouseUp={handleMouseUp}
                  />
                </>
              )}
            </>
          ))}
        </S.List>
      </S.TiebreakersContainer>

      <S.TiebreakersContainer>
        <BodyLBold className='card-title'>Inactive Tiebreakers</BodyLBold>
        {inactiveItems.map((value) => (
          <DraggableCard
            active={false}
            key={value.id}
            size='small'
            title={value.name}
            orientation='row'
            handleAdd={() => handleAdd(value)}
            info='Card Information'
          />
        ))}
      </S.TiebreakersContainer>

      <S.ButtonWrapper role='group' aria-labelledby='form-fieldset'>
        <FilledButton onClick={handleSave} isLoading={isLoading}>
          {isLoading ? '...' : 'Save'}
        </FilledButton>
      </S.ButtonWrapper>
    </S.TiebreakersWrapper>
  );
};

export default withRouter(TiebreakersPage);
