import { createGlobalStyle } from 'styled-components';
import { COLORS } from './variables';
import { toRem } from './functions';

export default createGlobalStyle`

  .ant-notification-notice {
    border-radius: ${toRem(16)};
    background-color: ${COLORS.grey900};
  }

  .ant-notification-notice-description {
    max-width: ${toRem(235)};
  }

  .ant-notification-close-x {
    padding: ${toRem(6)} ${toRem(8)};
    border-radius: ${toRem(8)};
    background-color: ${COLORS.grey800};
  }
  
  .ant-dropdown-menu {
    padding: ${toRem(4)};
    border-radius: ${toRem(12)};
    background-color: ${COLORS.grey950};

    border: 1px solid ${COLORS.grey800};
  }

  .ant-dropdown-menu-item-selected {
    color: ${COLORS.grey50};
    background-color: ${COLORS.grey900};
  }

  .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
    background-color: ${COLORS.grey900};
  }

  .ant-switch {
    &-checked {
      background-color: ${COLORS.brandPrimary};
    }
    &-handle {
      &::before {
        background-color: ${COLORS.grey900};
      }
    }
  }

  .ant-tabs-nav {
    ::before {
      display: none;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-nav-list {
    gap: 24px;
  }

  .ant-tabs-ink-bar {
    display: block !important;
    visibility: visible !important;
    background: ${COLORS.brandPrimary};
  }

  .ant-tabs-tab {
    padding: 0 !important;
    border: none !important;
    margin-bottom: 8px !important;
    background-color: unset !important;
  }

  .ant-tabs-tab:hover {
    color: ${COLORS.brandPrimary} !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${COLORS.brandPrimary} !important;
  }

  .ant-tabs-tab-btn:active {
    color: ${COLORS.brandPrimary};
  }

  .ant-pagination-item-link {
    border: none !important;
    font-size: 16px;
    color: ${COLORS.grey50};
  }

  .ant-pagination-next:hover .ant-pagination-item-link {
    color: ${COLORS.grey300};
  }

  .ant-pagination-prev:hover .ant-pagination-item-link {
    color: ${COLORS.grey300};
  }

  .ant-pagination-item {
    border: none;
    border-radius: 8px;
    color: ${COLORS.grey50};

    a {
      &:hover {
        color: ${COLORS.grey50};
      }
    }

    &:hover {
      border: none;
      background-color: ${COLORS.grey700};
    }
  }

  .ant-pagination-item-active {
    border: none;
    border-radius: 8px;
    background-color: ${COLORS.grey700};

    a {
      font-size: 13px;
      font-family: 'Boxed Regular';

      color: ${COLORS.grey50};

      &:hover {
        border: none;
        color: ${COLORS.grey50};
      }
    }
  }

  .ant-pagination-item-active:focus a {
    color: ${COLORS.grey50};
  }

  .ant-pagination-jump-next 
  .ant-pagination-item-container 
  .ant-pagination-item-link-icon {
    color: ${COLORS.grey100};
  }

  .ant-pagination-jump-prev 
  .ant-pagination-item-container 
  .ant-pagination-item-link-icon {
    color: ${COLORS.grey100};
  }

  .ant-drawer-content-wrapper {
    width: 500px !important;
  }

  .ant-drawer-header {
    border: none;
    background: ${COLORS.grey950};
  }

  .ant-drawer-body {
    background: ${COLORS.grey950};
  }

  .ant-drawer-footer {
    background: ${COLORS.grey950};
    border-top: 1px solid ${COLORS.grey800};
  }

  .ant-drawer-title {
    color: ${COLORS.brandPrimary};
  }

  .ant-drawer-close {
    padding: 8px;
    margin: 10px 16px;
    border-radius: 12px;
    background-color: ${COLORS.grey800};
  }
`;
