import React from 'react';

import { BodyLBold, BodyMBold, BodyS } from 'styles/v3/variables';
import * as S from './styles';

type MultiplierProps = {
  name?: string;
  maxDots?: number;
  multiplier?: number;
  description?: string;
};

export const Multiplier = ({
  name,
  multiplier,
  description,
}: MultiplierProps) => {
  const dots = new Array(0).fill(0);

  const isEnabled = multiplier ? multiplier >= 1 : false;

  return (
    <S.Container>
      <S.TextsWrapper>
        <BodyMBold>{name}</BodyMBold>
        <BodyS $color='grey400'>{description}</BodyS>
      </S.TextsWrapper>
      <S.MultiplierWrapper>
        <S.DotsWrapper>
          {dots.map((item) => (
            <S.Dots key={item} isEnabled={isEnabled} />
          ))}
        </S.DotsWrapper>
        <S.MultiplierNumber isEnabled={isEnabled}>
          <BodyLBold>+{multiplier}x</BodyLBold>
        </S.MultiplierNumber>
      </S.MultiplierWrapper>
    </S.Container>
  );
};
