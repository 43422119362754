import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

import Icon from 'assets/imgs/icons/select-check.svg';

export const SelectWrapper = styled.div<{
  $required?: boolean;
  $error?: boolean;
}>`
  .vault-select {
    &__control {
      font-family: 'Boxed Regular';
      background-color: transparent;
      border-radius: 12px;
      font-size: 1rem;
      border-color: ${(props) =>
        props.$error ? COLORS.supportError : COLORS.grey400};
      min-height: 50px;
      box-shadow: none;

      &:hover,
      &:focus {
        border-color: ${(props) =>
          props.$error ? COLORS.supportError : COLORS.brandPrimary};
        outline: none;
      }
    }
    &__placeholder {
      &::after {
        content: '*';
        color: ${COLORS.supportError};
        margin-left: ${toRem(5)};
        display: ${(props) => (props.$required ? 'inline-block' : 'none')};
      }
    }
    &--is-disabled {
      opacity: 0.5;
    }
    &__single-value,
    &__multiple-value,
    &__input-container {
      color: ${COLORS.white};
    }
    &__menu {
      font-family: 'Boxed Regular';
      font-size: 1rem;
      background-color: ${COLORS.grey950};
      border-radius: 12px;
      border: 1px solid ${COLORS.grey800};
    }
    &__option {
      display: flex;
      align-items: center;
      color: ${COLORS.grey200};
      background-color: transparent;
      &:before {
        content: '';
        display: block;
        margin-right: ${toRem(8)};
        width: 1rem;
        height: 1rem;
      }
      &:active {
        background-color: transparent;
      }
      &--is-focused {
        background-color: ${COLORS.grey900};
      }
      &--is-selected {
        color: ${COLORS.grey50};

        &:before {
          background: url(${Icon}) no-repeat center;
        }
      }
    }
  }
`;
