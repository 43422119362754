import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  width: 100%;

  img {
    border-radius: 24px 24px 0 0;
    object-fit: cover;

    width: 100%;
    height: 150px;
  }
`;

export const AvatarWrapper = styled.div`
  position: relative;
  top: -5.5rem;
  left: 1rem;

  width: 0;
  height: 0;
`;
