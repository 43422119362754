import React, { useEffect } from 'react';

import { BodySBold } from 'styles/v3/variables';
import CouponService from 'services/v3/Coupons/CouponServices';
import { useDispatch } from 'react-redux';
import { setSignupCoupon } from 'redux/v3/signupEvent/actions';
import { ClubCouponProps } from './types';
import * as S from './styles';

export const ClubCoupon = ({
  eventId,
  rosterFeeProductId,
}: ClubCouponProps) => {
  const [promoCode, setPromoCode] = React.useState('');
  const [isCouponValid, setIsCouponValid] = React.useState(true);
  const dispatch = useDispatch();
  const handleApplyCoupon = async () => {
    if (rosterFeeProductId && promoCode) {
      CouponService.validateEventRosterCoupon(
        promoCode,
        eventId,
        rosterFeeProductId
      ).then((res) => {
        if (res.httpCode === 404) {
          setIsCouponValid(false);
          dispatch(setSignupCoupon(undefined));
        }
        if (res.httpCode === 200) {
          setIsCouponValid(true);
          dispatch(setSignupCoupon(res.data));
        }
      });
    }
  };

  const handleSetCoupon = (code: string) => {
    setPromoCode(code);
    setIsCouponValid(true);
  };

  useEffect(() => {
    dispatch(setSignupCoupon(undefined));
  }, []);

  return (
    <S.Container>
      {!isCouponValid && (
        <BodySBold $color='supportError'>Invalid Coupon</BodySBold>
      )}
      <S.InputWrapper>
        <S.Input
          onChange={(e) => handleSetCoupon(e.target.value)}
          type='text'
          placeholder='Promo Code'
        />
        <BodySBold onClick={handleApplyCoupon} $color='grey400'>
          APPLY
        </BodySBold>
      </S.InputWrapper>
    </S.Container>
  );
};
