import { AxiosRequestConfig } from 'axios';
import { NewAgeDivision } from 'admin/models/AgeDivision';
import { CoachRosterItem, ParticipationStatus, PlayerRosterItem, Roster } from 'models/Roster/RosterModel';

import { ConfigApi } from 'services/config.api';
import APIServices from 'services/util/ApiServices';
import PageResponse from 'services/util/PageResponse';
import ServiceResponse from 'services/util/ServiceResponse';
import { EventData } from 'admin/models/event/Event';

export interface NewRoaster {
  id?: string;
  rosterId?: string;
  ageDivision?: NewAgeDivision;
  eventParticipationStatus?: ParticipationStatus;
  creationDate: Date;
  submittedDate: Date;
  updateDate: Date;
  coaches: CoachRosterItem[];
  players: PlayerRosterItem[];
  status?: string;
  club?: {
    id: string,
    logo: string,
    name: string,
  };
  eventId?: string;
  name?: string;

  event?: {
    id: string,
    logo: string,
    name: string,
    type: string,
    maximumCoachesNumber: number,
    maximumManagersNumber: number,
    maximumPlayersNumber: number,
    minimumCoachesNumber: number,
    minimumManagersNumber: number,
    minimumPlayersNumber: number,
    startDate: Date;
    endDate: Date;
    data: EventData,
  };
}

class RosterService {
  public static async findDetailed(id: string): Promise<ServiceResponse<NewRoaster>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/rosters/${id}/detailed`,
      method: 'GET',
    };
    return APIServices.request<NewRoaster>(axiosConfig);
  }

  public static async getRostersByClubId(
    id?: string,
    size?: number,
    page?: number
  ): Promise<ServiceResponse<PageResponse<NewRoaster>>> {
    const queryParams = [];

    if (size) queryParams.push(`size=${size}`);
    if (page) queryParams.push(`page=${page}`);

    const queryString =
      queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/rosters/${id}/club${queryString}`,
      method: 'GET',
    };
    return APIServices.request<PageResponse<NewRoaster>>(axiosConfig);
  }

  public static async getRostersByClubIdAndUser(
    id?: string,
    size?: number,
    page?: number
  ): Promise<ServiceResponse<PageResponse<NewRoaster>>> {
    const queryParams = [];

    if (size) queryParams.push(`size=${size}`);
    if (page) queryParams.push(`page=${page}`);

    const queryString =
      queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/rosters/${id}/club/user${queryString}`,
      method: 'GET',
    };
    return APIServices.request<PageResponse<NewRoaster>>(axiosConfig);
  }

  public static async addPlayer(rosterId: string, playerId: string): Promise<ServiceResponse<Roster>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/rosters/${rosterId}/user/${playerId}`,
      method: 'POST',
    };
    return APIServices.request<any>(axiosConfig);
  }

  public static async removePlayer(rosterId: string, playerId: string): Promise<ServiceResponse<Roster>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/rosters/${rosterId}/user/${playerId}`,
      method: 'DELETE',
    };
    return APIServices.request<any>(axiosConfig);
  }
}




export default RosterService;
