import React, { useState } from 'react';
import { Divider } from 'antd';
import { Table } from 'components/v3/Table/Table';
import { useRefereeDataFilter } from 'pages/V3/EventManager/hooks';
import { useGetEventInvites } from 'hooks/v3/event/useGetEventInvites/useGetEventInvites';
import { EventInvitesTypes } from 'models/v3/EventInvite/EventInvitesModel';
import { formatUserName } from 'util/formatUserName';
import { Filters } from '../../../Filters/Filters';
import { columns } from './columns';

type RefereesTableProps = {
  id: string;
};

export const RefereesTable = ({ id }: RefereesTableProps) => {
  const [filterValue, setFilterValue] = useState('');

  const { data } = useGetEventInvites({
    id,
    type: EventInvitesTypes.EVENT_TO_REFEREE,
  });

  const uniqueRefereeSet = new Set<string>();

  const newReferees = data?.filter((invite) => {
    if (!invite.referee) return true;

    const refereeName = formatUserName({ ...invite.referee });

    if (uniqueRefereeSet.has(refereeName)) return false;

    uniqueRefereeSet.add(refereeName);
    return true;
  });

  const { filteredData } = useRefereeDataFilter({
    filterValue,
    invites: newReferees,
  });

  return (
    <>
      <Divider style={{ margin: 0 }} />
      <Filters filterValue={filterValue} setFilterValue={setFilterValue} />
      <Table columns={columns} dataSource={filteredData} />
    </>
  );
};
