import { AxiosRequestConfig } from 'axios';
import ServiceResponse from 'services/util/ServiceResponse';
import { EventView } from 'admin/models/event/Event';
import { B2bEventsList } from 'redux/v3/b2bSetupEvent/types';
import { DeepPartial } from 'util/types';
import { ConfigApi } from '../../config.api';
import APIServices from '../../util/ApiServices';
import { PatchEventProps, RefereeApplicationView } from './types';

class B2bEventService {
  public static async listEvents(
    email: string,
    size = 5
  ): Promise<ServiceResponse<B2bEventsList>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: '/events/b2b',
      params: { size },
      method: 'GET',
      data: { email },
    };
    return APIServices.request<B2bEventsList>(axiosConfig);
  }

  public static async patchEvent(
    id: string,
    data: DeepPartial<PatchEventProps>
  ): Promise<unknown> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/events/${id}`,
      method: 'PATCH',
      data,
    };

    return APIServices.request<any>(axiosConfig);
  }

  public static async getEvent(
    id: string
  ): Promise<ServiceResponse<DeepPartial<EventView>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/events/${id}`,
      method: 'GET',
    };
    return APIServices.request<DeepPartial<EventView>>(axiosConfig);
  }

  public static async postRefereeApplication(
    eventId: string
  ): Promise<ServiceResponse<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: '/referee-application',
      method: 'POST',
      data: { eventId },
    };
    return APIServices.request(axiosConfig);
  }

  public static async getMyRefereeApplications(
    eventId?: string
  ): Promise<ServiceResponse<RefereeApplicationView[]>> {

    if (!eventId) return ServiceResponse.success<RefereeApplicationView[]>([], '');
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/referee-application/my-applications/${eventId}`,
      method: 'GET',
    };
    return APIServices.request(axiosConfig);
  }
}

export default B2bEventService;
