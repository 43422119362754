import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const MembershipCardWrapper = styled.div<{
  $theme: 'highlight' | 'secondary';
}>`
  & + div {
    margin-left: 1.5rem;
  }
  .simple-card-wrapper {
    width: ${(props) => (props.$theme === 'highlight' ? '320px' : '282px')};
    background-color: ${COLORS.grey900};
    border-color: ${(props) =>
      props.$theme === 'highlight' ? COLORS.brandPrimary : 'transparent'};
    box-shadow: ${(props) =>
      props.$theme === 'highlight'
        ? `0px -90px 150px -110px ${COLORS.brandPrimary}`
        : 'none'};

    img {
      max-width: 146px;
      margin: -80px auto 0;
    }
    h3 {
      margin: ${toRem(8)} 0 ${toRem(24)};
    }
    button {
      margin-bottom: ${toRem(24)};

      span {
        margin-left: ${toRem(8)};
      }
    }
  }
`;
export const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  padding: ${toRem(8)} ${toRem(26)};
  background-color: ${COLORS.grey800};
  margin-bottom: ${toRem(24)};

  h2 {
    margin: 0;
  }
  p {
    text-align: left;
    margin-left: ${toRem(8)};
  }
`;
export const ChildrenContainer = styled.div`
  text-align: left;
  color: ${COLORS.grey300};

  .details-line {
    display: flex;
    align-items: flex-start;

    span {
      margin-right: ${toRem(8)};
    }
  }

  p {
    margin-bottom: ${toRem(12)};
    &:first-child {
      margin-bottom: 1rem;
    }
    &.highlight {
      color: ${COLORS.white};
    }
  }
`;
