import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import ClubService from 'services/Club/ClubService';

export function useGetMyClubsByUserId() {
  const { id: userId } = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );
  const { data, isLoading, error } = useQuery(
    ['get-my-clubs-by-user-id', userId],
    async () => {
      if (userId) {
        const {
          data: { content: clubsData },
        } = await ClubService.list();
        if (clubsData) {
          const filterClubsByUserId = clubsData.filter((c) =>
            c.directorIds.includes(userId)
          );
          return {
            data: filterClubsByUserId,
          };
        }
      }
      return { data: null };
    }
  );

  return {
    ...data,
    data: data?.data,
    isLoading,
    error,
  };
}
