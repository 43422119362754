import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { b2bDraftEventAddOrRemoveBooster } from 'redux/v3/b2bDraftEvent/actions';
import { ApplicationState } from 'redux/store';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import BoosterCard from 'components/v3/Cards/BoosterCard/BoosterCard';
import BoosterBgImage from 'assets/imgs/boosters/booster-bg-image.png';

import * as S from './styles';
import { BodyM, BodyS, TitleH1, TitleH4 } from 'styles/v3/variables';

const BoosterAndOptionals: React.FunctionComponent = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { selectedEvent, selectedBoosters, boosters } = useSelector(
    (state: ApplicationState) => state.b2bDraftEvent.data
  );

  function handleNextStep() {
    window.location.href = '/v3/draft-event/general-event';
  }

  function handlePreviousStep() {
    history.push('/v3/draft-event/plan-selection');
  }

  const handleAddRemoveBooster = (boosterId: string) => {
    dispatch(b2bDraftEventAddOrRemoveBooster(boosterId));
  };

  return (
    <S.BoostersSelectionWrapper>
      <S.BoostersSelectionText>
        <TextButtonWithIcon
          color='primary'
          align='flex-end'
          reverse
          icon='back'
          onClick={handlePreviousStep}
        >
          Go back
        </TextButtonWithIcon>

        <TitleH1 $spanColor='brandPrimary'>
          MAKE YOUR EVENT LEGENDARY
          <br />
          <span>with BOOSTERS BY UNITED FUTSAL</span>
        </TitleH1>
        <BodyM $color='grey400' $spanColor='grey50'>
          Our team can help you to offer the best experience for your
          Tournament. We can help you with{' '}
          <span>Equipments, Sales & Marketing, Ticketing & Bundles</span> and{' '}
          <span>Event Production</span>
        </BodyM>

        <FilledButtonWithIcon onClick={handleNextStep} color='primary' isUpper>
          Continue
        </FilledButtonWithIcon>

        <BodyS $color='grey400'>
          By continuing with boosters, you agree with a contact from our sales
          team regarding your needs.
        </BodyS>
      </S.BoostersSelectionText>

      <S.BoostersSelectionItems>
        <TitleH4 $spanColor='brandPrimary'>
          What boosters interested you? <span>Select all that may apply</span>
        </TitleH4>
        <BodyM>
          You will be contacted by our sales team about the boosters selected
        </BodyM>

        <S.BoostersSelectionList>
          {boosters.map((booster) => (
            <BoosterCard
              className='booster-item'
              key={booster.id}
              id={`option_${booster.id}`}
              title={booster.name}
              description={booster.description}
              orientation='row'
              size='large'
              theme='primary'
              bgImage={BoosterBgImage}
              selected={!!selectedBoosters.find((sb) => sb.id === booster.id)}
              onClick={() => handleAddRemoveBooster(booster.id)}
            />
          ))}
        </S.BoostersSelectionList>
      </S.BoostersSelectionItems>
    </S.BoostersSelectionWrapper>
  );
};

export default withRouter(BoosterAndOptionals);
