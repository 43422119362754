import styled from 'styled-components'
import { toRem } from 'styles/v3/functions'
import { COLORS } from 'styles/v3/variables'

export const ContainerStepper = styled.div`
  display: flex;
  align-items: center;
`

export const StepData = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-content: center;
  align-items: center;

  color: ${({ isActive }) => (isActive ? COLORS.brandPrimary : COLORS.white)};
  font-size: ${toRem(16)};
  text-transform: uppercase;
  margin-right: ${toRem(16)};

  span {
    padding-bottom: ${toRem(10)};
  }

  svg {
    padding-top: ${toRem(1.5)};
  }

  border-bottom-width: ${toRem(2.5)};
  border-bottom-style: solid;
  border-bottom-color: ${({ isActive }) =>
    isActive ? COLORS.brandPrimary : 'transparent'};

  cursor: default;
`
