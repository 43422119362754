import { AxiosRequestConfig } from 'axios';
import { B2bEventsList } from 'redux/v3/b2bSetupEvent/types';
import DateUtil from 'util/DateUtil';
import { ConfigApi } from '../../config.api';
import APIServices from '../../util/ApiServices';
import ServiceResponse from '../../util/ServiceResponse';
import { EventUpdatePricing } from './types';

class B2bSetupEventService {
  public static async listEvents(
    email: string
  ): Promise<ServiceResponse<B2bEventsList>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: '/events/b2b',
      method: 'GET',
      data: { email },
    };
    return APIServices.request<B2bEventsList>(axiosConfig);
  }

  public static async createStripeOnboardingUrl(
    eventId: string,
    returnUrl: string
  ): Promise<ServiceResponse<string>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/events/${eventId}/create-stripe-onboard-url`,
      params: {
        returnUrl,
      },
      method: 'POST',
    };
    return APIServices.request<string>(axiosConfig);
  }

  public static async updateStripeOnboardingUrl(
    eventId: string,
    accountId: string,
    returnUrl: string
  ): Promise<ServiceResponse<string>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/events/${eventId}/update-stripe-onboard-url`,
      params: {
        returnUrl,
        accountId,
      },
      method: 'POST',
    };
    return APIServices.request<string>(axiosConfig);
  }

  public static async updatePricingInfo(
    eventId: string,
    data: EventUpdatePricing
  ): Promise<ServiceResponse<string>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/events/${eventId}/update-price-info`,
      data: {
        rosterFee: data.rosterFee,
        draftCoupons: data.draftCoupons.map((draft) => ({
          ...draft,
          createdAt: DateUtil.dateTimeToDateString(draft.createdAt),
          expiresAt: DateUtil.dateTimeToDateString(draft.expiresAt),
        })),
      },
      method: 'POST',
    };
    return APIServices.request<string>(axiosConfig);
  }
}

export default B2bSetupEventService;
