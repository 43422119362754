import * as React from 'react';
import { Star } from '@icon-park/react';
import { Popover } from 'antd';
import { useHistory } from 'react-router-dom';

import { ClubView } from 'admin/models/club/Club';
import ConvertUtil from 'util/ConvertUtil';

import { BodyL, BodyS, BodyXS } from 'styles/v3/variables';
import { DocumentStatusLabel } from 'redux/v3/baseUsers/types';
import { RequestStatus } from 'models/Request/RequestModel';

import * as S from './styles';

interface ClubCardProps {
  club: ClubView;
  isMainClub?: boolean;
  onClick?: () => void;
}

const ClubCard: React.FC<ClubCardProps> = ({ club, isMainClub, onClick }) => {
  const isPending =
    club.clubVerified && club.clubVerified === RequestStatus.PENDING;

  const popoverContent = () =>
    club.clubVerified && (
      <BodyS>{DocumentStatusLabel[club.clubVerified]}</BodyS>
    );

  const renderImage = () => (
    <S.ImageWrapper>
      <img src={ConvertUtil.getMinioUrl(club.logo)} alt={`${club.name} logo`} />
    </S.ImageWrapper>
  );

  return (
    <S.Wrapper $highlight={isMainClub} onClick={onClick}>
      <S.CardHeader>
        {isMainClub && (
          <BodyXS $isUpper $color='brandPrimary'>
            Main Club
          </BodyXS>
        )}
        <Star size={20} theme={isMainClub ? 'filled' : 'outline'} />
      </S.CardHeader>
      {isPending ? (
        <Popover content={popoverContent()}>{renderImage()}</Popover>
      ) : (
        renderImage()
      )}
      <BodyL $color={isMainClub ? 'brandPrimary' : 'grey500'}>
        {club.name}
      </BodyL>
    </S.Wrapper>
  );
};

export default ClubCard;
