import * as React from 'react';
import { AlarmClock, Calendar, Court } from '@icon-park/react';
import { useMemo, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import {
  BodyL,
  BodyM,
  BodyMBold,
  TitleH1,
  TitleH3,
  TitleH4,
} from 'styles/v3/variables';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import { useSelector } from 'react-redux';
import B2bDraftEventService from 'services/v3/B2bDraftEvent/B2bDraftEventService';
import { ApplicationState } from 'redux/store';
import ConvertUtil from 'util/ConvertUtil';
import * as S from './styles';

interface PaymentProps {
  children?: React.ReactNode;
  auth: Keycloak.KeycloakInstance;
}

const Payment: React.FunctionComponent = (props) => {
  const history = useHistory();
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  const { auth } = props as PaymentProps;

  const eventData = useSelector(
    (state: ApplicationState) => state.b2bDraftEvent.data
  );

  const userEmail = ((auth?.tokenParsed as any)?.email as string) ?? '';

  const handleCardButton = () => {
    if (isLoadingPayment) {
      return;
    }
    if (paymentStatus === 'success') {
      history.push('/v3/events?tab=created-by-me');
    } else {
      handleBuyEvent();
    }
  };

  // TODO: update only the payment link
  const handleBuyEvent = async () => {
    setIsLoadingPayment(true);

    if (!eventData?.selectedEvent) {
      history.push('/v3/draft-event/general-event');
      return;
    }

    try {
      const paymentRequest = await B2bDraftEventService.buyEvent({
        eventData,
        userEmail,
      });

      const {
        data: { paymentUrl },
      } = paymentRequest;

      if (paymentUrl) {
        // redirect to stripe checkout
        window.location.href = paymentUrl;
      }
      setIsLoadingPayment(false);
    } catch (e) {
      setIsLoadingPayment(false);
    }
  };

  const paymentStatus =
    new URLSearchParams((props as any).location.search).get('success') ===
    'true'
      ? 'success'
      : 'error';

  function handlePreviousStep() {
    history.push('/v3/draft-event/general-event');
  }

  const eventImageSrc = useMemo(() => {
    const defaultImg = '/assets/imgs/icons/event-trophy.svg';
    if (!eventData) {
      return defaultImg;
    }

    if (eventData.eventInfo?.logoImg) {
      return ConvertUtil.getMinioUrl(eventData.eventInfo.logoImg);
    }
    return defaultImg;
  }, [eventData]);

  const formatDate = (date: Date | string) => {
    if (!date) return 'not set';

    return new Date(date).toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const generalInfoData = useMemo(() => {
    if (!eventData) return [];
    return [
      {
        title: 'Starts',
        value: formatDate(eventData?.eventInfo?.startDate),
      },
      {
        title: 'Ends',
        value: formatDate(eventData?.eventInfo?.endDate),
      },
    ];
  }, [eventData]);

  const bankCardImgSrc = `/assets/imgs/icons/bank-card-${paymentStatus}.svg`;

  return (
    <S.PaymentWrapper>
      <S.PaymentContainer>
        <S.CardContainer $isSuccess={paymentStatus === 'success'}>
          <S.Image src={bankCardImgSrc} alt='bank card' />
          <S.Container>
            <TitleH3>
              {paymentStatus === 'success'
                ? 'Payment was successful'
                : 'something went wrong'}
            </TitleH3>

            <BodyM>
              {paymentStatus === 'success'
                ? 'You will be redirected soon. If you were not redirected, just click the button below.'
                : 'Please make sure your payment information is correct and try again'}
            </BodyM>
            <FilledButtonWithIcon color='dark-white' onClick={handleCardButton}>
              {paymentStatus === 'success'
                ? 'Go to my events'
                : 'Continue to payment'}
            </FilledButtonWithIcon>
          </S.Container>
        </S.CardContainer>

        <S.PaymentSummary>
          <TitleH1>ORDER</TitleH1>
          <TitleH1>SUMMARY</TitleH1>
        </S.PaymentSummary>

        <S.PaymentEvent>
          <S.EventLogoContainer>
            <S.Image src={eventImageSrc} alt='bank card' />
          </S.EventLogoContainer>
          <S.Container>
            <TitleH4>{eventData?.eventInfo?.name ?? ''}</TitleH4>
            <BodyM>{eventData?.eventInfo?.description ?? ''}</BodyM>
          </S.Container>
        </S.PaymentEvent>

        <S.InfoContainer>
          <BodyL>GENERAL INFO</BodyL>
          {generalInfoData.map((info) => (
            <S.Container key={info.title}>
              <S.Container className='title'>
                <BodyM>{info.title}</BodyM>
                <Calendar size='20px' />
              </S.Container>
              <S.Container className='value'>
                <BodyMBold>{info.value}</BodyMBold>
              </S.Container>
            </S.Container>
          ))}
        </S.InfoContainer>
        <S.MapContainer>
          <S.Container>
            <S.Image
              src='/assets/imgs/icons/map-pin-primary.svg'
              alt='bank card'
            />
            <BodyM>{eventData?.eventInfo?.location}</BodyM>
          </S.Container>
        </S.MapContainer>

        <S.InfoContainer>
          <BodyL>GAME SETTINGS</BodyL>
          <S.Container>
            <S.Container className='title'>
              <BodyM>Time Slots</BodyM>
              <AlarmClock size='20px' />
            </S.Container>
            <S.Container className='value'>
              <BodyMBold>{eventData?.eventInfo?.timeSlots}</BodyMBold>
            </S.Container>
          </S.Container>
          <S.Container>
            <S.Container className='title'>
              <BodyM>Number of Courts</BodyM>
              <Court size='20px' />
            </S.Container>
            <S.Container className='value'>
              <BodyMBold>{eventData?.eventInfo?.courtCount}</BodyMBold>
            </S.Container>
          </S.Container>
        </S.InfoContainer>

        <S.EventCardContainer>
          <S.Image height='52px' src='/assets/imgs/trophyx2.svg' alt='trophy' />
          <S.Container>
            <BodyM>{eventData?.selectedEvent?.title}</BodyM>
            <TitleH4>${(eventData.selectedEvent?.price ?? 1) / 100}</TitleH4>
          </S.Container>
        </S.EventCardContainer>
      </S.PaymentContainer>
    </S.PaymentWrapper>
  );
};

export default withRouter(Payment);
