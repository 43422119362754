import { filter as lFilter, lowerCase } from 'lodash';
import { AllEventInvites } from 'services/v3/Event/types';
import { formatUserName } from 'util/formatUserName';

type UseClubDataFilterProps = {
  filterValue: string;
  invites?: AllEventInvites[];
};

export const useClubDataFilter = ({
  invites,
  filterValue,
}: UseClubDataFilterProps) => {
  const filteredData = lFilter(invites, (item) => {
    const clubName = lowerCase(item?.club?.name);
    const directorName = lowerCase(formatUserName({ ...item.director }));
    const filter = lowerCase(filterValue);

    return (
      clubName.includes(filter) ||
      (directorName && directorName.includes(filter))
    );
  });

  return { filteredData };
};
