import { AxiosRequestConfig } from 'axios';
import ServiceResponse from 'services/util/ServiceResponse';
import { ConfigApi } from 'services/config.api';
import APIServices from 'services/util/ApiServices';
import { CouponView } from './types';

class CouponService {
  public static async getProductCoupons(
    productId?: string
  ): Promise<ServiceResponse<CouponView[]>> {

    if(!productId) return ServiceResponse.success([], '');
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/coupons/product/${productId}`,
      method: 'GET',
    };
    return APIServices.request<CouponView[]>(axiosConfig);
  }

  public static async getUsedCodesForAccount(
    accountId: string
  ): Promise<ServiceResponse<string[]>> {
    if (!accountId) {
      return ServiceResponse.success([], '');
    }
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/coupons/used-codes-for-connected-account/${accountId}`,
      method: 'GET',
    };
    return APIServices.request<string[]>(axiosConfig);
  }

  public static async validateEventRosterCoupon(
    code: string,
    eventId: string,
    productId: string
  ): Promise<ServiceResponse<CouponView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/coupons/validate-event-roster-coupon`,
      method: 'POST',
      data: {
        code,
        productId,
        eventId,
      },
    };
    return APIServices.request<CouponView>(axiosConfig);
  }
}

export default CouponService;
