import React from 'react';
import { Link } from 'react-router-dom';
import ConvertUtil from 'util/ConvertUtil';

import { toRem } from 'styles/v3/functions';
import { BodyS, BodySBold, TitleH4 } from 'styles/v3/variables';
import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';

import {
  EventContainer,
  EventIcon,
  EventInformationContainer,
  EventTitle,
} from './styles';

export interface EventCardHomeProps {
  id?: string;
  title: string;
  link: string;
  logo?: string;
  startDate: Date;
  finalDate: Date;
  isGreen?: boolean;
}

const EventCardHome: React.FC<EventCardHomeProps> = ({
  title,
  link,
  logo,
  startDate,
  finalDate,
  isGreen = false,
  ...props
}) => {
  const startAt = new Date(startDate).toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });

  const endAt = new Date(finalDate).toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });

  return (
    <EventContainer isGreen={isGreen} {...props}>
      <EventIcon>
        <img src={ConvertUtil.getMinioUrl(logo)} alt='Event Logo' />
      </EventIcon>

      <EventInformationContainer>
        <EventTitle>
          <TitleH4
            $color='grey50'
            style={{
              maxHeight: 66,
              display: 'block',
              textOverflow: 'ellipsis',
              wordWrap: 'break-word',
              overflow: 'hidden',
              textAlign: 'start',
            }}
          >
            {title}
          </TitleH4>
        </EventTitle>
        <BodySBold $color='grey200' $isUpper style={{ marginTop: toRem(8) }}>
          {startAt} - {endAt}
        </BodySBold>
        <BodyS
          $color='grey200'
          style={{ marginTop: toRem(4), marginBottom: toRem(16) }}
        >
          STATE - COUNTRY
        </BodyS>

        <Link to={link}>
          <OutlinedButtonWithIcon color='dark-white' isBold={false} isUpper>
            See Event
          </OutlinedButtonWithIcon>
        </Link>
      </EventInformationContainer>
    </EventContainer>
  );
};

export default EventCardHome;
