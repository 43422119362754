import { ClubView } from 'admin/models/club/Club';
import { AxiosRequestConfig } from 'axios';
import { User } from 'models/User/UserModel';
import UserType from 'models/User/UserTypeModel';
import { ConfigApi } from 'services/config.api';
import APIServices from 'services/util/ApiServices';
import ServiceResponse from 'services/util/ServiceResponse';
import PageResponse from 'services/util/PageResponse';
import RequestModel from 'models/Request/RequestModel';
import { ClubInvitesViewWithReceiver } from 'models/v3/ClubInvite/ClubInvitesModel';
import { InviteMembersByClubDTO, UpdateMemberPoolDTO } from './types';

class ClubService {
  public static async getMyClubsByType(
    userType: UserType
  ): Promise<ServiceResponse<ClubView[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/clubs/my-clubs/${userType}/by-user-type`,
      method: 'GET',
    };
    return APIServices.request<ClubView[]>(axiosConfig);
  }

  public static async getClubById(
    id?: string
  ): Promise<ServiceResponse<ClubView | undefined>> {
    if (!id) return ServiceResponse.success(undefined, '');
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/clubs/${id}`,
      method: 'GET',
    };
    return APIServices.request<ClubView>(axiosConfig);
  }

  public static async getClubsByUserId(
    userId?: string
  ): Promise<ServiceResponse<ClubView[]>> {
    if (!userId) return ServiceResponse.success([], '');
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/clubs/my-clubs/${userId}`,
      method: 'GET',
    };
    return APIServices.request<ClubView[]>(axiosConfig);
  }

  public static async getClubsMembersById(
    id?: string,
    size?: number,
    page?: number
  ): Promise<ServiceResponse<PageResponse<User>>> {
    const queryParams = [];

    if (size) queryParams.push(`size=${size}`);
    if (page) queryParams.push(`page=${page}`);

    const queryString =
      queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/clubs/${id}/members${queryString}`,
      method: 'GET',
    };
    return APIServices.request<PageResponse<User>>(axiosConfig);
  }

  public static async getMembersWithoutClub(): Promise<
    ServiceResponse<User[]>
  > {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/clubs/users-no-club`,
      method: 'GET',
    };
    return APIServices.request<User[]>(axiosConfig);
  }

  public static async getClubMembersPool(
    id?: string,
    validateAccess = false
  ): Promise<ServiceResponse<User[]>> {
    if (!id) return ServiceResponse.success([], '');
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/clubs/${id}/v3/memberpool`,
      params: {
        validateAccess,
      },
      method: 'GET',
    };
    return APIServices.request<User[]>(axiosConfig);
  }

  public static async updateClubMembersPool(
    id: string,
    dto: UpdateMemberPoolDTO
  ) {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/clubs/${id}/v3/memberpool`,
      method: 'POST',
      data: dto,
    };
    return APIServices.request(axiosConfig);
  }

  public static async getMyClubs(): Promise<
    ServiceResponse<PageResponse<RequestModel>>
  > {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/clubs/my-clubs-v3`,
      method: 'GET',
    };
    return APIServices.request<PageResponse<RequestModel>>(axiosConfig);
  }

  public static async inviteMembersByClub(
    body: InviteMembersByClubDTO
  ): Promise<ServiceResponse<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/club-invites`,
      method: 'POST',
      data: body,
    };
    return APIServices.request<any>(axiosConfig);
  }

  public static async getMyClubsInvites(
    clubId?: string
  ): Promise<ServiceResponse<ClubInvitesViewWithReceiver[]>> {
    if (!clubId) return ServiceResponse.success([], '');

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/club-invites/club/${clubId}`,
      method: 'GET',
    };
    return APIServices.request<ClubInvitesViewWithReceiver[]>(axiosConfig);
  }
}

export default ClubService;
