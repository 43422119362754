import React from 'react';

import { TitleH2 } from 'styles/v3/variables';
import { EventStatus } from 'components/v3/Cards/EventCard/styles';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import { SettingTwo } from '@icon-park/react';
import Image from 'assets/imgs/avatares/avatar.png';
import { useHistory } from 'react-router-dom';
import * as S from './styles';

type HeaderProps = {
  id?: string;
  title?: string;
};

export const Header = ({ id, title }: HeaderProps) => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  const handleGoToEventSetup = () => {
    if (id) history.push(`/v3/setup-event/my-events/${id}`);
  };

  return (
    <S.HeaderContainer image={Image}>
      <TextButtonWithIcon
        reverse
        icon='back'
        color='primary'
        align='flex-end'
        onClick={handleGoBack}
      >
        Go back
      </TextButtonWithIcon>
      <S.Flex>
        <S.TitleWrapper>
          <TitleH2>{title}</TitleH2>
          <EventStatus eventStatus='published'>Published</EventStatus>
        </S.TitleWrapper>
        <FilledButtonWithIcon
          isUpper
          customIcon={<SettingTwo />}
          onClick={handleGoToEventSetup}
        >
          Event setup
        </FilledButtonWithIcon>
      </S.Flex>
    </S.HeaderContainer>
  );
};
