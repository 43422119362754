import * as React from 'react';

import AvatarText, { AvatarTextProps } from '../AvatarText/AvatarText';
import * as S from './styles';

interface AvatarStyledProps extends AvatarTextProps {}

const AvatarStyled: React.FC<AvatarStyledProps> = ({ ...props }) => {
  return (
    <S.Container>
      <AvatarText {...props} />
    </S.Container>
  );
};

export default AvatarStyled;
