import { useQuery } from '@tanstack/react-query';
import ClubService from 'services/v3/Clubs/ClubService';

export const useGetMembersWithoutClub = () => {
  const response = useQuery(['clubs-members-without-club'], () =>
    ClubService.getMembersWithoutClub()
  );

  return {
    ...response,
    data: response.data?.data,
  };
};
