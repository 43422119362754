import React from 'react';

import { Avatar, Image } from 'antd';

import * as S from './styles';

type EventHeaderProps = {
  logoSrc?: string;
  backgroundSrc?: string;
};

export const EventHeader = ({ logoSrc, backgroundSrc }: EventHeaderProps) => {
  return (
    <>
      <S.HeaderWrapper>
        <Image
          width='100%'
          height='150px'
          preview={false}
          alt='background'
          src={backgroundSrc ?? ''}
        />
      </S.HeaderWrapper>
      <S.AvatarWrapper>
        <Avatar size={140} src={logoSrc} style={{ backgroundColor: 'white' }} />
      </S.AvatarWrapper>
    </>
  );
};
