import React from 'react';

import { Avatar } from 'antd';

import { BodyMBold } from 'styles/v3/variables';
import { UserOutlined } from '@ant-design/icons';
import { truncateText } from 'util/truncateText';

import UserType from 'models/User/UserTypeModel';
import { User } from 'models/User/UserModel';
import ConvertUtil from 'util/ConvertUtil';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from 'redux/v3/currentUser/actions';
import { formatUserName } from 'util/formatUserName';
import { MemberTag } from './components/MemberTag/MemberTag';
import { AccountTag } from '../../../AccountTag/AccountTag';

import * as S from './styles';

type AccountCardProps = {
  user: User;
  isMember?: boolean;
  types?: Array<UserType>;
};

export const AccountCard = ({ user, types, isMember }: AccountCardProps) => {
  const dispatch = useDispatch();

  const userName = formatUserName({
    lastName: user.lastName,
    firstName: user.firstName,
    middleName: user.middleName,
  });

  const handleClick = () => {
    dispatch(setCurrentUser(user));
  };

  return (
    <S.CardWrapper onClick={handleClick}>
      <S.AvatarWrapper>
        <Avatar
          size={38}
          alt={userName}
          icon={<UserOutlined />}
          src={ConvertUtil.getMinioUrl(user.photo)}
        />
      </S.AvatarWrapper>
      <S.CardHeaderWrapper>
        <S.CardHeader>
          <BodyMBold $color='grey50'>
            {truncateText(userName, isMember ? 26 : 35)}
          </BodyMBold>
          {isMember && <MemberTag />}
        </S.CardHeader>
        <S.AccountTagWrapper>
          {types?.map((type) => (
            <AccountTag key={type} type={type} />
          ))}
        </S.AccountTagWrapper>
      </S.CardHeaderWrapper>
    </S.CardWrapper>
  );
};
