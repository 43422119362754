/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import { Switch } from 'antd';

import { useParams } from 'react-router-dom';

import { NewAgeDivision } from 'admin/models/AgeDivision';
import { toRem } from 'styles/v3/functions';
import { BodyL, BodyS, BodyMBold } from 'styles/v3/variables';
import { DeepPartial } from 'util/types';
import { PatchEventProps } from 'services/v3/B2bEvent/types';
import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';
import { TeamInformation } from './components/TeamInformation';
import { DivisionAndClubsState } from '../..';

import {
  Container,
  GeneralInfoContainer,
  GeneralInfoHeader,
  ApplicationStatusContainer,
  DivisionContentDatas,
  DivisionData,
  TeamsContainer,
  TeamsList,
} from './styles';

interface ModalBodyDivisionProps {
  divisionAndClubs: DivisionAndClubsState;
  allDivisions: NewAgeDivision[];
}

const ModalBody: React.FC<ModalBodyDivisionProps> = ({
  divisionAndClubs,
  allDivisions,
}) => {
  const { division, divisionOrder, clubInformation } = divisionAndClubs;
  const [lockApplications, setLockApplications] = useState(
    division.locked ? division.locked : false
  );

  const params: { eventId: string } = useParams();

  const { mutateAsync } = useUpdateEvent({
    eventId: params.eventId,
  });

  const teamsApplications = divisionOrder
    .map((order) => {
      return order.quantity;
    })
    .reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);

  const totalReceived = divisionOrder
    .map((order) => {
      return order.total;
    })
    .reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);

  const percentage = (partialValue: number, totalValue: number) => {
    return ((100 * partialValue) / totalValue).toFixed(0);
  };

  const teamsInformation = divisionOrder.map((order) => {
    const teamClub = clubInformation.find((club) => club.id === order.clubId);

    return {
      clubLogo: teamClub?.logo,
      clubName: teamClub?.name,
      quantityTeams: order.quantity,
    };
  });

  useEffect(() => {
    handleSave();
  }, [lockApplications]);

  const handleSave = async () => {
    try {
      const selectedDisivion = allDivisions.find((d) => d.id === division.id);
      const payload: DeepPartial<PatchEventProps> = {
        ageDivisions: [
          ...allDivisions,
          {
            ...selectedDisivion,
            locked: lockApplications,
          },
        ],
      };
      await mutateAsync({ id: params.eventId, data: payload });
    } catch (e) {
      console.error(`Error: ${e}`);
    }
  };

  return (
    <Container>
      <GeneralInfoContainer>
        <GeneralInfoHeader>
          <BodyL $color='grey50'>General Info</BodyL>
          <ApplicationStatusContainer>
            <Switch
              size='small'
              checked={lockApplications}
              onChange={() => setLockApplications(!lockApplications)}
            />
            <BodyS $color={lockApplications ? 'brandPrimary' : 'grey500'}>
              Application {lockApplications ? 'Closed' : 'Open'}
            </BodyS>
          </ApplicationStatusContainer>
        </GeneralInfoHeader>
        <DivisionContentDatas>
          <DivisionData>
            <BodyS $color='grey300'>Occupation Rate</BodyS>
            <BodyMBold $color='grey300'>
              {percentage(
                teamsApplications,
                division.targetNumberOfTeams as number
              )}
              %
            </BodyMBold>
          </DivisionData>

          <DivisionData>
            <BodyS $color='grey300'>Teams / Target</BodyS>
            <BodyMBold $color='grey300'>
              {teamsApplications} / {division.targetNumberOfTeams}
            </BodyMBold>
          </DivisionData>

          <DivisionData>
            <BodyS $color='grey300'>Total Received</BodyS>
            <BodyMBold $color='grey300'>${totalReceived}</BodyMBold>
          </DivisionData>
        </DivisionContentDatas>
      </GeneralInfoContainer>
      <TeamsContainer>
        <BodyL $color='grey50'>Teams</BodyL>
        <BodyS
          $isUpper
          $color='grey400'
          style={{ marginTop: toRem(24), marginBottom: toRem(24) }}
        >
          Team name
        </BodyS>

        <TeamsList>
          {teamsInformation.map((information) => {
            const foo = new Array(information.quantityTeams).fill(0);
            return foo.map((_, index) => {
              const clubName =
                information.quantityTeams > 1
                  ? `${information.clubName} - Team ${index + 1}`
                  : `${information.clubName}`;
              return (
                <TeamInformation
                  key='test'
                  logo={information.clubLogo as string}
                  teamName={clubName}
                />
              );
            });
          })}
        </TeamsList>
      </TeamsContainer>
    </Container>
  );
};

export default ModalBody;
