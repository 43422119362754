import React from 'react';
import Logo from 'components/v3/Elements/Logo/Logo';

import AvatarAction from 'components/v3/Elements/Avatares/AvatarAction/AvatarAction';
import * as S from './styles';
import { HeaderProps } from './types';

const Header: React.FC<HeaderProps> = ({ auth }) => {
  const userName = ((auth?.tokenParsed as any)?.name as string) ?? '';
  return (
    <S.Wrapper>
      <S.Container>
        <S.LogoContainer>
          <Logo />
        </S.LogoContainer>

        <S.LocaleContainer>{userName && <AvatarAction />}</S.LocaleContainer>
      </S.Container>
    </S.Wrapper>
  );
};

export default Header;
