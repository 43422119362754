/* eslint-disable no-nested-ternary */
import styled from 'styled-components'
import { toRem } from 'styles/v3/functions'
import { COLORS } from 'styles/v3/variables'

const colorStatus = (status: 'ok' | 'draft' | 'error' | null) => {
  return status === 'ok'
    ? COLORS.brandPrimary
    : status === 'draft'
    ? COLORS.white
    : status === 'error'
    ? COLORS.supportError
    : COLORS.white
}

export const InfoContainer = styled.div`
  width: 100%;
  padding: ${toRem(24)};

  display: flex;
  background-color: ${COLORS.grey900};
  justify-content: space-between;
  align-items: center;
  border-radius: ${toRem(12)};

  margin-bottom: ${toRem(24)};

  cursor: pointer;
`
export const InfoIconTitle = styled.div`
  display: flex;
`

export const InfoTitleStatus = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  padding-left: ${toRem(24)};
`

export const InfoIcon = styled.span<{
  status: 'ok' | 'draft' | 'error' | null
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${toRem(64)};
  height: ${toRem(64)};
  border-radius: ${toRem(32)};
  background-color: ${({ status }) => colorStatus(status)};

  span {
    display: flex;
  }
`

export const InfoTitle = styled.p`
  font-size: ${toRem(25)};
  color: ${COLORS.white};

  text-transform: capitalize;
  margin-bottom: ${toRem(1.5)};
`

export const InfoStatus = styled.p<{ status: 'ok' | 'draft' | 'error' | null }>`
  display: flex;
  align-self: flex-start;
  margin: 0;

  font-size: ${toRem(13)};
  text-transform: uppercase;
  color: ${({ status }) => colorStatus(status)};

  border-style: solid;
  border-width: ${toRem(1)};
  border-color: ${({ status }) => colorStatus(status)};
  border-radius: ${toRem(7)};

  padding: ${toRem(4)} ${toRem(8)};
`

export const InfoArrow = styled.button`
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: ${toRem(12)};
  padding: ${toRem(8)};

  background-color: ${COLORS.grey800};

  span {
    display: flex;
  }

  cursor: pointer;
  transition: 0.7s ease-in-out;

  &:hover {
    background-color: ${COLORS.grey600};
  }
`
