import React from 'react';
import { Pagination } from 'antd';
import { User } from 'models/User/UserModel';
import { AvatarWithName } from '../AvatarWithName/AvatarWithName';
import * as S from './styles';

type MembersProps = {
  members?: User[];
  total?: number;
  pageSize?: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

export const Members = ({
  total,
  members,
  pageSize,
  setCurrentPage,
}: MembersProps) => {
  return (
    <S.MembersWrapper>
      <S.AvatarWrapper>
        {members?.map((item) => (
          <AvatarWithName
            key={item.id}
            photo={item.photo}
            name={item.firstName}
          />
        ))}
      </S.AvatarWrapper>
      {members?.length ? (
        <Pagination
          total={total}
          defaultCurrent={1}
          showSizeChanger={false}
          pageSize={pageSize ?? 10}
          onChange={(page) => setCurrentPage(page)}
        />
      ) : null}
    </S.MembersWrapper>
  );
};
