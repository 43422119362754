import React, { useCallback } from 'react';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { Plus } from '@icon-park/react';

import { BodyM, TitleH2 } from 'styles/v3/variables';
import { TABS } from './tabs';
import { DiscoverEvents, CreatedByMe } from './components';

import * as S from './styles';
import { MyEvents } from './components/MyEvents/MyEvents';

type EventsProps = {
  auth: Keycloak.KeycloakInstance,
};

const Events = ({ auth }: EventsProps) => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const activeTab = params.get('tab') ?? TABS.MY_EVENTS;

  const handleTabChange = useCallback(
    (key: string) => {
      history.push(`${location.pathname}?tab=${key}`);
    },
    [history, location.pathname]
  );

  return (
    <S.Container>
      <TitleH2>Events</TitleH2>
      <S.Tabs
        type='card'
        activeKey={activeTab}
        onChange={handleTabChange}
        tabBarExtraContent={
          <Link to='/v3/draft-event/plan-selection'>
            <S.NewEventButton>
              New Event <Plus />
            </S.NewEventButton>
          </Link>
        }
      >
        <S.TabPane tab={<BodyM $isUpper>My events</BodyM>} key={TABS.MY_EVENTS}>
          <MyEvents />
        </S.TabPane>
        <S.TabPane
          tab={<BodyM $isUpper>Created by me</BodyM>}
          key={TABS.CREATED_BY_ME}
        >
          <CreatedByMe auth={auth} />
        </S.TabPane>
        <S.TabPane
          tab={<BodyM $isUpper>Discover events</BodyM>}
          key={TABS.DISCOVER_EVENTS}
        >
          <DiscoverEvents />
        </S.TabPane>
      </S.Tabs>
    </S.Container>
  );
};

export default Events;
