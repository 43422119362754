import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { Select as AntSelect } from 'antd';

const { Option: AntOption } = AntSelect;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 100%;
`;
export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
`;

export const Image =
  styled.div <
  { $src: string } >
  `
  width: 38px;
  height: 38px;
  border-radius: 52.44px;
  background: url(${(props) => props.$src}), lightgray 50%;
  background-size: cover;
  background-position: center;
`;
export const Select = styled(AntSelect)`
  width: '100%';

  .ant-select-selection {
    background-color: green;
  }

  .ant-select-arrow {
    right: 25px !important;
    top: 23px !important;
    .i-icon svg {
      width: 24px;
      height: 24px;
      display: flex;
      &::before {
        display: none;
      }

      path {
        stroke: ${COLORS.white};
      }
    }
  }

  .ant-select-selector {
    height: 54px !important;
    border-radius: 43px !important;
    border: 1px solid ${COLORS.grey800} !important;

    background-color: ${COLORS.grey900} !important;
    border-color: ${COLORS.grey900};

    &:hover {
      border-color: ${COLORS.brandPrimary} !important;
    }
    box-shadow: none !important;

    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .ant-select-selection-item {
      margin-left: -15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 12px;
    }
  }
`;

export const Option = styled(AntOption)`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: ${COLORS.pink};
`;
