/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FunctionComponent, useRef, useState, useEffect } from 'react';
import { RightSmall, LeftSmall } from '@icon-park/react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import UserType from 'models/User/UserTypeModel';
import ConvertUtil from 'util/ConvertUtil';

// Components
import { TitleH2, TitleH3, TitleH4 } from 'styles/v3/variables';
import OutlinedButton from 'components/v3/Buttons/OutlinedButton';
import EventCard, {
  EventCardHomeProps,
} from 'components/v3/Cards/EventCardHome';
import { formatUserName } from 'util/formatUserName';
import { VideoHomeCard } from 'components/v3/Cards/VideoHomeCard';
import { b2bSetupEventFetchEventsSuccess } from 'redux/v3/b2bSetupEvent/actions';
import B2bEventService from 'services/v3/B2bEvent/B2bEventService';
import { CirclesNested } from './components/CirclesNested';
import {
  UserXPInformation,
  UserTypesInformation,
} from './components/UserXPInformation';

// Styled Components
import {
  Container,
  ContainerUser,
  ContainerUserProfile,
  UserProfileImage,
  UserInformation,
  UserNameAndTeams,
  ContainerEvents,
  EventsContent,
  EventMore,
  ButtonCarousel,
  MyEventsContainer,
  OtherEventsTitleAndControllers,
  OtherEventsContainer,
  UnitedVideosContainer,
  VideosContent,
} from './styles';

interface HomeProps {
  children?: React.ReactNode;
  auth: Keycloak.KeycloakInstance;
}

const Home: FunctionComponent = (props) => {
  const { auth } = props as HomeProps;
  const email = ((auth?.tokenParsed as any)?.email as string) ?? '';

  const { currentUser } = useSelector((state: ApplicationState) => state);
  const caroulselRefEvents = useRef<AliceCarousel>(null);
  const caroulselRefVideos = useRef<AliceCarousel>(null);
  const userInformation = currentUser.data;

  const fullName = formatUserName({
    lastName: userInformation.lastName,
    firstName: userInformation.firstName,
    middleName: userInformation.middleName,
  });
  const isMembership = userInformation?.membershipType === 'OFFICIAL';
  const types = [
    userInformation?.type,
    ...(Array.isArray(userInformation?.types) ? userInformation.types : []),
  ].filter(Boolean) as Array<UserType>;

  const userTypes: UserTypesInformation[] = types.map((userType) => {
    return {
      label: userType,
      value: userType.toLowerCase(),
    };
  });

  const [events, setEvents] = useState<EventCardHomeProps[]>();
  const dispatch = useDispatch();
  const { content: storedEvents } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent.data
  );

  const handleLoadEvents = async () => {
    try {
      const { data: fetchedEvents } = await B2bEventService.listEvents(email);
      if (fetchedEvents.content.length !== storedEvents.length) {
        dispatch(b2bSetupEventFetchEventsSuccess(fetchedEvents));
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    handleLoadEvents();

    const updateEvents = storedEvents?.map((event) => {
      return {
        id: event.id,
        title: event.name,
        link: `/v3/setup-event/my-events/${event.id}`,
        logo: event.logo,
        startDate: event.startDate,
        finalDate: event.endDate,
      };
    }) || [];

    setEvents(updateEvents);
  }, [storedEvents]);

  const teams = [
    {
      id: 'blabla',
      logo: 'uiui',
    },
    {
      id: 'blabla',
      logo: 'uiui',
    },
    {
      id: 'blabla',
      logo: 'uiui',
    },
    {
      id: 'blabla',
      logo: 'uiui',
    },
    {
      id: 'blabla',
      logo: 'uiui',
    },
    {
      id: 'blabla',
      logo: 'uiui',
    },
  ];

  const videosVault = [
    {
      title: 'United Futsal® Futures™',
      link: 'https://vimeo.com/394735246',
      featureImage:
        'https://i.vimeocdn.com/video/860731221-b6b38b8c8ec6e82e48404cfac16fc0595d679f57c394c7c74a36a5bbfcd9b5ac-d_1280x720',
    },
    {
      title: 'Champions Cup Series',
      link: 'https://vimeo.com/315294825',
      featureImage:
        'https://i.vimeocdn.com/video/774160929-10290978b0460323019e32e4a3d517f1e4a56495906b27085af3f5d24dae9c60-d_1280x720',
    },
    {
      title: 'WFC VIII Day 4 Highlights',
      link: 'https://vimeo.com/382050502',
      featureImage:
        'https://i.vimeocdn.com/video/843033964-7c1a068854f38cd1a803512d4e1941c233b90802a59682b57158f9cbddc0d9c4-d_1280x720',
    },
  ];

  /// /////////////// CARROUSELS //////////////////////////////////////////////////////////////////////////
  // Configuration for Events Carousel
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 2 },
  };
  const [carouselItem, setItemCarousel] = useState(3);

  const handleNext = () => {
    setItemCarousel(carouselItem + 1);
    caroulselRefEvents.current?.slideNext();
  };

  const handleBack = () => {
    setItemCarousel(carouselItem - 1);
    caroulselRefEvents.current?.slidePrev();
  };

  // Configuration for Videos Carousel
  const responsiveVideos = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 2 },
  };
  const [carouselItemVideo, setCarouselItemVideo] = useState(2);

  const handleNextVideos = () => {
    setCarouselItemVideo(carouselItemVideo + 1);
    caroulselRefVideos.current?.slideNext();
  };

  const handleBackVideos = () => {
    setCarouselItemVideo(carouselItemVideo - 1);
    caroulselRefVideos.current?.slidePrev();
  };

  return (
    <Container>
      <ContainerUser>
        <ContainerUserProfile>
          <UserProfileImage>
            <img
              src={ConvertUtil.getMinioUrl(userInformation.photo)}
              alt='User Avatar'
            />
          </UserProfileImage>
          <UserInformation>
            <UserNameAndTeams>
              <TitleH2>{fullName}</TitleH2>
              {/* <CirclesNested circlesInfos={teams} /> */}
            </UserNameAndTeams>
            {!isMembership && (
              <OutlinedButton color='primary' isBold={false} isUpper>
                Upgrade Membership
              </OutlinedButton>
            )}
          </UserInformation>
        </ContainerUserProfile>
        <UserXPInformation userOptions={userTypes} />
      </ContainerUser>

      <ContainerEvents>
        <TitleH3>Events</TitleH3>

        <EventsContent>
          <div>
            <TitleH4 $color='grey400'>My Events</TitleH4>
            <MyEventsContainer>
              {events && events.length > 0 && (
                <>
                  <EventCard
                    key={`key_event_${events[0].id}`}
                    title={events[0].title}
                    startDate={events[0].startDate}
                    finalDate={events[0].finalDate}
                    logo={events[0].logo}
                    link={events[0].link}
                    isGreen
                  />
                  <EventMore>
                    {events && <span>+{events.length - 1}</span>}
                  </EventMore>
                </>
              )}
            </MyEventsContainer>
          </div>
          <div>
            <OtherEventsTitleAndControllers>
              <TitleH4 $color='grey400' style={{ marginRight: 16 }}>
                Other Events
              </TitleH4>
              <ButtonCarousel
                disabled={carouselItem === 3}
                onClick={() => (carouselItem === 3 ? null : handleBack())}
              >
                <LeftSmall size={16} />
              </ButtonCarousel>
              <ButtonCarousel
                disabled={events ? carouselItem === events.length : false}
                onClick={() =>
                  events && carouselItem === events.length ? null : handleNext()
                }
              >
                <RightSmall size={16} />
              </ButtonCarousel>
            </OtherEventsTitleAndControllers>
            <OtherEventsContainer>
              <AliceCarousel
                responsive={responsive}
                ref={caroulselRefEvents}
                disableSlideInfo
              >
                {events &&
                  events.map((event) => (
                    <EventCard
                      key={`key_event_${event.id}`}
                      title={event.title}
                      startDate={event.startDate}
                      finalDate={event.finalDate}
                      logo={event.logo}
                      link={event.link}
                    />
                  ))}
              </AliceCarousel>
            </OtherEventsContainer>
          </div>
        </EventsContent>
      </ContainerEvents>

      <UnitedVideosContainer>
        <OtherEventsTitleAndControllers>
          <TitleH3 style={{ marginRight: 16 }}>United Videos</TitleH3>
          <ButtonCarousel
            disabled={carouselItemVideo === 2}
            onClick={() =>
              carouselItemVideo === 2 ? null : handleBackVideos()
            }
          >
            <LeftSmall size={16} />
          </ButtonCarousel>
          <ButtonCarousel
            disabled={carouselItemVideo === videosVault.length}
            onClick={() =>
              carouselItemVideo === videosVault.length
                ? null
                : handleNextVideos()
            }
          >
            <RightSmall size={16} />
          </ButtonCarousel>
        </OtherEventsTitleAndControllers>
        <VideosContent>
          <AliceCarousel
            responsive={responsiveVideos}
            ref={caroulselRefVideos}
            disableSlideInfo
          >
            {videosVault.map((video) => (
              <VideoHomeCard
                key={`key_video_${video.title}`}
                title={video.title}
                link={video.link}
                featuredImage={video.featureImage}
              />
            ))}
          </AliceCarousel>
        </VideosContent>
      </UnitedVideosContainer>
    </Container>
  );
};

export default Home;
