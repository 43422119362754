import React from 'react';

import { useGetActiveEvents } from 'hooks/v3/event/useGetActiveEvents/useGetActiveEvents';

import EventCard from 'components/v3/Cards/EventCard/EventCard';
import * as S from './styles';

export const DiscoverEvents = () => {
  const { data: response } = useGetActiveEvents();

  return (
    <S.EventsList>
      {response?.data?.content?.map((item) => {
        const eventStatus = item.draft ? 'draft' : 'published';
        return (
          <EventCard
            size='small'
            key={item.id}
            id={item.id}
            logo={item.logo}
            title={item.name}
            orientation='column'
            actionText='See event'
            eventStatus={eventStatus}
            description={item.description}
            startDate={item.startDate}
            finalDate={item.endDate}
            link={`/v3/event/${item.id}`}
          />
        );
      })}
    </S.EventsList>
  );
};
