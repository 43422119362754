import * as React from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import { ArrowRight } from '@icon-park/react';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import { TitleH2 } from 'styles/v3/variables';

import { useGetEventById } from 'hooks/v3/event/useGetEventById/useGetEventById';

import { useGetProductById } from 'hooks/v3/products/useGetProductById/useGetProductById';
import FilledButton from 'components/v3/Buttons/FilledButton';
import OrderService from 'services/v3/Order/OrderService';
import { CheckoutProps } from 'services/v3/Order/types';
import { NewAgeDivision } from 'admin/models/AgeDivision';
import { useGetMyClubsByUserType } from 'hooks/v3/clubs/useGetMyClubsByUserType/useGetMyClubsByUserType';
import { UserType } from 'redux/user/types';
import Loading from 'components/v3/Loading/Loading';
import * as S from './styles';

import { DivisionInfo } from './components/DivisionInfo/DivisionInfo';
import { CheckoutHeader } from './components/CheckoutHeader/CheckoutHeader';
import { CheckoutGeneralInfo } from './components/CheckoutGeneralInfo/CheckoutHeader';
import { CheckoutBox } from './components/CheckoutBox/CheckoutBox';

const CheckoutAsClubDirector: React.FC = () => {
  const history = useHistory();

  const [isLoadingPayment, setIsLoadingPayment] = React.useState(false);

  const params: { eventId: string } = useParams();

  const { data: currentEvent } = useGetEventById(params.eventId);

  const { data: rosterFeeProduct } = useGetProductById(
    currentEvent?.data?.paymentInfo?.rosterFeeProductId
  );

  const { data: myClubs } = useGetMyClubsByUserType(UserType.CLUB_DIRECTOR);

  const { divisions, currentDivisions, clubId } = useSelector(
    (state: ApplicationState) => state.signupEvent.data
  );

  console.log(currentDivisions);

  const ageDivisions = React.useMemo(() => {
    if (!currentDivisions) return [];

    return currentDivisions.filter((div) => !!div.targetNumberOfTeams);
  }, [currentDivisions]);

  const myClub = React.useMemo(() => {
    if (!myClubs || !clubId) return undefined;
    return myClubs?.find((club) => club.id === clubId) || undefined;
  }, [myClubs, clubId]);

  function handlePrevious() {
    history.push(`/v3/event/${params.eventId}/signup-as-club-director`);
  }

  const divisionInfoData = React.useMemo(() => {
    return ageDivisions.reduce<{ years: number[]; genders: string[] }>(
      (cur, acc) => {
        acc.years.forEach((y) => {
          if (!cur.years.includes(y)) {
            cur.years.push(y);
          }
        });
        if (!cur.genders.includes(acc.gender)) {
          cur.genders.push(acc.gender);
        }
        return cur;
      },
      {
        years: [],
        genders: [],
      }
    );
  }, [ageDivisions]);

  console.log(ageDivisions);

  const handleCheckout = async () => {
    if (rosterFeeProduct) {
      const checkoutDto: CheckoutProps = {
        cart: [
          {
            productId: rosterFeeProduct.id || '',
            quantity: divisions.reduce((cur, acc) => {
              return cur + acc.quantity;
            }, 0),
            type: 'EVENT_ROSTER_FEE',
            b2bEventRosterFee: {
              divisions: divisions.map((division) => ({
                id: division.divisionId,
                name: division.name,
                description: getDivisionInfo(
                  ageDivisions.find((d) => d.id === division.divisionId)
                ),
                quantity: division.quantity,
                itemPrice: rosterFeeProduct.formattedPrice,
                total: division.quantity * rosterFeeProduct.formattedPrice,
              })),
              eventId: currentEvent?.id || '',
              total: divisions.reduce((cur, acc) => {
                return cur + acc.quantity * rosterFeeProduct.formattedPrice;
              }, 0),
              club: {
                name: myClub?.name || '',
                id: myClub?.id || '',
                logo: myClub?.logo || '',
              },
            },
          },
        ],
        type: 'EVENT_ROSTER_FEE',
        cancelUrl: `/v3/event/${currentEvent?.id}/checkout-as-club-director`,
      };

      setIsLoadingPayment(true);
      OrderService.getCheckoutStripeUrl(checkoutDto)
        .then((res) => {
          if (res.httpCode === 200) {
            window.location.href = res.data.paymentUrl;
          } else if (
            res.message === 'This order was already paid for this Club'
          ) {
            alert('You have already paid for this event');
          } else {
            alert(
              res.message ||
                'Error on checkout check event information and try again'
            );
          }
        })
        .finally(() => {
          setIsLoadingPayment(false);
        });
    }
  };

  if (isLoadingPayment) {
    return <Loading />;
  }

  return (
    <S.CheckoutAsClubDirectorContainer>
      <S.DivisionWrapper>
        <S.Header>
          <TextButtonWithIcon
            color='primary'
            align='flex-end'
            reverse
            icon='back'
            onClick={handlePrevious}
          >
            Go back
          </TextButtonWithIcon>

          <TitleH2>SELECT YOUR AGE DIVISIONS</TitleH2>
        </S.Header>
        <DivisionInfo
          ageDivisions={ageDivisions}
          years={divisionInfoData.years}
          genders={divisionInfoData.genders}
          price={rosterFeeProduct?.formattedPrice}
          divisions={divisions}
        />
      </S.DivisionWrapper>
      <S.CheckoutWrapper>
        {currentEvent && (
          <>
            <CheckoutHeader
              image={currentEvent.logo}
              name={currentEvent.name}
            />
            <CheckoutGeneralInfo event={currentEvent} />
            <CheckoutBox
              price={rosterFeeProduct?.formattedPrice ?? 0}
              divisions={divisions}
              event={currentEvent}
            />
            <FilledButton
              className='btn-checkout'
              disabled={divisions.length === 0 || isLoadingPayment}
              onClick={handleCheckout}
              color='primary'
              isUpper
            >
              Continue To Payment <ArrowRight /> {isLoadingPayment && '...'}
            </FilledButton>
          </>
        )}
      </S.CheckoutWrapper>
    </S.CheckoutAsClubDirectorContainer>
  );
};

export default withRouter(CheckoutAsClubDirector);

export const getDivisionInfo = (division?: NewAgeDivision) => {
  if (!division) return '';

  const years = division.years.join('/');
  const allowGirls = division.allowGirlBoysOnAnotherDivision
    ? ' - Allow Girls'
    : '';
  const subtitle = `${division.gender} - ${years} ${division.rule
    .charAt(0)
    .toUpperCase()}${division.rule.slice(1).toLocaleLowerCase()} ${allowGirls}`;

  return subtitle;
};
