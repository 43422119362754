import * as React from 'react';
import { message } from 'antd';
import Icon, { IconType } from '@icon-park/react/es/all';

import { BodyM } from 'styles/v3/variables';
import * as S from './styles';

export interface InputFileProps {
  id: string;
  text: string;
  error?: boolean;
  isUpper?: boolean;
  className?: string;
  disabled?: boolean;
  ref?: React.RefObject<HTMLInputElement>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  format?: 'square' | 'circle';
  previewUrl?: string;
  icon?: IconType;
  required?: boolean;
}

const SIZE_LIMIT = 4 * 1024 * 1024;
const convertFileSizeToMb = (size: number) =>
  (Math.floor(size / 1024) / 1000).toFixed(2);

// eslint-disable-next-line react/display-name
const InputFile = React.forwardRef<HTMLInputElement, InputFileProps>(
  (
    {
      isUpper = false,
      error = false,
      text,
      id,
      format = 'circle',
      previewUrl,
      onChange,
      icon = 'AddPic',
      className,
      ...props
    },
    ref
  ) => {
    const imageMimeType = /^image\/(png|jpg|jpeg)$|^application\/(pdf)$/i;
    const [file, setFile] = React.useState<File | null>(null);
    const [fileDataURL, setFileDataURL] = React.useState<
      string | ArrayBuffer | null
    >(null);

    const clearImageHandler = (e: any) => {
      if (ref) {
        // eslint-disable-next-line no-param-reassign
        (ref as React.RefObject<HTMLInputElement>).current!.value = '';
      }
      setFile(null);
      setFileDataURL(null);
      if (onChange && previewUrl) {
        onChange(e);
      }
    };
    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      // eslint-disable-next-line no-shadow
      const file = e.target?.files && e.target?.files[0];
      if (file) {
        if (!file.type.match(imageMimeType)) {
          const errorMessage = 'Image mime type is not valid';
          message.error({
            content: errorMessage,
            duration: 5,
          });
          console.error(errorMessage);
          return;
        }
        if (file.size > SIZE_LIMIT) {
          const errorMessage = `${convertFileSizeToMb(
            file.size
          )} file size is too big!`;
          message.error({
            content: errorMessage,
            duration: 5,
          });
          console.error(errorMessage);
          return;
        }
        setFile(file);
      }
      if (onChange) {
        onChange(e);
      }
    };

    React.useEffect(() => {
      if (previewUrl) {
        setFileDataURL(previewUrl);
      }
    }, [previewUrl]);
    React.useEffect(() => {
      let fileReader: FileReader;
      let isCancel = false;
      if (file) {
        fileReader = new FileReader();
        fileReader.onload = (e: ProgressEvent<FileReader>) => {
          if (e?.target) {
            const { result } = e.target;
            if (result && !isCancel) {
              setFileDataURL(result);
            }
          }
        };
        fileReader.readAsDataURL(file);
      }
      return () => {
        isCancel = true;
        if (fileReader && fileReader.readyState === 1) {
          fileReader.abort();
        }
      };
    }, [file]);

    return (
      <S.Container
        $format={format}
        $error={error}
        className={`${className} input-file-container`}
      >
        <S.Input
          name={id}
          ref={ref}
          type='file'
          onChange={changeHandler}
          accept='image/png, image/jpeg, application/pdf'
          {...props}
        />
        {!fileDataURL && (
          <S.Label $isUpper={isUpper}>
            <Icon type={icon} size={24} />
            {text}
          </S.Label>
        )}
        {fileDataURL && typeof fileDataURL === 'string' ? (
          <S.ImagePreviewWrapper>
            <S.ImageClearWrapper
              className='image-clear-wrapper'
              onClick={clearImageHandler}
            >
              <BodyM>REMOVE</BodyM>
            </S.ImageClearWrapper>
            <img src={fileDataURL} alt='preview' />
          </S.ImagePreviewWrapper>
        ) : null}
      </S.Container>
    );
  }
);

export default InputFile;
