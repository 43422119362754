import React from 'react';
import { RoastersForClub } from './components/RoastersForClub';

export const RoastersOpen = () => {
  return (
    <>
      <RoastersForClub />
    </>
  );
};
