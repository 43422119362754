import React, { FunctionComponent } from 'react';
import { BodyL } from 'styles/v3/variables';

import play from 'assets/imgs/icons/icon_play_video.svg';

// eslint-disable-next-line import/named
import { VideoContainer, VideoContentContainer } from './styles';

interface VideoHomeCardProps {
  title: string;
  link: string;
  featuredImage: string;
}

export const VideoHomeCard: FunctionComponent<VideoHomeCardProps> = ({
  title,
  link,
  featuredImage,
  ...props
}) => {
  return (
    <VideoContainer {...props}>
      <a href={link} target='_blank' rel='noopener noreferrer'>
        <VideoContentContainer backgroundImg={featuredImage}>
          <div className='video-btn'>
            <img className='video-icon' src={play} alt='Play Video Icon' />
          </div>
        </VideoContentContainer>
      </a>
      <BodyL>{title}</BodyL>
    </VideoContainer>
  );
};
