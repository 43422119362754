import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const RefereeApplicationContainer = styled.div`
  display: flex;

  gap: ${toRem(126)};
`;

export const RefereeApplicationConditionsContainer = styled.div`
  width: 50%;
`;

export const ContainerInformationCondition = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${toRem(24)};

  gap: ${toRem(24)};
`;

export const EventInfoContainer = styled.div`
  width: 30%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  padding: ${toRem(30)};
  gap: ${toRem(30)};

  background-color: ${COLORS.grey950};
  border-radius: ${toRem(24)};

  .btn-deny-invite {
    border: 1px solid ${COLORS.grey50};
    background-color: ${COLORS.grey950};

    &:hover {
      background-color: ${COLORS.grey900};
      color: ${COLORS.grey50};
    }
    .i-icon-close::before {
      display: none;
    }
  }
`;

export const RefereeUploadSafeSportContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(8)};
`;

export const EventImage = styled.div`
  width: ${toRem(140)};
  height: ${toRem(140)};
  border-radius: ${toRem(140)};

  margin: 0 auto;
  overflow: hidden;

  background-color: ${COLORS.brandPrimary};
  margin-bottom: ${toRem(14)};

  img {
    object-fit: cover;
    max-width: 100%;

    width: ${toRem(140)};
    height: ${toRem(140)};
  }
`;

export const EventInformationAndImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${toRem(16)};
`;

export const GeneralInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(16)};

  span {
    display: flex;
    margin-right: ${toRem(12)};
  }
`;

export const AgreeConditionsContainer = styled.div`
  display: flex;
  gap: ${toRem(12)};
`;

export const Information = styled.div`
  width: 100%;
  height: 60vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InformationContainer = styled.div`
  width: ${toRem(588)};

  padding: ${toRem(16)} ${toRem(30)} ${toRem(30)};
  background-color: ${COLORS.grey900};

  border-radius: ${toRem(32)};
  border: ${toRem(4)} solid ${COLORS.grey600};

  box-shadow: 0 0 ${toRem(240)} ${toRem(30)} ${COLORS.shadowBrandPrimary};

  display: flex;
`;

export const EventImageInformation = styled.div`
  width: ${toRem(100)};
  height: ${toRem(100)};
  border-radius: ${toRem(100)};

  margin-top: ${toRem(16)};
  overflow: hidden;

  background-color: ${COLORS.brandPrimary};

  img {
    object-fit: cover;
    max-width: 100%;

    width: ${toRem(100)};
    height: ${toRem(100)};
  }
`;

export const InformationContent = styled.div`
  margin-left: ${toRem(32)};

  display: flex;
  flex-direction: column;
  padding: ${toRem(16)} ${toRem(15)} 0 0;
  justify-content: center;
  align-items: flex-start;

  flex: 1 0 0;

  gap: ${toRem(16)};

  button {
    align-self: start;
  }
`;
