import {AxiosError, AxiosRequestConfig} from 'axios';
import ServiceResponse from 'services/util/ServiceResponse';

import APIServices from 'services/util/ApiServices';
import { UserViewAccounts } from 'models/User/UserViewAccounts';
import { DeepPartial } from 'redux';
import { PatchBaseUser } from 'redux/v3/baseUsers/types';
import { User } from 'models/User/UserModel';
import { ConfigApi } from '../../config.api';
import { GetProfiles } from './types';

interface UserDocsResponse {
  photoUser: string;
  ageProofUser: string;
  safeSSportUser: string;
}
export default class UserService {
  public static async getProfiles(): Promise<ServiceResponse<GetProfiles>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `users/profile-accounts`,
      method: 'GET',
    };
    return APIServices.request<GetProfiles>(axiosConfig);
  }

  public static async getUser(
    id?: string
  ): Promise<ServiceResponse<User>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/users/find-user/${id}`,
      method: 'GET',
    };
    return APIServices.request<User>(axiosConfig);
  }

  public static async getBaseUserInfo(
    id?: string
  ): Promise<ServiceResponse<User>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/base-users/${id}`,
      method: 'GET',
    };
    return APIServices.request<User>(axiosConfig);
  }

  public static async getUserInfo(
    id?: string
  ): Promise<ServiceResponse<UserViewAccounts | undefined>> {
    if (!id) return ServiceResponse.success(undefined, '');
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `users/${id}/info`,
      method: 'GET',
    };
    return APIServices.request<UserViewAccounts>(axiosConfig);
  }

  public static async getUserDocsV3(
    id?: string
  ): Promise<ServiceResponse<UserDocsResponse | undefined>> {
    if (!id) return ServiceResponse.success(undefined, '');
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `users/${id}/v3/documents`,
      method: 'GET',
    };
    return APIServices.request<UserDocsResponse>(axiosConfig);
  }

  public static async updateUser(
    id: string,
    data: DeepPartial<PatchBaseUser>,
    isBaseUser: boolean,
    userType?: string
  ): Promise<ServiceResponse<AxiosError>> {
    const url = isBaseUser ? `/base-users/${id}/v3` : `/users/${id}/v3`;
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url,
      params: {
        userType
      },
      method: 'PUT',
      data,
    };
    return APIServices.request<AxiosError>(axiosConfig);
  }
}
