import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const VideoContainer = styled.div`
  margin-top: ${toRem(32)};
`;

export const VideoContentContainer = styled.div<{ backgroundImg: string }>`
  width: ${toRem(486)};
  height: ${toRem(273.375)};

  background: ${({ backgroundImg }) =>
    `linear-gradient(0deg, #000 -35.51%, rgba(0, 0, 0, 0.00) 118.82%), url(${backgroundImg}), lightgray 50% / cover no-repeat`};
  background-size: cover;

  border-radius: ${toRem(24)};
  margin-bottom: ${toRem(16)};

  .video-btn {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: ${toRem(66)};
    height: ${toRem(66)};

    opacity: 0.6;

    border-radius: ${toRem(66)};
    background-color: ${COLORS.grey200};

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: ${toRem(33)};
      height: ${toRem(33)};

      padding-left: ${toRem(4)};
    }

    transition: ease-in-out 0.4s;

    :hover {
      opacity: 1;
    }
  }
`;
