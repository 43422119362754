import React from 'react';
import { Link } from 'react-router-dom';
import ConvertUtil from 'util/ConvertUtil';

import { BodyS, TitleH4 } from 'styles/v3/variables';
import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';
import { RoundedCardProps } from '../RoundedCard/RoundedCard';

import * as S from './styles';
import { PermissionIcon } from './components/PermissionIcon/PermissionIcon';

interface MyEventCardProps extends RoundedCardProps {
  title: string;
  description: string;
  eventStatus: 'draft' | 'published' | 'roaster locked' | 'done';
  id: string;
  link: string;
  logo?: string;
  startDate: Date | string;
  finalDate: Date | string;
  actionText?: string;
  permission: {
    isPlayer: boolean,
    isCoach: boolean,
    isReferee: boolean,
  };
}

const MyEventCard: React.FC<MyEventCardProps> = ({
  title,
  description,
  size,
  orientation,
  logo,
  eventStatus,
  id,
  link,
  startDate,
  finalDate,
  actionText = 'Manage',
  permission,
  ...props
}) => {
  const startAt = new Date(
    startDate.toString().replace('[UTC]', '')
  ).toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });

  const endAt = new Date(
    finalDate.toString().replace('[UTC]', '')
  ).toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });

  const startEvent = new Date(startDate).getTime();
  const today = new Date().getTime();
  const finalEvent = new Date(finalDate).getTime();

  const isHappening = today >= startEvent && today <= finalEvent;

  return (
    <S.EventCardWrapper isHappening={isHappening}>
      <S.EventContentWrapper>
        <S.EventIcon>
          <img src={ConvertUtil.getMinioUrl(logo)} alt='Event Logo' />
        </S.EventIcon>

        <S.EventContent>
          <S.EventDates>
            {isHappening && <S.IsTodayEvent>Today</S.IsTodayEvent>}
            <S.EventDateOccurrence>
              {startAt} - {endAt}
            </S.EventDateOccurrence>
          </S.EventDates>

          <TitleH4>{title}</TitleH4>
          <BodyS $color='grey300'>{description}</BodyS>
          <S.PermissionWrapper>
            {permission.isPlayer && <PermissionIcon title='Player' />}
            {permission.isCoach && <PermissionIcon title='Coach' />}
            {permission.isReferee && <PermissionIcon title='Referee' />}
          </S.PermissionWrapper>
        </S.EventContent>

        <S.EventBtnActions>
          <Link to={link}>
            <OutlinedButtonWithIcon color='dark-white' isBold={false} isUpper>
              {actionText}
            </OutlinedButtonWithIcon>
          </Link>
        </S.EventBtnActions>
      </S.EventContentWrapper>
    </S.EventCardWrapper>
  );
};

export default MyEventCard;
