import * as React from 'react'

import * as S from './styles'

interface InputCheckboxProps {
  value?: string | number
  name: string
  id: string
  checked?: boolean
  disabled?: boolean
  theme?: 'primary' | 'light'
  onChange: () => void
}

const InputCheckbox: React.FC<InputCheckboxProps> = ({
  id,
  theme = 'primary',
  disabled = false,
  ...props
}) => {
  return (
    <S.Container>
      <S.Item>
        <S.CheckboxButton  type='checkbox' disabled={disabled} id={id} $theme={theme} {...props} />
        <S.CheckboxButtonLabel htmlFor={id} $theme={theme} />
      </S.Item>
    </S.Container>
  )
}

export default InputCheckbox
