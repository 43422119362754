import React, { FunctionComponent, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';
import UserType from 'models/User/UserTypeModel';
import { useGetRostersByClubId } from 'hooks/v3/clubs/useGetRostersByClubId/useGetRostersByClubId';
import { Filters } from '../Filters/Filters';
import { RosterDivision } from './components';

export const RoastersForClub: FunctionComponent = () => {
  const params: { clubId: string } = useParams();
  const { data: currentClub } = useGetClubById(params.clubId);
  const [currentPage, setCurrentPage] = useState(1);
  const { data: rostersClub } = useGetRostersByClubId({
    clubId: params.clubId,
    page: currentPage - 1,
  });

  const [filterRoster, setFilterRoster] = useState('');

  const {
    clubDirector: {
      memberPool: { selectedUserIds },
    },
  } = useSelector((state: ApplicationState) => state.clubDashboard);

  const handleFilter = (text: string) => {
    setFilterRoster(text);
  };

  const rosterClubData = useMemo(() => {
    if (!rostersClub?.content.length || !currentClub) return [];

    const getPermissionType = (
      userId: string,
      permission?: UserType
    ): UserType => {
      if (currentClub.directorIds?.includes(userId))
        return UserType.CLUB_DIRECTOR;
      if (currentClub.coachIds?.includes(userId)) return UserType.COACH;
      if (currentClub.teamManagers?.map((t) => t.userId).includes(userId))
        return UserType.TOURNAMENT_DIRECTOR;
      if (currentClub.playerIds?.includes(userId)) return UserType.PLAYER;
      return permission || UserType.PLAYER;
    };

    return rostersClub.content
      .map((roster) => {
        return {
          id: roster.id as string,
          eventName: roster.event?.name ?? '',
          eventLogo: roster.event?.logo ?? '',
          rosterName: roster.name ?? '',
          totalOfCoachs: roster.coaches.length,
          totalOfPlayers: roster.players.length,
          status: roster.status ?? 'PENDING',
        };
      })
      .filter((roster) =>
        !filterRoster
          ? true
          : roster.eventName.toLowerCase().includes(filterRoster.toLowerCase())
      );
  }, [selectedUserIds, filterRoster, currentClub]);

  console.log('rosterClubData', rostersClub);

  return (
    <>
      <Filters handleChange={handleFilter} />
      {rosterClubData.map((roster) => (
        <RosterDivision
          key={`key_roster_${roster.rosterName}`}
          id={roster.id}
          eventLogo={roster.eventLogo}
          eventName={roster.eventName}
          rosterName={roster.rosterName}
          status={roster.status}
          totalOfCoachs={roster.totalOfCoachs}
          totalOfPlayers={roster.totalOfPlayers}
        />
      ))}
    </>
  );
};
