import React, { FunctionComponent } from 'react';
import ConvertUtil from 'util/ConvertUtil';

import { BodyM } from 'styles/v3/variables';

import { Container, LogoTeam } from './styles';

interface TeamInformationProps {
  logo: string
  teamName: string
}

export const TeamInformation: FunctionComponent<TeamInformationProps> = ({
  logo,
  teamName
}) => {
  console.log( 'TEAM INFORMATION')
  return (
  <Container>
    <LogoTeam>
      <img src={ConvertUtil.getMinioUrl(logo)} alt='Team Logo' />
    </LogoTeam>
    <BodyM $color='grey200' $isUpper={false} >{teamName}</BodyM>
  </Container>
  )
}
