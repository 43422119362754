import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
`;

export const Button = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  cursor: pointer;

  border-radius: 12px;
  background: ${COLORS.grey800};

  .i-icon svg {
    width: 24px;
    height: 24px;
    display: flex;
    &::before {
      display: none;
    }

    path {
      stroke: ${COLORS.grey50};
    }
  }
`;
