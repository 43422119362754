import { AxiosRequestConfig } from 'axios';
import { User } from '../../models/User/UserModel';
import { ConfigApi } from '../config.api';
import APIServices from '../util/ApiServices';
import ServiceResponse from '../util/ServiceResponse';

class DocumentsVerificationServiceV3 {
  public static async approve(
    id: string,
    docType: 'photo' | 'age' | 'documents',
    urlParams: URLSearchParams
  ): Promise<ServiceResponse<User>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/verifications/${docType}/${id}/approval`,
      method: 'GET',
      params: urlParams,
    };
    return APIServices.request<User>(axiosConfig);
  }

  public static async decline(
    id: string,
    docType: 'photo' | 'age' | 'documents',
    feedback: string,
    baseUser = false
  ): Promise<ServiceResponse<User>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/verifications/${docType}/${id}/decline`,
      method: 'POST',
      data: { feedback, baseUser },
    };
    return APIServices.request<User>(axiosConfig);
  }
}

export default DocumentsVerificationServiceV3;
