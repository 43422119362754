import styled from 'styled-components';
import ModalCard from 'components/v3/Cards/ModalCard/ModalCard';
import { COLORS } from 'styles/v3/variables';

export const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;

  gap: 16px;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 42px;
`;

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  gap: 32px;

  transition: all 0.5;
`;

export const EventCardDivision = styled(ModalCard)`
  span {
    min-height: 45px;
  }
`;

export const SelectWrapper = styled.div`
  max-width: 280px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 16px;

  .vault-select__control {
    min-height: 25px;

    .vault-select__multi-value {
      color: ${COLORS.grey50};
      background-color: unset;

      .vault-select__multi-value__remove {
        background-color: unset;
        cursor: pointer;
      }
    }

    .vault-select__multi-value__label {
      color: ${COLORS.grey50};
    }
  }
`;
