import { useMutation, useQueryClient } from '@tanstack/react-query';
import B2bSetupEventService from 'services/v3/B2bSetupEvent/SetupEventService';
import { EventUpdatePricing } from 'services/v3/B2bSetupEvent/types';
import { useDispatch } from 'react-redux';

import { notification } from 'components/v3/Notification/notification';
import { b2bSetupEventFetchEventRequest } from 'redux/v3/b2bSetupEvent/actions';
import { UsePostUpdatePriceInfoProps } from './types';

export function usePostUpdatePriceInfo(dto: UsePostUpdatePriceInfoProps) {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const { mutate, data, isLoading, error } = useMutation({
    mutationFn: (dataData: EventUpdatePricing) =>
      B2bSetupEventService.updatePricingInfo(dto.eventId, dataData),
    onSuccess: () => {
      queryClient.invalidateQueries(['get-product-coupons', dto.productId]);
      queryClient.invalidateQueries([
        'get-used-codes-for-account',
        dto.accountId,
      ]);
      notification.success({
        message: 'Successfully updated event',
      });

      dispatch(b2bSetupEventFetchEventRequest(dto.eventId));
    },
  });

  return {
    mutate,
    data,
    isLoading,
    error,
  };
}
