import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  button {
    min-height: 0;
    max-height: 48px;
    color: ${COLORS.grey300};
  }
`;

export const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 4px;

  h1 {
    margin-bottom: 0;
  }
`;
