import * as React from 'react';
import { PDFReader } from 'reactjs-pdf-reader';
import { message as MessageSnackbar } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Upload } from '@icon-park/react';
import moment from 'moment';

import { BodyM, BodyMBold, BodyS, BodySBold } from 'styles/v3/variables';
import InputFile from 'components/v3/Forms/InputFile/InputFile';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import InputDate from 'components/v3/Forms/InputDate/InputDate';
import { StatusChip } from 'components/v3/Cards/DocumentCard/styles';

import {
  DocumentStatusLabel,
  SafeSportType,
} from 'redux/v3/baseUsers/types';
import { ApplicationState } from 'redux/store';

import { RequestStatus } from 'models/Request/RequestModel';
import { Clubs } from 'models/User/UserModel';

import ConvertUtil from 'util/ConvertUtil';

import UploadService from 'services/upload/UploadService';
import DownloadService from 'services/download/DownloadService';
import UserServices from 'services/v3/User/UserService';

import ExamplePhoto from 'assets/imgs/passport.jpg';
import { useLocation } from 'react-router-dom';
import { setCurrentUser } from 'redux/v3/currentUser/actions';
import { usersFetchSuccess } from 'redux/v3/users/actions';
import { FooterProps, ProfilePhotoModalProps } from './ProfilePhotoModal';
import OutlinedButtonWithIcon from '../Buttons/OutlinedButtonWithIcon';
import FilledButton from '../Buttons/FilledButton';
import OutlinedButton from '../Buttons/OutlinedButton';
import UploadModal from './UploadModal';

import * as S from './styles';

const ModalFooterInfo = () => (
  <>
    <BodyMBold $color='white'>Why do we need this?</BodyMBold>
    <BodyS $color='grey200' $spanColor='brandPrimary' className='tip-text'>
      We require your SafeSport to make sure you are able to act as a coach,
      referee or club director.
    </BodyS>
  </>
);

const SendFileFooter: React.FC<FooterProps> = ({
  setStep,
  isLoading,
  sendToApproval,
}) => (
  <S.ButtonsWrapper>
    <OutlinedButton isUpper onClick={() => setStep(0)} color='white-dark'>
      Change File
    </OutlinedButton>
    <FilledButton
      className='button-approval'
      isUpper
      onClick={sendToApproval}
      disabled={isLoading}
    >
      Continue
    </FilledButton>
  </S.ButtonsWrapper>
);

const ResendFileFooter: React.FC<{ setStep: (e: number) => void }> = ({
  setStep,
}) => (
  <S.ButtonsWrapper>
    <OutlinedButtonWithIcon
      isUpper
      color='white-dark'
      customIcon={<Upload size={24} />}
      onClick={() => setStep(0)}
    >
      Reupload File
    </OutlinedButtonWithIcon>
  </S.ButtonsWrapper>
);

const ResendFileFooterInfo: React.FC<{ reason: string }> = ({ reason }) => (
  <>
    <BodyMBold>Why was my SafeSport denied?</BodyMBold>
    <BodyS className='tip-text'>{reason}</BodyS>
  </>
);

const ApprovedFooter: React.FC<{ date: string }> = ({ date }) => (
  <S.DateWrapper>
    <BodyM $color='grey200'>Expiration Date</BodyM>&nbsp;
    <BodyMBold $color='grey200'>{date}</BodyMBold>
  </S.DateWrapper>
);

const typeOptions: OptionsType[] = [
  { label: 'SafeSport Trained US Soccer Federation course', value: 'TRAINED' },
  {
    label: 'Refresher 1: Recognizing and Reporting Misconduct US Soccer',
    value: 'REFRESHER_1',
  },
  {
    label: 'Refresher 2: Preventing Misconduct US Soccer',
    value: 'REFRESHER_2',
  },
  {
    label: 'Refresher 3: Creating a Positive Sport Environment US Soccer',
    value: 'REFRESHER_3',
  },
];

type Fields = {
  type: { value: string; error: boolean; required: boolean };
  date: { value: string; error: boolean; required: boolean };
};

const SafeSportModal: React.FC<ProfilePhotoModalProps> = ({
  status,
  user,
  ...props
}) => {
  const formRef = React.useRef<HTMLFormElement>(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const userType = searchParams.get('type') ?? '';

  const [step, setStep] = React.useState<number>(0);
  const [filePath, setFilePath] = React.useState<string>('');
  const [filePathConverted, setFilePathConverted] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [fileType, setFileType] = React.useState<'PDF' | 'IMAGE' | ''>('');
  const [fileList, setFileList] = React.useState<FileList | null>(null);

  const stateFields: Fields = {
    type: { value: '', error: false, required: true },
    date: { value: '', error: false, required: true },
  };
  const [fields, setFields] = React.useState<Fields>(stateFields);

  const dispatch = useDispatch();
  const { data: currentUser } = useSelector(
    (state: ApplicationState) => state.currentUser
  );

  const isBaseUser = React.useMemo(() => {
    if (!currentUser) return false
    return Array.isArray(currentUser?.types)
  }, [currentUser]);


  const upload = async () => {
    setIsLoading(true);
    try {
      await UploadService.upload({
        file: fileList![0],
        prefix: 'user',
        public: false,
      }).then((response) => {
        if (!response.success) {
          MessageSnackbar.error({ content: response.message, duration: 5 });
          return;
        }
        const url = response.data.id;
        setFilePath(url);

        DownloadService.download(url).then((res) => {
          if (res.success) {
            setFilePathConverted(res.data);
            setIsLoading(false);
          }
        });
      });
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        MessageSnackbar.error({ content: e.message, duration: 5 });
      }
    }
  };

  const sendToApproval = async () => {
    const documentationVerified = RequestStatus.PENDING;
    try {
      if (formRef.current) {
        const formData: FormData = new FormData(formRef.current);
        setIsLoading(true);

        if (isBaseUser) {
          const payload: any = {
            safesportType: formData.get('type') as SafeSportType,
            safesportValidUntil: formData.get('date') as string,
            extraInfo: {
              ...currentUser?.extraInfo,
              certificate: filePath,
            },
          };


          UserServices.updateUser(`${currentUser.id}`, payload, isBaseUser, userType).then(async (res) => {
            if (res.success) {
              MessageSnackbar.success('User data Updated');


              const { data: updatedProfile } = await UserServices.getProfiles()

              const updatedBaseUser = updatedProfile?.baseUsers?.find(u => u.id === currentUser.id)

              if (updatedBaseUser) {
                dispatch(setCurrentUser(updatedBaseUser));
                const newUsers = [...(updatedProfile.baseUsers || []), ...(updatedProfile.users || [])];
                dispatch(usersFetchSuccess(newUsers));
              }

              setIsLoading(false);
              setStep(2);
            } else {
              setIsLoading(false);
              MessageSnackbar.error(`Error${res.data}`);
            }
          });

        } else {
          const { id = '' } = currentUser;
          const payload: any = {
            id: currentUser.id ?? '',
            ...(currentUser.clubs
              ? {
                clubs: [...(currentUser.clubs as Clubs[])],
              }
              : {
                clubs: [{ id: '', logo: '', name: '' }],
              }),
            safesportType: formData.get('type') as SafeSportType,
            safesportValidUntil: formData.get('date') as string,
            extraInfo: {
              ...currentUser?.extraInfo,
              certificate: filePath,
            },
          };
          UserServices.updateUser(id, payload, isBaseUser, userType).then(async (res) => {
            if (res.success) {
              MessageSnackbar.success('User data Updated');

              const { data: updatedProfile } = await UserServices.getProfiles()

              const myNewBaseUser = updatedProfile?.baseUsers?.find(u => u.userIds?.includes(`${currentUser.id}`))

              if (myNewBaseUser) {
                dispatch(setCurrentUser(myNewBaseUser));
                const newUsers = [...(updatedProfile.baseUsers || []), ...(updatedProfile.users || [])];
                dispatch(usersFetchSuccess(newUsers));
              }

              setIsLoading(false);
              setStep(2);
            } else {
              setIsLoading(false);
              MessageSnackbar.error(`Error${res.data}`);
            }
          });
        }
      }
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        MessageSnackbar.error({ content: e.message, duration: 5 });
      }
    }
  };

  const handleChangeSelect = (field: keyof Fields, e: any) => {
    setFields({
      ...fields,
      [field]: {
        ...fields[field],
        value: e.value,
        error: false,
      },
    });
    setIsLoading(false);
  };

  const step0 = () => (
    <S.ModalContentUploadPhoto>
      <S.ModalContentWrapperPhoto>
        <BodyM className='example-title'>Example photo</BodyM>
        <S.WrapperImage>
          <img src={ExamplePhoto} alt='Example' />
        </S.WrapperImage>
      </S.ModalContentWrapperPhoto>
      <S.ModalContentWrapperPhoto>
        <BodyM className='example-title'>Choose your file</BodyM>
        <InputFile
          id='safesportFile'
          text='Upload File'
          icon='UploadLogs'
          format='square'
          onChange={(e: any) => {
            setFileList(e.target.files);
          }}
        />
      </S.ModalContentWrapperPhoto>
    </S.ModalContentUploadPhoto>
  );

  const step1 = () => (
    <S.Form ref={formRef} onSubmit={(e: any) => e.preventDefault()}>
      <S.InputsWrapper>
        <Select
          required
          name='type'
          placeholder='Select Type'
          options={typeOptions}
          onChange={(e: any) => handleChangeSelect('type', e)}
          error={fields.type.error}
          className='document-input'
        />
        <InputDate
          required
          id='date'
          placeholder='Completion date'
          label='Completion date'
          value={fields.date.value}
          onChange={(e: any) =>
            setFields({
              ...fields,
              date: { ...fields.date, value: e.target.value, error: false },
            })
          }
          className='document-input'
        />
      </S.InputsWrapper>
      {fileType !== '' && filePathConverted !== '' && (
        <S.ChoseFileWrapper $type='square'>
          {fileType === 'PDF' ? (
            <S.PdfWrapper>
              <PDFReader
                url={ConvertUtil.getPrivateMinioUrl(filePathConverted)}
                scale={0.5}
                showAllPage
              />
            </S.PdfWrapper>
          ) : (
            <img
              src={`${ConvertUtil.getPrivateMinioUrl(filePathConverted)}`}
              alt='User Document'
            />
          )}
        </S.ChoseFileWrapper>
      )}
    </S.Form>
  );

  const step2 = () => (
    <>
      <S.StatusHeader>
        <BodyM>DOCUMENT STATUS</BodyM>
        <StatusChip $status={status}>
          <BodySBold
            $isUpper
            $color={
              status === DocumentStatusLabel.DECLINED ||
                status === DocumentStatusLabel.EXPIRED
                ? 'white'
                : 'grey800'
            }
          >
            {status}
          </BodySBold>
        </StatusChip>
      </S.StatusHeader>
      {fileType !== '' && filePathConverted !== '' && (
        <S.ChoseFileWrapper $status={status} $type='square'>
          {fileType === 'PDF' ? (
            <S.PdfWrapper>
              <PDFReader
                url={ConvertUtil.getPrivateMinioUrl(filePathConverted)}
                scale={0.5}
                showAllPage
              />
            </S.PdfWrapper>
          ) : (
            <img
              src={`${ConvertUtil.getPrivateMinioUrl(filePathConverted)}`}
              alt='User Document'
            />
          )}
        </S.ChoseFileWrapper>
      )}
    </>
  );

  const renderFooter = () => {
    if (step === 1) {
      return (
        <SendFileFooter
          isLoading={
            fields.type.value === '' || fields.date.value === '' || isLoading
          }
          sendToApproval={sendToApproval}
          setStep={setStep}
        />
      );
    }
    if (step === 2) {
      const formatDate = moment(currentUser?.safesportValidUntil).format(
        'MM/DD/YYYY'
      );
      return status === DocumentStatusLabel.DECLINED ||
        status === DocumentStatusLabel.EXPIRED ? (
        <ResendFileFooter setStep={setStep} />
      ) : (
        status === DocumentStatusLabel.APPROVED && (
          <ApprovedFooter date={formatDate} />
        )
      );
    }
    return true;
  };

  const renderFooterInfo = () => {
    if (step === 0) {
      return <ModalFooterInfo />;
    }
    if (step === 2) {
      return (
        status === DocumentStatusLabel.DECLINED &&
        currentUser?.feedbacks?.document && (
          <ResendFileFooterInfo reason={currentUser.feedbacks.document} />
        )
      );
    }
    return true;
  };

  React.useEffect(() => {
    if (step === 0) {
      setFilePath('');
      setFilePathConverted('');
    }
  }, [step]);

  React.useEffect(() => {
    if (fileList) {
      setStep(1);
      if (fileList[0]) {
        if (fileList[0].type === 'application/pdf') {
          setFileType('PDF');
        } else {
          setFileType('IMAGE');
        }
        upload();
      }
    }
  }, [fileList]);

  React.useEffect(() => {
    if (currentUser?.extraInfo?.certificate) {
      DownloadService.download(currentUser?.extraInfo?.certificate).then((res) => {
        if (res.success) {
          setFilePathConverted(res.data);
          fetch(ConvertUtil.getPrivateMinioUrl(res.data))
            .then((blobResp) => blobResp.blob())
            .then((blob) => {
              if (blob.type === 'application/pdf') {
                setFileType('PDF');
              } else {
                setFileType('IMAGE');
              }
            });
        }
      });
    }
  }, [currentUser?.extraInfo?.certificate]);

  React.useEffect(() => {
    if (currentUser?.extraInfo?.certificate) {
      setStep(2);
    }
  }, []);


  console.log('userType', userType)
  return (
    <UploadModal
      {...props}
      title='Your SafeSport'
      footer={renderFooter()}
      footerInfo={renderFooterInfo()}
      onAfterClose={() => {
        return currentUser?.extraInfo?.certificate ? false : setStep(0);
      }}
    >
      {step === 0 && step0()}
      {step === 1 && step1()}
      {step === 2 && step2()}
    </UploadModal>
  );
};

export default SafeSportModal;
