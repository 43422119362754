import React from 'react';

import { BodyM, BodyMBold } from 'styles/v3/variables';
import InputText from 'components/v3/Forms/InputText/InputText';
import { Dropdown, Menu } from 'antd';
import { Down } from '@icon-park/react';
import * as S from './styles';
import { status } from './status';

type FiltersProps = {
  filterValue: string;
  setFilterValue: React.Dispatch<React.SetStateAction<string>>;
};

export const Filters = ({ filterValue, setFilterValue }: FiltersProps) => {
  return (
    <S.Container>
      <S.FilterWrapper>
        <BodyMBold $isUpper $color='grey500'>
          Filter by
        </BodyMBold>
        <Dropdown
          overlay={
            <Menu>
              {status.map((item) => (
                <Menu.Item key={item.key}>
                  <BodyM $color='grey200'>{item.name}</BodyM>
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <S.FilterText>
            <BodyM $isUpper $color='grey100'>
              Status
            </BodyM>
            <Down />
          </S.FilterText>
        </Dropdown>
      </S.FilterWrapper>
      <InputText
        id='search'
        icon='Search'
        placeholder='Search'
        value={filterValue}
        onChange={({ target }) => setFilterValue(target.value)}
      />
    </S.Container>
  );
};
