import styled from 'styled-components';

export const ReleaseTermsWrapper = styled.div`
  margin: 0 auto;
  max-width: 588px;

  h1 {
    margin-bottom: 1rem;
  }

  .caption {
    margin-bottom: 1.5rem;
  }
`;
