import * as React from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { message as MessageSnackbar } from 'antd';

import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import InputText from 'components/v3/Forms/InputText/InputText';
import InputPhone from 'components/v3/Forms/InputPhone/InputPhone';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import { BreadcrumbItemType } from 'components/v3/Breadcrumb/Breadcrumb';

import { User } from 'models/User/UserModel';
import { ApplicationState } from 'redux/store';
import { PatchBaseUser } from 'redux/v3/baseUsers/types';
import { baseUserUpdateData } from 'redux/v3/baseUsers/actions';
import { useGetUser } from 'hooks/v3/users/useGetUser/useGetUser';

import { DeepPartial } from 'util/types';

import BaseUserService from 'services/v3/BaseUsers/BaseUsersApi';

import { BodyXL } from 'styles/v3/variables';
import breadcrumbItems from './breadcrumb.json';
import CompleteUserTemplate from '../Template/Template';
import * as S from '../Template/styles';
import { useGetBaseUser } from '../../../../../hooks/v3/users/useGetBaseUser/useGetBaseUser';


type Fields = {
  firstName: { value: string; error: boolean; required: boolean };
  middleName: { value: string; error: boolean; required: boolean };
  lastName: { value: string; error: boolean; required: boolean };
  suffix: { value: string; error: boolean; required: boolean };
  selectPhone: { value: string; error: boolean; required: boolean };
  selectEmail: { value: string; error: boolean; required: boolean };
  newPhone?: { value: string; error: boolean; required: boolean };
  newEmail?: { value: string; error: boolean; required: boolean };
};

const LegalGuardian = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const formRef = React.useRef<HTMLFormElement>(null);
  const { userId, data } = useSelector(
    (state: ApplicationState) => state.baseUsers
  );
  const userData = data.find((u) => u?.id === userId);
  const guardianData = userData?.guardian;

  const stateFields: Fields = {
    firstName: {
      value: guardianData?.firstName ?? '',
      error: false,
      required: true,
    },
    middleName: {
      value: guardianData?.middleName ?? '',
      error: false,
      required: false,
    },
    lastName: {
      value: guardianData?.lastName ?? '',
      error: false,
      required: true,
    },
    suffix: {
      value: guardianData?.suffix ?? '',
      error: false,
      required: false,
    },
    selectEmail: {
      value: guardianData?.email ?? userData?.accountEmail ?? '',
      error: false,
      required: true,
    },
    selectPhone: {
      value: guardianData?.phoneNumber ?? userData?.phoneNumber ?? '',
      error: false,
      required: true,
    },
  };

  const emailOptions: OptionsType[] = [
    {
      label: stateFields.selectEmail.value,
      value: stateFields.selectEmail.value,
    },
    {
      label: 'New Email',
      value: 'new-email',
    },
  ];
  const phoneOptions: OptionsType[] = [
    {
      label: stateFields.selectPhone.value,
      value: stateFields.selectPhone.value,
    },
    {
      label: 'New Phone',
      value: 'new-phone',
    },
  ];
  const setLabelToEmail = emailOptions.find(
    (e) => e.value === stateFields.selectEmail.value
  );
  const setLabelToPhone = phoneOptions.find(
    (p) => p.value === stateFields.selectPhone.value
  );

  const [fields, setFields] = React.useState<Fields>(stateFields);
  const [validating, setValidating] = React.useState<boolean>(false);
  const [isNewEmail, setIsNewEmail] = React.useState<boolean>(false);
  const [isNewPhone, setIsNewPhone] = React.useState<boolean>(false);

  const params: { userId: string } = useParams();
  const { data: userFromBack, isLoading } = useGetBaseUser(params.userId);
  const [userLogged, setUserLogged] = React.useState<User>();
  const [loggedBreadCrumbs, setLoggedBreadCrumbs] = React.useState<BreadcrumbItemType[]>();

  const handleChangeSelect = (field: keyof Fields, e: any) => {
    setFields({
      ...fields,
      [field]: {
        ...fields[field],
        value: e.value,
        error: false,
      },
    });
    setValidating(false);
  };
  const handleInputClear = (field: keyof Fields) => {
    if (field === 'newEmail') {
      setIsNewEmail(false);
    }
    setFields({
      ...fields,
      [field]: { ...fields[field], value: '' },
    });
    setValidating(false);
  };
  const handleChangeInputValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof Fields
  ) => {
    const { name, value } = e.target;

    if (name === 'newEmail' || name === 'newPhone') {
      if (value === '') {
        if (name === 'newEmail') {
          setIsNewEmail(false);
        } else {
          setIsNewPhone(false);
        }
      }
    }
    setFields({
      ...fields,
      [name]: {
        required: stateFields[field]?.required ?? true,
        error: false,
        value,
      },
    });
    setValidating(false);
  };

  const validate = () => {
    setValidating(true);
    for (const [key, values] of Object.entries(fields)) {
      if (values?.value === '' && values?.required) {
        setFields((newFields) => ({
          ...newFields,
          [key]: { value: values.value, error: true },
        }));
      }
    }
  };

  const saveUser = async () => {
    try {
      if (formRef.current) {
        const formData: FormData = new FormData(formRef.current);

        const payload: DeepPartial<PatchBaseUser> = {
          guardian: {
            firstName: formData.get('firstName') as string,
            middleName: formData.get('middleName') as string,
            lastName: formData.get('lastName') as string,
            suffix: formData.get('suffix') as string,
            email: (isNewEmail
              ? formData.get('newEmail')
              : formData.get('selectEmail')) as string,
            phoneNumber: (isNewPhone
              ? formData.get('newPhone')
              : formData.get('selectPhone')) as string,
          },
        };
        const idForPatch = userData?.id || params.userId;
        const baseUserUpdated = await BaseUserService.updateUser(idForPatch, payload);
        if (baseUserUpdated.data.message) throw new Error(baseUserUpdated.data.message);

        dispatch(baseUserUpdateData({ ...payload, id: userId }));
        history.push('/v3/new-user/address');
      }
    } catch (error) {
      setValidating(false);
      if (error instanceof Error) {
        MessageSnackbar.error({ content: error.message, duration: 5 });
      }
    }
  };

  React.useEffect(() => {
    if (validating) {
      const getFields = Object.values(fields);
      const hasError = getFields.find((i) => i?.error);

      if (!hasError) {
        saveUser();
      } else {
        setValidating(false);
      }
    }
  }, [validating, fields]);
  React.useEffect(() => {
    if (fields.selectEmail.value === 'new-email') {
      setIsNewEmail(true);
    } else {
      setIsNewEmail(false);
    }
  }, [fields.selectEmail.value]);
  React.useEffect(() => {
    if (fields.selectPhone.value === 'new-phone') {
      setIsNewPhone(true);
    } else {
      setIsNewPhone(false);
    }
  }, [fields.selectPhone.value]);
  React.useEffect(() => {
    if (isNewEmail) {
      setFields({
        ...fields,
        newEmail: { value: '', error: false, required: true },
        selectEmail: { ...fields.selectEmail, required: false },
      });
    } else {
      setFields({
        ...fields,
        newEmail: { value: '', error: false, required: false },
        selectEmail: {
          ...fields.selectEmail,
          value: stateFields.selectEmail.value,
          required: true,
        },
      });
    }
  }, [isNewEmail]);
  React.useEffect(() => {
    if (isNewPhone) {
      setFields({
        ...fields,
        newPhone: { value: '', error: false, required: true },
        selectPhone: { ...fields.selectPhone, required: false },
      });
    } else {
      setFields({
        ...fields,
        newPhone: { value: '', error: false, required: false },
        selectPhone: {
          ...fields.selectPhone,
          value: stateFields.selectPhone.value,
          required: true,
        },
      });
    }
  }, [isNewPhone]);

  React.useEffect(() => {
    if (!isLoading && userFromBack !== undefined) {

      const stateFieldsHasUser: Fields = {
        firstName: {
          value: userFromBack.guardian1?.firstName ?? '',
          error: false,
          required: true,
        },
        middleName: {
          value: userFromBack.guardian1?.middleName ?? '',
          error: false,
          required: false,
        },
        lastName: {
          value: userFromBack.guardian1?.lastName ?? '',
          error: false,
          required: true,
        },
        suffix: {
          value: userFromBack.guardian1?.suffix ?? '',
          error: false,
          required: false,
        },
        selectEmail: {
          value: userFromBack.guardian1?.email ?? userData?.accountEmail ?? '',
          error: false,
          required: true,
        },
        selectPhone: {
          value: userFromBack.guardian1?.phoneNumber ?? userData?.phoneNumber ?? '',
          error: false,
          required: true,
        },
      };

      setFields(stateFieldsHasUser);

        setLoggedBreadCrumbs([{
          "icon": "CheckSmall",
          "status": "active",
          "text": "User Info",
          "link": `/v3/new-user/user-info/${params.userId}`,
        },
        {
          "icon": "CheckSmall",
          "status": "checked",
          "text": "Legal Guardian",
          "link": `/v3/new-user/legal-guardian/${params.userId}`,
        },
        {
          "icon": "CheckSmall",
          "status": "checked",
          "text": "Address",
          "link": `/v3/new-user/address/${params.userId}`,
        },
        {
          "icon": "CheckSmall",
          "status": "checked",
          "text": "Documents",
          "link": `/v3/new-user/documents/${params.userId}`,
        }
      ]);


      setUserLogged(userFromBack);

    }
  }, [isLoading]);

  return (
    <CompleteUserTemplate
      breadcrumbItems={breadcrumbItems as BreadcrumbItemType[]}
    >
      <S.UserForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
        <fieldset>
          <legend>
            <BodyXL $color='brandPrimary' $isUpper>
              Legal Guardian
            </BodyXL>
          </legend>

          <InputText
            id='firstName'
            placeholder='First Name'
            label='First Name'
            required
            defaultValue={fields.firstName.value}
            onClearInput={(id) => handleInputClear(id)}
            error={fields.firstName.error}
            onChange={(e) => handleChangeInputValue(e, 'firstName')}
            className='user-info-input'
          />
          <InputText
            id='middleName'
            placeholder='Middle Name (Optional)'
            label='Middle Name (Optional)'
            defaultValue={fields.middleName.value}
            onClearInput={(id) => handleInputClear(id)}
            error={fields.middleName.error}
            onChange={(e) => handleChangeInputValue(e, 'middleName')}
            className='user-info-input'
          />
          <InputText
            id='lastName'
            placeholder='Last Name'
            label='Last Name'
            required
            defaultValue={fields.lastName.value}
            onClearInput={(id) => handleInputClear(id)}
            error={fields.lastName.error}
            onChange={(e) => handleChangeInputValue(e, 'lastName')}
            className='user-info-input'
          />
          <InputText
            id='suffix'
            placeholder='Suffix (Optional)'
            label='Suffix (Optional)'
            defaultValue={fields.suffix.value}
            onClearInput={(id) => handleInputClear(id)}
            onChange={(e) => handleChangeInputValue(e, 'suffix')}
            className='user-info-input'
          />
        </fieldset>

        <S.FormFieldset>
          {isNewEmail ? (
            <InputText
              id='newEmail'
              placeholder='New Email'
              label='New Email'
              required
              onClearInput={(id) => handleInputClear(id)}
              error={fields.newEmail?.error}
              onChange={(e) => handleChangeInputValue(e, 'newEmail')}
              className='user-info-input'
            />
          ) : (
            <Select
              name='selectEmail'
              placeholder='Select Email'
              required
              options={emailOptions}
              defaultValue={setLabelToEmail}
              onChange={(e) => handleChangeSelect('selectEmail', e)}
              error={fields.selectEmail.error}
              className='user-info-input'
            />
          )}
          {isNewPhone ? (
            <InputPhone
              id='newPhone'
              placeholder='New Phone'
              label='New Phone'
              required
              error={fields.newPhone?.error}
              onChange={(e) => handleChangeInputValue(e, 'newPhone')}
              className='user-info-input'
            />
          ) : (
            <Select
              name='selectPhone'
              placeholder='Select Telephone'
              required
              options={phoneOptions}
              defaultValue={setLabelToPhone}
              onChange={(e) => handleChangeSelect('selectPhone', e)}
              error={fields.selectPhone.error}
              className='user-info-input'
            />
          )}
        </S.FormFieldset>

        <FilledButtonWithIcon onClick={validate} disabled={userLogged !== undefined}>
          {validating ? 'Saving' : 'Continue'}
        </FilledButtonWithIcon>
      </S.UserForm>
    </CompleteUserTemplate>
  );
};

export default withRouter(LegalGuardian);
