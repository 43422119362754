import { User } from 'models/User/UserModel';
import DateUtil from 'util/DateUtil';
import { formatUserName } from 'util/formatUserName';
import { InvitationFiltersType } from '../InviteMembersToClub/components/Find/components/FilterDrawer/types';

type UseFilterMembersProps = {
  members?: User[];
  memberName: string;
  advancedFilter: InvitationFiltersType;
};

export const useFilterMembersToClub = ({
  members,
  memberName,
  advancedFilter,
}: UseFilterMembersProps) => {
  if (!members) return [];

  return members.filter((user) => {
    const {
      firstName,
      middleName,
      lastName,
      address,
      birthDate,
      gender,
      membershipType,
    } = user;

    const formattedName = formatUserName({
      firstName,
      middleName,
      lastName,
    }).toLowerCase();
    const formattedMemberName = memberName.toLowerCase();

    const nameFilter = formattedName.includes(formattedMemberName);

    const cityFilter = advancedFilter.city.length
      ? advancedFilter.city.includes(address?.city || '')
      : true;
    const ageFilter = advancedFilter.age.length
      ? advancedFilter.age.includes(
          DateUtil.getYear(new Date(birthDate as any)) as any
        )
      : true;
    const genderFilter = advancedFilter.gender.length
      ? advancedFilter.gender.includes(gender || '')
      : true;
    const membershipFilter = advancedFilter.membership.length
      ? advancedFilter.membership.includes(membershipType || '')
      : true;
    const countryFilter = advancedFilter.country.length
      ? advancedFilter.country.includes(address?.country || '')
      : true;
    const stateFilter = advancedFilter.state.length
      ? advancedFilter.state.includes(address?.state || '')
      : true;

    return (
      nameFilter &&
      cityFilter &&
      ageFilter &&
      genderFilter &&
      membershipFilter &&
      countryFilter &&
      stateFilter
    );
  });
};
