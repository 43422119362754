import React from 'react';
import { Down } from '@icon-park/react';

import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import { useGetMyClubsByUserType } from 'hooks/v3/clubs/useGetMyClubsByUserType/useGetMyClubsByUserType';
import { UserType } from 'redux/user/types';
import ConvertUtil from 'util/ConvertUtil';
import { BodyL } from 'styles/v3/variables';
import { setSignupClub } from 'redux/v3/signupEvent/actions';
import * as S from './styles';

export const ClubSelect = () => {
  const { clubId } = useSelector(
    (state: ApplicationState) => state.signupEvent.data
  );

  const dispatch = useDispatch();
  const { data: myClubs } = useGetMyClubsByUserType(UserType.CLUB_DIRECTOR);

  const handleSelectClub = (id: string) => {
    dispatch(setSignupClub(id));
  };

  return (
    <S.Container>
      <S.Select
        bordered={false}
        suffixIcon={<Down size={24} />}
        key={clubId}
        defaultValue={clubId}
        onChange={(e) => handleSelectClub(e.toString())}
      >
        {myClubs?.map((club) => (
          <S.Option key={club.id} value={club.id}>
            <S.OptionWrapper>
              <S.Image $src={ConvertUtil.getMinioUrl(club.logo)} />
              <BodyL>{club.name}</BodyL>
            </S.OptionWrapper>
          </S.Option>
        ))}
      </S.Select>
    </S.Container>
  );
};
