import { Modal } from 'antd';
import BasicButton from 'components/v3/Buttons/BasicButton';
import styled from 'styled-components';

import { COLORS } from 'styles/v3/variables';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: 420px;
    border-radius: 24px;
    background-color: ${COLORS.grey900};
  }

  .ant-modal-header {
    border-radius: 24px;
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: 24px;
    padding: 6px 12px 6px 12px;
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  gap: 16px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  gap: 16px;
`;

export const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  gap: 16px;
`;

export const UpgradeButton = styled(BasicButton)`
  width: 100%;

  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;

  color: ${COLORS.grey900};
  background: ${COLORS.brandPrimary};
`;

export const SettingsButton = styled.button`
  width: 38px;
  height: 38px;

  border: none;
  box-shadow: none;
  padding: 7.4px 0;
  border-radius: 10px;
  background-color: ${COLORS.grey700};

  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    background-color: ${COLORS.grey600};
  }
`;

export const ButtonWrapper = styled.div`
  position: absolute;

  top: 20px;
  right: 20px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  button {
    background-color: ${COLORS.grey700};
    border-radius: 10px;

    border: none;
  }
`;
