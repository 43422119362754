import * as React from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { message as MessageSnackbar } from 'antd';
import moment from 'moment';

import { BodyXL } from 'styles/v3/variables';

import InputText from 'components/v3/Forms/InputText/InputText';
import InputPhone from 'components/v3/Forms/InputPhone/InputPhone';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import InputDate from 'components/v3/Forms/InputDate/InputDate';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import { BreadcrumbItemType } from 'components/v3/Breadcrumb/Breadcrumb';

import { User } from 'models/User/UserModel';

import { ApplicationState } from 'redux/store';
import { PatchBaseUser } from 'redux/v3/baseUsers/types';
import { baseUserUpdateData } from 'redux/v3/baseUsers/actions';
import { useGetUser } from 'hooks/v3/users/useGetUser/useGetUser';
import { useGetBaseUser } from 'hooks/v3/users/useGetBaseUser/useGetBaseUser';

import BaseUserService from 'services/v3/BaseUsers/BaseUsersApi';
import UserServices from 'services/v3/User/UserService';

import DateUtil from 'util/DateUtil';
import { Gender } from 'admin/models/Enums';
import { setCurrentUser } from 'redux/v3/currentUser/actions';
import { usersFetchSuccess } from 'redux/v3/users/actions';
import CompleteUserTemplate from '../Template/Template';
import breadcrumbAdultItems from './breadcrumb-adult.json';
import breadcrumbItems from './breadcrumb.json';
import * as S from '../Template/styles';

type Fields = {
  firstName: { value: string; error: boolean; required: boolean };
  middleName: { value: string; error: boolean; required: boolean };
  lastName: { value: string; error: boolean; required: boolean };
  suffix: { value: string; error: boolean; required: boolean };
  selectEmail: { value: string; error: boolean; required: boolean };
  selectPhone: { value: string; error: boolean; required: boolean };
  birthDate: {
    value: string | moment.Moment;
    error: boolean;
    required: boolean;
  };
  gender: { value: string; error: boolean; required: boolean };
  newEmail?: { value: string; error: boolean; required: boolean };
  newPhone?: { value: string; error: boolean; required: boolean };
};

const UserInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const formRef = React.useRef<HTMLFormElement>(null);
  // const params: { userId: string } = useParams();
  // const { data: userFromBack } = useGetBaseUser(params.userId);
  // const { data: userFromBackBase, isLoading } = useGetUser(params.userId);
  const { data: currentUser } = useSelector(
    (state: ApplicationState) => state.currentUser
  );

  const isBaseUser = React.useMemo(() => {
    if (!currentUser) return false
    return Array.isArray(currentUser?.types)
  }, [currentUser]);

  // const [userLogged, setUserLogged] = React.useState<User>();
  const [loggedBreadCrumbs, setLoggedBreadCrumbs] = React.useState<BreadcrumbItemType[]>();
  const [emailUser, setEmailUser] = React.useState<OptionsType>();

  const stateFields: Fields = {
    firstName: {
      value: currentUser?.firstName ?? '',
      error: false,
      required: true,
    },
    middleName: {
      value: currentUser?.middleName ?? '',
      error: false,
      required: false,
    },
    lastName: { value: currentUser?.lastName ?? '', error: false, required: true },
    suffix: { value: currentUser?.suffix ?? '', error: false, required: false },
    selectEmail: {
      value: currentUser?.email ?? currentUser?.accountEmail ?? '',
      error: false,
      required: true,
    },
    selectPhone: {
      value: currentUser?.phoneNumber ?? '',
      error: false,
      required: true,
    },
    birthDate: {
      value: currentUser?.birthDate ? currentUser?.birthDate as any : '',
      error: false,
      required: true,
    },
    gender: { value: currentUser?.gender ?? '', error: false, required: true },
  };

  const emailOptions: OptionsType[] = [
    {
      label: stateFields.selectEmail.value,
      value: stateFields.selectEmail.value,
    },
    {
      label: 'New Email',
      value: 'new-email',
    },
  ];
  const phoneOptions: OptionsType[] = [
    {
      label: stateFields.selectPhone.value,
      value: stateFields.selectPhone.value,
    },
    {
      label: 'New Phone',
      value: 'new-phone',
    },
  ];
  const genderOptions: OptionsType[] = [
    {
      label: 'Male',
      value: Gender.MALE,
    },
    {
      label: 'Female',
      value: Gender.FEMALE,
    },
  ];

  const [fields, setFields] = React.useState<Fields>(stateFields);
  const [validating, setValidating] = React.useState<boolean>(false);
  const [isNewEmail, setIsNewEmail] = React.useState<boolean>(false);
  const [isNewPhone, setIsNewPhone] = React.useState<boolean>(false);

  const setLabelToEmail = emailOptions.find(
    (e) => e.value === stateFields.selectEmail.value
  );
  const setLabelToPhone = phoneOptions.find(
    (p) => p.value === stateFields.selectPhone.value
  );
  const setLabelToGender = genderOptions.find(
    (g) => g.value === stateFields.gender.value
  );

  React.useEffect(() => {
    if (currentUser) {

      // const stateFieldsHasUser: Fields = {
      //   firstName: {
      //     value: currentUser?.firstName ?? '',
      //     error: false,
      //     required: true,
      //   },
      //   middleName: {
      //     value: currentUser?.middleName ?? '',
      //     error: false,
      //     required: false,
      //   },
      //   lastName: { value: currentUser?.lastName ?? '', error: false, required: true },
      //   suffix: { value: currentUser?.suffix ?? '', error: false, required: false },
      //   selectEmail: {
      //     value: currentUser?.accountEmail ?? '',
      //     error: false,
      //     required: true,
      //   },
      //   selectPhone: {
      //     value: currentUser?.phoneNumber ?? '',
      //     error: false,
      //     required: true,
      //   },
      //   birthDate: {
      //     value: currentUser?.birthDate ?? '',
      //     error: false,
      //     required: true,
      //   },
      //   gender: { value: currentUser?.gender ?? '', error: false, required: true },
      // };
      setEmailUser({ value: `${currentUser?.accountEmail}`, label: `${currentUser?.accountEmail}` });
      // setFields(stateFieldsHasUser);
      
      if (DateUtil.isAnAdult(currentUser?.birthDate ? currentUser?.birthDate as any : new Date())) {
        setLoggedBreadCrumbs([{
          "icon": "CheckSmall",
          "status": "checked",
          "text": "User Info",
          "link": `/v3/new-user/user-info`,
        },
        {
          "icon": "CheckSmall",
          "status": "checked",
          "text": "Address",
          "link": `/v3/new-user/address`,
        },
        {
          "icon": "CheckSmall",
          "status": "checked",
          "text": "Documents",
          "link": `/v3/new-user/documents`,
        }]);

      } else {
        setLoggedBreadCrumbs([{
          "icon": "CheckSmall",
          "status": "active",
          "text": "User Info",
          "link": `/v3/new-user/user-info`,
        },
        {
          "icon": "CheckSmall",
          "status": "checked",
          "text": "Legal Guardian",
          "link": `/v3/new-user/legal-guardian`,
        },
        {
          "icon": "CheckSmall",
          "status": "checked",
          "text": "Address",
          "link": `/v3/new-user/address`,
        },
        {
          "icon": "CheckSmall",
          "status": "checked",
          "text": "Documents",
          "link": `/v3/new-user/documents`,
        }
      ]);
      }

      // setUserLogged(findedUser);
    }
  }, [currentUser]);

  const handleChangeSelect = (field: keyof Fields, e: any) => {
    setFields({
      ...fields,
      [field]: {
        ...fields[field],
        value: e.value,
        error: false,
      },
    });
    setValidating(false);
  };
  const handleInputClear = (field: keyof Fields) => {
    if (field === 'newEmail') {
      setIsNewEmail(false);
    }
    setFields({
      ...fields,
      [field]: { ...fields[field], value: '' },
    });
    setValidating(false);
  };
  const handleChangeInputValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof Fields
  ) => {
    const { name, value } = e.target;

    if (name === 'newEmail' || name === 'newPhone') {
      if (value === '') {
        if (name === 'newEmail') {
          setIsNewEmail(false);
        } else {
          setIsNewPhone(false);
        }
      }
    }
    setFields({
      ...fields,
      [name]: {
        required: stateFields[field]?.required ?? true,
        error: false,
        value,
      },
    });
    setValidating(false);
  };

  const validate = () => {
    setValidating(true);
    for (const [key, values] of Object.entries(fields)) {
      if (values?.value === '' && values?.required) {
        setFields((newFields) => ({
          ...newFields,
          [key]: { value: values.value, error: true },
        }));
      }
    }
  };
  const saveUser = async () => {
    try {
      if (formRef.current) {
        const formData: FormData = new FormData(formRef.current);

        const payload: PatchBaseUser = {
          firstName: formData.get('firstName') as string,
          middleName: formData.get('middleName') as string,
          lastName: formData.get('lastName') as string,
          suffix: formData.get('suffix') as string,
          email: (isNewEmail
            ? formData.get('newEmail')
            : formData.get('selectEmail')) as string,
          phoneNumber: (isNewPhone
            ? formData.get('newPhone')
            : formData.get('selectPhone')) as string,
          birthDate: formData.get('birthDate') as string,
          gender: String(formData.get('gender')) as Gender,
        };


        const idForPatch = `${currentUser?.id}`

        if(isBaseUser) {

          console.log('updating base user')
          const { data: userUpdated } = await BaseUserService.updateUser(
            idForPatch,
            payload
          );

          if (userUpdated.message) throw new Error(userUpdated.message);
          dispatch(baseUserUpdateData({ ...payload, id: idForPatch }));
          const { data: updatedProfile } = await UserServices.getProfiles()
          const updatedBaseUser = updatedProfile?.baseUsers?.find(u => u.id === currentUser.id)

          if (updatedBaseUser) {
            console.log('updating base user current')
            dispatch(setCurrentUser(updatedBaseUser));
            const newUsers = [...(updatedProfile.baseUsers || []), ...(updatedProfile.users || [])];
            dispatch(usersFetchSuccess(newUsers));
          }

        } else {

          console.log('update user')
          UserServices.updateUser(`${currentUser.id}`, payload, isBaseUser).then(async (res) => {
            if (res.success) {
              dispatch(baseUserUpdateData({ ...payload, id: idForPatch }));
              const { data: updatedProfile } = await UserServices.getProfiles()
              const myNewBaseUser = updatedProfile?.baseUsers?.find(u => u.userIds?.includes(`${currentUser.id}`))
              if (myNewBaseUser) {
                dispatch(setCurrentUser(myNewBaseUser));
                const newUsers = [...(updatedProfile.baseUsers || []), ...(updatedProfile.users || [])];
                dispatch(usersFetchSuccess(newUsers));
              }

              console.log('update current user user')
            } else {
              MessageSnackbar.error(`Error${res.data}`);
            }
          });
        }
        
        if (DateUtil.isAnAdult(currentUser?.birthDate ? currentUser?.birthDate as any : '')) {
          history.push('/v3/new-user/address');
        } else {
          history.push('/v3/new-user/legal-guardian');
        }
      }
    } catch (error) {
      setValidating(false);
      if (error instanceof Error) {
        MessageSnackbar.error({ content: error.message, duration: 5 });
      }
    }
  };

  React.useEffect(() => {
    if (validating) {
      const getFields = Object.values(fields);
      const hasError = getFields.find((i) => i?.error);

      if (!hasError) {
        saveUser();
      } else {
        setValidating(false);
      }
    }
  }, [validating, fields]);
  React.useEffect(() => {
    if (fields.selectEmail.value === 'new-email') {
      setIsNewEmail(true);
    } else {
      setIsNewEmail(false);
    }
  }, [fields.selectEmail.value]);
  React.useEffect(() => {
    if (fields.selectPhone.value === 'new-phone') {
      setIsNewPhone(true);
    } else {
      setIsNewPhone(false);
    }
  }, [fields.selectPhone.value]);
  React.useEffect(() => {
    if (isNewEmail) {
      setFields({
        ...fields,
        newEmail: { value: '', error: false, required: true },
        selectEmail: { ...fields.selectEmail, required: false },
      });
    } else {
      setFields({
        ...fields,
        newEmail: { value: '', error: false, required: false },
        selectEmail: {
          ...fields.selectEmail,
          value: stateFields.selectEmail.value,
          required: true,
        },
      });
    }
  }, [isNewEmail]);
  React.useEffect(() => {
    if (isNewPhone) {
      setFields({
        ...fields,
        newPhone: { value: '', error: false, required: true },
        selectPhone: { ...fields.selectPhone, required: false },
      });
    } else {
      setFields({
        ...fields,
        newPhone: { value: '', error: false, required: false },
        selectPhone: {
          ...fields.selectPhone,
          value: stateFields.selectPhone.value,
          required: true,
        },
      });
    }
  }, [isNewPhone]);

  const valueButton = () => {
    if (validating) {
      return 'Saving'
    }

    if (currentUser) {
      return 'Updated'
    }

    return 'Continue'
  }

  return (
    <CompleteUserTemplate
      breadcrumbItems={loggedBreadCrumbs || (DateUtil.isAnAdult(currentUser?.birthDate ? currentUser?.birthDate as any : new Date() )
          ? breadcrumbAdultItems
          : breadcrumbItems) as BreadcrumbItemType[]
      }
      user={currentUser as any}
    >
      {currentUser ?
        <>
          <S.UserForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
            <fieldset>
              <legend>
                <BodyXL $color='brandPrimary' $isUpper>
                  User Info Edit
                </BodyXL>
              </legend>

              <InputText
                id='firstName'
                placeholder='First Name'
                label='First Name'
                required
                defaultValue={fields.firstName.value}
                onClearInput={(id) => handleInputClear(id)}
                error={fields.firstName.error}
                onChange={(e) => handleChangeInputValue(e, 'firstName')}
                className='user-info-input'
              />
              <InputText
                id='middleName'
                placeholder='Middle Name (Optional)'
                label='Middle Name (Optional)'
                defaultValue={fields.middleName.value}
                onClearInput={(id) => handleInputClear(id)}
                error={fields.middleName.error}
                onChange={(e) => handleChangeInputValue(e, 'middleName')}
                className='user-info-input'
              />
              <InputText
                id='lastName'
                placeholder='Last Name'
                label='Last Name'
                required
                defaultValue={fields.lastName.value}
                onClearInput={(id) => handleInputClear(id)}
                error={fields.lastName.error}
                onChange={(e) => handleChangeInputValue(e, 'lastName')}
                className='user-info-input'
              />
              <InputText
                id='suffix'
                placeholder='Suffix (Optional)'
                label='Suffix (Optional)'
                defaultValue={fields.suffix.value}
                onClearInput={(id) => handleInputClear(id)}
                onChange={(e) => handleChangeInputValue(e, 'suffix')}
                className='user-info-input'
              />
            </fieldset>

            <S.FormFieldset>
                <InputText
                  id='newEmail'
                  placeholder='New Email'
                  label='New Email'
                  required
                  onClearInput={(id) => handleInputClear(id)}
                  defaultValue={fields.selectEmail.value}
                  error={fields.newEmail?.error}
                  onChange={(e) => handleChangeInputValue(e, 'newEmail')}
                  className='user-info-input'
                />

                <InputPhone
                  id='newPhone'
                  placeholder='New Phone'
                  label='New Phone'
                  defaultValue={fields.selectPhone.value}
                  required
                  error={fields.newPhone?.error}
                  onChange={(e) => handleChangeInputValue(e, 'newPhone')}
                  className='user-info-input'
                />

            </S.FormFieldset>
            <S.FormFieldset>
              <InputDate
                id='birthDate'
                placeholder='Birthdate'
                label='Birthdate'
                defaultValue={fields.birthDate.value as string}
                onChange={(e) => handleChangeInputValue(e, 'birthDate')}
                required
                error={fields.birthDate.error}
                className='user-info-input'
              />
              <Select
                name='gender'
                placeholder='Gender'
                required
                error={fields.gender.error}
                options={genderOptions}
                defaultValue={genderOptions.find(gender => gender.value === fields.gender.value)}
                onChange={(e) => handleChangeSelect('gender', e)}
                className='user-info-input'
              />
            </S.FormFieldset>

            <FilledButtonWithIcon onClick={validate} disabled={false}>
              {valueButton()}
            </FilledButtonWithIcon>
          </S.UserForm>
        </>
        :
        <>
            <S.UserForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
              <fieldset>
                <legend>
                  <BodyXL $color='brandPrimary' $isUpper>
                    User Info
                  </BodyXL>
                </legend>

                <InputText
                  id='firstName'
                  placeholder='First Name'
                  label='First Name'
                  required
                  defaultValue={fields.firstName.value}
                  onClearInput={(id) => handleInputClear(id)}
                  error={fields.firstName.error}
                  onChange={(e) => handleChangeInputValue(e, 'firstName')}
                  className='user-info-input'
                />
                <InputText
                  id='middleName'
                  placeholder='Middle Name (Optional)'
                  label='Middle Name (Optional)'
                  defaultValue={fields.middleName.value}
                  onClearInput={(id) => handleInputClear(id)}
                  error={fields.middleName.error}
                  onChange={(e) => handleChangeInputValue(e, 'middleName')}
                  className='user-info-input'
                />
                <InputText
                  id='lastName'
                  placeholder='Last Name'
                  label='Last Name'
                  required
                  defaultValue={fields.lastName.value}
                  onClearInput={(id) => handleInputClear(id)}
                  error={fields.lastName.error}
                  onChange={(e) => handleChangeInputValue(e, 'lastName')}
                  className='user-info-input'
                />
                <InputText
                  id='suffix'
                  placeholder='Suffix (Optional)'
                  label='Suffix (Optional)'
                  defaultValue={fields.suffix.value}
                  onClearInput={(id) => handleInputClear(id)}
                  onChange={(e) => handleChangeInputValue(e, 'suffix')}
                  className='user-info-input'
                />
              </fieldset>

              <S.FormFieldset>
                {isNewEmail ? (
                  <InputText
                    id='newEmail'
                    placeholder='New Email'
                    label='New Email'
                    required
                    onClearInput={(id) => handleInputClear(id)}
                    error={fields.newEmail?.error}
                    onChange={(e) => handleChangeInputValue(e, 'newEmail')}
                    className='user-info-input'
                  />
                ) : (
                  <Select
                    name='selectEmail'
                    placeholder='Select Email'
                    required
                    options={emailOptions}
                    defaultValue={emailUser || setLabelToEmail}
                    onChange={(e) => handleChangeSelect('selectEmail', e)}
                    error={fields.selectEmail.error}
                    className='user-info-input'
                  />
                )}
                {isNewPhone ? (
                  <InputPhone
                    id='newPhone'
                    placeholder='New Phone'
                    label='New Phone'
                    required
                    error={fields.newPhone?.error}
                    onChange={(e) => handleChangeInputValue(e, 'newPhone')}
                    className='user-info-input'
                  />
                ) : (
                  <Select
                    name='selectPhone'
                    placeholder='Select Telephone'
                    required
                    options={phoneOptions}
                    defaultValue={setLabelToPhone}
                    onChange={(e) => handleChangeSelect('selectPhone', e)}
                    error={fields.selectPhone.error}
                    className='user-info-input'
                  />
                )}
              </S.FormFieldset>
              <S.FormFieldset>
                <InputDate
                  id='birthDate'
                  placeholder='Birthdate'
                  label='Birthdate'
                  defaultValue={fields.birthDate.value as string}
                  onChange={(e) => handleChangeInputValue(e, 'birthDate')}
                  required
                  error={fields.birthDate.error}
                  className='user-info-input'
                />
                <Select
                  name='gender'
                  placeholder='Gender'
                  required
                  error={fields.gender.error}
                  options={genderOptions}
                  defaultValue={setLabelToGender}
                  onChange={(e) => handleChangeSelect('gender', e)}
                  className='user-info-input'
                />
              </S.FormFieldset>

              <FilledButtonWithIcon onClick={validate} disabled={currentUser !== undefined}>
                {valueButton()}
              </FilledButtonWithIcon>
            </S.UserForm>
          </>
      }

        </CompleteUserTemplate>
      );
};

      export default withRouter(UserInfo);
