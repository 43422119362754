import { Moment } from 'moment';
import { AgeDivision } from '../../admin/models/AgeDivision';

export interface PaginationModel {
  current: number;
  size: number;
  total: number;
  loading: boolean;
  sort?: string[];
}

export interface FilterPagination {
  query: string;
  pagination: PaginationModel;
}

export interface FilterPaginationMatch extends FilterPagination {
  startDate?: Moment | undefined;
  endDate?: Moment | undefined;
  ageDivision?: AgeDivision | undefined;
  status?: string;
  specialDivision?: string;
  pagination: PaginationModel;
}

export interface FilterPaginationUsers extends FilterPagination {
  clubs?: string[];
  memberships?: string[];
  birthYear?: string[];
  gender?: string;
  type?: string;
}

export const getSortParams = (sorter: any) => {
  let resultParam: string[] = [];
  if (sorter.length || sorter.order != undefined) {
    if (Array.isArray(sorter)) {
      for (let sort of sorter) {
        if (Array.isArray(sort.field)) {
          let param = '';
          param += sort.field.join('.');
          param += ':';
          param += sort.order === 'ascend' ? 'asc' : 'desc';
          resultParam.push(param);
        } else {
          let param = '';
          param += sort.field;
          param += ':';
          param += sort.order === 'ascend' ? 'asc' : 'desc';
          resultParam.push(param);
        }
      }
    } else {
      if (Array.isArray(sorter.field)) {
        let param = '';
        param += sorter.field.join('.');
        param += ':';
        param += sorter.order === 'ascend' ? 'asc' : 'desc';
        resultParam.push(param);
      } else {
        let param = '';
        param += sorter.field;
        param += ':';
        param += sorter.order === 'ascend' ? 'asc' : 'desc';
        resultParam.push(param);
      }
    }
  }
  return resultParam;
};
