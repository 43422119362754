import styled from 'styled-components';

import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const PlanCardWrapper = styled.div<{
  $selected?: boolean;
  $disabled?: boolean;
  $withBorder?: boolean;
  $bgImage?: string;
}>`
  .simple-card-wrapper {
    justify-content: flex-start;
    align-items: baseline;
    border-color: ${(props) => {
      if (props.$selected) return COLORS.brandPrimary;
      if (props.$withBorder) return COLORS.grey50;
      return 'transparent';
    }};
    color: ${(props) => {
      if (props.$disabled) return COLORS.grey200;
      return COLORS.grey50;
    }};
    background: ${(props) =>
      props.$bgImage
        ? `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${props.$bgImage}), lightgray 50% / cover no-repeat`
        : 'transparent'};
  }
`;

export const PlanCardContainer = styled.div<{
  $selected?: boolean;
  $orientation: 'column' | 'row';
}>`
  margin-top: ${(props) => (props.$orientation === 'column' ? '1.5rem' : '0')};
  margin-left: ${(props) => (props.$orientation === 'row' ? '1.5rem' : '0')};
  width: 100%;

  h4 {
    margin-bottom: ${toRem(10)};
    color: ${(props) => (props.$selected ? COLORS.brandPrimary : 'inherit')};
  }

  .card-title {
    text-transform: uppercase;
  }
  .card-description {
    max-width: 90%;
  }
`;
