import * as React from 'react';

import { BodyLBold, BodyS, BodySBold } from 'styles/v3/variables';
import moment from 'moment';
import { PurchasedByProps } from './types';

import * as S from './styles';

const PurchasedBy: React.FC<PurchasedByProps> = ({ transactions }) => {
  const soldAt = React.useMemo(() => {
    if (transactions?.length) {
      transactions.sort((a, b) => {
        return new Date(b.updated).getTime() - new Date(a.updated).getTime();
      });
      const transaction = transactions[0];
      return moment(transaction.updated).format('MMM DD YYYY LT');
    }
    return 0;
  }, [transactions]);

  return (
    <S.Container>
      <BodyLBold>Purchased by</BodyLBold>
      <S.SoldWrapper>
        <BodyS>Sold at</BodyS>
        <BodySBold>{soldAt}</BodySBold>
      </S.SoldWrapper>
    </S.Container>
  );
};

export default PurchasedBy;
