import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { Star, CheckOne } from '@icon-park/react';

import { BodyM, BodyS, COLORS, TitleH1 } from 'styles/v3/variables';
import MembershipCard from 'components/v3/Cards/MembershipCard/MembershipCard';

import * as S from './styles';

const ChoosePlan: React.FC = () => {
  const [freeDetails] = React.useState<{ text: string; highlight: boolean }[]>([
    { text: 'Vault Player Profile', highlight: false },
    { text: 'View upcoming events', highlight: false },
    {
      text: 'Access to photos and media from United Futsal events',
      highlight: false,
    },
  ]);
  const [upgradeDetails] = React.useState<
    { text: string; highlight: boolean }[]
  >([
    { text: 'Vault Player Profile', highlight: false },
    { text: 'View upcoming events', highlight: false },
    {
      text: 'Access to photos and media from United Futsal events',
      highlight: false,
    },
    { text: 'Discounts on United Futsal Merchandise', highlight: true },
    {
      text:
        'Participate in any Officially Sanctioned United Futsal Leagues and Tournaments',
      highlight: true,
    },
    { text: 'Industry - Leading Insurance', highlight: true },
    { text: 'Earn XP to LEVEL UP your player profile', highlight: true },
    {
      text: 'StatTracker+TM System for tracking goals and other metrics.',
      highlight: true,
    },
  ]);

  return (
    <S.UpgradeMembershipPlansWrapper>
      <BodyS>
        COMPLETE THE REGISTRATION TO JOIN THE UNITED FUTSAL COMMUNITY!
      </BodyS>
      <TitleH1 $isUpper>upgrade membership</TitleH1>

      <S.CardsWrapper>
        <MembershipCard
          logo='logo-vault-grey'
          orientation='column'
          size='small'
          theme='secondary'
          title='FREE'
          description='Community Player Membership'
        >
          <BodyM>What You’ll Get</BodyM>
          {freeDetails.map((detail) => (
            <div className='details-line'>
              <CheckOne size={16} />
              <BodyM className={detail.highlight ? 'highlight' : ''}>
                {detail.text}
              </BodyM>
            </div>
          ))}
        </MembershipCard>
        <MembershipCard
          logo='logo-vault-green'
          orientation='column'
          size='small'
          theme='highlight'
          title='Season'
          hasSeason
          price='$25'
          link='/v3/profile/upgrade-membership/release-terms'
          description='Official Player (USA) Membership'
        >
          <BodyM>What You’ll Get</BodyM>
          {upgradeDetails.map((detail) => (
            <div className='details-line'>
              {detail.highlight ? (
                <Star size={16} color={COLORS.white} theme='filled' />
              ) : (
                <CheckOne size={16} />
              )}
              <BodyM className={detail.highlight ? 'highlight' : ''}>
                {detail.text}
              </BodyM>
            </div>
          ))}
        </MembershipCard>
      </S.CardsWrapper>
    </S.UpgradeMembershipPlansWrapper>
  );
};

export default withRouter(ChoosePlan);
