import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';
import {
  useGetRostersByClubId,
  useGetRostersByClubIdAndUser,
} from 'hooks/v3/clubs/useGetRostersByClubId/useGetRostersByClubId';
import B2bDraftEventService from 'services/v3/B2bDraftEvent/B2bDraftEventService';
import { RosterDivision } from './components';

export const RoastersForClub = (props: { onlyMyResults: boolean }) => {
  const { onlyMyResults } = props;
  const params: { clubId: string } = useParams();
  const { data: currentClub } = useGetClubById(params.clubId);
  const [currentPage, setCurrentPage] = useState(1);

  const currentUserId = useSelector(
    (state: ApplicationState) => state.currentUser.data.id
  );

  const { data: rostersClub } = useGetRostersByClubId({
    clubId: params.clubId,
    page: currentPage - 1,
  });

  const { data: rostersClubMyRosters } = useGetRostersByClubIdAndUser({
    clubId: params.clubId,
    page: currentPage - 1,
  });

  const {
    clubDirector: {
      memberPool: { selectedUserIds },
    },
  } = useSelector((state: ApplicationState) => state.clubDashboard);

  const rosterClubData = useMemo(() => {
    const rostersArray = onlyMyResults ? rostersClubMyRosters : rostersClub;

    if (!rostersArray?.content.length || !currentClub) return [];

    return rostersArray.content.map((roster) => {
      return {
        id: roster.id as string,
        eventName: roster.event?.name ?? '',
        eventLogo: roster.event?.logo ?? '',
        gender: roster.ageDivision?.gender ?? '',
        year: ((roster.ageDivision?.years[0] as unknown) as string) ?? '',
        isCoach:
          roster.coaches?.some((coach) => coach.id === currentUserId) ?? false,
        isPlayer:
          roster.players?.some((player) => player.id === currentUserId) ??
          false,
        startDate: dateTime2DateStr(roster.event?.startDate) ?? '',
        endDate: dateTime2DateStr(roster.event?.endDate) ?? '',
        players: roster.players ?? [],
        coaches: roster.coaches ?? []
      };
    });
  }, [selectedUserIds, currentClub, rostersClub, rostersClubMyRosters]);

  return (
    <>
      {rosterClubData.map((roster) => (
        <RosterDivision
          key={`key_roster_${roster.id}`}
          id={roster.id}
          eventLogo={roster.eventLogo}
          eventName={roster.eventName}
          gender={roster.gender}
          year={roster.year}
          isCoach={roster.isCoach}
          isPlayer={roster.isPlayer}
          startDate={roster.startDate}
          endDate={roster.endDate}
          players={roster.players}
          coaches={roster.coaches}
        />
      ))}
    </>
  );
};
const dateTime2DateStr = (date: Date | string | undefined) => {
  if (!date) return '';
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  const [month, day, year] = formattedDate.split(' ');
  const formattedMonth = month.toUpperCase();
  return `${formattedMonth} ${day} ${year}`;
};
export default B2bDraftEventService;
