import * as React from 'react';
import { useParams, withRouter } from 'react-router-dom';

import { BodyLBold, BodyMBold, BodyS } from 'styles/v3/variables';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import InputText from 'components/v3/Forms/InputText/InputText';
import { EventMatchSettings } from 'admin/models/event/Event';
import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';
import * as S from './styles';

const MatchSettings: React.FunctionComponent = (props) => {
  const params: { eventId: string } = useParams();
  const eventId: string = params?.eventId || '';

  const { isLoading, mutateAsync } = useUpdateEvent({ eventId });

  const formRef = React.useRef<HTMLFormElement>(null);

  const { eventData } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );

  const matchSettingsData = React.useMemo(() => {
    return {
      data: {
        matchSettings: {
          pointsToWin: eventData?.data?.matchSettings?.pointsToWin ?? undefined,
          pointsToDraw:
            eventData?.data?.matchSettings?.pointsToDraw ?? undefined,
          pointsToLose:
            eventData?.data?.matchSettings?.pointsToLose ?? undefined,
          pointsToShutout:
            eventData?.data?.matchSettings?.pointsToShutout ?? undefined,
          recordForForfeit:
            eventData?.data?.matchSettings?.recordForForfeit ?? undefined,
          poolGamesLength:
            eventData?.data?.matchSettings?.poolGamesLength ?? undefined,
          bracketGamesLength:
            eventData?.data?.matchSettings?.bracketGamesLength ?? undefined,
          maxTimeBetweenGames:
            eventData?.data?.matchSettings?.maxTimeBetweenGames ?? undefined,
          minTimeBetweenGames:
            eventData?.data?.matchSettings?.minTimeBetweenGames ?? undefined,
          maxGamesPerTeam:
            eventData?.data?.matchSettings?.maxGamesPerTeam ?? undefined,
        },
      },
    };
  }, [eventData]);

  const handleSave = async () => {
    try {
      if (formRef.current) {
        const formData = new FormData(formRef.current);
        const matchSettings: Partial<EventMatchSettings> = {};

        formData.forEach((value, key) => {
          if (Object.keys(matchSettingsData.data.matchSettings).includes(key)) {
            matchSettings[key as keyof EventMatchSettings] = value
              ? Number(value)
              : undefined;
          }
        });

        const payload = {
          data: {
            matchSettings,
          },
        };

        await mutateAsync({ id: eventId, data: payload });
      }
    } catch (e) {
      console.log('Error updating event', e);
    }
  };

  return (
    <S.MatchSettingsWrapper>
      <S.MatchSettingsFormWrapper>
        <S.MatchSettingsForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
          <BodyLBold $color='white'>Pointing System</BodyLBold>

          <fieldset>
            <InputText
              id='pointsToWin'
              label='Points for Win'
              placeholder='Points for Win'
              type='number'
              defaultValue={
                matchSettingsData.data.matchSettings.pointsToWin ?? ''
              }
              key={`pointsToWin_${String(
                matchSettingsData.data.matchSettings.pointsToWin ?? ''
              )}`}
              className='general-event-input'
            />
          </fieldset>

          <fieldset>
            <InputText
              id='pointsToDraw'
              label='Points for Draw'
              placeholder='Points for Draw'
              type='number'
              defaultValue={
                matchSettingsData.data.matchSettings.pointsToDraw ?? ''
              }
              key={`pointsToDraw_${String(
                matchSettingsData.data.matchSettings.pointsToDraw ?? ''
              )}`}
              className='general-event-input'
            />
          </fieldset>

          <fieldset>
            <InputText
              id='pointsToLose'
              label='Points for Lose'
              placeholder='Points for Lose'
              type='number'
              defaultValue={
                matchSettingsData.data.matchSettings.pointsToLose ?? ''
              }
              key={`pointsToLose_${String(
                matchSettingsData.data.matchSettings.pointsToLose ?? ''
              )}`}
              className='general-event-input'
            />
          </fieldset>

          <fieldset>
            <InputText
              id='pointsToShutout'
              label='Points for Shutout'
              placeholder='Points for Shutout'
              type='number'
              defaultValue={
                matchSettingsData.data.matchSettings.pointsToShutout ?? ''
              }
              key={`pointsToShutout_${String(
                matchSettingsData.data.matchSettings.pointsToShutout ?? ''
              )}`}
              className='general-event-input'
            />
          </fieldset>

          <fieldset>
            <InputText
              id='recordForForfeit'
              label='Record for Forfeit (W.O.)'
              placeholder='Record for Forfeit (W.O.)'
              type='number'
              defaultValue={
                matchSettingsData.data.matchSettings.recordForForfeit ?? ''
              }
              key={`recordForForfeit_${String(
                matchSettingsData.data.matchSettings.recordForForfeit ?? ''
              )}`}
              className='general-event-input'
            />
          </fieldset>

          <BodyLBold $color='white'>Schedule</BodyLBold>

          <BodyMBold $color='grey200'>Game length</BodyMBold>

          <S.MatchSettingsFieldset>
            <fieldset>
              <InputText
                id='poolGamesLength'
                label='Pool Games'
                placeholder='Pool Games'
                type='number'
                key={`poolGamesLength_${String(
                  matchSettingsData.data.matchSettings.poolGamesLength ?? ''
                )}`}
                defaultValue={
                  matchSettingsData.data.matchSettings.poolGamesLength ?? ''
                }
              />
              <BodyS className='input-info'>In minutes</BodyS>
            </fieldset>
            <fieldset>
              <InputText
                id='bracketGamesLength'
                label='Bracket Games'
                placeholder='Bracket Games'
                type='number'
                key={`bracketGamesLength_${String(
                  matchSettingsData.data.matchSettings.bracketGamesLength ?? ''
                )}`}
                defaultValue={
                  matchSettingsData.data.matchSettings.bracketGamesLength ?? ''
                }
              />
              <BodyS className='input-info'>In minutes</BodyS>
            </fieldset>
          </S.MatchSettingsFieldset>

          <BodyMBold $color='grey200'>Time between games</BodyMBold>

          <fieldset>
            <InputText
              id='maxTimeBetweenGames'
              label='Max time between games per team'
              placeholder='Max time between games per team'
              type='number'
              key={`maxTimeBetweenGames_${String(
                matchSettingsData.data.matchSettings.maxTimeBetweenGames ?? ''
              )}`}
              defaultValue={
                matchSettingsData.data.matchSettings.maxTimeBetweenGames ?? ''
              }
            />
            <BodyS className='input-info'>In minutes</BodyS>
          </fieldset>

          <fieldset>
            <InputText
              id='minTimeBetweenGames'
              label='Min time between games per team'
              placeholder='Min time between games per team'
              type='number'
              key={`minTimeBetweenGames_${String(
                matchSettingsData.data.matchSettings.minTimeBetweenGames ?? ''
              )}`}
              defaultValue={
                matchSettingsData.data.matchSettings.minTimeBetweenGames ?? ''
              }
            />
            <BodyS className='input-info'>In minutes</BodyS>
          </fieldset>

          <fieldset>
            <InputText
              id='maxGamesPerTeam'
              label='Max games per team per day'
              placeholder='Max games per team per day'
              type='number'
              key={`maxGamesPerTeam_${String(
                matchSettingsData.data.matchSettings.maxGamesPerTeam ?? ''
              )}`}
              defaultValue={
                matchSettingsData.data.matchSettings.maxGamesPerTeam ?? ''
              }
            />
          </fieldset>

          <FilledButton
            isUpper
            color='primary'
            onClick={handleSave}
            isLoading={isLoading}
          >
            Save {isLoading && '...'}
          </FilledButton>
        </S.MatchSettingsForm>
      </S.MatchSettingsFormWrapper>
    </S.MatchSettingsWrapper>
  );
};

export default withRouter(MatchSettings);
