import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div``;

export const EditContainer = styled.div`
  padding: 0 ${toRem(180)};
`;

export const HeaderEdit = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HeaderTitle = styled.div`
  display: flex;
`;

export const EventStatusCrumb = styled.p`
  display: flex;
  align-self: flex-start;
  margin: 0;

  font-size: ${toRem(13)};
  text-transform: uppercase;
  color: ${COLORS.white};

  border-style: solid;
  border-width: ${toRem(1)};
  border-color: ${COLORS.white};
  border-radius: ${toRem(7)};

  margin-top: ${toRem(10)};
  margin-left: ${toRem(32)};
  padding: ${toRem(4)} ${toRem(8)};
`;

export const EditContentList = styled.div`
  margin-top: ${toRem(24)};
  padding-right: ${toRem(25)};
  padding-left: ${toRem(25)};
`;

export const PublishEventContainer = styled.div`
  width: 100vw;
  padding: ${toRem(20)} ${toRem(120)};
  background-color: ${COLORS.grey900};

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EventSetupAndProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${toRem(24)};
`;
