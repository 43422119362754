import React, { useMemo, useState } from 'react';
import { Table } from 'components/v3/Table/Table';
import { useParams } from 'react-router-dom';
import { useGetClubMembersPool } from 'hooks/v3/clubs/useGetClubMembersPool/useGetClubMembersPool';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import RightDrawer from 'components/v3/RightDrawer';
import { User } from 'models/User/UserModel';
import DateUtil from 'util/DateUtil';
import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';
import UserType from 'models/User/UserTypeModel';
import { useGetUsersStats } from 'hooks/v3/users/useGetUserStats/useGetUserStats';
import { Filters } from './components/Filters/Filters';
import { columns } from './columns';
import ModalHeader from './components/Modal/ModalHeader/ModalHeader';
import ModalBody from './components/Modal/ModalBody/ModalBody';

export const MembersPoolTable = () => {
  const params: { clubId: string } = useParams();
  const { data: currentClub } = useGetClubById(params.clubId);

  const { data: members } = useGetClubMembersPool(params.clubId);

  const { data: usersStats } = useGetUsersStats(
    members?.map((m) => m.id) || []
  );

  const [filterMember, setFilterMember] = useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentMember, setCurrentMember] = React.useState<User | undefined>(
    undefined
  );

  const {
    clubDirector: {
      memberPool: { selectedUserIds },
    },
  } = useSelector((state: ApplicationState) => state.clubDashboard);

  const handleFilter = (text: string) => {
    setFilterMember(text);
  };

  const membersData = useMemo(() => {
    if (!members?.length || !currentClub) return [];

    const getPermissionType = (
      userId: string,
      permission?: UserType
    ): UserType => {
      if (currentClub.directorIds?.includes(userId))
        return UserType.CLUB_DIRECTOR;
      if (currentClub.coachIds?.includes(userId)) return UserType.COACH;
      if (currentClub.teamManagers?.map((t) => t.userId).includes(userId))
        return UserType.TOURNAMENT_DIRECTOR;
      if (currentClub.playerIds?.includes(userId)) return UserType.PLAYER;
      return permission || UserType.PLAYER;
    };

    return members
      .map((m) => ({
        id: m.id,
        name: `${m.firstName} ${m.lastName}`,
        permission: getPermissionType(m.id, m.type),
        xp:
          usersStats?.find((u: { userId: string }) => u.userId === m.id)?.xp ||
          0,
        age: DateUtil.getYearFromDateString(m.birthDate?.toString()),
        gender: m.gender,
        membership: m.membership?.type || 'COMMUNITY',
        photo: m.photo,
      }))
      .filter((member) =>
        !filterMember
          ? true
          : member.name.toLowerCase().includes(filterMember.toLowerCase())
      );
  }, [members, selectedUserIds, filterMember, currentClub, usersStats]);

  const handleCloseModal = () => {
    setCurrentMember(undefined);
    setIsOpen(false);
  };

  return (
    <>
      <Filters handleChange={handleFilter} />
      <Table columns={columns} dataSource={membersData} />
      <RightDrawer
        isOpen={isOpen && !!currentMember}
        handleCloseModal={handleCloseModal}
        headerComponent={<ModalHeader photo={currentMember?.photo} />}
      >
        {currentMember && <ModalBody user={currentMember} />}
      </RightDrawer>
    </>
  );
};
