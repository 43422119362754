import DraftEventBody from 'components/v3/DraftEvent/Body';
import DraftEventHeader from 'components/v3/DraftEvent/Header';
import SideModalHeader from 'components/v3/SideModal/Header';
import SideModalBody from 'components/v3/SideModal/Body';
import SetupEventHeader from 'components/v3/SetupEvent/Header';
import SetupEventBody from 'components/v3/SetupEvent/Body';
import Footer from 'components/v3/SetupEvent/Footer';
import EditEventBody from 'components/v3/EditEvent/Body';
import { DraftEventContentWrapper } from 'components/v3/DraftEvent/styles';
import { SideModalContentWrapper } from 'components/v3/SideModal/styles';
import { SetupEventContentWrapper } from 'components/v3/SetupEvent/styles';
import * as React from 'react';
import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

interface V3LayoutBaseProps {
  layout?: string;
  auth?: Keycloak.KeycloakInstance | undefined;
}

const V3LayoutBase: React.FunctionComponent<V3LayoutBaseProps> = (props) => {
  const { children, layout, auth } = props;

  switch (layout) {
    case 'base':
      return (
        <>
          <ContentWrapper className={layout}>{children}</ContentWrapper>
        </>
      );
    case 'draftEvent':
      return (
        <>
          <DraftEventContentWrapper className={layout}>
            <DraftEventHeader auth={auth} />
            <DraftEventBody>{children}</DraftEventBody>
          </DraftEventContentWrapper>
        </>
      );
    case 'sidebarModal':
      return (
        <>
          <SideModalContentWrapper className={layout}>
            <SideModalHeader />
            <SideModalBody>{children}</SideModalBody>
          </SideModalContentWrapper>
        </>
      );
    case 'setupEvent':
      return (
        <>
          <SetupEventContentWrapper className={layout}>
            <SetupEventHeader auth={auth} />
            <SetupEventBody>{children}</SetupEventBody>
            <Footer />
          </SetupEventContentWrapper>
        </>
      );
    case 'editEvent':
      return (
        <>
          <SetupEventContentWrapper className={layout}>
            <SetupEventHeader auth={auth} />
            <EditEventBody>{children}</EditEventBody>
          </SetupEventContentWrapper>
        </>
      );
    case 'profile':
      return (
        <>
          <SetupEventContentWrapper className={layout}>
            <SetupEventHeader auth={auth} hasMenu={false} />
            <SetupEventBody>{children}</SetupEventBody>
            <Footer />
          </SetupEventContentWrapper>
        </>
      );
    default:
      return (
        <>
          <ContentWrapper className={layout}>{children}</ContentWrapper>
        </>
      );
  }
};

const ContentWrapper = styled.div`
  background-color: ${COLORS.grey1000};
  height: 100vh;
  color: ${COLORS.white};
  padding: 20px;

  button {
    color: ${COLORS.grey1000};
    background-color: ${COLORS.brandPrimary};
    padding: 10px;
    border-radius: 10px;
  }

  select {
    padding: 10px;
    margin-right: 10px;

    color: ${COLORS.grey1000};
    background-color: ${COLORS.brandPrimary};
  }
`;

export default V3LayoutBase;
