import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Wrapper = styled.div<{ $highlight?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  max-width: 180px;
  padding: ${toRem(16)} ${toRem(5)};
  border-radius: ${toRem(19)};
  background-color: ${(props) =>
    props.$highlight ? 'rgba(202, 252, 1, 0.10)' : 'transparent'};
  border: 2px solid
    ${(props) => (props.$highlight ? COLORS.brandPrimary : 'transparent')};

  * {
    transition: 0.5s;
  }

  .i-icon {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    color: ${(props) =>
      props.$highlight ? COLORS.brandPrimary : COLORS.white};
    display: ${(props) => (props.$highlight ? 'flex' : 'none')};
  }

  img {
    filter: ${(props) =>
      props.$highlight
        ? 'none'
        : `grayscale(60%) brightness(0.5) saturate(150%) sepia(20%)`};
  }

  &:hover {
    .i-icon {
      display: flex;
    }
    img {
      filter: none;
    }
    p {
      color: ${(props) =>
        props.$highlight ? COLORS.brandPrimary : COLORS.white};
    }
  }
`;
export const CardHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  min-height: ${toRem(20)};

  p {
    flex: 1;
  }
`;
export const ImageWrapper = styled.div`
  background-color: ${COLORS.grey200};
  border-radius: 50%;
  width: ${toRem(160)};
  height: ${toRem(160)};
  overflow: hidden;
  margin: 1rem 0;

  img {
    max-width: 100%;
    object-fit: cover;
  }
`;
