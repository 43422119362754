import styled from 'styled-components';

import { Radio } from 'antd';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 16px;

  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: none !important;
    color: ${COLORS.grey950};
    background: ${COLORS.brandPrimary} !important;

    &:hover {
      color: ${COLORS.grey950};
    }
  }

  .ant-radio-button-wrapper:hover {
    color: ${COLORS.grey300};
  }
`;

export const Group = styled(Radio.Group)`
  display: flex;
  gap: 8px;
`;

export const Button = styled(Radio.Button)`
  border-radius: 24px !important;
  border: 1px solid ${COLORS.grey800} !important;

  span:nth-child(2) {
    font-family: 'Boxed Regular';
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
  }
`;
