import React, { ReactNode } from 'react';

import { BodyXLBold } from 'styles/v3/variables';
import { Lock } from '@icon-park/react';
import * as S from './styles';

export type TabsProps = {
  tabs?: {
    key?: string;
    title?: string;
    isLocked?: boolean;
    children?: ReactNode;
  }[];
};

export const Tabs = ({ tabs }: TabsProps) => {
  return (
    <S.Container>
      <S.Tabs type='card'>
        {tabs?.map((tab) => (
          <S.TabPane
            key={tab.key}
            tabKey={tab.key}
            disabled={tab.isLocked}
            tab={
              <S.TabPaneTitle>
                <BodyXLBold>{tab.title}</BodyXLBold>
                {tab.isLocked && <Lock size='22px' />}
              </S.TabPaneTitle>
            }
          >
            {tab.children}
          </S.TabPane>
        ))}
      </S.Tabs>
    </S.Container>
  );
};
