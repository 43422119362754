import styled from 'styled-components';
import { BodyM, COLORS } from 'styles/v3/variables';

interface MultiplierWrapper {
  multiplierColor: string;
}

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;

  padding: 16px;
  border-radius: 16px;
  background-color: ${COLORS.grey900};
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${BodyM} {
    width: 83px;
    text-align: left !important;
  }
`;

export const MultiplierWrapper = styled.div<MultiplierWrapper>`
  display: flex;
  gap: 22px;

  h3 {
    color: ${(props) => props.multiplierColor};
    margin-bottom: 0 !important;
  }
`;
