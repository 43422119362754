import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: ${toRem(16)} 0 ${toRem(16)} 0;
`;

export const FilterWrapper = styled.div`
  display: flex;
  gap: ${toRem(32)};
  align-items: center;
`;

export const FilterText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${toRem(8)};

  span {
    margin-top: ${toRem(6)};
  }
`;
