import * as React from 'react';
import { withRouter } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import { Header } from './components/Header/Header';

import * as S from './styles';
import { UserCard } from './components/UserCard/UserCard';

const Profile: React.FC = () => {
  const users = useSelector((state: ApplicationState) => state.users.data);

  return (
    <S.ProfileWrapper>
      <Header />
      <S.CardWrapper>
        {users?.map((user) => (
          <UserCard key={user.id} {...user} />
        ))}
      </S.CardWrapper>
    </S.ProfileWrapper>
  );
};

export default withRouter(Profile);
