import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

export const UpgradeMembershipPlansWrapper = styled.div`
  text-align: center;

  h1 {
    margin: 0;
  }

  a {
    display: flex;
    align-items: center;

    span {
      font-size: initial;
      line-height: initial;
    }
  }
`;
export const CardsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${toRem(140)};
`;
