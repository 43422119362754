import { AxiosRequestConfig } from 'axios';
import ServiceResponse from '../util/ServiceResponse';
import APIServices from '../util/ApiServices';
import { ConfigApi } from '../config.api';
import { ProductView } from './types';

class ProductsServices {
  public static async getB2bEventProducts(): Promise<ServiceResponse<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getPublicConfig(),
      url: 'products/b2b-events',
      method: 'GET',
    };
    return APIServices.request<any>(axiosConfig);
  }

  public static async getProductById(
    id?: string
  ): Promise<ServiceResponse<ProductView|undefined>> {
    
    if (!id) return ServiceResponse.success<ProductView | undefined>(undefined, '');

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `products/${id}`,
      method: 'GET',
    };
    return APIServices.request<ProductView>(axiosConfig);
  }
}

export default ProductsServices;
