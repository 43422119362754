import React, { useState } from 'react';
import { PeoplePlusOne, Plus } from '@icon-park/react';

import { BodyLBold } from 'styles/v3/variables';
import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';
import { SUB_TAB_KEYS } from './styles';

import { RoastersOpen } from './components/RoastersOpen/RoastersOpen';

import * as S from './styles';


type ExtraButtonsProps = {
  type: 'clubs' | 'referees';
};

const ExtraButtons = ({ type }: ExtraButtonsProps) => {
  if (type === 'clubs') {
    return (
      <OutlinedButtonWithIcon isUpper customIcon={<Plus />}>
        Invite club
      </OutlinedButtonWithIcon>
    );
  }

  if (type === 'referees') {
    return (
      <OutlinedButtonWithIcon isUpper customIcon={<PeoplePlusOne />}>
        Invite referee
      </OutlinedButtonWithIcon>
    );
  }

  return null;
};

export const ClubDirectorTeamsAndRoster = () => {
  const [activeTab, setActiveTab] = useState(SUB_TAB_KEYS.OPEN);

  const handleOnChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <S.Container>
      <S.Tabs
        type='card'
        activeKey={activeTab}
        onChange={handleOnChange}
        tabBarExtraContent={<ExtraButtons type={activeTab as any} />}
      >
        <S.TabPane key={SUB_TAB_KEYS.OPEN} tab={<BodyLBold>{SUB_TAB_KEYS.OPEN}</BodyLBold>}>
          <RoastersOpen />
        </S.TabPane>
        <S.TabPane key={SUB_TAB_KEYS.LOCKED} tab={<BodyLBold>{SUB_TAB_KEYS.LOCKED}</BodyLBold>}>
          <strong>Locked content</strong>
        </S.TabPane>
        <S.TabPane key={SUB_TAB_KEYS.OLD} tab={<BodyLBold>{SUB_TAB_KEYS.OLD}</BodyLBold>}>
          <strong>Old content</strong>
        </S.TabPane>
      </S.Tabs>
    </S.Container>
  );
};
