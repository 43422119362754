import React, { FC, ReactNode } from 'react';
import { ThemeProvider as Provider } from 'styled-components';

import { COLORS } from './variables';

const theme = {
  bgColor: COLORS.grey800,
  buttons: {
    filled: {
      primary: {
        color: COLORS.grey1000,
        bgColor: COLORS.brandPrimary,
      },
      darkPrimary: {
        color: COLORS.brandPrimary,
        bgColor: COLORS.grey1000,
      },
      darkWhite: {
        color: COLORS.white,
        bgColor: COLORS.grey1000,
      },
      whiteDark: {
        color: COLORS.grey1000,
        bgColor: COLORS.white,
      },
    },
    outlined: {
      primary: {
        color: COLORS.brandPrimary,
        bgColor: COLORS.grey1000,
      },
      darkPrimary: {},
      darkWhite: {
        color: COLORS.white,
        bgColor: COLORS.grey1000,
      },
      whiteDark: {},
    },
    default: {
      color: COLORS.grey900,
      bgColor: COLORS.brandPrimary,
    },
  },
};

interface ThemeProps {
  children: ReactNode;
}

const ThemeProvider: FC<ThemeProps> = ({ children }) => (
  <Provider theme={theme}>{children}</Provider>
);

export default ThemeProvider;
