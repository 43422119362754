import React, { useState } from 'react';
import { Drawer } from 'antd';
import { BodyLBold } from 'styles/v3/variables';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import FilledButton from 'components/v3/Buttons/FilledButton';

import { MultiValue, SingleValue } from 'react-select';
import { FilterDrawerProps, InvitationFiltersType } from './types';
import * as S from './styles';

export const FilterDrawer = ({
  isOpen,
  onClose,
  handleChange,
  drawerOptions,
  advancedFilter,
}: FilterDrawerProps) => {
  const genders = ['MALE', 'FEMALE', 'OTHER'];
  const membershipTypes = ['COMMUNITY', 'OFFICIAL'];

  const [selectedFilters, setSelectedFilters] = useState<InvitationFiltersType>(
    advancedFilter
  );

  const handleChangeMultiSelect = (
    type: keyof InvitationFiltersType,
    value: MultiValue<OptionsType> | SingleValue<OptionsType>
  ) => {
    if (Array.isArray(value)) {
      setSelectedFilters((prev) => ({
        ...prev,
        [type]: value.map((v) => v.value),
      }));
    }
  };

  const handleApply = () => {
    handleChange(selectedFilters);
    onClose();
  };

  return (
    <Drawer
      visible={isOpen}
      placement='right'
      onClose={onClose}
      title={<BodyLBold>Filters</BodyLBold>}
      footer={
        <S.Footer>
          <FilledButton isUpper onClick={handleApply}>
            Apply filters
          </FilledButton>
        </S.Footer>
      }
    >
      <S.Container>
        <S.Section>
          <BodyLBold $color='grey50'>General</BodyLBold>
          <Select
            isMulti
            name='gender'
            placeholder='Gender'
            options={genders.map((g) => ({ label: g, value: g }))}
            onChange={(e) => handleChangeMultiSelect('gender', e)}
          />
          <Select
            isMulti
            name='age'
            placeholder='Age'
            options={drawerOptions?.ages as any}
            onChange={(e) => handleChangeMultiSelect('age', e)}
          />
        </S.Section>

        <S.Section>
          <BodyLBold $color='grey50'>Location</BodyLBold>
          <Select
            isMulti
            name='country'
            placeholder='Country'
            options={drawerOptions?.countries as any}
            onChange={(e) => handleChangeMultiSelect('country', e)}
          />
          <Select
            isMulti
            name='state'
            placeholder='State / Region'
            options={drawerOptions?.states as any}
            onChange={(e) => handleChangeMultiSelect('state', e)}
          />
          <Select
            isMulti
            name='city'
            placeholder='City'
            options={drawerOptions?.cities as any}
            onChange={(e) => handleChangeMultiSelect('city', e)}
          />
        </S.Section>

        <S.Section>
          <BodyLBold $color='grey50'>Management</BodyLBold>
          <Select
            isMulti
            name='membershipType'
            placeholder='Membership type'
            onChange={(e) => handleChangeMultiSelect('membership', e)}
            options={membershipTypes.map((g) => ({ label: g, value: g }))}
          />
        </S.Section>
      </S.Container>
    </Drawer>
  );
};
