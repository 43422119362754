import { Modal } from 'antd';
import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;

  margin-bottom: 2rem;

  span {
    cursor: pointer;
  }
`;

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: 468px;
    border-radius: 12px;
    background-color: ${COLORS.grey900};
  }

  .ant-modal-header {
    border-radius: 12px;
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: 12px;
    padding: 6px 12px 6px 12px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid ${COLORS.grey600};

  h3 {
    margin: 0 !important;
  }
`;

export const CloseButton = styled.button`
  width: 38px;
  height: 38px;

  border: none;
  box-shadow: none;
  padding: 7.4px 0;
  border-radius: 10px;
  background-color: unset;

  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    background-color: unset;
  }
`;

export const MultiplierWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
`;
