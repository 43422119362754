import {
  CameraOutlined,
  FileTextOutlined,
  IdcardOutlined,
} from '@ant-design/icons/lib';
import { Button, message, Space, Table, Tooltip } from 'antd';
import { Key, TablePaginationConfig } from 'antd/lib/table/interface';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentApprovalBox from '../../../components/base/DocumentApprovalBox/DocumentApprovalBox';
import PageContainer from '../../../components/base/PageContainer/PageContainer';
import GroupBox from '../../../components/uielements/GroupBox/GroupBox';
import { User } from '../../../models/User/UserModel';
import { membershipsFetchRequest } from '../../../redux/memberships/actions';
import { ApplicationState } from '../../../redux/store';
import DocumentsVerificationService from '../../../services/DocumentsVerification/DocumentsVerificationService';
import UserServices from '../../../services/User/UserApi';
import PageResponse from '../../../services/util/PageResponse';
import ServiceResponse from '../../../services/util/ServiceResponse';

const DocumentsApprovalListPage: React.FunctionComponent<any> = () => {
  const dispatch = useDispatch();
  const memberships = useSelector(
    (state: ApplicationState) => state.memberships
  );
  const initialState = {
    data: [] as User[],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      pageSizeOptions: ['10', '30', '50', '100'],
    },
    loading: false,
  };

  const [state, setState] = useState(initialState);
  const [urlParams, setUrlParams] = useState<URLSearchParams>(
    new URLSearchParams({
      size: '10',
      page: '0',
    })
  );
  const [documentBoxOpen, setDocumentBoxOpen] = useState(false);
  const [documentUrl, setDocumentUrl] = useState<string>('');
  const [documentTypeState, setDocumentTypeState] = useState<
    'photo' | 'age' | 'documents'
  >('photo');
  const [currentUser, setCurrentUser] = useState<User>();
  const [feedbackText, setFeedbackText] = useState<string>('');

  const approveDocument = () => {
    const key = 'documents';
    message.loading({ content: 'Loading...', key });
    if (currentUser != null) {
      DocumentsVerificationService.approve(
        currentUser.id,
        documentTypeState
      ).then((response) => {
        if (response.success) {
          message.success({
            content: 'Document analysis performed.',
            key,
            duration: 3,
          });
          findAll();
        } else {
          message.error({ content: response.message, key, duration: 3 });
        }
      });
    }
  };

  const rejectDocument = () => {
    const key = 'documents';
    message.loading({ content: 'Loading...', key });
    if (currentUser != null) {
      DocumentsVerificationService.decline(
        currentUser.id,
        documentTypeState,
        feedbackText
      ).then((response) => {
        if (response.success) {
          message.success({
            content: 'Document analysis performed.',
            key,
            duration: 3,
          });
          findAll();
        } else {
          message.error({ content: response.message, key, duration: 3 });
        }
      });
    }
  };

  const handleTableChange = (
    pagination?: TablePaginationConfig,
    filters?: Record<string, (Key | boolean)[] | null>,
    sorter?: any
  ) => {
    const urlParamsTmp = new URLSearchParams();
    urlParamsTmp.set(
      'page',
      pagination?.current ? (pagination.current - 1).toString() : '0'
    );
    urlParamsTmp.set(
      'size',
      pagination?.pageSize ? pagination.pageSize.toString() : '10'
    );
    setUrlParams(urlParamsTmp);
  };

  useEffect(() => {
    handleTableChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    findAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams]);

  useEffect(() => {
    dispatch(membershipsFetchRequest());
  }, [dispatch]);

  const confirmRequest = () => {
    approveDocument();
    setDocumentBoxOpen(false);
  };

  const rejectRequest = () => {
    rejectDocument();
    setDocumentBoxOpen(false);
  };

  const openDialog = async (
    user: User,
    documentType: 'photo' | 'age' | 'documents'
  ) => {
    setFeedbackText('');
    setCurrentUser(user);
    setDocumentTypeState(documentType);
    if (documentType === 'photo') {
      setDocumentUrl(user.photo || '');
      setDocumentBoxOpen(true);
    }
    if (documentType === 'age') {
      await UserServices.getUserDocuments(user.id).then((response) => {
        if (response.success) {
          setDocumentUrl(response.data?.ageProof || '');
          setDocumentBoxOpen(true);
        } else {
          message.error(response.message, 3);
        }
      });
    }
    if (documentType === 'documents') {
      await UserServices.getUserDocuments(user.id).then((response) => {
        if (response.success) {
          setDocumentUrl(response.data?.certificate || '');
          setDocumentBoxOpen(true);
        } else {
          message.error(response.message, 3);
        }
      });
    }
    // approveDocument(club.id);
  };

  const findAll = async () => {
    setState({ ...state, loading: true });
    await UserServices.search(urlParams, {
      pendingDocumentation: true,
    }).then((response: ServiceResponse<PageResponse<User>>) => {
      if (response.success) {
        setState({
          ...state,
          loading: false,
          data: response.data.content,
          pagination: {
            ...state.pagination,
            current: response.data.page + 1,
            pageSize: response.data.pageSize,
            total: response.data.total,
          },
        });
      } else {
        setState({ ...state, loading: false });
        message.error({ content: response.message, duration: 5 });
      }
    });
  };

  function photoStatus(record: User) {
    let content;
    if (record.photoVerified === 'PENDING') {
      content = (
        <Tooltip placement='topLeft' title='Photo'>
          <Button
            shape='circle'
            icon={<CameraOutlined />}
            onClick={() => openDialog(record, 'photo')}
          />
        </Tooltip>
      );
    } else if (record.photoVerified === 'APPROVED') {
      content = (
        <Tooltip placement='topLeft' title='Photo Approved'>
          {' '}
          <CameraOutlined style={{ color: '#00cc00', fontSize: '20px' }} />{' '}
        </Tooltip>
      );
    } else {
      content = (
        <Tooltip placement='topLeft' title='Photo Declined'>
          {' '}
          <CameraOutlined style={{ color: '#ff0000', fontSize: '20px' }} />{' '}
        </Tooltip>
      );
    }
    return content;
  }

  function ageStatus(record: User) {
    let content;
    if (record.ageVerified === 'PENDING') {
      content = (
        <Tooltip placement='topLeft' title='Age certificate'>
          <Button
            shape='circle'
            icon={<IdcardOutlined />}
            onClick={() => openDialog(record, 'age')}
          />
        </Tooltip>
      );
    } else if (record.ageVerified === 'APPROVED') {
      content = (
        <Tooltip placement='topLeft' title='Age certificate Approved'>
          {' '}
          <IdcardOutlined style={{ color: '#00cc00', fontSize: '20px' }} />{' '}
        </Tooltip>
      );
    } else {
      content = (
        <Tooltip placement='topLeft' title='Age certificate Declined'>
          {' '}
          <IdcardOutlined style={{ color: '#ff0000', fontSize: '20px' }} />{' '}
        </Tooltip>
      );
    }
    return content;
  }

  function documentStatus(record: User) {
    let content;
    if (record.documentationVerified === 'PENDING') {
      content = (
        <Tooltip placement='topLeft' title='Extra Documents'>
          <Button
            shape='circle'
            icon={<FileTextOutlined />}
            onClick={() => openDialog(record, 'documents')}
          />
        </Tooltip>
      );
    } else if (record.documentationVerified === 'APPROVED') {
      content = (
        <Tooltip placement='topLeft' title='Documents Approved'>
          {' '}
          <FileTextOutlined
            style={{ color: '#00cc00', fontSize: '20px' }}
          />{' '}
        </Tooltip>
      );
    } else {
      content = (
        <Tooltip placement='topLeft' title='Documents Declined'>
          {' '}
          <FileTextOutlined
            style={{ color: '#ff0000', fontSize: '20px' }}
          />{' '}
        </Tooltip>
      );
    }
    return content;
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      render: (text: string, record: User) =>
        `${record.firstName} ${record.middleName} ${record.lastName} ${record.suffix}`,
    },
    {
      title: 'Creation Date',
      dataIndex: 'creationDate',
      sorter: true,
      render: (text: string) => moment(text).format('MM/DD/YYYY'),
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      sorter: true,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: true,
    },
    {
      title: 'Membership',
      dataIndex: 'membershipId',
      sorter: true,
      render: (text: string) =>
        memberships.data.find((e) => e.id === text)?.name,
    },
    {
      title: 'Date of birth',
      dataIndex: 'birthDate',
      sorter: true,
      render: (text: string) => moment(text).format('MM/DD/YYYY'),
    },
    {
      title: 'Pending approval',
      key: 'action',
      // eslint-disable-next-line react/display-name
      render: (text: string, record: User, index: number) => (
        <Space size='middle'>
          {photoStatus(record)}
          {ageStatus(record)}
          {documentStatus(record)}
        </Space>
      ),
    },
  ];

  return (
    <PageContainer title='Document Analysis'>
      <GroupBox title='List'>
        <Table
          rowKey='id'
          dataSource={state.data}
          loading={state.loading}
          pagination={state.pagination}
          columns={columns}
          onChange={handleTableChange}
        />
      </GroupBox>

      <DocumentApprovalBox
        isOpen={documentBoxOpen}
        onClose={() => setDocumentBoxOpen(false)}
        onConfirm={confirmRequest}
        onCancel={rejectRequest}
        documentType={documentTypeState}
        setFeedback={setFeedbackText}
        title='Check the uploaded file'
        documentUrl={documentUrl}
        user={currentUser}
        labelConfirm='Approve'
        labelCancel='Reject'
      />
    </PageContainer>
  );
};
export default DocumentsApprovalListPage;