import React from 'react';
import { useHistory } from 'react-router-dom';
import { HeadsetOne, SoccerOne } from '@icon-park/react';
import ConvertUtil from 'util/ConvertUtil';

import { BodyM, COLORS, TitleH4 } from 'styles/v3/variables';
import { SimplesBreadCrumbWithIcon } from 'components/v3/SimpleBreadCrumbWithIcon';
import FilledButton from 'components/v3/Buttons/FilledButton';

import {
  Container,
  ContainerInformationDivision,
  AvatarEventInformation,
  EventImage,
} from './styles';

interface RosterDivisionProps {
  id: string;
  eventLogo: string;
  eventName: string;
  rosterName: string;
  status: string;
  totalOfCoachs: number;
  totalOfPlayers: number;
}

export const RosterDivision = ({
  id,
  eventLogo,
  eventName,
  rosterName,
  status,
  totalOfCoachs,
  totalOfPlayers,
}: RosterDivisionProps) => {
  const history = useHistory();

  const handleEditRoster = async () => {
    history.push(`/v3/roster/${id}/edit`);
  };
  return (
    <>
      <Container>
        <ContainerInformationDivision>
          <AvatarEventInformation>
            <EventImage>
              <img src={ConvertUtil.getMinioUrl(eventLogo)} alt='Event Logo' />
            </EventImage>
            <BodyM>{eventName}</BodyM>
          </AvatarEventInformation>
          <TitleH4 style={{ lineHeight: 0 }}>{rosterName}</TitleH4>
          <SimplesBreadCrumbWithIcon
            icon={<HeadsetOne size={20} />}
            textInformation={`${totalOfCoachs} coachs`}
            bgColor={totalOfCoachs === 0 ? `grey800` : `brandSecondary`}
            textColor='white'
          />
          <SimplesBreadCrumbWithIcon
            icon={
              <SoccerOne
                fill={totalOfPlayers === 0 ? COLORS.white : COLORS.grey950}
                size={20}
              />
            }
            textInformation={`${totalOfPlayers} players`}
            bgColor={totalOfPlayers === 0 ? 'grey800' : 'brandPrimary'}
            textColor={totalOfPlayers === 0 ? 'white' : 'grey950'}
          />
        </ContainerInformationDivision>
        <FilledButton
          disabled={status === 'SUBMITTED'}
          isUpper
          isBold={false}
          onClick={handleEditRoster}
        >
          Edit Roaster
        </FilledButton>
      </Container>
    </>
  );
};
