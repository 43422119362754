import * as React from 'react';
import styled from 'styled-components';
import HeaderMenu from '../../../HeaderMenu/HeaderMenu';
import SideMenuFull from '../../../SideMenuFull/SideMenuFull';
import MobileMenu from '../../../MobileMenu/MobileMenu';
import bgImage from '../../../../assets/imgs/bg.png';
import ScrollToTop from '../../../uielements/ScrollToTop/ScrollToTop';
import LayoutBaseAdmin from '../LayoutBaseAdmin/LayoutBaseAdmin';
import { color } from '../../../../styles/styleVariables';

interface LayoutBaseProps {
  layout?: string;
}

const LayoutBase: React.FunctionComponent<LayoutBaseProps> = (props) => {
  const { children, layout } = props;

  switch (layout) {
    case 'menu-empty':
      return (
        <>
          <ScrollToTop />
          <div>
            <HeaderMenu empty />
          </div>
          <ContentWrapper className={layout}>{children}</ContentWrapper>
        </>
      );
    case 'no-menu':
      return (
        <>
          <ScrollToTop />
          <ContentWrapper className={layout}>{children}</ContentWrapper>
        </>
      );
    case 'admin':
      return (
        <>
          <ScrollToTop />
          <div>
            <HeaderMenu />
          </div>
          <div>
            <SideMenuFull />
            <MobileMenu />
          </div>
          <ContentWrapper className={layout}>
            <LayoutBaseAdmin>{children}</LayoutBaseAdmin>
          </ContentWrapper>
        </>
      );
    case 'none':
      return (
        <>
          <ScrollToTop />
          <ContentFullEmpty>{children}</ContentFullEmpty>
        </>
      );
    default:
      return (
        <>
          <>
            <div>
              <HeaderMenu />
            </div>
            <div>
              <SideMenuFull />
              <MobileMenu />
            </div>
          </>
          <ContentWrapper className={layout}>{children}</ContentWrapper>
        </>
      );
  }
};

const ContentWrapper = styled.div`
  padding-left: 72px;
  padding-top: 72px;
  min-height: 100vh;
  background-image: url(${bgImage});
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.5s;
  @media (max-width: 768px) {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 80px;
  }
  &.no-menu {
    padding-left: 0;
    padding-top: 0;
  }
`;

const ContentFullEmpty = styled.div`
  min-height: 100vh;
  padding-left: 0;
  padding-top: 0;
  background: #fff;
  color: ${color.greyDark};
`;

export default LayoutBase;
