import React from 'react';
import { CloseSmall } from '@icon-park/react';
import { RequestStatus } from 'models/Request/RequestModel';

import ConvertUtil from 'util/ConvertUtil';
import { BodyM, BodyS, ColorsInterface } from 'styles/v3/variables';

import {
  StyledModal,
  Container,
  ModalHeader,
  CloseWrapper,
  StatusWrapper,
  Status,
  DocumentImage,
} from './styles';

interface DocumentsStatusViewModalProps {
  isOpen: boolean;
  documentName: string;
  documentStatus: RequestStatus;
  isPhoto?: boolean;
  urlImg: string;
  handleIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DocumentStatusViewModal = ({
  isOpen,
  documentName,
  documentStatus,
  isPhoto = false,
  urlImg,
  handleIsOpen,
}: DocumentsStatusViewModalProps) => {
  console.log('DOCUMENT URL', urlImg);

  const statusColor = (status: RequestStatus): keyof ColorsInterface => {
    if (status === 'APPROVED' || status === 'PENDING') {
      return 'grey800';
    }

    return 'grey50';
  };

  return (
    <StyledModal
      centered
      footer={false}
      closable={false}
      visible={isOpen}
      onOk={() => handleIsOpen(false)}
      onCancel={() => handleIsOpen(false)}
    >
      <Container>
        <ModalHeader>
          <BodyM $color='grey50'>Your {documentName}</BodyM>
          <CloseWrapper onClick={() => handleIsOpen(false)}>
            <CloseSmall />
          </CloseWrapper>
        </ModalHeader>

        <StatusWrapper>
          <BodyM $color='grey50' $isUpper>
            {documentName} Status
          </BodyM>
          <Status status={documentStatus}>
            <BodyS $color={statusColor(documentStatus)}>
              {documentStatus}
            </BodyS>
          </Status>
        </StatusWrapper>

        <DocumentImage isPhoto={isPhoto}>
          <img src={ConvertUtil.getMinioUrl(urlImg)} alt='Document Img' />
        </DocumentImage>
      </Container>
    </StyledModal>
  );
};
