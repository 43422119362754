import * as React from 'react';
import styled from 'styled-components';
import { ArrowRight } from '@icon-park/react';

import FilledButton, { FilledButtonProps } from './FilledButton';

const FilledButtonWithIcon: React.FC<
  FilledButtonProps & { customIcon?: React.ReactNode }
> = ({ children, customIcon, ...props }) => {
  return (
    <FilledButtonIcon {...props}>
      {children}
      {customIcon || <ArrowRight />}
    </FilledButtonIcon>
  );
};

const FilledButtonIcon = styled(FilledButton)`
  span {
    display: flex;
    font-size: 1.5rem;
    margin-left: 0.5rem;
    align-items: center;
  }
`;

export default FilledButtonWithIcon;
