import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import EventService from 'services/v3/Event/EventService';

export function useGetJoinedEvents(pageSize = 50) {
  const { id: accountId } = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );
  return useQuery(['get-joined-event', accountId], () => {
    return EventService.getJoinedEvents(pageSize);
  });
}
