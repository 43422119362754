import * as React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Pagination, message as MessageSnackbar } from 'antd';

import ClubCardInline from 'components/v3/Cards/ClubCardInline/ClubCardInline';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import InputSearch from 'components/v3/Forms/InputSearch/InputSearch';
import TabBar from 'components/v3/Elements/TabBar/TabBar';
import Loading from 'components/v3/Loading/Loading';

import { ClubView } from 'admin/models/club/Club';
import { DocumentStatusLabel } from 'redux/v3/baseUsers/types';

import { TitleH2, BodyL } from 'styles/v3/variables';

import ClubService from 'services/Club/ClubService';

import * as S from './styles';

type PaginationProps = {
  page: number;
  pageSize: number;
  total: number;
};

const EmptyState = () => (
  <S.EmptyState>
    <BodyL $color='grey400'>
      We did&apos;t find what you are looking for. Try redoing your search.
    </BodyL>
  </S.EmptyState>
);

const FindClubs = () => {
  const history = useHistory();
  const formRef = React.useRef<HTMLFormElement>(null);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [query, setQuery] = React.useState<string>('');
  const [term, setTerm] = React.useState<string>('');
  const [clubs, setClubs] = React.useState<ClubView[]>([]);
  const [pagination, setPagination] = React.useState<PaginationProps>({
    page: 0,
    pageSize: 0,
    total: 0,
  });

  function handlePreviousStep() {
    history.goBack();
  }

  const onChangePagination = async (page: number) => {
    setPagination({ ...pagination, page: page - 1 });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setQuery(term);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === '') setQuery('');
    setTerm(value);
  };

  const handleGoToDashboard = (clubId: string) => {
    history.push(`/v3/club/${clubId}`);
  };

  const loadClubs = async () => {
    setLoading(true);
    try {
      const { data: clubsData } = await ClubService.list(
        new URLSearchParams({
          size: '8',
          page: `${pagination.page}`,
        })
      );
      setClubs(clubsData.content);
      setPagination({
        page: clubsData.page,
        pageSize: clubsData.pageSize,
        total: clubsData.total,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        MessageSnackbar.error({ content: error.message, duration: 5 });
      }
    }
  };

  React.useEffect(() => {
    loadClubs();
  }, []);

  React.useEffect(() => {
    loadClubs();
  }, [pagination.page]);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data: clubData } = await ClubService.quickSearch(
          query,
          new URLSearchParams({
            size: '8',
            page: '0',
          })
        );
        setClubs(clubData.content);
        setPagination({
          page: clubData.page,
          pageSize: clubData.pageSize,
          total: clubData.total,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error instanceof Error) {
          MessageSnackbar.error({ content: error.message, duration: 5 });
        }
      }
    })();
  }, [query]);

  return (
    <>
      <S.PageTitle $maxWidth={1044}>
        <TextButtonWithIcon
          color='light'
          align='flex-end'
          reverse
          icon='back'
          onClick={handlePreviousStep}
        >
          Go back
        </TextButtonWithIcon>
        <TitleH2 $isUpper>Clubs</TitleH2>
      </S.PageTitle>

      <S.Wrapper $maxWidth={1044}>
        <S.WrapperHeader>
          <TabBar
            items={[
              {
                name: 'my clubs',
                link: '/v3/clubs',
                path: '/v3/clubs',
              },
              {
                name: 'find clubs',
                link: '/v3/clubs/find',
                path: '/v3/clubs/find',
              },
            ]}
          />
        </S.WrapperHeader>

        <S.WrapperSubHeader>
          <TextButtonWithIcon customIcon='Filter' color='grey400' noPadding>
            FILTERS
          </TextButtonWithIcon>

          <S.Form $noPadding ref={formRef} onSubmit={handleSubmit}>
            <InputSearch id='search' onChange={handleSearch} />
          </S.Form>
        </S.WrapperSubHeader>

        {!loading ? (
          <S.WrapperBody
            $empty={clubs?.length === 0}
            $gridTemplateColumns='2,1'
            $noMarginTop
          >
            {clubs?.length > 0 ? (
              clubs?.map((club) => (
                <ClubCardInline
                  key={club.name}
                  name={club.name}
                  locality={`${club.city}, ${club.state}`}
                  logo={club.logo}
                  members={club.totalMembers}
                  onClick={() => handleGoToDashboard(club.id)}
                  status={
                    club.clubVerified
                      ? DocumentStatusLabel[club.clubVerified]
                      : DocumentStatusLabel.APPROVED
                  }
                />
              ))
            ) : (
              <EmptyState />
            )}
          </S.WrapperBody>
        ) : (
          <Loading />
        )}

        <S.WrapperPagination>
          {pagination.total !== 0 && (
            <Pagination
              total={pagination.total}
              defaultPageSize={pagination.pageSize}
              onChange={onChangePagination}
              showSizeChanger={false}
            />
          )}
        </S.WrapperPagination>
      </S.Wrapper>
    </>
  );
};

export default withRouter(FindClubs);
