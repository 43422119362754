import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 38px;
  align-items: center;
  padding: 8px 16px;

  height: 50px;
  border-radius: 12px;
  border: 1px solid ${COLORS.grey400};

  p {
    cursor: pointer;
  }
`;

export const Input = styled.input`
  background-color: transparent;
  border: 0;
  outline: 0;
`;
