import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';
import { DocumentStatusLabel } from 'redux/v3/baseUsers/types';

export const DocumentCardWrapper = styled.div`
  display: flex;
  padding: 1.5rem;
  justify-content: space-between;
    border-top: 1px solid ${COLORS.grey700};

`;
export const BoxInfo = styled.div<{ $isRequired?: boolean }>`
  flex: 1;
  display: flex;
  max-width: 318px;
  flex-direction: column;

  .card-title {
    margin-right: ${toRem(8)};
    &::after {
      content: '*';
      margin-left: ${toRem(8)};
      color: ${COLORS.supportError};
      display: ${(props) => (props.$isRequired ? 'inline-block' : 'none')};
    }
  }
`;
export const BoxInfoHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${toRem(8)};
`;
export const StatusChip = styled.span<{
  $status: DocumentStatusLabel;
}>`
  padding: ${toRem(4)} ${toRem(12)};
  background-color: ${(props) => {
    switch (props.$status) {
      case DocumentStatusLabel.APPROVED:
        return COLORS.brandPrimary;
      case DocumentStatusLabel.EXPIRED:
      case DocumentStatusLabel.DECLINED:
        return COLORS.supportError;
      default:
        return COLORS.grey200;
    }
  }};
  border-radius: ${toRem(36)};
`;
export const BoxAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const IconWrapper = styled.span`
  border-radius: ${toRem(12)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: ${COLORS.grey700};

  .i-icon {
    display: flex;
  }
`;
