import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  padding: ${toRem(24)};

  border-radius: ${toRem(24)};
  border: ${toRem(1)} solid ${COLORS.grey800};

  background-color: ${COLORS.grey950};

  margin-top: ${toRem(40)};
`;

export const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: ${toRem(24)};
`;

export const BodyList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerUser = styled.div`
  width: 100%;

  display: flex;
  padding: ${toRem(16)};
  align-items: center;
  justify-content: space-between;
`;

export const UserInformationContent = styled.div`
  display: flex;
  gap: ${toRem(24)};
  align-items: center;
`;

export const UserIconName = styled.div`
  display: flex;
  gap: ${toRem(16)};
  align-items: center;
`;

export const UserImage = styled.div`
  width: ${toRem(40)};
  height: ${toRem(40)};
  border-radius: ${toRem(100)};

  margin: 0;
  overflow: hidden;

  background-color: ${COLORS.grey50};

  img {
    object-fit: cover;
    max-width: 100%;

    width: ${toRem(40)};
    height: ${toRem(40)};
  }
`;

export const UserMemberType = styled.div`
  display: flex;
  gap: ${toRem(16)};
  align-items: center;
`;

export const UserDocumentsAndMore = styled.div`
  display: flex;
  gap: ${toRem(16)};
  align-items: center;
`;

export const UserDocuments = styled.div`
  display: flex;
  padding: ${toRem(8)};
  gap: ${toRem(8)};
  align-items: center;
`;

export const UserDocument = styled.div`
  display: flex;
  align-items: center;

  span {
    display: flex;
  }
`;

export const UserJerseyContainer = styled.div`
  padding: ${toRem(8)} ${toRem(8)} ${toRem(8)} ${toRem(16)};
  display: flex;
  flex-direction: column;
  align-items: start;

  border-radius: ${toRem(12)};
  border: ${toRem(1)} solid ${COLORS.grey400};
`;
