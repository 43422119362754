import { AxiosRequestConfig, AxiosError } from 'axios';

import { ConfigApi } from 'services/config.api';
import APIServices from 'services/util/ApiServices';
import ServiceResponse from 'services/util/ServiceResponse';

import { BaseUser, PatchBaseUser } from 'redux/v3/baseUsers/types';
import { DeepPartial } from 'util/types';

class BaseUserService {
  public static async saveUser(
    data: BaseUser
  ): Promise<ServiceResponse<BaseUser>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: '/base-users',
      method: 'POST',
      data,
    };
    return APIServices.request<BaseUser>(axiosConfig);
  }

  public static async updateUser(
    id: string,
    data: DeepPartial<PatchBaseUser>,
    userType?: string
  ): Promise<ServiceResponse<AxiosError>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/base-users/${id}`,
      method: 'PATCH',
      data,
    };
    return APIServices.request<AxiosError>(axiosConfig);
  }
}

export default BaseUserService;
