import * as React from 'react';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Plus as PlusIcon,
  CloseSmall as CloseSmallIcon,
} from '@icon-park/react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import {
  sideModalHasBackButton,
  sideModalSetTitle,
} from 'redux/sideModal/actions';
import { useGetStripeAccount } from 'hooks/v3/stripe/useGetStripeAccount/useGetStripeAccount';
import StripeService from 'services/v3/Stripe/StripeService';
import FilledButton from 'components/v3/Buttons/FilledButton';
import Select from 'components/v3/Forms/Select/Select';
import InputText from 'components/v3/Forms/InputText/InputText';
import { BodyLBold, BodyM, BodyMBold, BodyS } from 'styles/v3/variables';
import { useGetProductById } from 'hooks/v3/products/useGetProductById/useGetProductById';
import { useGetProductCoupons } from 'hooks/v3/coupons/useGetProductCoupons/useGetProductCoupons';
import { useGetUsedCodesForAccount } from 'hooks/v3/coupons/useGetUsedCodesForAccount/useGetUsedCodesForAccount';
import { CouponView, StripeCouponType } from 'services/v3/Coupons/types';
import InputDate from 'components/v3/Forms/InputDate/InputDate';
import { StripeConnectedAccount } from 'models/Stripe/StripeConnectedAccount';
import { usePostUpdatePriceInfo } from 'hooks/v3/event/price/usePostUpdatePriceInfo/usePostUpdatePriceInfo';
import DateUtil from 'util/DateUtil';
import { notification } from 'components/v3/Notification/notification';
import * as S from './styles';

const Pricing: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const params: { eventId: string } = useParams();
  const location = useLocation();

  const [saving, setSaving] = React.useState(false);

  const eventId: string = params?.eventId || '';

  const formRef = React.useRef<HTMLFormElement>(null);
  const formRefNewCoupon = React.useRef<HTMLFormElement>(null);
  const formRefUpdateCoupon = React.useRef<HTMLFormElement>(null);

  const [selectedCoupon, setSelectedCoupon] = React.useState<
    CouponView | undefined
  >(undefined);

  const [percentageOffHasError, setPercentageOffHasError] = React.useState(
    false
  );
  const [amountOffHasError, setAmountOffHasError] = React.useState(false);
  const [codeHasError, setCodeHasError] = React.useState(false);
  const [codeErrorMessage, setCodeErrorMessage] = React.useState('');
  const [expiresAtHasError, setExpiresAtHasError] = React.useState(false);
  const [maxRedemptionsHasError, setMaxRedemptionsHasError] = React.useState(
    false
  );
  const [rosterFeeHasError, setRosterFeeHasError] = React.useState(false);

  const [selectedAccount, setSelectedAccount] = React.useState<
    StripeConnectedAccount | undefined
  >(undefined);

  const [selectedType, setSelectedType] = React.useState<StripeCouponType>(
    'PERCENTAGE_OFF'
  );
  const { eventData } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );

  const { data: rosterFeeProduct } = useGetProductById(
    eventData?.data?.paymentInfo?.rosterFeeProductId ?? ''
  );

  const { data: productCoupons } = useGetProductCoupons(
    eventData?.data?.paymentInfo?.rosterFeeProductId ?? ''
  );

  const { data: usedCodes } = useGetUsedCodesForAccount(
    selectedAccount?.id ?? ''
  );

  const [draftCoupons, setDraftCoupons] = React.useState<CouponView[]>([]);

  const { backButtonClickCount, hasBackButton } = useSelector(
    (state: ApplicationState) => state.sideModal
  );

  const {
    mutate: updatePriceInfo,
    isLoading: isUpdatingPriceInfo,
  } = usePostUpdatePriceInfo({
    accountId: eventData?.data?.paymentInfo?.stripeConnectedAccountId ?? '',
    eventId,
    productId: eventData?.data?.paymentInfo?.rosterFeeProductId ?? '',
  });

  const [currentPage, setCurrentPage] = React.useState<
    'Pricing' | 'Discount Coupon' | 'Update Coupon'
  >('Pricing');

  const { data: stripeAccount } = useGetStripeAccount(
    eventData?.data?.paymentInfo?.stripeConnectedAccountId
  );

  React.useEffect(() => {
    if (currentPage === 'Pricing') {
      dispatch(sideModalHasBackButton(false));
    }
    dispatch(sideModalSetTitle(currentPage));
  }, [currentPage, dispatch]);

  React.useEffect(() => {
    const stripeConnectedAccountId =
      eventData?.data?.paymentInfo?.stripeConnectedAccountId ?? '';
    if (!!stripeConnectedAccountId && !!stripeAccount) {
      setSelectedAccount(stripeAccount);
    }
  }, [eventData, stripeAccount]);

  React.useEffect(() => {
    setDraftCoupons(productCoupons);
  }, [productCoupons]);

  React.useEffect(() => {
    const handleBackButton = () => {
      if (hasBackButton && backButtonClickCount > 0) {
        if (
          currentPage === 'Discount Coupon' ||
          currentPage === 'Update Coupon'
        ) {
          setCurrentPage('Pricing');
        }
      }
    };

    handleBackButton();
  }, [backButtonClickCount, hasBackButton]);

  const handleCreateStripeAccount = async () => {
    const {
      success,
      data: stripeOnboardingUrl,
    } = await StripeService.getOauthStripeAccountConnectUrl(
      eventId,
      location.pathname
    );

    if (success) {
      window.location.href = stripeOnboardingUrl;
    }
  };

  const handleResetSelectedAccount = () => {
    setSelectedAccount(undefined);
  };

  const handleDeleteCoupon = (id: string) => {
    setDraftCoupons(draftCoupons.filter((coupon) => coupon.id !== id));
  };

  const handleGoToEditCouponPage = (couponId: string) => {
    const selected = draftCoupons.find((c) => c.id === couponId);

    if (selected?.timesRedeemed) {
      notification.error({
        message: 'This coupon has already been redeemed and cannot be edited',
      });
      return;
    }

    setSelectedCoupon(selected);
    if (selected) {
      setSelectedType(selected.type);
    }
    setCurrentPage('Update Coupon');
    dispatch(sideModalHasBackButton(true));
  };

  const currencyData = [{ label: 'USD', value: 'USD' }];
  const typeData = [
    { label: 'PERCENTAGE OFF', value: 'PERCENTAGE_OFF' },
    { label: 'AMOUNT OFF', value: 'AMOUNT_OFF' },
  ];

  const handleGoToNewCouponPage = () => {
    if (!selectedAccount) {
      notification.error({
        message: 'Please connect to an account first',
      });
      return;
    }
    setCurrentPage('Discount Coupon');
    dispatch(sideModalHasBackButton(true));
  };

  const handleAddNewCoupon = (e: React.FormEvent) => {
    e.preventDefault();
    setSaving(true);
    try {
      if (formRefNewCoupon.current) {
        const formData = new FormData(formRefNewCoupon.current);

        const promotionCode = formData.get('code') as string;

        const type = (formData.get('type') as any) as StripeCouponType;

        if (type === 'AMOUNT_OFF' && !validateField(formData, 'amountOff')) {
          setSaving(false);
          return;
        }

        if (
          type === 'PERCENTAGE_OFF' &&
          !validateField(formData, 'percentageOff')
        ) {
          setSaving(false);
          return;
        }

        if (!validateField(formData, 'code')) {
          setSaving(false);
          return;
        }

        if (!validateField(formData, 'expiresAt')) {
          setSaving(false);
          return;
        }

        if (!validateField(formData, 'maxRedemptions')) {
          setSaving(false);
          return;
        }

        const amountOff =
          ((formData.get('amountOff') as unknown) as number) ?? undefined;

        const percentageOff =
          ((formData.get('percentageOff') as unknown) as number) ?? undefined;

        const payload: CouponView = {
          id: `NEW__${Math.random()}`,
          name: formData.get('code') as string,
          type: (formData.get('type') as any) as StripeCouponType,
          percentageOff: percentageOff ? Number(percentageOff) : undefined,
          amountOff: amountOff ? Number(amountOff) * 100 : undefined,
          promotionCode,
          expiresAt: (formData.get('expiresAt') as unknown) as Date,
          maxRedemptions: (formData.get('maxRedemptions') as unknown) as number,
          timesRedeemed: 0,
          createdAt: new Date(),
          active: true,
        };

        setDraftCoupons((draft) => draft.concat(payload));

        setCurrentPage('Pricing');
        dispatch(sideModalHasBackButton(false));
      }
    } catch (error) {
      console.log('oups e', error);
      setSaving(false);
    }

    setSaving(false);
  };

  const handleUpdateCoupon = (e: React.FormEvent) => {
    e.preventDefault();
    setSaving(true);
    try {
      if (formRefUpdateCoupon.current && selectedCoupon) {
        const formData = new FormData(formRefUpdateCoupon.current);

        const promotionCode = formData.get('code') as string;

        const amountOff =
          ((formData.get('amountOff') as unknown) as number) ?? undefined;

        const percentageOff =
          ((formData.get('percentageOff') as unknown) as number) ?? undefined;

        const type = (formData.get('type') as any) as StripeCouponType;

        if (type === 'AMOUNT_OFF' && !validateField(formData, 'amountOff')) {
          setSaving(false);
          return;
        }

        if (
          type === 'PERCENTAGE_OFF' &&
          !validateField(formData, 'percentageOff')
        ) {
          setSaving(false);
          return;
        }

        if (!validateField(formData, 'code')) {
          setSaving(false);
          return;
        }

        if (!validateField(formData, 'expiresAt')) {
          setSaving(false);
          return;
        }

        if (!validateField(formData, 'maxRedemptions')) {
          setSaving(false);
          return;
        }

        const isUpdatedCoupon =
          selectedCoupon.id.startsWith('NEW__') ||
          selectedCoupon.id.startsWith('UPDATE__');

        const payload: CouponView = {
          id: isUpdatedCoupon
            ? selectedCoupon.id
            : `UPDATE__${selectedCoupon.id}`,
          name: formData.get('code') as string,
          type: (formData.get('type') as any) as StripeCouponType,
          percentageOff: percentageOff ? Number(percentageOff) : undefined,
          amountOff: amountOff ? Number(amountOff) * 100 : undefined,
          promotionCode,
          expiresAt: (formData.get('expiresAt') as unknown) as Date,
          maxRedemptions: (formData.get('maxRedemptions') as unknown) as number,
          timesRedeemed: 0,
          createdAt: new Date(),
          active: true,
        };

        setDraftCoupons((draft) =>
          draft.map((d) => (d.id === selectedCoupon.id ? payload : d))
        );

        setCurrentPage('Pricing');
        dispatch(sideModalHasBackButton(false));
      }
    } catch (error) {
      console.log('e', error);
      setSaving(false);
    }

    setSaving(false);
  };

  const handleSave = async () => {
    if (!selectedAccount) {
      notification.error({
        message: 'Please connect to an account first',
      });
      return;
    }
    setSaving(true);

    if (formRef.current) {
      const formData = new FormData(formRef.current);

      const rosterFee =
        ((formData.get('rosterFee') as unknown) as number) ?? undefined;

      if (!rosterFee || isNaN(rosterFee) || rosterFee < 1) {
        setRosterFeeHasError(true);
        setSaving(false);
      }

      const payload = {
        draftCoupons,
        rosterFee: rosterFee * 100,
      };

      updatePriceInfo(payload);
    }

    setSaving(false);
  };

  function validateField(formData: FormData, field: string): boolean {
    const fieldValue = formData.get(field);
    if (field === 'amountOff') {
      if (!Number(fieldValue) || Number(fieldValue) < 1) {
        setAmountOffHasError(true);
        return false;
      }
    } else if (field === 'percentageOff') {
      if (
        !Number(fieldValue) ||
        Number(fieldValue) < 1 ||
        Number(fieldValue) > 100
      ) {
        setPercentageOffHasError(true);
        return false;
      }
    } else if (field === 'code') {
      const promotionCode = fieldValue?.toString() || '';
      const validCouponFormatRe = /^[a-zA-Z0-9]{1,20}$/;

      if (!promotionCode || !validCouponFormatRe.test(promotionCode)) {
        setCodeErrorMessage('Coupon should not have special characters');
        setCodeHasError(true);
        return false;
      }
      const updatedUsedCodes = [
        ...usedCodes,
        ...draftCoupons.map((c) => c.promotionCode),
      ];
      if (
        !!selectedCoupon &&
        !!promotionCode &&
        updatedUsedCodes.includes(promotionCode) &&
        promotionCode !== selectedCoupon.promotionCode
      ) {
        setCodeErrorMessage(`Coupon code ${promotionCode} already used`);
        setCodeHasError(true);
        return false;
      }
    } else if (field === 'expiresAt') {
      if (
        fieldValue &&
        new Date(fieldValue.toString()).getTime() < new Date().getTime()
      ) {
        setExpiresAtHasError(true);
        return false;
      }
    } else if (field === 'maxRedemptions') {
      if (!Number(fieldValue) || Number(fieldValue) < 1) {
        setMaxRedemptionsHasError(true);
        return false;
      }
    }

    return true;
  }

  return (
    <S.Container>
      {currentPage === 'Pricing' && (
        <S.PricingWrapper>
          <S.PricingForm ref={formRef}>
            <Select
              className='priceItem'
              name='currency'
              placeholder='Currency'
              defaultValue={currencyData[0]}
              options={currencyData}
            />
            <fieldset>
              <InputText
                id='rosterFee'
                placeholder='Price per roster'
                type='text'
                error={rosterFeeHasError}
                onChange={(e) => setRosterFeeHasError(false)}
                errorMessage='Invalid price'
                className='general-event-input'
                defaultValue={rosterFeeProduct?.formattedPrice ?? ''}
                key={`rosterFee_${rosterFeeProduct?.formattedPrice}`}
              />
            </fieldset>
          </S.PricingForm>

          <S.HR />

          <BodyLBold $color='white'>Online Payments</BodyLBold>
          <BodyM>
            If you want to collect online payments, connect your Stripe Wallet.
            Vault is working with Stripe to provide a secure way for you to
            collect payments.
          </BodyM>

          {!selectedAccount?.id ? (
            <>
              <S.LargeButton
                $bgColor='grey50'
                $color='grey900'
                $borderColor='grey50'
                onClick={handleCreateStripeAccount}
              >
                Connect to Stripe
              </S.LargeButton>

              <S.LargeButton
                $bgColor='grey950'
                $color='grey50'
                $borderColor='grey50'
                onClick={handleCreateStripeAccount}
              >
                Create a Stripe Account
              </S.LargeButton>
            </>
          ) : (
            <S.DisconnectWrapper>
              <S.Content className='leftSide'>
                <BodyM>Connected to Stripe as </BodyM>
                <BodyMBold $color='brandPrimary'>
                  {selectedAccount.businessName ??
                    selectedAccount.businessEmail ??
                    selectedAccount.id ??
                    ''}
                </BodyMBold>
              </S.Content>
              <S.Content
                className='rightSide'
                onClick={handleResetSelectedAccount}
              >
                Disconnect
                <CloseSmallIcon className='eventIcon' size='18px' />
              </S.Content>
            </S.DisconnectWrapper>
          )}

          <S.HR />

          <S.DiscountsWrapper
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <BodyLBold $color='white'>Discounts</BodyLBold>
            <S.TextButtonWrapper
              onClick={handleGoToNewCouponPage}
              color='dark-white'
            >
              Create Coupon <PlusIcon className='eventIcon' size='24px' />
            </S.TextButtonWrapper>
          </S.DiscountsWrapper>

          <S.CouponListWrapper>
            {selectedAccount &&
              draftCoupons.map((coupon) => (
                <S.CouponListItem key={coupon.id}>
                  <S.Content className='leftSide'>
                    <BodyS $color='grey400'>
                      {coupon.timesRedeemed} / {coupon.maxRedemptions}
                    </BodyS>
                    <BodyLBold $color='grey50'>
                      {coupon.promotionCode}
                    </BodyLBold>
                    {coupon.type === 'AMOUNT_OFF' ? (
                      <BodyM $color='white'>
                        ${(coupon.amountOff ?? 0) / 100} Discount
                      </BodyM>
                    ) : (
                      <BodyM $color='white'>
                        {coupon.percentageOff}% Discount{' '}
                      </BodyM>
                    )}
                  </S.Content>

                  <S.Content className='rightSide'>
                    <S.Content
                      onClick={() => handleDeleteCoupon(coupon.id)}
                      className='button-container'
                    >
                      <DeleteIcon className='eventIcon delete' size='24px' />
                    </S.Content>
                    <S.Content
                      onClick={() => handleGoToEditCouponPage(coupon.id)}
                      className='button-container'
                    >
                      <EditIcon className='eventIcon' size='24px' />
                    </S.Content>
                  </S.Content>
                </S.CouponListItem>
              ))}
          </S.CouponListWrapper>

          <S.HR />
          <FilledButton
            disabled={saving || isUpdatingPriceInfo}
            onClick={handleSave}
            color='primary'
            isUpper
          >
            {isUpdatingPriceInfo || saving ? 'Updating...' : 'Save'}
          </FilledButton>
        </S.PricingWrapper>
      )}
      {currentPage === 'Discount Coupon' && (
        <S.NewCouponFormWrapper>
          <S.PricingForm ref={formRefNewCoupon} onSubmit={handleAddNewCoupon}>
            <fieldset>
              <Select
                className='type'
                name='type'
                placeholder='Type of Coupon'
                value={typeData.find((t) => t.value === selectedType)}
                onChange={(e) => setSelectedType((e as any).value)}
                options={typeData}
              />
            </fieldset>
            <fieldset>
              {selectedType === 'PERCENTAGE_OFF' ? (
                <InputText
                  id='percentageOff'
                  placeholder='Percentage'
                  label='Percentage'
                  type='number'
                  error={percentageOffHasError}
                  onChange={(e) => setPercentageOffHasError(false)}
                  errorMessage='Percentage must be between 1 and 100'
                  className='general-event-input'
                />
              ) : (
                <InputText
                  id='amountOff'
                  placeholder='Amount Off'
                  label='Amount Off'
                  error={amountOffHasError}
                  onChange={(e) => setAmountOffHasError(false)}
                  errorMessage='Amount must be greater then 0'
                  type='number'
                  className='general-event-input'
                />
              )}
            </fieldset>

            <fieldset>
              <InputText
                id='code'
                placeholder='Coupon Code'
                label='Coupon Code'
                error={codeHasError}
                onChange={(e) => setCodeHasError(false)}
                errorMessage={codeErrorMessage}
                type='text'
                className='general-event-input'
              />
            </fieldset>

            <fieldset>
              <InputDate
                id='expiresAt'
                placeholder='Expiration Date'
                label='Expiration Date'
                error={expiresAtHasError}
                onChange={(e) => setExpiresAtHasError(false)}
                errorMessage='Expiration date must be greater then today'
                className='general-event-input-date'
              />
            </fieldset>
            <fieldset>
              <InputText
                id='maxRedemptions'
                placeholder='Usage time'
                label='Usage time'
                type='number'
                error={maxRedemptionsHasError}
                onChange={(e) => setMaxRedemptionsHasError(false)}
                errorMessage='Usage time must be greater then 0'
                className='general-event-input'
              />
            </fieldset>

            <S.SubmitButton disabled={saving} type='submit'>
              <FilledButton disabled={saving} color='primary' isUpper>
                Save {saving && '...'}
              </FilledButton>
            </S.SubmitButton>
          </S.PricingForm>
        </S.NewCouponFormWrapper>
      )}
      {currentPage === 'Update Coupon' && (
        <S.NewCouponFormWrapper>
          <S.PricingForm
            ref={formRefUpdateCoupon}
            onSubmit={handleUpdateCoupon}
          >
            <fieldset>
              <Select
                className='type'
                name='type'
                placeholder='Type of Coupon'
                value={typeData.find((t) => t.value === selectedType)}
                onChange={(e) => setSelectedType((e as any).value)}
                options={typeData}
              />
            </fieldset>
            <fieldset>
              {selectedType === 'PERCENTAGE_OFF' ? (
                <InputText
                  id='percentageOff'
                  placeholder='Percentage'
                  label='Percentage'
                  error={percentageOffHasError}
                  onChange={(e) => setPercentageOffHasError(false)}
                  errorMessage='Percentage must be between 1 and 100'
                  type='number'
                  key={`percentageOff_${selectedCoupon?.percentageOff}`}
                  defaultValue={selectedCoupon?.percentageOff ?? ''}
                  // required
                  className='general-event-input'
                />
              ) : (
                <InputText
                  id='amountOff'
                  placeholder='Amount Off'
                  label='Amount Off'
                  error={amountOffHasError}
                  onChange={(e) => setAmountOffHasError(false)}
                  errorMessage='Amount must be greater then 0'
                  key={`amountOff_${selectedCoupon?.amountOff}`}
                  defaultValue={
                    selectedCoupon?.amountOff
                      ? selectedCoupon?.amountOff / 100
                      : ''
                  }
                  type='number'
                  className='general-event-input'
                />
              )}
            </fieldset>

            <fieldset>
              <InputText
                id='code'
                placeholder='Coupon Code'
                label='Coupon Code'
                key={`code_${selectedCoupon?.promotionCode}`}
                defaultValue={selectedCoupon?.promotionCode ?? ''}
                error={codeHasError}
                onChange={(e) => setCodeHasError(false)}
                errorMessage={codeErrorMessage}
                type='text'
                className='general-event-input'
              />
            </fieldset>

            <fieldset>
              <InputDate
                id='expiresAt'
                key={`expiresAt_${String(selectedCoupon?.expiresAt)}`}
                defaultValue={
                  selectedCoupon?.expiresAt
                    ? DateUtil.dateTimeToDateString(selectedCoupon.expiresAt)
                    : DateUtil.dateTimeToDateString(new Date())
                }
                placeholder='Expiration Date'
                label='Expiration Date'
                error={expiresAtHasError}
                onChange={(e) => setExpiresAtHasError(false)}
                errorMessage='Expiration date must be greater then today'
                className='general-event-input-date'
              />
            </fieldset>
            <fieldset>
              <InputText
                id='maxRedemptions'
                placeholder='Usage time'
                label='Usage time'
                key={`maxRedemptions_${selectedCoupon?.maxRedemptions}`}
                defaultValue={selectedCoupon?.maxRedemptions ?? ''}
                type='number'
                error={maxRedemptionsHasError}
                onChange={(e) => setMaxRedemptionsHasError(false)}
                errorMessage='Usage time must be greater then 0'
                className='general-event-input'
              />
            </fieldset>

            <S.SubmitButton disabled={saving} type='submit'>
              <FilledButton disabled={saving} color='primary' isUpper>
                Save {saving && '...'}
              </FilledButton>
            </S.SubmitButton>
          </S.PricingForm>
        </S.NewCouponFormWrapper>
      )}
    </S.Container>
  );
};

export default withRouter(Pricing);
