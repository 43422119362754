import * as React from 'react';

import { ModalBodyProps } from './types';

import * as S from './styles';
import PurchasedBy from './components/PurchasedBy/PurchasedBy';
import ClubBanner from './components/ClubBanner/ClubBanner';
import Summary from './components/Summary/Summary';

const ModalBody: React.FC<ModalBodyProps> = ({ order }) => {
  return (
    <S.Container>
      <PurchasedBy transactions={order.transactions.succeed} />
      <ClubBanner
        club={order.item.b2bEventRosterFee?.club}
        userId={order.userId}
      />
      <S.HR />
      <Summary order={order} />
    </S.Container>
  );
};

export default ModalBody;
