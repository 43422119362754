import Keycloak from 'keycloak-js';
import * as React from 'react';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import { v3Routes } from 'pages/V3/routes/V3.routes';
import useGoogleAnalytics from '../hooks/useGoogleAnalytics/useGoogleAnalytics';
import UserType from '../models/User/UserTypeModel';
import UserSelection from '../pages/UserSelection/UserSelection.page';
import { getUserId } from '../util/UsersUtil';

interface PrivateRouteProps {
  auth: Keycloak.KeycloakInstance | undefined;
  render: React.FC;
  path: string;
  exact: boolean;
  userSelectedNotRequired?: boolean;
  roles?: UserType[];
}

const verifyRole = (role: UserType[], auth: any): boolean => {
  if (role === undefined || role.length === 0) return true;
  const userId = getUserId();
  return userId ? role.includes(auth.tokenParsed?.['accounts'][userId]) : false;
};

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = (props) => {
  useGoogleAnalytics();

  const targetPage = (window.location.pathname + window.location.search).substr(
    1
  );

  const isB2bRoute = React.useMemo(() => {
    return !!v3Routes.find(
      (r) => targetPage.indexOf(r.path.substring(1)) !== -1
    );
  }, [targetPage]);

  const { auth, roles, path, exact, userSelectedNotRequired, render } = props;
  const location = useLocation();
  const history = useHistory();
  auth?.updateToken(30);
  if (auth?.authenticated) {
    // If No User is selected userSelection is required
    // (if userSelectedNotRequired was not sent, UserSelection is mandatory)

    // on V3 route do not render UserSelection
    if (!isB2bRoute) {
      const selectedUser = getUserId();
      if (
        (!userSelectedNotRequired && !selectedUser) ||
        (!userSelectedNotRequired && selectedUser === 'null')
      ) {
        return <UserSelection />;
      }

      // If user don't have permission for Role
      if (roles && !verifyRole(roles, auth)) {
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        );
      }

      if (auth?.isTokenExpired()) {
        auth
          .updateToken(5)
          .then(() => {
            localStorage.setItem('kc_token', auth.token || '');
            localStorage.setItem('kc_refreshToken', auth.refreshToken || '');
          })
          .catch(() => {
            localStorage.setItem('kc_token', '');
            localStorage.setItem('kc_refreshToken', '');
            if (isB2bRoute) {
              history.push('/v3/login');
            } else {
              history.push('/login');
            }
          });
      }
    }

    // otherwise render route
    return <Route path={path} exact={exact} component={render} />;
  }

  // If user is not authenticated, redirect to login
  return (
    <Redirect
      to={{
        pathname: isB2bRoute ? '/v3/login' : '/login',
        state: { from: location },
      }}
    />
  );
};

export default PrivateRoute;
