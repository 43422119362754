import * as React from 'react';
import { IconType } from '@icon-park/react/es/all';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { User } from 'models/User/UserModel';
import moment from 'moment';

import Breadcrumb, {
  BreadcrumbItemType,
} from 'components/v3/Breadcrumb/Breadcrumb';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import AvatarStyled from 'components/v3/Elements/Avatares/AvatarStyled/AvatarStyled';

import { ApplicationState } from 'redux/store';

import DateUtil from 'util/DateUtil';
import ConvertUtil from 'util/ConvertUtil';

import { TitleH1 } from 'styles/v3/variables';
import * as S from './styles';

interface TemplateProps {
  breadcrumbItems: BreadcrumbItemType[];
  user?: User;
  children: React.ReactNode;
}

const CompleteUserTemplate: React.FC<TemplateProps> = ({
  breadcrumbItems,
  user,
  children,
}) => {
  const history = useHistory();

  function handlePreviousStep() {
    history.goBack();
  }

  const { userId, data } = useSelector(
    (state: ApplicationState) => state.baseUsers
  );
  const { data: userSelected } = useSelector(
    (state: ApplicationState) => state.currentUser
  );

  const userData = data.find((u) => u?.id === userId) ?? userSelected;
  const userBirthDate =
    typeof userData?.birthDate === 'string'
      ? userData?.birthDate?.split('T')[0]
      : moment();
  const userAge = DateUtil.getUserAge(userBirthDate);

  const avatarImage = userData?.photo
    ? { bgImage: `${ConvertUtil.getMinioUrl(userData?.photo)}` }
    : { icon: 'User' as IconType };

  return (
    <S.TemplateUserWrapper>
      <TextButtonWithIcon
        color='light'
        align='flex-end'
        reverse
        icon='back'
        onClick={handlePreviousStep}
      >
        Go back
      </TextButtonWithIcon>
      {
        user ? <>
          <S.UserDetail>
            <S.UserImage>
              <img src={ConvertUtil.getMinioUrl(user.photo)} alt='User Profile pic' />
            </S.UserImage>
            <TitleH1 $color='grey50'>{user.firstName} {user.lastName}</TitleH1>
          </S.UserDetail>
        </> :
          <>
            <S.TemplateUserHeader>
              <TitleH1>User Profile</TitleH1>
              <AvatarStyled
                {...avatarImage}
                size='extra-large'
                caption={`${userAge} y.o.`}
                text={`${userData?.firstName} ${userData?.lastName}`}
              />
            </S.TemplateUserHeader>
          </>
      }


      <Breadcrumb items={breadcrumbItems} />

      {children}
    </S.TemplateUserWrapper>
  );
};

export default CompleteUserTemplate;
