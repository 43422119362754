import { useQuery } from '@tanstack/react-query';
import RequestService from 'services/v3/Request/RequestService';

type UseGetUserClubRequest = {
  clubId?: string;
  userId?: string;
  isMemberOfThisClub?: boolean;
};

export const useGetUserClubRequest = ({
  clubId,
  userId,
  isMemberOfThisClub,
}: UseGetUserClubRequest) => {
  const response = useQuery(
    ['request', clubId, userId],
    () => RequestService.getUserRequestToClub(clubId, userId),
    {
      enabled: Boolean(clubId && userId && !isMemberOfThisClub),
    }
  );

  return {
    ...response,
    data: response.data?.data,
  };
};
