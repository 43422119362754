import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: ${toRem(24)};
  justify-content: space-between;
  align-items: center;
  gap: ${toRem(24)};

  border-radius: ${toRem(12)};
  background: ${COLORS.grey900};
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${toRem(9)};
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${toRem(9)};
`;

export const SubTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${toRem(24)};
  color: ${COLORS.grey500};
`;

export const SubTitleItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${toRem(8)};
`;;

export const Icon = styled.div<{ $src?: string }>`
  width: ${toRem(32)};
  height: ${toRem(32)};
  border-radius: 33.12px;
  background: url(${(props) => props.$src}), lightgray 50%;
  background-size: cover;
  background-position: center;
`;
export const RightColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr ${toRem(40)};
  column-gap: ${toRem(24)};

  align-items: end;
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ViewIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  padding: ${toRem(8)};
  justify-content: center;
  align-items: center;
  gap: ${toRem(10)};

  border-radius: ${toRem(8)};
  background: ${COLORS.grey800};

  &:hover {
    background: ${COLORS.grey700};
  }

  .i-icon svg {
    width: ${toRem(24)};
    height: ${toRem(24)};
    display: flex;
    &::before {
      display: none;
    }
    path {
      stroke: ${COLORS.white};
    }
  }
`;