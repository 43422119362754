import React, { useMemo } from 'react';
import { Minus, Plus } from '@icon-park/react';
import { BodyXL } from 'styles/v3/variables';
import { useDispatch } from 'react-redux';
import {
  signupDivisionQtyDecrease,
  signupDivisionQtyIncrease,
} from 'redux/v3/signupEvent/actions';
import { UpdateQtyProps } from './types';
import * as S from './styles';

export const UpdateQty = ({ divisions, divisionId }: UpdateQtyProps) => {
  const quantity = useMemo(() => {
    return divisions.find((d) => d.divisionId === divisionId)?.quantity ?? 0;
  }, [divisionId, divisions]);

  const dispatch = useDispatch();
  const increment = (id: string) => {
    dispatch(signupDivisionQtyIncrease(id));
  };
  const decrement = (id: string) => {
    dispatch(signupDivisionQtyDecrease(id));
  };

  return (
    <S.Container>
      <S.Button onClick={() => decrement(divisionId)}>
        <Minus />
      </S.Button>
      <BodyXL>{quantity}</BodyXL>
      <S.Button onClick={() => increment(divisionId)}>
        <Plus />
      </S.Button>
    </S.Container>
  );
};
