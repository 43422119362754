import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

export const PaymentWrapper = styled.div`
  margin-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: baseline;
  height: '100%';
  background-color: ${COLORS.grey1000};

  div.summary {
    h1 {
      margin: 0;

      &:nth-child(2) {
        color: ${COLORS.brandPrimary};
      }
    }
  }
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  max-width: 588px;
  height: fit-content;
`;

export const PaymentSummary = styled.div`
  h1 {
    margin: 0;
    &:nth-child(2) {
      color: ${COLORS.brandPrimary};
    }
  }
`;

export const Container = styled.div``;

export const Image = styled.img``;

export const PaymentEvent = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 30px;
  align-items: center;

  h4 {
    margin: 0;
  }

  p {
    color: ${COLORS.grey300};
    width: 222px;
  }
`;

export const EventLogoContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 135px;
  height: 135px;
  padding: 10px;
  justify-content: center;
  align-items: center;

  border-radius: 1000px;
  background: ${COLORS.grey900};

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`;

export const PaymentText = styled.div`
  max-width: 40%;
`;

interface CardContainerProps {
  $isSuccess: boolean;
}

export const CardContainer =
  styled.div <
  CardContainerProps >
  `
  display: flex;
  padding: 16px 30px 30px 30px;
  gap: 32px;
  border-radius: 32px;

  border: 4px solid
    ${(props) =>
      props.$isSuccess ? COLORS.brandPrimary : COLORS.supportError};

  > img {
    align-self: baseline;
    margin-top: -8px; /* Figma image has padding white space */
  }
  > div {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    h3 {
      margin: 0;
      color: ${(props) =>
        props.$isSuccess ? COLORS.brandPrimary : COLORS.supportError};
    }

    button {
      color: ${COLORS.grey900};
      max-width: fit-content;
      padding: 8px 24px;
      background-color: ${(props) =>
        props.$isSuccess ? COLORS.brandPrimary : COLORS.white};

      font-family: 'Boxed Regular';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.48px;
      text-transform: uppercase;

      svg path {
        fill: ${COLORS.grey900};
      }
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  &.centered {
    align-self: center;
  }

  /* BodyL */
  > p {
    margin: 0;
  }
  div {
    display: flex;
    flex-direction: row;
    column-gap: 12px;

    div.title {
      color: ${COLORS.grey300};
      display: flex;
      flex-direction: row;
      column-gap: 4px;
    }

    div.value {
      color: ${COLORS.grey50};
    }
  }
`;

export const MapContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;

  border-radius: 24px;

  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    url('/assets/imgs/map-placeholder.png'), lightgray 50% / cover no-repeat;

  div {
    display: flex;
    column-gap: 7px;
  }
`;

export const EventCardContainer = styled.div`
  display: flex;
  padding: 20px;
  column-gap: 18px;

  border-radius: 12px;
  background: #373731;

  h4 {
    color: ${COLORS.brandPrimary};
  }
`;
export const CardContainerHeader =
  styled.div <
  CardContainerProps >
  `
  color: ${(props) =>
    props.$isSuccess ? COLORS.brandPrimary : COLORS.supportError};
  /* Title/H3 */
  font-family: UF Display;
  font-size: 39px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%; /* 40.95px */
`;
