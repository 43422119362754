import { User } from '../../models/User/UserModel';

export enum UserType {
  PLAYER = 'PLAYER',
  COACH = 'COACH',
  CLUB_DIRECTOR = 'CLUB_DIRECTOR',
  REFEREE = 'REFEREE',
  TOURNAMENT_DIRECTOR = 'TOURNAMENT_DIRECTOR',
  STAFF = 'STAFF',
}

export enum UserActionTypes {
  FETCH_REQUEST = '@@user/FETCH_REQUEST',
  FETCH_SUCCESS = '@@user/FETCH_SUCCESS',
  FETCH_ERROR = '@@user/FETCH_ERROR',
  UPDATE_ACTIVE = '@@user/UPDATE_ACTIVE',
  UPDATE = '@@user/UPDATE',
}

export interface UserState {
  readonly loading: boolean;
  readonly data: User;
  readonly errors?: string;
}
