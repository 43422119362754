/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import Keycloak from 'keycloak-js';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import ProgressBar from '@ramonak/react-progress-bar';

// styles properties
import { TitleH2, BodyL, COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';
import {
  InfoEventEdit,
  InfoEventEditProps,
} from 'components/v3/Cards/InfoEventEdit';
import { Stepper, Step } from 'components/v3/Elements/Stepper';
import FilledButton from 'components/v3/Buttons/FilledButton';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import { useDispatch, useSelector } from 'react-redux';
import { b2bSetupEventFetchEventRequest } from 'redux/v3/b2bSetupEvent/actions';
import { ApplicationState } from 'redux/store';
import SetupEventModal from 'components/v3/SetupEvent/Modal';

// TYPES STATUS FOR PUBLISH
import {
  EventInfoModel,
  GeneralRulesModel,
  MatchSettingsModel,
} from 'models/v3/setupEvent';
import { PaymentInfo, EventPublishedStatus } from 'admin/models/event/Event';

import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';
import {
  EditContainer,
  HeaderEdit,
  HeaderTitle,
  EventStatusCrumb,
  EditContentList,
  PublishEventContainer,
  Container,
  EventSetupAndProgressBarContainer,
} from './styles';

interface EventParams {
  auth: Keycloak.KeycloakInstance | undefined;
}

const EditEvent: FunctionComponent = (param) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { auth } = param as EventParams;

  const params: { eventId: string } = useParams();
  const eventId: string = params?.eventId || '';

  const { isLoading, mutateAsync } = useUpdateEvent({ eventId });

  const [totallyInfos, setTotallyInfos] = useState<number>(0);
  const [infosToEdit] = useState<InfoEventEditProps[]>([
    { title: 'Event Info', selectedModal: 'eventInfo' },
    { title: 'General Rules', selectedModal: 'generalRules' },
    { title: 'Match Setting', selectedModal: 'matchSettings' },
    { title: 'Divisions', selectedModal: 'divisions' },
    { title: 'Venues', selectedModal: 'venues' },
    { title: 'Courts', selectedModal: 'courts' },
    { title: 'Pricing', selectedModal: 'pricing' },
    { title: 'Multipliers', selectedModal: 'multipliers' },
    { title: 'Tiebreaker', selectedModal: 'tiebreakers' },
  ]);

  useEffect(() => {
    dispatch(b2bSetupEventFetchEventRequest(eventId));
  }, [dispatch]);

  const { eventData } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );

  function handlePrevious() {
    history.push('/v3/events?tab=created-by-me');
  }

  const steps: Step[] = [
    { title: 'Setup', isActive: true },
    { title: 'Clubs', isActive: false },
    { title: 'Format', isActive: false },
    { title: 'Schedule', isActive: false },
  ];

  function isReadyToGo(obj: any) {
    for (const o in obj)
      if (obj[o] === null || obj[o] === undefined || obj[o] === '') {
        return false;
      }

    return true;
  }

  useEffect(() => {
    const eventInfoStatus: EventInfoModel = {
      name: eventData.name,
      description: eventData.description,
      startDate: eventData.startDate as Date,
      endDate: eventData.endDate as Date,
      logo: eventData.logo,
      data: {
        eventInfo: { ...eventData.data?.eventInfo },
      },
    };

    const generalRulesStatus: GeneralRulesModel = {
      minimumPlayersNumber: eventData.minimumPlayersNumber,
      maximumPlayersNumber: eventData.maximumPlayersNumber,
      minimumCoachesNumber: eventData.minimumCoachesNumber,
      maximumCoachesNumber: eventData.maximumCoachesNumber,
      roasterLockDate: eventData.data?.generalRules?.rosterLockDate as Date,
    };

    const matchSettingsStatus: MatchSettingsModel = {
      pointsToWin: eventData.data?.matchSettings?.pointsToWin,
      pointsToDraw: eventData.data?.matchSettings?.pointsToDraw,
      pointsToLose: eventData.data?.matchSettings?.pointsToLose,
      pointsToShutout: eventData.data?.matchSettings?.pointsToShutout,
      recordForForfeit: eventData.data?.matchSettings?.recordForForfeit,
      poolGamesLength: eventData.data?.matchSettings?.poolGamesLength,
      bracketGamesLength: eventData.data?.matchSettings?.bracketGamesLength,
      maxTimeBetweenGames: eventData.data?.matchSettings?.maxTimeBetweenGames,
      minTimeBetweenGames: eventData.data?.matchSettings?.minTimeBetweenGames,
      maxGamesPerTeam: eventData.data?.matchSettings?.maxGamesPerTeam,
    };

    const { ageDivisions, venues, data } = eventData;
    const multipliers = data?.multipliers;
    const tiebrakers = data?.tieBreakers;
    const pricing = data?.paymentInfo;
    const courts = venues?.map((venue) => venue?.subVenues);

    let updateInfos = 0;
    if (
      isReadyToGo(eventInfoStatus) &&
      isReadyToGo(eventInfoStatus.data?.eventInfo)
    ) {
      updateInfos += 1;
    }

    updateInfosToProgressBar(undefined, true, generalRulesStatus) &&
      (updateInfos += 1);

    updateInfosToProgressBar(undefined, true, matchSettingsStatus) &&
      (updateInfos += 1);

    updateInfosToProgressBar(undefined, true, pricing) && (updateInfos += 1);

    updateInfosToProgressBar(ageDivisions, false, undefined) &&
      (updateInfos += 1);

    updateInfosToProgressBar(venues, false, undefined) && (updateInfos += 1);

    updateInfosToProgressBar(courts, false, undefined) && (updateInfos += 1);

    updateInfosToProgressBar(multipliers, false, undefined) &&
      (updateInfos += 1);

    updateInfosToProgressBar(tiebrakers, false, undefined) &&
      (updateInfos += 1);
    setTotallyInfos(updateInfos);
  }, [eventData]);

  const updateInfosToProgressBar = (
    infoToCheck?: any[],
    itUsesReadyToGo?: boolean,
    infoToCheckObject?:
      | MatchSettingsModel
      | GeneralRulesModel
      | EventInfoModel
      | PaymentInfo
  ) => {
    if (itUsesReadyToGo) {
      if (isReadyToGo(infoToCheckObject)) {
        return true;
      }
    }

    if (infoToCheck && infoToCheck.length > 0) {
      return true;
    }

    return false;
  };

  const handlePublishEvent = async () => {
    try {
      const payload = {
        data: {
          eventPublished: EventPublishedStatus.PENDING,
        },
      };

      await mutateAsync({ id: eventId, data: payload });
    } catch (e) {
      console.log('Error updating event', e);
    }
  };

  const allowPublishEvent = useMemo(() => {
    const eventPublished = eventData?.data?.eventPublished;
    return (
      !eventPublished || eventPublished === EventPublishedStatus.UNPUBLISHED
    );
  }, [eventData]);

  return (
    <Container>
      <EditContainer>
        <HeaderEdit>
          <TextButtonWithIcon
            color='primary'
            align='flex-end'
            reverse
            icon='back'
            onClick={handlePrevious}
          >
            Go back
          </TextButtonWithIcon>

          <HeaderTitle>
            <TitleH2 style={{ paddingLeft: 25 }}>
              {eventData?.name || ''}
            </TitleH2>
            <EventStatusCrumb>
              {eventData?.data?.eventPublished || 'UNPUBLISHED'}
            </EventStatusCrumb>
          </HeaderTitle>

          <Stepper steps={steps} style={{ paddingLeft: 25 }} />
        </HeaderEdit>

        <EditContentList>
          {infosToEdit.map((info) => (
            <InfoEventEdit
              key={info.title}
              title={info.title}
              selectedModal={info.selectedModal}
              status={info.status}
            />
          ))}
        </EditContentList>
        <SetupEventModal auth={auth} />
      </EditContainer>
      <PublishEventContainer>
        <EventSetupAndProgressBarContainer>
          <BodyL $color='brandPrimary'>Event Setup</BodyL>
          <ProgressBar
            completed={totallyInfos}
            maxCompleted={9}
            bgColor={COLORS.brandPrimary}
            baseBgColor={COLORS.grey600}
            labelColor={COLORS.brandPrimary}
            width={`${toRem(441)}`}
            height={`${toRem(4)}`}
            borderRadius={`${toRem(25)}`}
            customLabel=''
          />
          <BodyL $color='brandPrimary'>{totallyInfos + 1}0%</BodyL>
        </EventSetupAndProgressBarContainer>
        <FilledButton
          color='white-dark'
          isLoading={isLoading}
          onClick={handlePublishEvent}
          disabled={totallyInfos !== 9 || !allowPublishEvent}
        >
          Publish Event
        </FilledButton>
      </PublishEventContainer>
    </Container>
  );
};

export default withRouter(EditEvent);
