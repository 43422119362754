import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './App.css';
import './styles/style.scss';
import './styles/v3/fonts.css';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
// import GlobalFonts from './styles/v3/fonts';
import ThemeProvider from './styles/v3/ThemeProvider';
import GlobalAntdStyles from './styles/v3/antd';

import Routes from './routes/Routes';
import ResetStyle from './styles/v3/reset';
import configureStore from './configStore';

const initialState = window.INITIAL_REDUX_STATE;

export const store = configureStore(initialState);
const persistor = persistStore(store);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FunctionComponent = () => (
  <Provider store={store}>
    <ResetStyle />
    <QueryClientProvider client={queryClient}>
      {/* <GlobalFonts /> */}
      <GlobalAntdStyles />
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <Routes />
        </ThemeProvider>
      </PersistGate>
    </QueryClientProvider>
  </Provider>
);

export default App;
