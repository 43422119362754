import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const OrderSummaryWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 996px;
  justify-content: space-between;
`;
export const SummaryInfoContainer = styled.div`
  display: flex;
  max-width: 486px;
  flex-direction: column;

  p {
    margin-bottom: 1rem;
  }

  .details-line {
    display: flex;
    margin-bottom: ${toRem(12)};
    align-items: flex-start;

    p {
      margin: 0 0 0 ${toRem(8)};
    }
  }
`;
export const SummaryBoxInfo = styled.div`
  padding: ${toRem(24)};
  border-radius: 1.5rem;
  margin-top: ${toRem(12)};
  background-color: ${COLORS.grey900};

  p {
    margin: 0;
  }
`;
export const SummaryAside = styled.aside`
  display: flex;
  max-width: 384px;
  border-radius: 1.5rem;
  padding: ${toRem(30)};
  flex-direction: column;
  justify-content: center;
  margin-left: ${toRem(126)};
  background-color: ${COLORS.grey950};
  text-align: center;

  img {
    margin: 0 auto 1.5rem;
    max-width: 120px;
  }

  .aside-title {
    margin-bottom: ${toRem(8)};
  }
`;
export const AsideBoxInfo = styled.div`
  padding: ${toRem(20)};
  width: 324px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${toRem(30)} 0;
  border-radius: ${toRem(12)};
  background-color: ${COLORS.grey900};

  .aside-box-info-price {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    p {
      text-align: left;
      max-width: 150px;
    }
  }

  h3 {
    margin: 0 ${toRem(16)} 0 0;
  }
`;
