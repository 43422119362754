import React from 'react';
import { BodyM, TitleH4 } from 'styles/v3/variables';
import { formatUserName } from 'util/formatUserName';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { Mail } from '@icon-park/react';
import { User } from 'models/User/UserModel';
import { SimpleCard } from '../../../components';
import * as S from './styles';

type InviteListProps = {
  selectedMembersData?: User[];
  handleSendInvites: () => Promise<void>;
  handleRemoveCard: (id: string) => void;
};

export const InviteList = ({
  handleRemoveCard,
  handleSendInvites,
  selectedMembersData,
}: InviteListProps) => {
  return (
    <S.InviteListWrapper>
      <S.InviteListMain>
        <TitleH4 $isUpper>Invite list</TitleH4>
        <BodyM $color='grey400'>
          If you invite members that have already been invited, they will be
          notified again.
        </BodyM>
        <S.SimpleCardList>
          {selectedMembersData?.map((item) => (
            <SimpleCard
              id={item.id}
              key={item.id}
              imgSrc={item.photo}
              handleRemoveCard={handleRemoveCard}
              address={`${item?.address?.city} - ${item?.address?.state}`}
              name={formatUserName({
                firstName: item.firstName,
                lastName: item.lastName,
                middleName: item.middleName,
              })}
            />
          ))}
        </S.SimpleCardList>
        <FilledButton isUpper isBold={false} onClick={handleSendInvites}>
          Send invites
        </FilledButton>
      </S.InviteListMain>
      <S.InviteEmailWrapper>
        <BodyM $isUpper>Invite via email</BodyM>
        <Mail size={24} />
      </S.InviteEmailWrapper>
    </S.InviteListWrapper>
  );
};
