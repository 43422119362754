import React, { useMemo, useState } from 'react';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import { BodyMBold, TitleH2, TitleH4 } from 'styles/v3/variables';
import InputText from 'components/v3/Forms/InputText/InputText';
import { useHistory } from 'react-router-dom';
import { useFilterMembersToClub } from 'pages/V3/Invites/hooks/useFilterMembersToClub';
import { User } from 'models/User/UserModel';
import DateUtil from 'util/DateUtil';
import { Filter } from '@icon-park/react';
import { formatUserName } from 'util/formatUserName';
import { FilterDrawer } from './components';
import { Card } from '../../../components';
import { InvitationFiltersType } from './components/FilterDrawer/types';
import * as S from './styles';

type FindProps = {
  data?: User[];
  selectedMembers: string[];
  handleCardSelect: (id: string) => void;
};

export const Find = ({
  data,
  selectedMembers,
  handleCardSelect,
}: FindProps) => {
  const history = useHistory();
  const [memberName, setMemberName] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState<InvitationFiltersType>({
    age: [],
    city: [],
    state: [],
    gender: [],
    country: [],
    membership: [],
  });

  const drawerOptions = useMemo(
    () => ({
      cities: Array.from(
        new Set(
          data?.map((item) => ({
            label: item.address?.city,
            value: item.address?.city,
          }))
        )
      ),
      states: Array.from(
        new Set(
          data?.map((item) => ({
            label: item.address?.state,
            value: item.address?.state,
          }))
        )
      ),
      countries: Array.from(
        new Set(
          data?.map((item) => ({
            label: item.address?.country,
            value: item.address?.country,
          }))
        )
      ),
      ages: Array.from(
        new Set(
          data?.map((item) => ({
            label: DateUtil.getYear(new Date(item.birthDate as any)),
            value: DateUtil.getYear(new Date(item.birthDate as any)),
          }))
        )
      ),
    }),
    [data]
  );

  const filteredMembers = useFilterMembersToClub({
    memberName,
    members: data,
    advancedFilter,
  });

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleSetAdvancedFilter = (filters: InvitationFiltersType) => {
    setAdvancedFilter(filters);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <S.FindWrapper>
      <TextButtonWithIcon
        reverse
        icon='back'
        color='light'
        align='flex-end'
        onClick={handleGoBack}
      >
        Go back
      </TextButtonWithIcon>
      <TitleH2 $color='brandPrimary'>Find members</TitleH2>
      <InputText
        id='search'
        icon='Search'
        value={memberName}
        placeholder='Search by name'
        onChange={({ target }) => setMemberName(target.value)}
      />
      <S.FilterWrapper>
        <S.FilterButton onClick={() => setIsDrawerOpen((prev) => !prev)}>
          <BodyMBold $isUpper $color='grey500'>
            Filters
          </BodyMBold>
          <Filter />
        </S.FilterButton>
        <FilterDrawer
          isOpen={isDrawerOpen}
          onClose={handleCloseDrawer}
          drawerOptions={drawerOptions}
          advancedFilter={advancedFilter}
          handleChange={handleSetAdvancedFilter}
        />
      </S.FilterWrapper>
      {!filteredMembers?.length ? (
        <S.FallbackMessageWrapper>
          <TitleH4 $isUpper $color='grey700'>
            didn&apos;t find the member you were looking for?
          </TitleH4>
          <TitleH4
            $color='grey500'
            onClick={() => history.push('/v3/invites/email')}
          >
            Invite via email
          </TitleH4>
        </S.FallbackMessageWrapper>
      ) : (
        filteredMembers?.map((item) => (
          <Card
            id={item.id}
            key={item.id}
            logoSrc={item.photo}
            onSelect={handleCardSelect}
            selected={selectedMembers.includes(item.id)}
            year={DateUtil.getYear(new Date(item.birthDate as string))}
            address={`${item?.address?.city} - ${item?.address?.state}`}
            name={formatUserName({
              firstName: item.firstName,
              lastName: item.lastName,
              middleName: item.middleName,
            })}
          />
        ))
      )}
    </S.FindWrapper>
  );
};
