import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CalendarThree, Local, MapDraw, ShareOne } from '@icon-park/react';
import moment from 'moment';

import {
  BodyLBold,
  BodyM,
  BodyMBold,
  COLORS,
  TitleH2,
  TitleH3,
} from 'styles/v3/variables';

import FilledButton from 'components/v3/Buttons/FilledButton';
import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';
import OutlinedButton from 'components/v3/Buttons/OutlinedButton';
import { EventTierCard } from 'components/v3/Cards/EventTierCard/EventTierCard';

import { ApplicationState } from 'redux/store';
import { EventView } from 'admin/models/event/Event';
import { useGetProductById } from 'hooks/v3/products/useGetProductById/useGetProductById';

import { InfoCard } from '../../../InfoCard/InfoCard';
import { ApplyModal } from './components/ApplyModal/ApplyModal';
import { InfoModal } from './components/InfoModal';
import { MultiplierInfoModal } from './components/MultiplierInfoModal/MultiplierInfoModal';

import * as S from './styles';

type InformationSectionProps = {
  event?: EventView;
};

export const InformationSection = ({ event }: InformationSectionProps) => {
  const params: any = useParams();
  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const {
    data: { content: allB2bSetupEvents },
  } = useSelector((state: ApplicationState) => state.b2bSetupEvent);
  const { data: allEvents } = useSelector(
    (state: ApplicationState) => state.events
  );
  const joinAllEvents = [...allB2bSetupEvents, ...allEvents];

  const findEvent = joinAllEvents.find((e) => e.id === params?.eventId);

  const { data: rosterFeeProduct } = useGetProductById(
    findEvent?.data?.paymentInfo?.rosterFeeProductId ?? ''
  );

  const multiplier = event?.data?.multipliers?.reduce(
    (accumulator, currentValue) =>
      accumulator + (currentValue?.multiplierValue as number),
    1
  );

  return (
    <>
      <ApplyModal
        isOpen={isApplyModalOpen}
        handleIsOpen={setIsApplyModalOpen}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleIsOpen={setIsInfoModalOpen}
        emailContact={event?.data?.eventInfo?.emailContact}
        phoneContact={event?.data?.eventInfo?.phoneContact}
      />
      <S.InformationSection>
        <S.MainInformationSection>
          <S.TextsWrapper>
            <S.GeneralInfoTextWrapper>
              <TitleH3 $isUpper>{event?.name}</TitleH3>
              <BodyLBold $color='grey200'>LOS ANGELES, CA</BodyLBold>
              <BodyM $color='grey300'>{event?.description}</BodyM>
            </S.GeneralInfoTextWrapper>
            <S.PriceTextWrapper>
              {rosterFeeProduct?.formattedPrice ? (
                <TitleH2 $color='brandPrimary'>
                  ${rosterFeeProduct?.formattedPrice}
                </TitleH2>
              ) : (
                <BodyM $color='brandPrimary'>
                  There is no set price for this event yet.
                </BodyM>
              )}
              {rosterFeeProduct?.formattedPrice && (
                <BodyM $color='brandPrimary'>PER TEAM</BodyM>
              )}
            </S.PriceTextWrapper>
          </S.TextsWrapper>
          <S.ActionsWrapper>
            <FilledButton isUpper onClick={() => setIsApplyModalOpen(true)}>
              Apply now
            </FilledButton>
            {/* <OutlinedButtonWithIcon
              isUpper
              color='dark-white'
              customIcon={<ShareOne />}
            >
              Share
            </OutlinedButtonWithIcon>
            <OutlinedButtonWithIcon
              isUpper
              color='dark-white'
              customIcon={<MapDraw />}
            >
              Go to event
            </OutlinedButtonWithIcon> */}
            <OutlinedButton
              isUpper
              color='dark-white'
              onClick={() => setIsInfoModalOpen(true)}
            >
              Contact Event Manager
            </OutlinedButton>
          </S.ActionsWrapper>
        </S.MainInformationSection>
        <S.CardsWrapper>
          <EventTierCard
            multiplier={multiplier ?? 1}
            extra={
              <MultiplierInfoModal
                multiplier={multiplier}
                multipliers={event?.data?.multipliers}
              />
            }
          />
          <InfoCard
            title='Dates'
            icon={<CalendarThree color={`${COLORS.grey700}`} size={50} />}
          >
            <S.DatesText>
              <BodyMBold $isUpper $color='grey400'>
                Starts
              </BodyMBold>
              <BodyM $isUpper $color='grey100'>
                {moment(event?.startDate).format('MMM DD yy')}
              </BodyM>
            </S.DatesText>

            <S.DatesText>
              <BodyMBold $color='grey400' $isUpper>
                Finishes
              </BodyMBold>
              <BodyM $isUpper $color='grey100'>
                {moment(event?.endDate).format('MMM DD yy')}
              </BodyM>
            </S.DatesText>
          </InfoCard>
          <InfoCard title='Vanues' icon={<Local size={50} />}>
            <BodyMBold $color='grey400'>
              ARENA PANTAL, DAS DUNAS, MORUMBI, +1
            </BodyMBold>
          </InfoCard>
        </S.CardsWrapper>
      </S.InformationSection>
    </>
  );
};
