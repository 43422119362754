import React, { useEffect } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';

import { useGetEvent } from 'hooks/v3/event/useGetEvent/useGetEvent';
import ConvertUtil from 'util/ConvertUtil';
import Loading from 'components/v3/Loading/Loading';
import { EventHeader, EventMain } from './components';

import * as S from './styles';

const Event = () => {
  const history = useHistory();
  const params: { eventId: string } = useParams();

  const { data: response, isLoading } = useGetEvent(params.eventId);

  useEffect(() => {
    if (!params.eventId) history.goBack();
  }, [history, params.eventId]);

  return isLoading ? (
    <Loading />
  ) : (
    <S.Container>
      <EventHeader
        logoSrc={ConvertUtil.getMinioUrl(response?.data.logo)}
        backgroundSrc={ConvertUtil.getMinioUrl(
          response?.data.data?.eventInfo?.header
        )}
      />
      <EventMain event={response?.data} />
    </S.Container>
  );
};

export default withRouter(Event);
