import * as React from 'react';
import Select, { Props } from 'react-select';
import AsyncSelect from 'react-select/async';

import * as S from './styles';

export type OptionsType = {
  readonly label: string;
  readonly value: string | number;
};

type Option = OptionsType;

interface SelectProps extends Props<Option> {
  error?: boolean;
  isSearchable?: boolean;
  isAsync?: boolean;
  loadOptions?: (v: string) => void;
}

function InputSelect<Option>(props: SelectProps) {
  return (
    <S.SelectWrapper $required={props.required} $error={props.error}>
      {props.isAsync ? (
        <AsyncSelect
          {...props}
          cacheOptions
          defaultOptions
          loadOptions={props.loadOptions}
          isSearchable={props.isSearchable ?? false}
          classNamePrefix='vault-select'
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      ) : (
        <Select
          {...props}
          isSearchable={props.isSearchable ?? false}
          classNamePrefix='vault-select'
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      )}
    </S.SelectWrapper>
  );
}

export default InputSelect;
