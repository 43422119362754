import { AxiosRequestConfig } from 'axios';

import { ConfigApi } from 'services/config.api';
import APIServices from 'services/util/ApiServices';
import ServiceResponse from 'services/util/ServiceResponse';
import PageResponse from 'services/util/PageResponse';
import { ClubView } from 'admin/models/club/Club';
import { UserRequest } from './types';

class RequestService {
  public static async getUserRequestToClub(
    clubId?: string,
    userId?: string
  ): Promise<ServiceResponse<UserRequest>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/requests/club/${clubId}/user/${userId}`,
      method: 'GET',
    };
    return APIServices.request<UserRequest>(axiosConfig);
  }

  public static async requestJoin(
    body: any
  ): Promise<ServiceResponse<PageResponse<ClubView>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: '/requests',
      method: 'POST',
      data: body,
    };
    return APIServices.request<PageResponse<ClubView>>(axiosConfig);
  }
}

export default RequestService;
