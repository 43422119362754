import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div<{ $image: string; $maxWidth: boolean }>`
  width: 100%;

  height: ${toRem(200)};
  border-radius: ${toRem(24)} ${toRem(24)} 0 0;
  max-width: ${({ $maxWidth }) => ($maxWidth ? toRem(996) : '')};
  background: linear-gradient(0deg, #252622 0%, rgba(27, 28, 25, 0) 75.75%),
    url(${({ $image }) => $image}), lightgray 0% 0% / ${toRem(50)} ${toRem(50)};
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  padding: 0 ${toRem(30)};
`;

export const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: -100px;
  align-items: center;

  button {
    height: ${toRem(50)};
  }
`;

export const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${toRem(11)};
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${toRem(6)};
`;

export const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${toRem(8)};
  align-items: center;

  .i-icon svg {
    width: 20px;
    height: 20px;
    display: flex;
    &::before {
      display: none;
    }

    path {
      stroke: ${COLORS.grey400};
    }
  }
`;
