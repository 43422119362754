import { VenueView } from "admin/models/event/Venue";
import { AxiosRequestConfig } from "axios";
import { ConfigApi } from "services/config.api";
import APIServices from "services/util/ApiServices";
import ServiceResponse from "services/util/ServiceResponse";

class VenueService {
  public static async getVenueById(
    id?: string
  ): Promise<ServiceResponse<VenueView | undefined>> {
    if (!id)
      return ServiceResponse.success<VenueView | undefined>(undefined, '');

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `venues/${id}`,
      method: 'GET',
    };
    return APIServices.request<VenueView>(axiosConfig);
  }
}

export default VenueService;
