import React from 'react';

import { EventManagerDashboard } from 'components/v3/EventManagerDashboard/EventManagerDashboard';
import Sales from './components/Sales/Sales';
import { TABS_KEYS } from './tab-keys';

const PublishedEvent = () => {
  return (
    <EventManagerDashboard
      tabs={[
        {
          title: 'Invites',
          key: TABS_KEYS.INVITES,
        },
        {
          title: 'Sales',
          children: <Sales />,
          key: TABS_KEYS.SALES,
        },
      ]}
    />
  );
};

export default PublishedEvent;
