import React from 'react';
import { RoastersForClub } from './components/RoastersForClub';

export const RoastersOpen = (props: { onlyMyResults: boolean }) => {
  const { onlyMyResults } = props;
  return (
    <>
      <RoastersForClub onlyMyResults={onlyMyResults} />
    </>
  );
};
