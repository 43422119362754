import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const TemplateUserWrapper = styled.div`
  width: 100%;
  max-width: 636px;
  margin: 0 auto;
`;
export const TemplateUserHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;
const FormStyle = `
  background-color: ${COLORS.grey950};
  border-radius: ${toRem(24)};
  flex-direction: column;
  display: flex;
`;
export const UserForm = styled.form`
  ${FormStyle}
  padding: ${toRem(24)};

  .user-info-input {
    margin-top: ${toRem(24)};
  }

  button {
    margin-top: ${toRem(40)};
  }
`;
export const FormFieldset = styled.fieldset`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
`;
export const DocumentsWrapper = styled.div`
  ${FormStyle}
`;
export const DocumentsWrapperHeader = styled.div`
  padding: 1.5rem;
`;
export const DocumentsWrapperFooter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`;

export const UserDetail = styled.div`
  display: flex;
  gap: ${toRem(32)};
`;

export const UserImage = styled.div`
  width: ${toRem(78)};
  height: ${toRem(78)};
  border-radius: ${toRem(100)};

  margin: 0;
  overflow: hidden;

  background-color: ${COLORS.grey50};

  img {
    object-fit: cover;
    max-width: 100%;

    width: ${toRem(78)};
    height: ${toRem(78)};
  }
`;
