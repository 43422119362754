import { AxiosRequestConfig } from 'axios';
import ServiceResponse from 'services/util/ServiceResponse';
import { ConfigApi } from '../../config.api';
import APIServices from '../../util/ApiServices';

class StripeService {
  public static async getStripeUrlFromUpdateAccount(
    accountId: string,
    returnUrl: string
  ): Promise<ServiceResponse<string>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/stripe/connected-accounts/onboarding/${accountId}`,
      params: {
        returnUrl,
      },
      method: 'GET',
    };
    return APIServices.request<string>(axiosConfig);
  }

  public static async getOauthStripeAccountConnectUrl(
    eventId: string,
    redirectUrl: string
  ): Promise<ServiceResponse<string>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/stripe/connected-accounts/oauth-connect`,
      data: {
        redirectUrl,
        type: 'B2B_EVENT',
        eventId,
      },
      method: 'POST',
    };
    return APIServices.request<string>(axiosConfig);
  }
}

export default StripeService;
