import React from 'react'
import { Right } from '@icon-park/react'
import { ContainerStepper, StepData } from './style'

export interface Step {
  title: string
  isActive?: boolean
}

interface StepperProps {
  style?: React.CSSProperties
  steps: Step[]
}

export const Stepper: React.FC<StepperProps> = ({ steps, style, ...props }) => {
  return (
    <ContainerStepper style={style}>
      {steps.map((step, index) => {
        const { title, isActive } = step

        return (
          <StepData key={`step+${title}`} isActive={isActive}>
            <span>{title}</span>
            {index !== steps.length - 1 && <Right />}
          </StepData>
        )
      })}
    </ContainerStepper>
  )
}
