import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { BodyM, COLORS } from 'styles/v3/variables';

export const MultiplierContainer = styled.div``;

export const MultiplierDescription = styled.div`
  padding: ${toRem(5)} ${toRem(94)} ${toRem(13)} ${toRem(30)};
`;

export const MultiplierForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const MultiplierContent = styled.div`
  display: flex;
  padding: ${toRem(25)} ${toRem(30)};

  justify-content: space-between;
  align-items: center;

  border-bottom: solid ${toRem(0.5)};
  border-bottom-color: ${COLORS.grey900};

  cursor: pointer;
  transition: ease-in-out 0.3s;

  &:hover {
    background-color: ${COLORS.grey900};
  }
`;

export const MultiplierSelectContent = styled.div`
  display: flex;
  padding: ${toRem(10)} ${toRem(30)};

  cursor: pointer;

  &:last-child {
    margin-bottom: ${toRem(10)};
  }
`;

interface SelectedValue {
  value: number;
  isSelected: boolean;
}

export const MultiplierContainerContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${toRem(16)};

  span {
    margin: 0;
  }
  svg {
    margin: 0;
  }
`;

export const MultiplyContentdescription = styled.div<SelectedValue>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${toRem(8)} ${toRem(24)};

  border: solid ${toRem(1)};
  border-color: ${({ isSelected }) =>
    isSelected ? COLORS.brandPrimary : COLORS.white};
  border-radius: ${toRem(87)};

  background-color: ${({ isSelected }) =>
    isSelected ? COLORS.shadowBrandPrimary : 'transparent'};

  cursor: pointer;

  span {
    font-size: ${toRem(12)};
    font-weight: 500;
    color: ${({ isSelected }) =>
      isSelected ? COLORS.brandPrimary : COLORS.white};
  }

  transition: ease-in-out 0.3s;

  &:hover {
    background-color: ${COLORS.grey900};
  }
`;

export const MultiplierContentDescription = styled.div<SelectedValue>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${toRem(8)} ${toRem(24)};

  border: solid ${toRem(1)};
  border-color: ${({ value }) =>
    value === 0 ? COLORS.white : COLORS.brandPrimary};
  border-radius: ${toRem(87)};

  background-color: ${({ value }) =>
    value === 0 ? 'transparent' : COLORS.shadowBrandPrimary};

  cursor: pointer;

  span {
    font-size: ${toRem(12)};
    font-weight: 500;
    color: ${({ value }) => (value === 0 ? COLORS.white : COLORS.brandPrimary)};
  }

  transition: ease-in-out 0.3s;

  &:hover {
    background-color: ${COLORS.grey900};
  }
`;

export const MultiplyContentValue = styled.div<SelectedValue>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${toRem(6)};

  color: ${COLORS.grey950};

  margin-left: ${toRem(24)};
  border-radius: ${toRem(65)};
  background-color: ${({ isSelected }) =>
    isSelected ? COLORS.brandPrimary : COLORS.white};

  span {
    font-size: ${toRem(13)};
    font-weight: 500;
    color: ${COLORS.grey950};
  }
`;

export const MultiplierContentValue = styled.div<SelectedValue>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${toRem(6)};

  color: ${COLORS.grey950};

  margin-left: ${toRem(24)};
  border-radius: ${toRem(65)};
  background-color: ${({ value }) =>
    value === 0 ? COLORS.white : COLORS.brandPrimary};

  span {
    font-size: ${toRem(13)};
    font-weight: 500;
    color: ${COLORS.grey950};
  }
`;

export const TotalMultiplierContainer = styled.div`
  display: block;

  padding: ${toRem(23)} ${toRem(30)};

  ${BodyM} {
    text-align: center;
  }
`;

export const MultiplierSave = styled.div`
  width: 100%;
  display: grid;

  padding: ${toRem(30)} ${toRem(30)} ${toRem(30)};
  margin-top: ${toRem(10)};

  border-top: solid ${toRem(0.5)};
  border-top-color: ${COLORS.grey900};
`;

export const MultipliersSave = styled.div`
  width: 100%;
  display: grid;

  padding: ${toRem(2)} ${toRem(30)} ${toRem(30)};
`;
