/* eslint-disable import/no-cycle */
import React, { FunctionComponent } from 'react';
import { toRem } from 'styles/v3/functions';
import { DivisionAndClubsState } from '..';
import { DivisionInformation } from './DivisionInformation/index';

interface DivisionsListProps {
  divisionsAndClubs: DivisionAndClubsState[];
  divisionFiltered: DivisionAndClubsState[] | undefined;
  handleOpenModal: (division: DivisionAndClubsState) => void;
}

export const DivisionsList: FunctionComponent<DivisionsListProps> = ({
  divisionsAndClubs,
  divisionFiltered,
  handleOpenModal,
  ...props
}) => {
  const divisionsClubs = divisionFiltered || divisionsAndClubs;
  const allDivisions = divisionsAndClubs.map((divisions) => divisions.division);

  return (
    <div style={{ paddingTop: toRem(24), paddingBottom: toRem(24) }}>
      {divisionsClubs &&
        divisionsClubs.map((divisionAndClub) => {
          return (
            <DivisionInformation
              key={`division_informatio_${divisionAndClub.division.name}`}
              divisionAndClub={divisionAndClub}
              allDivisions={allDivisions}
              handleOpenModal={handleOpenModal}
            />
          );
        })}
    </div>
  );
};
