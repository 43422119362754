import { loadStripe, RedirectToCheckoutOptions } from '@stripe/stripe-js';
import * as React from 'react';
import { Star, CheckOne } from '@icon-park/react';
import { withRouter, useHistory } from 'react-router-dom';
import { message as MessageSnackbar } from 'antd';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import Avatar from 'components/v3/Elements/Avatares/AvatarAction/AvatarAction';
import Logo from 'assets/imgs/logos/logo-vault-green.png';

import DateUtil from 'util/DateUtil';

import PaymentServices from 'services/Payment/PaymentServices';

import { TitleH1, BodyM, COLORS, BodyS, TitleH3 } from 'styles/v3/variables';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import * as S from './styles';

const OrderSummary: React.FC = () => {
  const history = useHistory();

  const currentUser = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );

  function handlePreviousStep() {
    history.goBack();
  }
  const redirectStripe = async (sessionId: RedirectToCheckoutOptions) => {
    const { data } = await PaymentServices.getPaymentKey();
    const stripe = await loadStripe(data.publicKey);
    return stripe?.redirectToCheckout(sessionId);
  };

  const createMembership = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const url = '/v3/profile/upgrade-membership/payment';
    try {
      const { data } = await PaymentServices.fetchCheckoutMembershipSession(
        url
      );
      if (data.message) {
        throw new Error(data.message);
      }
      redirectStripe(data);
    } catch (error) {
      if (error instanceof Error) {
        MessageSnackbar.error({ content: error.message, duration: 5 });
      } else {
        MessageSnackbar.error({
          content: 'An unexpected error occurred',
          duration: 5,
        });
      }
    }
  };

  const currentSeason = DateUtil.formatSeason();
  const firstYear = currentSeason.split('-')[0];
  const lastYear = currentSeason.split('-')[1];

  return (
    <S.OrderSummaryWrapper>
      <S.SummaryInfoContainer>
        <TextButtonWithIcon
          color='light'
          align='flex-end'
          reverse
          icon='back'
          onClick={handlePreviousStep}
        >
          Go back
        </TextButtonWithIcon>
        <TitleH1 $color='brandPrimary'>Order Summary</TitleH1>
        <BodyM $color='grey300'>What You’ll Get</BodyM>
        <div className='details-line'>
          <CheckOne size={16} />
          <BodyM $color='grey300' className='highlight'>
            Vault Player Profile
          </BodyM>
        </div>
        <div className='details-line'>
          <CheckOne size={16} />
          <BodyM $color='grey300' className='highlight'>
            View upcoming events
          </BodyM>
        </div>
        <div className='details-line'>
          <CheckOne size={16} />
          <BodyM $color='grey300' className='highlight'>
            Access to photos and media from United Futsal events
          </BodyM>
        </div>
        <div className='details-line'>
          <Star size={16} color={COLORS.white} theme='filled' />
          <BodyM className='highlight'>
            Discounts on United Futsal Merchandise
          </BodyM>
        </div>
        <div className='details-line'>
          <Star size={16} color={COLORS.white} theme='filled' />
          <BodyM className='highlight'>
            Participate in any Officially Sanctioned United Futsal Leagues and
            Tournaments
          </BodyM>
        </div>
        <div className='details-line'>
          <Star size={16} color={COLORS.white} theme='filled' />
          <BodyM className='highlight'>Industry - Leading Insurance</BodyM>
        </div>
        <div className='details-line'>
          <Star size={16} color={COLORS.white} theme='filled' />
          <BodyM className='highlight'>
            Earn XP to LEVEL UP your player profile
          </BodyM>
        </div>
        <div className='details-line'>
          <Star size={16} color={COLORS.white} theme='filled' />
          <BodyM className='highlight'>
            StatTracker+TM System for tracking goals and other metrics.
          </BodyM>
        </div>

        <S.SummaryBoxInfo>
          <BodyS $color='grey200' $spanColor='brandPrimary'>
            By upgrading your account you authorize <span>United Futsal</span>{' '}
            to authenticate the requested documentation and accept and
            acknowledge the <span>Release & Liability Waiver</span> and{' '}
            <span>Covid-19 Waiver</span> presented to you during the application
            process.
          </BodyS>
        </S.SummaryBoxInfo>
      </S.SummaryInfoContainer>

      <S.SummaryAside>
        <img src={Logo} alt='Vault Logo' />
        <TitleH3 className='aside-title'>Season {currentSeason}</TitleH3>
        <BodyM $color='grey300'>
          Starts at September 1st, {firstYear}
          <br />
          Ends at August 31th, {lastYear}
        </BodyM>

        <S.AsideBoxInfo>
          <div className='aside-box-info-price'>
            {currentUser.membershipType !== 'OFFICIAL' ? (
              <>
                <TitleH3 $color='brandPrimary'>$25</TitleH3>
                <BodyM> Official Membership</BodyM>
              </>
            ) : (
              <BodyM $color='supportError'>
                You are already an OFFICIAL member
              </BodyM>
            )}
          </div>
          {currentUser?.firstName && <Avatar />}
        </S.AsideBoxInfo>

        <FilledButtonWithIcon
          isUpper
          onClick={createMembership}
          disabled={currentUser.membershipType === 'OFFICIAL'}
        >
          Continue to Payment
        </FilledButtonWithIcon>
      </S.SummaryAside>
    </S.OrderSummaryWrapper>
  );
};

export default withRouter(OrderSummary);
