import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import TrashIcon from 'assets/imgs/icons/trash.svg';

interface ContainerProps {
  $format?: 'circle' | 'square';
  $error?: boolean;
}
export const Container = styled.div<ContainerProps>`
  height: 130px;
  width: ${(props) => (props?.$format === 'square' ? '100%' : '130px')};
  position: relative;
  border-radius: ${(props) => (props?.$format === 'square' ? '12px' : '50%')};
  border: ${(props) =>
    props.$error ? `2px solid ${COLORS.supportError}` : '0'};
  overflow: hidden;
`;

export const Input = styled.input`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: relative;
  z-index: 1;
  cursor: pointer;

  &:disabled {
    & + label {
      pointer-events: none;
      opacity: 0.3;
    }
  }
`;

export const Label = styled.label<{ $isUpper?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${COLORS.grey800};
  text-transform: ${(props) => (props.$isUpper ? 'uppercase' : 'none')};
`;

export const ImagePreviewWrapper = styled.div`
  position: absolute;
  text-align: center;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    max-width: -webkit-fill-available;
    max-height: min-content;
    object-fit: cover;
  }

  &:hover {
    .image-clear-wrapper {
      display: flex;
    }
  }
`;

export const ImageClearWrapper = styled.div`
  display: none;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  p {
    margin: 0;
  }

  &::before {
    content: '';
    background: url(${TrashIcon}) center no-repeat;
    width: 24px;
    height: 24px;
    display: block;
    margin-right: 0.5rem;
  }
`;
