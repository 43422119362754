import moment from 'moment';

export default class DateUtil {
  static monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  public static formatDate(date: Date): string {
    return `${
      this.monthNames[date.getUTCMonth()]
    } ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
  }

  public static getDayYear(date: Date): string {
    return `${this.monthNames[date.getUTCMonth()]} ${date.getUTCDate()}`;
  }

  public static getYear(date: Date): string {
    return `${date.getUTCFullYear()}`;
  }

  public static getAge(date: Date): number {
    const ageDifMs = Date.now() - date.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  public static getUserAge(date: string | moment.Moment): number {
    const now = moment();
    const convertDateToMoment = moment(date);
    const age = now.diff(convertDateToMoment, 'year');
    return age;
  }

  public static isAnAdult(date?: string | moment.Moment): boolean {
    const parseDate = moment(date).isValid()
      ? moment(date).format('MM/DD/YYYY')
      : '';
    return date ? DateUtil.getUserAge(parseDate) >= 18 : false;
  }

  public static isExpired(date?: string): boolean {
    return moment(date).diff(moment(), 'days') < 0;
  }

  public static createDateAsUTC(date: string) {
    const newDate = new Date(date);
    return new Date(
      Date.UTC(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        newDate.getHours(),
        newDate.getMinutes(),
        newDate.getSeconds()
      )
    );
  }

  public static formatSeason() {
    let currentSeason: string;
    const momentFormatMonthDay = moment().format('MM-DD');
    const currentYear = moment().format('YYYY');
    const nextYear = moment().add(1, 'y').format('YYYY');
    const lastYear = moment().subtract(1, 'y').format('YYYY');
    const targetDay = '09-01';

    if (momentFormatMonthDay >= targetDay) {
      currentSeason = ` ${currentYear}-${nextYear}`;
    } else {
      currentSeason = ` ${lastYear}-${currentYear}`;
    }

    return currentSeason;
  }

  public static dateTimeToDateString(date?: Date | string): string | undefined {
    if (!date) return undefined;
    return new Date(date).toISOString().split('T')[0];
  }

  public static getYearFromDateString = (dateStr: string | undefined) => {
    if (!dateStr || !/\d{4}-\d{2}-\d{2}/.test(dateStr)) return 'N/A';
    return new Date(dateStr).getFullYear();
  };
}
