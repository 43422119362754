import React, { useState } from 'react';
import { BodyM } from 'styles/v3/variables';
import InputSelect, { OptionsType } from 'components/v3/Forms/Select/Select';
import { RadioGroup } from 'components/v3/Forms/RadioGroup/RadioGroup';
import { MultiValue } from 'react-select';
import * as S from './styles';
import { DivisionInfoProps } from './types';
import { DivisionItem } from './components/DivisionItem/DivisionItem';

export const DivisionInfo = ({ price, divisions, ageDivisions }: DivisionInfoProps) => {
  
  const [selectedYears, setSelectedYears] = useState<MultiValue<OptionsType>>(
    []
  );
  const [gender, setGender] = useState('');

  const handleSetGender = (newGender: string) => {
    if(newGender === gender){
      setGender('')
    } else {
      setGender(newGender)
    }
  }

  const yearsOptions = Array.from(
    new Set(ageDivisions?.flatMap((item) => item.years))
  ).map((value) => ({
    value,
    label: value.toString(),
  }));

  const newAgeDivisions = ageDivisions
    ?.filter((item) =>
      gender ? item.gender.toLowerCase() === gender.toLowerCase() : true
    )
    .filter((item) =>
      selectedYears.length > 0
        ? selectedYears.some((year) =>
          item.years.includes(Number(year.value))
        )
        : true
    );
  
  return (
    <S.Container>
    <S.FiltersWrapper >
      <S.SelectWrapper>
        <BodyM $isUpper>Year</BodyM>
        <InputSelect
          isMulti
          options={yearsOptions}
          hideSelectedOptions={false}
          onChange={(values) => setSelectedYears(values as any)}
        />
      </S.SelectWrapper>
      <RadioGroup
        label='Gender'
          onChange={(e) => handleSetGender(e.target.value)}
        options={[
          { label: 'Female', value: 'female' },
          { label: 'Male', value: 'male' },
          { label: 'Coed', value: 'coed' },
        ]}
      />
    </S.FiltersWrapper>
      <S.DivisionList>
        {newAgeDivisions.map((currentDivision) => (
          <DivisionItem
            key={currentDivision.gender}
            item={currentDivision}
            price={price}
            divisions={divisions}
          />
        ))}
      </S.DivisionList>
    </S.Container>
  );
};
