import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import ClubService from 'services/v3/Clubs/ClubService';

export function useGetClubMembersPool(clubId?: string, validateAccess = false) {
  const { id: accountId } = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );
  const { data, isLoading, error } = useQuery(
    ['get-club-members-pool', clubId, accountId],
    async () => await ClubService.getClubMembersPool(clubId, validateAccess)
  );

  return {
    ...data,
    data: data?.data ?? [],
    isLoading,
    error,
  };
}
