import { Input } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import alert_icon from '../../../assets/imgs/icons/exclamation.svg';
import LightBox from '../../../components/base/LightBox/LightBox';
import LocationBox from '../../../components/base/LocationBox/LocationBox';
import Button from '../../../components/uielements/Button/Button';
import {
  CoachRosterItem,
  PlayerRosterItem,
} from '../../../models/Roster/RosterModel';
import { rosterFetchRequest } from '../../../redux/roster/actions';
import { ApplicationState } from '../../../redux/store';
import RosterService from '../../../services/Rosters/RosterService';
import { color, font } from '../../../styles/styleVariables';
import ConvertUtil from '../../../util/ConvertUtil';
import RosterEditPlayerRow from './RosterEditPlayerRow';

interface IRosterEditProps {
  openPlayerPool: () => void;
  openCoachPool: () => void;
  staffUnlock?: boolean;
}

const RosterEdit: React.FunctionComponent<IRosterEditProps> = (props) => {
  const { openPlayerPool, openCoachPool, staffUnlock } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const roster = useSelector((state: ApplicationState) => state.roster);
  const memberpoolRoster = useSelector(
    (state: ApplicationState) => state.memberpoolRoster
  );

  const [confirmRosterSubmissionBox, setConfirmRosterSubmissionBox] = useState(
    false
  );
  const [submitError, setSubmitError] = useState({ error: false, message: '' });
  const [editRosterName, setEditRosterName] = useState(false);
  const [rosterNewName, setRosterNewName] = useState('');

  React.useEffect(() => {
    setRosterNewName(roster.data.name || '');
  }, [roster.data.name]);

  const dialogSubmitFinalRoster = () => {
    setConfirmRosterSubmissionBox(true);
  };

  const submitFinalRoster = () => {
    if (roster.data.rosterId) {
      RosterService.submitFinalRoster(roster.data.rosterId).then((response) => {
        if (response.success) {
          if (roster.data.rosterId) {
            dispatch(rosterFetchRequest(roster.data.rosterId));
            setConfirmRosterSubmissionBox(false);
          }
        } else {
          setConfirmRosterSubmissionBox(false);
          setSubmitError({ error: true, message: response.message });
        }
      }).catch((error) => {
        setConfirmRosterSubmissionBox(false);
        setSubmitError({ error: true, message: error.message });
      });
    }
  };

  const evaluateMembershipRequirements = (
    user: PlayerRosterItem | CoachRosterItem
  ): boolean => {
    if (
      memberpoolRoster.data.players.find((item) => item.id === user.id)
        ?.rosterAvailability.membership ||
      memberpoolRoster.data.coaches.find((item) => item.id === user.id)
        ?.rosterAvailability.membership
    ) {
      return false;
    }
    return true;
  };

  const updateRosterName = () => {
    if (roster.data.rosterId) {
      RosterService.update(roster.data.rosterId, {
        id: roster.data.rosterId,
        ageDivision: roster.data.ageDivision!,
        clubId: roster.data.club?.id!,
        eventId: roster.data.event?.id!,
        name: rosterNewName,
      }).then(() => {
        setEditRosterName(false);
      });
    }
  };

  return (
    <div>
      {staffUnlock && (
        <AlertBox>
          <div style={{ width: '50px', marginRight: '20px' }}>
            <img src={alert_icon} alt='' />
          </div>
          <div>
            IMPORTANT: You're editing this roster as a STAFF. All changes will
            be reflected in the Roster regardless of the Event roles.
          </div>
        </AlertBox>
      )}
      <Header>
        <Button
          className='bt-white'
          style={{ marginTop: '0', height: '100%' }}
          onClick={() => history.push('/club-director/rosters/')}
        >
          Go Back
        </Button>
        {roster.data.status === 'PENDING' && (
          <Button
            onClick={() => dialogSubmitFinalRoster()}
            style={{ marginTop: '0', height: '100%' }}
          >
            Submit Final Roster
          </Button>
        )}
        {roster.data.status === 'SUBMITTED' && (
          <Button disabled style={{ marginTop: '0', height: '100%' }}>
            Roster Submited
          </Button>
        )}
      </Header>
      <RosterInfoBox>
        <LocationBox />
        <div className='roster-info-card'>
          <div
            className='logo'
            style={{
              backgroundImage: `url(${ConvertUtil.getMinioUrl(
                roster.data.club?.logo
              )})`,
            }}
          />
          <div className='name'>
            {roster.data.club && (
              <div className='sub'>{roster.data.club.name}</div>
            )}
            {!editRosterName && (
              <div className='title'>
                {rosterNewName || roster.data.name}

                {(roster.data.status !== 'SUBMITTED' || staffUnlock) && (
                  <div
                    className='bt-action i-icon-edit'
                    onClick={() => setEditRosterName(true)}
                  />
                )}
              </div>
            )}
            {editRosterName && (
              <div className='title'>
                <Input
                  defaultValue={roster.data.name}
                  value={rosterNewName}
                  onChange={(props) =>
                    setRosterNewName(props.currentTarget.value)
                  }
                />
                <div
                  className='bt-action i-icon-check'
                  onClick={() => updateRosterName()}
                />
              </div>
            )}
          </div>
        </div>
        <div className='event-info'>
          <div className='type-event'>
            <div className='sub'>Tournament</div>
            <div className='title'>{roster.data.event?.name}</div>
          </div>

          <div className='category-event'>
            <div className='sub'>Category</div>
            <div className='title'>
              {`${roster.data.ageDivision?.gender} - ${
                roster.data.ageDivision?.years[0] === 1900
                  ? 'Adult'
                  : roster.data.ageDivision?.years.sort().join(', ')
              }`}
              {roster.data.ageDivision?.rule === 'YOUNGER' && (
                <>
                  <br />
                  <span style={{ fontSize: '11px' }}>(and younger)</span>
                </>
              )}
            </div>
          </div>
        </div>
      </RosterInfoBox>

      <RosterList>
        {roster.data.status === 'SUBMITTED' && (
          <div className='top'>ROSTER SUBMITED</div>
        )}
        <div
          className={
            roster.data.status === 'SUBMITTED' && !staffUnlock
              ? 'content locked'
              : 'content'
          }
        >
          <div className='coach-list'>
            <div className='title'>COACH</div>
            <div className='list'>
              {!roster.data?.coaches?.length && (
                <div style={{ textAlign: 'center' }}>
                  You have no Coaches in This Roster yet
                </div>
              )}
              {[...roster.data?.coaches].map((coach) => (
                <RosterEditPlayerRow
                  missingMembership={evaluateMembershipRequirements(coach)}
                  key={coach.id}
                  user={coach}
                  isLocked={roster.data.status !== 'PENDING' && !staffUnlock}
                />
              ))}
              {(roster.data.status === 'PENDING' || staffUnlock) && (
                <Button className='bt-white' onClick={openCoachPool}>
                  ADD Coach
                </Button>
              )}
            </div>
          </div>
          <div className='player-list'>
            <div className='title'>PLAYERS</div>
            <div className='row-title'>
              <div>Photo</div>
              <div>Full Name</div>
              <div style={{ textAlign: 'center' }}>Year Born</div>
              <div>Jersey #</div>
            </div>
            <div className='list'>
              {!roster.data?.players?.length && (
                <div style={{ textAlign: 'center' }}>
                  You have no Players in This Roster yet
                </div>
              )}

              {[...roster.data?.players].map((player) => (
                <RosterEditPlayerRow
                  missingMembership={evaluateMembershipRequirements(player)}
                  user={player}
                  key={player.id}
                  isPlayer
                  isLocked={roster.data.status !== 'PENDING' && !staffUnlock}
                />
              ))}
              {(roster.data.status === 'PENDING' || staffUnlock) && (
                <Button className='bt-white' onClick={openPlayerPool}>
                  ADD PLAYER
                </Button>
              )}
            </div>
          </div>
        </div>
      </RosterList>

      <LightBox
        isOpen={confirmRosterSubmissionBox}
        onClose={() => {
          setConfirmRosterSubmissionBox(false);
        }}
      >
        <ConfirmBox style={{ textAlign: 'center' }}>
          <h2 style={{ fontSize: '18px', marginBottom: '10px' }}>
            Are you sure you want to submit this Roster?
          </h2>
          <p>
            After submit the roster it will not possible to edit it anymore.
          </p>
          <div>
            <ConfirmBt onClick={() => setConfirmRosterSubmissionBox(false)}>
              Cancel
            </ConfirmBt>
            <ConfirmBt
              className='save-leave'
              onClick={() => submitFinalRoster()}
            >
              Submit Roster
            </ConfirmBt>
          </div>
        </ConfirmBox>
      </LightBox>

      <LightBox
        isOpen={submitError.error}
        onClose={() => setSubmitError({ error: false, message: '' })}
      >
        {submitError && (
          <ConfirmBox style={{ textAlign: 'center' }}>
            {submitError.message}
          </ConfirmBox>
        )}
      </LightBox>
    </div>
  );
};

const ConfirmBox = styled.div`
  padding: 30px 50px;
  border-radius: 20px;
  background-color: ${color.orange};
  width: 100%;
`;
const ConfirmBt = styled.div`
  display: inline-block;
  font-weight: 600;
  margin: 10px 10px;
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
  &.save-leave {
    background-color: #fff;
    color: ${color.orange};
  }
  &:hover {
    opacity: 0.8;
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  align-items: center;
`;

const AlertBox = styled.div`
  width: 100%;
  padding: 20px;
  background: #f55030;
  border-radius: 10px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const RosterInfoBox = styled.div`
  margin: 10px 0;
  padding: 20px;
  background-color: ${color.greyLight};
  border-radius: 20px;
  .roster-info-card {
    margin: 20px 0;
    border-radius: 20px;
    padding: 15px;
    background-color: ${color.orange};
    display: flex;
    align-items: center;
    .logo {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      background-size: contain;
      background-position: center;
    }
    .name {
      .title {
        font-size: 22px;
        font-family: ${font.druk};
        display: flex;
        .bt-action:before {
          top: -3px;
        }
      }
    }
  }
  .event-info {
    display: flex;
    .type-event,
    .category-event {
      width: 50%;
      .title {
        font-size: 14px;
        font-family: ${font.druk};
      }
    }
    .category-event {
      text-align: right;
    }
  }
`;

const RosterList = styled.div`
  margin: 10px 0;
  overflow: hidden;
  background-color: ${color.greyLight};
  border-radius: 20px;
  .top {
    width: 100%;
    padding: 15px;
    background-color: ${color.orange};
    color: ${color.greyLight};
    font-weight: 700;
    text-align: center;
  }
  .content {
    padding: 30px 40px;
    &.locked {
      opacity: 0.5;
    }
    .row-title {
      background: ${color.greyDark};
      border-radius: 10px;
      padding: 5px 10px;
      margin: 10px 0;
      display: grid;
      grid-template-columns: 60px 1fr 1fr 1fr;
    }
    .coach-list,
    .player-list {
      .title {
        color: ${color.orange};
        font-weight: 600;
        span {
          margin-left: 10px;
          font-size: 12px;
          color: #ffffff73;
          font-weight: 300;
        }
      }
    }
    .list {
      margin: 20px 0;
    }
  }
`;

export default RosterEdit;
