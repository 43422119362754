import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { b2bSetupEventFetchEventRequest } from 'redux/v3/b2bSetupEvent/actions';
import { ApplicationState } from 'redux/store';
import ConvertUtil from 'util/ConvertUtil';
import B2bEventService from 'services/v3/B2bEvent/B2bEventService';
import UserServices from 'services/v3/User/UserService';

// Icons
import { Calendar, LocalTwo, RightSmallUp, Close } from '@icon-park/react';

// Components
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import InputCheckbox from 'components/v3/Forms/InputCheckbox/InputCheckbox';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import {
  TitleH1,
  TitleH4,
  BodyXL,
  BodyL,
  BodyM,
  BodyS,
} from 'styles/v3/variables';
import {
  PaymentAndValueProps,
  PaymentAndValue,
} from 'components/v3/Cards/PaymentAndValue';

// Types
import { RequestStatus } from 'models/Request/RequestModel';
import { useGetMyRefereeApplications } from 'hooks/v3/referees/useGetMyRefereeApplications/useGetMyRefereeApplications';
import { useGetEventInvites } from 'hooks/v3/event/useGetEventInvites/useGetEventInvites';
import { EventInvitesStatus, EventInvitesTypes } from 'models/v3/EventInvite/EventInvitesModel';
import { AllEventInvites } from 'services/v3/Event/types';
import EventService from 'services/v3/Event/EventService';
import { useQueryClient } from '@tanstack/react-query';
import { setCurrentUser } from 'redux/v3/currentUser/actions';
import { usersFetchSuccess } from 'redux/v3/users/actions';
import { InformationScreenProps } from './InformationScreen';

// Styles
import {
  RefereeApplicationContainer,
  RefereeApplicationConditionsContainer,
  ContainerInformationCondition,
  EventInfoContainer,
  EventInformationAndImage,
  EventImage,
  GeneralInfoContainer,
  AgreeConditionsContainer,
  RefereeUploadSafeSportContainer
} from './styles';
import OutlinedButtonWithIcon from '../../../components/v3/Buttons/OutlinedButtonWithIcon';

interface RefereeApplicationParam {
  auth: Keycloak.KeycloakInstance | undefined;
}

const RefereeApplication: FunctionComponent = (param: any) => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryClient = useQueryClient();

  const history = useHistory();
  const params: { eventId: string } = useParams();
  const dispatch = useDispatch();
  const [acceptTerms, setAcceptTerms] = useState(false);

  const { data: myRefereeApplications } = useGetMyRefereeApplications(params.eventId);
  const invite = searchParams.get('invite') ?? '';

  const { data: eventInvites } = useGetEventInvites({ id: params.eventId, type: EventInvitesTypes.EVENT_TO_REFEREE });

  const lastApplications = useMemo(() => {

    if (!myRefereeApplications?.length) return undefined

    const sorted = [...myRefereeApplications]

    sorted.sort((a, b) => {
      return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
    })

    return sorted[0]
  }, [myRefereeApplications]);

  const alreadyApplied = useMemo(() => {
    return lastApplications && ![RequestStatus.DECLINED, RequestStatus.EXPIRED].includes(lastApplications.status)
  }, [lastApplications])

  const userData = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );

  const isBaseUser = React.useMemo(() => {
    if (!userData) return false
    return Array.isArray(userData?.types)
  }, [userData]);

  const myInvite = useMemo<AllEventInvites | undefined>(() => {
    if (!invite || !eventInvites?.length) return undefined
    return eventInvites.find((i) => i?.invite?.status !== EventInvitesStatus.DENIED && (userData.id === i.invite.receiverId || userData?.userIds?.includes(i.invite.receiverId)))

  }, [invite, eventInvites, userData]);

  const hasSafesPortUploaded = useMemo(() => {
    return !!userData?.extraInfo?.certificate
  }, [userData])

  useEffect(() => {
    dispatch(b2bSetupEventFetchEventRequest(params.eventId));
  }, [dispatch]);

  const { eventData } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );

  const { logo, name, description, startDate, endDate } = eventData;

  const startAt = new Date(startDate as string).toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });

  const endAt = new Date(endDate as string).toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });

  const paymentsAndValues: PaymentAndValueProps[] = [
    {
      title: 'Single Referee',
      value: 600,
    },
    {
      title: 'Referee Pair',
      value: 400,
    },
    {
      title: 'Score Keeper',
      value: 300,
    },
  ];


  const handleDenyInvite = () => {
    if (myInvite?.invite?.id){
      EventService.denyInvite(myInvite.invite.id).then(res => {
        queryClient.invalidateQueries(['event-invites', params.eventId, EventInvitesTypes.EVENT_TO_REFEREE]);
      })
    }
  }

  const handleApplyToThisEvent = async () => {
    try {
      await B2bEventService.postRefereeApplication(params.eventId);

      const stateToInformation: InformationScreenProps = {
        title: 'Your application is now pending.',
        description:
          'The event manager will review your application soon. With any updates, we will notify you.',
        eventIcon: logo ?? '',
        eventId: params.eventId,
      };

      const { data: updatedProfile } = await UserServices.getProfiles()

      if(isBaseUser){
        const updatedBaseUser = updatedProfile?.baseUsers?.find(u => u.id === userData.id)
        if (updatedBaseUser) {
          dispatch(setCurrentUser(updatedBaseUser));
          const newUsers = [...(updatedProfile.baseUsers || []), ...(updatedProfile.users || [])];
          dispatch(usersFetchSuccess(newUsers));
        }        
      } else {
        const myNewBaseUser = updatedProfile?.baseUsers?.find(u => u.userIds?.includes(`${userData.id}`))
        if (myNewBaseUser) {
          dispatch(setCurrentUser(myNewBaseUser));
          const newUsers = [...(updatedProfile.baseUsers || []), ...(updatedProfile.users || [])];
          dispatch(usersFetchSuccess(newUsers));
        }
      }

      history.push('/v3/referee-application-status', stateToInformation);
    } catch (err) {
      console.error(err);
    }
  };

  const handleUploadSafeSport = () => {
    history.push('/v3/current-user/documents?type=referee');
  }


  return (
    <RefereeApplicationContainer>
      <RefereeApplicationConditionsContainer>
        <TextButtonWithIcon
          color='light'
          align='flex-end'
          reverse
          icon='back'
          onClick={() => history.push('/v3/setup-event/my-events')}
        >
          Go back
        </TextButtonWithIcon>
        <TitleH1 $color='brandPrimary'>Conditions</TitleH1>

        <ContainerInformationCondition>
          <BodyXL>Pay per Game</BodyXL>

          <BodyM $color='grey400'>
            Every payment will be done by the Event Manager.
          </BodyM>
          {paymentsAndValues.map((payment) => (
            <PaymentAndValue
              key={`key_payment_${payment.title}`}
              title={payment.title}
              value={payment.value}
            />
          ))}

          <BodyXL>Requirements</BodyXL>
          <BodyM $color='grey400'>
            Lorem ipsum dolor sit amet consectetur. Netus nec a elit risus nec.
            Erat interdum et scelerisque adipiscing elementum nascetur cras sed.
            Aliquet in consectetur sed lorem neque vivamus odio in sagittis.
            Molestie volutpat aliquet in pretium quam dolor laoreet tincidunt
            nunc. Ut eleifend tincidunt rutrum sit tortor fringilla sit. Enim
            tellus cursus nibh quis sodales elit gravida condimentum. Amet donec
            lacus sed sagittis.
          </BodyM>

          <BodyXL>Travel Conditions</BodyXL>
          <BodyM $color='grey400'>
            Lorem ipsum dolor sit amet consectetur. Netus nec a elit risus nec.
            Erat interdum et scelerisque adipiscing elementum nascetur cras sed.
            Aliquet in consectetur sed lorem neque vivamus odio in sagittis.
            Molestie volutpat aliquet in pretium quam dolor laoreet tincidunt
            nunc. Ut eleifend tincidunt rutrum sit tortor fringilla sit. Enim
            tellus cursus nibh quis sodales elit gravida condimentum. Amet donec
            lacus sed sagittis.
          </BodyM>
        </ContainerInformationCondition>
      </RefereeApplicationConditionsContainer>

      <EventInfoContainer>
        <EventInformationAndImage>
          <EventImage>
            <img src={ConvertUtil.getMinioUrl(logo)} alt='Event Logo' />
          </EventImage>
          <TitleH4>{name}</TitleH4>
          <BodyM $color='grey200'>LOCAL</BodyM>
          <BodyS $color='grey300'>{description}</BodyS>
        </EventInformationAndImage>
        <GeneralInfoContainer>
          <BodyL $isUpper>General Info</BodyL>
          <BodyM $isUpper style={{ display: 'flex', alignItems: 'center' }}>
            <Calendar /> {startAt} - {endAt}
          </BodyM>
          <BodyM $isUpper style={{ display: 'flex', alignItems: 'center' }}>
            <LocalTwo /> Local do Event
          </BodyM>
        </GeneralInfoContainer>

        {alreadyApplied ? (
          <BodyL $color='brandPrimary'>
            You already applied to this event.
          </BodyL>
        ) : <>
          <AgreeConditionsContainer>
            <InputCheckbox
              id='accept_terms'
              name='accept_terms'
              checked={acceptTerms}
              onChange={() => setAcceptTerms(!acceptTerms)}
            />
            <BodyM $color='grey100'>
              I agree with the conditions provided for being a referee in this
              event.
            </BodyM>
          </AgreeConditionsContainer>

            {!myInvite ? (
            <FilledButtonWithIcon
              disabled={!acceptTerms || !hasSafesPortUploaded}
              color={!acceptTerms ? 'white-dark' : 'primary'}
              isUpper
              onClick={handleApplyToThisEvent}
            >
              Apply as Referee
            </FilledButtonWithIcon>)  : (

              <>
                  <FilledButtonWithIcon
                    disabled={!acceptTerms || !hasSafesPortUploaded}
                    color={!acceptTerms ? 'white-dark' : 'primary'}
                    isUpper
                    onClick={handleApplyToThisEvent}
                  >
                    Apply as Referee
                  </FilledButtonWithIcon>
                    <FilledButtonWithIcon
                      customIcon={<Close />}
                      className='btn-deny-invite'
                      color='dark-white'
                      isUpper
                      onClick={handleDenyInvite}
                    >
                      DENY INVITE
                    </FilledButtonWithIcon>

              </>
            ) }
          
        </>
        }

        {
          !hasSafesPortUploaded &&
          <RefereeUploadSafeSportContainer>
            <BodyL $color='supportError'>
              To apply as a Referee, you need to upload your SafeSport.
            </BodyL>
            <OutlinedButtonWithIcon
              customIcon={<RightSmallUp />}
              color='white-dark'
              isUpper
              onClick={handleUploadSafeSport}
            >
              Upload Safesport
            </OutlinedButtonWithIcon>
          </RefereeUploadSafeSportContainer>
        }
      </EventInfoContainer>
    </RefereeApplicationContainer>
  );
};

export default withRouter(RefereeApplication);
