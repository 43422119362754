import Keycloak from 'keycloak-js';
import * as React from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import InputDate from 'components/v3/Forms/InputDate/InputDate';
import InputText from 'components/v3/Forms/InputText/InputText';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import InputCheckbox from 'components/v3/Forms/InputCheckbox/InputCheckbox';
import FilledButton from 'components/v3/Buttons/FilledButton';

import { ApplicationState } from 'redux/store';

import { DeepPartial } from 'util/types';
import { PatchEventProps } from 'services/v3/B2bEvent/types';

import { BodyLBold, BodyM, BodyMBold } from 'styles/v3/variables';
import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';
import * as S from './styles';

interface GeneralRulesProps {
  children?: React.ReactNode;
  auth: Keycloak.KeycloakInstance;
}

const playersOptions: OptionsType[] = [
  {
    value: 'not-allow-players',
    label: 'We are not allowing players to play in multiple teams',
  },
  {
    value: 'allow-players',
    label: 'Yes, players can play both in the same or different age divisions',
  },
  {
    value: 'allow-players-different-teams-different-divisions',
    label:
      'Players can play in different teams, as long as they are in different age divisions',
  },
  {
    value: 'allow-players-different-teams-same-divisions',
    label:
      'Players can play in different teams, as long as they are in same age divisions',
  },
];
const coachesOptions: OptionsType[] = [
  {
    value: 'not-allow-coaches',
    label: 'We are not allowing coaches to coach in multiple teams',
  },
  {
    value: 'allow-coaches',
    label: 'Yes, coaches can coach both in the same or different age divisions',
  },
  {
    value: 'allow-coaches-different-teams-different-divisions',
    label:
      'Coaches can coach in different teams, as long as they are in different age divisions',
  },
  {
    value: 'allow-coaches-different-teams-same-divisions',
    label:
      'Coaches can coach in different teams, as long as they are in same age divisions',
  },
];

const GeneralRules: React.FunctionComponent = () => {
  const { eventData } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );

  const eventInfoData: DeepPartial<PatchEventProps> = React.useMemo(
    () => ({
      minimumPlayersNumber: eventData.minimumPlayersNumber ?? 0,
      maximumPlayersNumber: eventData.maximumPlayersNumber ?? 1,
      minimumCoachesNumber: eventData.minimumCoachesNumber ?? 0,
      maximumCoachesNumber: eventData.maximumCoachesNumber ?? 1,
      data: {
        generalRules: {
          rosterLockDate:
            eventData.data?.generalRules?.rosterLockDate ??
            new Date().toISOString().split('T')[0],
          allowPlayersOnDifferentTeams:
            eventData.data?.generalRules?.allowPlayersOnDifferentTeams ?? false,
          allowPlayersOnDifferentClubs:
            eventData.data?.generalRules?.allowPlayersOnDifferentClubs ?? false,
          playerAgeDivisionPermission:
            eventData.data?.generalRules?.playerAgeDivisionPermission ?? '',
          allowCoachesOnDifferentTeams:
            eventData.data?.generalRules?.allowCoachesOnDifferentTeams ?? false,
          allowCoachesOnDifferentClubs:
            eventData.data?.generalRules?.allowCoachesOnDifferentClubs ?? false,
          coachAgeDivisionPermission:
            eventData.data?.generalRules?.coachAgeDivisionPermission ?? '',
          allowMultipleTeamsSameClubAndSameAge:
            eventData.data?.generalRules
              ?.allowMultipleTeamsSameClubAndSameAge ?? false,
        },
      },
    }),
    [eventData]
  );
  const [allowPlayerTeam, setAllowPlayerTeam] = React.useState<boolean>(
    eventInfoData.data?.generalRules?.allowPlayersOnDifferentTeams ?? false
  );
  const [allowPlayerClub, setAllowPlayerClub] = React.useState<boolean>(
    eventInfoData.data?.generalRules?.allowPlayersOnDifferentClubs ?? false
  );
  const [allowCoachTeam, setAllowCoachTeam] = React.useState<boolean>(
    eventInfoData.data?.generalRules?.allowCoachesOnDifferentTeams ?? false
  );
  const [allowCoachClub, setAllowCoachClub] = React.useState<boolean>(
    eventInfoData.data?.generalRules?.allowCoachesOnDifferentClubs ?? false
  );
  const [
    allowMultipleTeamsSameClubAndSameAge,
    setAllowMultipleTeamsSameClubAndSameAge,
  ] = React.useState<boolean>(
    eventInfoData.data?.generalRules?.allowMultipleTeamsSameClubAndSameAge ??
      false
  );
  const setLabelToPlayersPermission = playersOptions.find(
    (p) =>
      p.value === eventInfoData.data?.generalRules?.playerAgeDivisionPermission
  );
  const setLabelToCoachesPermission = coachesOptions.find(
    (p) =>
      p.value === eventInfoData.data?.generalRules?.coachAgeDivisionPermission
  );

  const formRef = React.useRef<HTMLFormElement>(null);
  const params: { eventId: string } = useParams();
  const eventId: string = params?.eventId || '';

  const { isLoading, mutateAsync } = useUpdateEvent({ eventId });

  const handleSave = async () => {
    try {
      if (formRef.current) {
        const formData: FormData = new FormData(formRef.current);
        const payload: DeepPartial<PatchEventProps> = {
          minimumPlayersNumber: Number(formData.get('minPlayerRules')),
          maximumPlayersNumber: Number(formData.get('maxPlayerRules')),
          minimumCoachesNumber: Number(formData.get('minCoachesRules')),
          maximumCoachesNumber: Number(formData.get('maxCoachesRules')),
          data: {
            generalRules: {
              rosterLockDate: formData.get('rosterLockDate') as string,
              allowPlayersOnDifferentTeams: Boolean(
                formData.get('allowPlayersOnDifferentTeams')
              ),
              allowPlayersOnDifferentClubs: Boolean(
                formData.get('allowPlayersOnDifferentClubs')
              ),
              playerAgeDivisionPermission: formData.get(
                'playerAgeDivisionPermission'
              ) as string,
              allowCoachesOnDifferentTeams: Boolean(
                formData.get('allowCoachesOnDifferentTeams')
              ),
              allowCoachesOnDifferentClubs: Boolean(
                formData.get('allowCoachesOnDifferentClubs')
              ),
              coachAgeDivisionPermission: formData.get(
                'coachAgeDivisionPermission'
              ) as string,
              allowMultipleTeamsSameClubAndSameAge: Boolean(
                formData.get('allowMultipleTeamsSameClubAndSameAge')
              ),
            },
          },
        };

        await mutateAsync({ id: eventId, data: payload });
      }
    } catch (e) {
      console.error('Error updating event', e);
    }
  };

  return (
    <S.GeneralRulesForm
      id='generalRules'
      ref={formRef}
      onSubmit={(e) => e.preventDefault()}
    >
      <fieldset>
        <legend>
          <BodyLBold $color='white'>Roster Rules</BodyLBold>
        </legend>
        <InputDate
          id='rosterLockDate'
          placeholder='Roster lock date'
          label='Roster lock date'
          defaultValue={
            eventInfoData.data?.generalRules?.rosterLockDate as string
          }
          containerClassName='general-rules-input'
        />
        <S.ElementCheckboxWrapper>
          <InputCheckbox
            id='allowMultipleTeamsSameClubAndSameAge'
            name='allowMultipleTeamsSameClubAndSameAge'
            onChange={() =>
              setAllowMultipleTeamsSameClubAndSameAge((prev) => !prev)
            }
            checked={allowMultipleTeamsSameClubAndSameAge}
          />
          <BodyM>
            Allow multiple teams from the same club to play in the same age
            division?
          </BodyM>
        </S.ElementCheckboxWrapper>
      </fieldset>

      <fieldset>
        <legend>
          <BodyLBold $color='white'>Player Rules</BodyLBold>
        </legend>
        <InputText
          containerClassName='general-rules-input'
          id='minPlayerRules'
          placeholder='Min Number of Players'
          label='Min Number of Players'
          defaultValue={eventInfoData.minimumPlayersNumber}
          type='number'
          min={0}
        />
        <InputText
          containerClassName='general-rules-input'
          id='maxPlayerRules'
          placeholder='Max Number of Players'
          label='Max Number of Players'
          defaultValue={eventInfoData.maximumPlayersNumber}
          type='number'
          min={0}
        />
        <BodyMBold>Permissions</BodyMBold>
        <S.ElementCheckboxWrapper>
          <InputCheckbox
            id='allowPlayersOnDifferentTeams'
            name='allowPlayersOnDifferentTeams'
            onChange={() => setAllowPlayerTeam(!allowPlayerTeam)}
            checked={allowPlayerTeam}
          />
          <BodyM>
            Allow players to play in{' '}
            <BodyMBold $color='brandPrimary'>different Teams</BodyMBold>
          </BodyM>
        </S.ElementCheckboxWrapper>
        {allowPlayerTeam && (
          <>
            <S.ElementCheckboxWrapper>
              <InputCheckbox
                id='allowPlayersOnDifferentClubs'
                name='allowPlayersOnDifferentClubs'
                checked={allowPlayerClub}
                onChange={() => setAllowPlayerClub(!allowPlayerClub)}
              />
              <BodyM>
                Allow players to play in{' '}
                <BodyMBold $color='brandPrimary'>different Clubs</BodyMBold>
              </BodyM>
            </S.ElementCheckboxWrapper>
            <Select
              name='playerAgeDivisionPermission'
              placeholder='Select Age Division Permission'
              defaultValue={setLabelToPlayersPermission}
              options={playersOptions}
            />
          </>
        )}
      </fieldset>

      <fieldset>
        <legend>
          <BodyLBold $color='white'>Coaches Rules</BodyLBold>
        </legend>
        <InputText
          containerClassName='general-rules-input'
          id='minCoachesRules'
          placeholder='Min Number of Coaches'
          label='Min Number of Coaches'
          defaultValue={eventInfoData.minimumCoachesNumber}
          type='number'
          min={0}
        />
        <InputText
          containerClassName='general-rules-input'
          id='maxCoachesRules'
          placeholder='Max Number of Coaches'
          label='Max Number of Coaches'
          defaultValue={eventInfoData.maximumCoachesNumber}
          type='number'
          min={0}
        />
        <BodyMBold>Permissions</BodyMBold>
        <S.ElementCheckboxWrapper>
          <InputCheckbox
            id='allowCoachesOnDifferentTeams'
            name='allowCoachesOnDifferentTeams'
            onChange={() => setAllowCoachTeam(!allowCoachTeam)}
            checked={allowCoachTeam}
          />
          <BodyM>
            Allow coaches to coach in{' '}
            <BodyMBold $color='brandPrimary'>different Teams</BodyMBold>
          </BodyM>
        </S.ElementCheckboxWrapper>
        {allowCoachTeam && (
          <>
            <S.ElementCheckboxWrapper>
              <InputCheckbox
                id='allowCoachesOnDifferentClubs'
                name='allowCoachesOnDifferentClubs'
                onChange={() => setAllowCoachClub(!allowCoachClub)}
                checked={allowCoachClub}
              />
              <BodyM>
                Allow coaches to coach in{' '}
                <BodyMBold $color='brandPrimary'>different Clubs</BodyMBold>
              </BodyM>
            </S.ElementCheckboxWrapper>
            <Select
              name='coachAgeDivisionPermission'
              placeholder='Select Age Division Permission'
              defaultValue={setLabelToCoachesPermission}
              options={coachesOptions}
            />
          </>
        )}
      </fieldset>

      <S.ButtonWrapper>
        <FilledButton onClick={handleSave} isLoading={isLoading}>
          {isLoading ? '...' : 'Save'}
        </FilledButton>
      </S.ButtonWrapper>
    </S.GeneralRulesForm>
  );
};

export default withRouter(GeneralRules);
