import * as React from 'react';
import { useEffect } from 'react';
import Keycloak from 'keycloak-js';
import Loading from 'components/v3/Loading/Loading';

const V3Logout: React.FunctionComponent<{
  auth?: Keycloak.KeycloakInstance | undefined;
}> = (props) => {
  const { auth } = props;
  useEffect(() => {
    localStorage.clear();
    auth?.logout({
      redirectUri: `${process.env.REACT_APP_URL_BASE}/v3`,
    });
  }, [auth]);
  return (
    <div>
      <Loading />
    </div>
  );
};

export default V3Logout;
