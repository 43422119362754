import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const DivisionInformationContainer = styled.div`
  background-color: ${COLORS.grey900};
  padding: ${toRem(24)};
  border-radius: ${toRem(12)};
  margin-bottom: ${toRem(16)};
`;

export const DivisionInformationHeader = styled.div`
  display: flex;
  align-items: center;

  justify-content: space-between;
  margin-bottom: ${toRem(24)};
`;

export const DivisionsInformationTitle = styled.div`
  display: flex;
  align-items: center;

  gap: ${toRem(8)};
`;

export const ViewIconWrapper = styled.div`
  max-width: fit-content;
  cursor: pointer;
  display: flex;
  flex: 1;
  padding: ${toRem(8)};
  justify-content: center;
  align-items: center;
  gap: ${toRem(10)};

  border-radius: ${toRem(8)};
  background: ${COLORS.grey800};

  transition: ease-in-out 0.5s;
  &:hover {
    background: ${COLORS.grey700};
  }

  .i-icon svg {
    width: ${toRem(24)};
    height: ${toRem(24)};
    display: flex;
    &::before {
      display: none;
    }
    path {
      stroke: ${COLORS.white};
    }
  }
`;

export const DivisionsContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DivisionContentInformation = styled.div``;

export const DivisionContentInformationHeader = styled.div`
  display: flex;
  align-items: center;

  gap: ${toRem(8)};
  margin-bottom: ${toRem(8)};
`;

export const DivisionCircleColor =
  styled.div <
  { divisionColor?: string } >
  `
  width: ${toRem(16)};
  height: ${toRem(16)};
  border-radius: ${toRem(16)};

  background-color: ${({ divisionColor }) =>
    divisionColor || COLORS.brandPrimary};
`;

export const DivisionContentDatas = styled.div`
  display: flex;
  align-items: center;

  gap: ${toRem(8)};
`;

export const DivisionData = styled.div`
  display: flex;
  padding: ${toRem(8)} ${toRem(56)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${toRem(4)};

  border: ${toRem(1)} solid ${COLORS.grey800};
  border-radius: ${toRem(8)};
`;
