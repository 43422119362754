import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons/lib';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { PDFReader } from 'reactjs-pdf-reader';
import styled from 'styled-components';
import { User } from '../../../models/User/UserModel';
import { color } from '../../../styles/styleVariables';
import ConvertUtil from '../../../util/ConvertUtil';
import Button from '../../uielements/Button/Button';
import LightBox from '../LightBox/LightBox';

const YEAR = 2024;

const rejectionReasonsMsg = [
  // Safe Sport issues
  {
    reason: 'expiringSoon',
    message: `SafeSport training needs to be valid through the entire season until August 30, ${YEAR}. Please complete the following refresher training to maintain compliance. You can access the SafeSport Training Modules using the following link and access code YC3E-6P5G-YYIL-CS2M: https://safesporttrained.org/#/signup`,
  },
  {
    reason: 'expired',
    message: `Your SafeSport document is expired. Please complete a refresher training and upload the updated certificate valid through August 30,  ${YEAR}. You can access the SafeSport Training Modules using the following link and access code YC3E-6P5G-YYIL-CS2M: https://safesporttrained.org/#/signup`,
  },
  {
    reason: 'invalid',
    message: `This document is not valid. Please upload an official SafeSport Certificate valid through August 30,  ${YEAR}. If needed, you can access the SafeSport Training Modules using the following link and access code YC3E-6P5G-YYIL-CS2M: https://safesporttrained.org/#/signup`,
  },
  {
    reason: 'nameMismatch',
    message:
      'Please upload your own SafeSport Certificate. The full name provided is not matching the name added on the VAULT profile.',
  },

  // Birth certificate issues
  {
    reason: 'wrongDocument',
    message:
      'Please upload the required documents (Passport or Official Birth Certificate)',
  },
  {
    reason: 'pixelated',
    message:
      'Your picture is too pixelated and we cannot confirm the information. Please upload a photo with a higher resolution.',
  },
  {
    reason: 'wrongName',
    message:
      'Please upload your own birth certificate. The full name provided does not match the name added on the VAULT profile.',
  },
  {
    reason: 'wrongDate',
    message:
      'Please upload your own birth certificate. The date of birth provided does not match the data provided on the VAULT profile.',
  },
  {
    reason: 'wrongVaultData',
    message:
      'Please note that the information from the document provided does not match with the data provided on the VAULT profile. To move forward, please create another user with correct data because the VAULT will require you to add additional guardian information (for those 18 and under).',
  },
  {
    reason: 'wrongUser',
    message:
      'Please note that the document does not match with the data provided on the VAULT profile. To move forward, please create a new user with the correct data and send an email to montse@unitedfutsal.com. We will move the membership information from the incorrect accounts to the updated account.',
  },

  // Photo issues
  {
    reason: 'dark',
    message:
      'Your picture is too dark. Please try to upload your photo on a different browser and device. Uploads via smartphone will not work.',
  },
  {
    reason: 'pixelated',
    message:
      'Your picture is too pixelated. Please upload a photo with a higher resolution.',
  },
  {
    reason: 'obstructed',
    message:
      'We require an unobstructed photo to complete this step. Therefore, please upload a headshot photo with a clear background, and without hats, glasses, etc.',
  },
  {
    reason: 'nonHeadshot',
    message:
      'We require a headshot to complete this step. Therefore, please upload a picture with just your face. A full body or half-body picture will not be accepted.',
  },
  {
    reason: 'solo',
    message:
      'We require a solo-headshot to complete this step. Please upload a picture without any additional individuals in the photo or background.',
  },
];

interface IDocumentApprovalBoxProps {
  isOpen: boolean;
  title: string;
  documentUrl: string;
  user?: User;
  documentType: 'photo' | 'age' | 'documents';
  labelConfirm: string;
  labelCancel: string;
  onConfirm: () => void; // Button Confirm trigger it
  onCancel: () => void; // Button Cancel trigger it
  setFeedback: (text: string) => void;
  onClose: () => void; // Click out of box trigger it
}

const DocumentApprovalBox: React.FunctionComponent<IDocumentApprovalBoxProps> = (
  props
) => {
  const {
    onConfirm,
    onCancel,
    setFeedback,
    documentType,
    documentUrl,
    user,
    title,
    labelConfirm,
    labelCancel,
    isOpen,
    onClose,
  } = props;

  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [fileType, setFileType] = useState<'PDF' | 'IMAGE' | ''>('');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      setShowFeedback(false);

      if (documentUrl.trim() !== '') {
        // Veirfy if file is an IMAGE or PDF to correctly show icon on preview
        fetch(`${ConvertUtil.getPrivateMinioUrl(documentUrl)}`)
          .then((res) => res.blob()) // Gets the response and returns it as a blob
          .then((blob) => {
            if (blob.type === 'application/pdf') {
              setFileType('PDF');
            } else {
              setFileType('IMAGE');
            }
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    } else {
      setFileType('');
      setShowFeedback(false);
    }
  }, [isOpen, documentUrl]);

  function showImageContent() {
    let content;
    if (fileType === 'IMAGE') {
      content = (
        <img
          alt=''
          src={`${ConvertUtil.getPrivateMinioUrl(documentUrl)}`}
          style={{ maxWidth: '500px' }}
        />
      );
    } else if (fileType === 'PDF') {
      content = (
        <div style={{ height: 700, overflow: 'scroll' }}>
          <PDFReader
            url={ConvertUtil.getPrivateMinioUrl(documentUrl)}
            scale={1}
            showAllPage
          />
        </div>
      );
    }
    return content;
  }

  function photoContainer() {
    let content;

    if (documentType === 'photo' && documentUrl.trim() !== '') {
      content = (
        <PhotoBox>
          <div className='profile-image-wrapper'>
            <div
              className='profile-image-img'
              style={{
                backgroundImage: `url("${ConvertUtil.getMinioUrl(
                  documentUrl
                )}")`,
              }}
            />
          </div>
        </PhotoBox>
      );
    } else if (documentUrl.trim() !== '') {
      content = (
        <div style={{ maxWidth: '100%', maxHeight: '100%' }}>
          {showImageContent()}
        </div>
      );
    } else {
      content = <p>No document uploaded.</p>;
    }
    return content;
  }

  function showDocumentDownload() {
    let content;

    if (documentUrl.trim() !== '') {
      content = (
        <>
          <a
            href={`${ConvertUtil.getPrivateMinioUrl(documentUrl)}`}
            target='_blank'
            rel='noopener noreferrer'
            style={{ alignItems: 'center' }}
          >
            <p>
              <DownloadOutlined /> View original file
            </p>
          </a>
          <div>
            <h4>
              Name: {user?.firstName} {user?.middleName} {user?.lastName}{' '}
              {user?.suffix}
            </h4>
            <h4>Birth Date: {moment(user?.birthDate).format('MM/DD/YYYY')}</h4>
            <h4>Gender: {user?.gender}</h4>
          </div>
        </>
      );
    }
    return content;
  }

  const prefillFeedback = (reason: string) => {
    const message =
      rejectionReasonsMsg.find((r) => r.reason === reason)?.message || '';
    setFeedback(message);
    feedbackRef.current!!.value = message;
  };

  const feedbackRef = useRef<TextAreaRef>(null);
  return (
    <LightBox isOpen={isOpen} onClose={onClose}>
      <Wrapper style={{ maxHeight: '100vh', overflowY: 'auto' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {loading ? (
            <LoadingOutlined />
          ) : (
            <>
              <h3 style={{ textAlign: 'center' }}>{title}</h3>

              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                  flexBasis: '100%',
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {photoContainer()}
                {showDocumentDownload()}
              </div>

              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                  flex: 1,
                  justifyContent: 'center',
                }}
              >
                {!showFeedback ? (
                  <>
                    <Button onClick={onConfirm} style={{ margin: '10px 5px' }}>
                      {labelConfirm}
                    </Button>
                    <span> </span>
                    <Button
                      onClick={() => setShowFeedback(true)}
                      style={{ margin: '10px 5px' }}
                    >
                      {labelCancel}
                    </Button>
                  </>
                ) : (
                  <>
                    <div className='form-group'>
                      <select
                        defaultValue=''
                        onChange={(selected) =>
                          prefillFeedback(selected.currentTarget.value)
                        }
                      >
                        {documentType === 'photo' && (
                          <>
                            <option value='' disabled>
                              Custom reasons
                            </option>
                            <option value='dark'>Too Dark</option>
                            <option value='pixelated'>Pixelated</option>
                            <option value='nonHeadshot'>Non headshot</option>
                            <option value='obstructed'>Obstructed</option>
                            <option value='solo'>Not Solo</option>
                            <option value=''>Other</option>
                          </>
                        )}
                        {documentType === 'age' && (
                          <>
                            <option value='' disabled>
                              Custom reasons
                            </option>
                            <option value='wrongDocument'>
                              Wrong document
                            </option>
                            <option value='pixelated'>Pixelated</option>
                            <option value='wrongName'>Wrong name</option>
                            <option value='wrongDate'>
                              Wrong date of birth
                            </option>
                            <option value='wrongVaultData'>
                              Wrong Vault Data
                            </option>
                            <option value='wrongUser'>Wrong user</option>
                            <option value=''>Other</option>
                          </>
                        )}
                        {documentType === 'documents' && (
                          <>
                            <option value='' disabled>
                              Custom reasons
                            </option>
                            <option value='expiringSoon'>Expiring soon</option>
                            <option value='expired'>Expired</option>
                            <option value='invalid'>Invalid</option>
                            <option value='nameMismatch'>Name Mismatch</option>
                            <option value=''>Other</option>
                          </>
                        )}
                      </select>
                    </div>
                    <div className='form-group'>
                      <textarea
                        defaultValue=''
                        ref={feedbackRef}
                        maxLength={300}
                        onChange={(e: any) => {
                          setFeedback(e.target.value);
                        }}
                        style={{ margin: '10px 5px', height: '150px' }}
                      />
                    </div>
                    <Button onClick={onCancel} style={{ margin: '10px 5px' }}>
                      SEND
                    </Button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </Wrapper>
    </LightBox>
  );
};

const Wrapper = styled.div`
  padding: 50px;
  box-sizing: border-box;
  background: #333;
  border-radius: 20px;
  max-width: 800px;
  width: 800px;
`;

const PhotoBox = styled.div`
  position: relative;
  .profile-image-wrapper {
    width: 220px;
    height: 220px;
    border-radius: 300px;
    background-color: ${color.silverLightShadow};
    padding: 10px;
    z-index: 10;
    margin: 0 auto;
    margin-top: 10px;
    @media (max-width: 1100px) {
      width: 140px;
      height: 140px;
    }
    .profile-image-img {
      width: 200px;
      height: 200px;
      border-radius: 300px;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      background-color: #fff;
      @media (max-width: 1100px) {
        width: 120px;
        height: 120px;
      }
      img {
        width: 100%;
      }
    }
  }
`;

export default DocumentApprovalBox;
