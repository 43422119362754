import { filter as lFilter, lowerCase } from 'lodash';

import { AllEventInvites } from 'services/v3/Event/types';
import DateUtil from 'util/DateUtil';

type UseRefereeDataFilterProps = {
  filterValue: string;
  invites?: AllEventInvites[];
};

export const useRefereeDataFilter = ({
  invites,
  filterValue,
}: UseRefereeDataFilterProps) => {
  const filteredData = lFilter(invites, (item) => {
    const email = lowerCase(item?.referee?.email);
    const lastName = lowerCase(item?.referee?.lastName);
    const firstName = lowerCase(item?.referee?.firstName);
    const birthYear = lowerCase(
      DateUtil.getYear(new Date(item?.referee?.birthDate as string))
    );

    const filter = lowerCase(filterValue);

    return (
      email.includes(filter) ||
      lastName.includes(filter) ||
      firstName.includes(filter) ||
      birthYear.includes(filter)
    );
  });

  return { filteredData };
};
