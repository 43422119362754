import React from 'react';

import { Divider } from 'antd';

import { EventView } from 'admin/models/event/Event';
import { AgeSection, InformationSection } from './components';

import * as S from './styles';

type EventMainProps = {
  event?: EventView;
};

export const EventMain = ({ event }: EventMainProps) => {
  return (
    <S.MainWrapper>
      <InformationSection event={event} />
      <Divider style={{ margin: '48px 0' }} />
      <AgeSection ageDivisions={event?.ageDivisions as any} />
    </S.MainWrapper>
  );
};
