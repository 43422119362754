import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
`;

export const Container = styled.div<{
  $focus: boolean;
  $error: boolean;
  $disabled?: boolean;
  $isSerchable?: boolean;
}>`
  display: flex;
  flex-direction: ${(props) => (props.$isSerchable ? 'row' : 'column')};
  min-height: 50px;
  position: relative;
  font-family: 'Boxed Regular';
  font-weight: 500;
  border: 1px solid
    ${(props) => {
      if (props.$error) return COLORS.supportError;
      return props.$focus ? COLORS.brandPrimary : COLORS.grey400;
    }};
  border-radius: ${toRem(12)};
  padding: ${toRem(8)} ${toRem(16)};
  pointer-events: ${(props) => (props.$disabled ? 'none' : 'all')};

  &:hover {
    border-color: ${(props) =>
      props.$error ? COLORS.supportError : COLORS.brandPrimary};
  }

  .i-icon {
    display: flex;
    align-items: center;

    &-search {
      padding-right: ${toRem(8)};
      color: ${COLORS.grey400};
    }

    &-close-one {
      color: ${(props) =>
        props.$error ? COLORS.supportError : COLORS.grey400};
    }
  }
`;

export const Input = styled.input<{
  $error: boolean;
  $clearable?: boolean;
  $hasIcon?: boolean;
}>`
  color: ${(props) => (props.$error ? COLORS.supportError : COLORS.white)};
  font-size: 1rem;
  background-color: transparent;
  border: 0;
  max-width: ${(props) =>
    props.$clearable || props.$hasIcon ? '95%' : '100%'};
  flex: 1;
  padding: 0;
  line-height: 1rem;

  &:focus {
    outline: none;
  }
  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  & + .i-icon {
    position: absolute;
    right: 12px;
    top: 12px;
  }
`;

export const Label = styled.label<{ $required: boolean }>`
  font-size: ${toRem(13)};
  color: ${COLORS.grey400};
  line-height: 1rem;

  &::after {
    content: '*';
    color: ${COLORS.supportError};
    margin-left: ${toRem(5)};
    display: ${(props) => (props.$required ? 'inline-block' : 'none')};
  }
`;

export const ClearText = styled.span<{ $error: boolean }>`
  position: absolute;
  right: 12px;
  top: 14px;
  cursor: pointer;
`;
