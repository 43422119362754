import moment from 'moment';
import { RequestStatus } from 'models/Request/RequestModel';
import { MembershipView } from 'admin/models/membership/Membership';
import { Feedback, SafeSportType } from 'redux/v3/baseUsers/types';

import { Address } from './AddressModel';
import { Guardian } from './GuardianModel';
import { ExtraInfo } from './ExtraInfoModel';
import UserType from './UserTypeModel';

export type Clubs = { id: string; logo: string; name: string };

export type User = {
  id: string,
  accountId?: string,
  accountEmail?: string,
  type?: UserType,
  types?: UserType[],
  userIds?: string[],
  activeUserIds?: string[],
  firstName?: string,
  middleName?: string,
  lastName?: string,
  suffix?: string,
  birthDate?: string | moment.Moment,
  gender?: string,

  address?: Address,
  guardian1?: Guardian,
  guardian2?: Guardian,

  phoneNumber?: string;
  email?: string;
  guardians?: Guardian[];
  membershipId?: string;
  membershipPrice?: number;
  pendingDocumentation?: boolean;
  baseUser?: boolean;
  avatar?: string;
  photo?: string;
  ageProof?: string;
  profileVisibility?: string;
  signedWaivers?: boolean;
  extraInfo?: ExtraInfo;
  photoVerified?: RequestStatus;
  ageVerified?: RequestStatus;
  safesportValidUntil?: string;
  safesportType?: SafeSportType;
  documentationVerified?: RequestStatus;
  enabled?: boolean;
  playerInfo?: {
    nickName?: string;
    preferredName?: 'NICKNAME' | 'OFFICIAL';
    height?: number;
    preferredPosition?: string;
    preferredShirtNumber?: number;
    primaryClubId?: string;
    specialty?: string;
  };
  clubs: Clubs[];
  membershipType?: 'COMMUNITY' | 'OFFICIAL';
  age?: number;
  membership?: MembershipView;
  baseUserId?: string;
  feedbacks?: Feedback;
  jerseyNum?: any;
};

export const getUserTypeName = (type?: UserType) => {
  switch (type) {
    case UserType.PLAYER:
      return 'Player';
    case UserType.COACH:
      return 'Coach';
    case UserType.CLUB_DIRECTOR:
      return 'Club Director';
    case UserType.REFEREE:
      return 'Referee';
    case UserType.TOURNAMENT_DIRECTOR:
      return 'Tournament Director';
    case UserType.STAFF:
      return 'Admin';
    default:
      return '';
  }
};
