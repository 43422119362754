import { useQuery } from '@tanstack/react-query';
import VenueService from 'services/v3/Venue/VenueService';

export function useGetVenueById(id?: string) {
  const { data, isLoading, error } = useQuery(
    ['/get-venue-by-id', id],
    async () => await VenueService.getVenueById(id)
  );
  return {
    ...data,
    data: data?.data ?? undefined,
    isLoading,
    error,
  };
}
