import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { CONTENT_WIDTH } from '../../DraftEvent/styles';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 100%;
  max-width: 1200px;
  padding: ${toRem(48)} 0;

  @media (max-width: ${CONTENT_WIDTH}) {
    padding: 0 ${toRem(10)};
  }
`;
