import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: ${toRem(16)} 0 ${toRem(16)} 0;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${toRem(32)};
`;

export const SelectWrapper = styled.div`
  width: fit-content;
  min-width: ${toRem(140)};
`;

export const MoreActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${toRem(24)};
`;

export const IconWrapper = styled.div`
  max-width: fit-content;
  cursor: pointer;
  display: flex;
  flex: 1;
  padding: ${toRem(8)};
  justify-content: center;
  align-items: center;
  gap: ${toRem(10)};

  border-radius: ${toRem(8)};
  background: ${COLORS.grey800};

  transition: ease-in-out 0.5s;
  &:hover {
    background: ${COLORS.grey700};
  }

  .i-icon svg {
    width: ${toRem(24)};
    height: ${toRem(24)};
    display: flex;
    &::before {
      display: none;
    }
    path {
      stroke: ${COLORS.white};
    }
  }
`;

export const ChooseApplications = styled.div`
  display: flex;
  flex-direction: column;
  width: ${toRem(257)};
  padding: ${toRem(8)} 0;
  align-items: flex-start;
  gap: ${toRem(10)};

  background-color: ${COLORS.grey950};
  border: ${toRem(1)} solid ${COLORS.grey800};
  border-radius: ${toRem(12)};

  position: absolute;
  right: 0;
  bottom: ${toRem(111)};
  margin-right: ${toRem(90)};
`;

export const ApplicationAllSelect = styled.div`
  width: 100%;
  padding: ${toRem(8)} ${toRem(16)};

  transition: ease-in-out 0.5s;
  :hover {
    background-color: ${COLORS.grey800};
  }
`;
