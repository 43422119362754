import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import {
  eventsFetchError,
  eventsFetchSuccess,
  eventsFetchRequest,
} from 'redux/v3/events/actions';
import EventService from 'services/v3/Event/EventService';

export function useGetActiveEvents() {
  const dispatch = useDispatch();

  return useQuery(
    ['events'],
    () => {
      dispatch(eventsFetchRequest());
      return EventService.getActiveEvents();
    },
    {
      onSuccess: ({ data: { content } }) => {
        dispatch(eventsFetchSuccess(content));
      },
      // TODO: Verificar como o backend retorna o erro
      onError: () => {
        dispatch(eventsFetchError('An error occurred'));
      },
    }
  );
}
