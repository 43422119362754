import React from 'react';
import StripeCard from 'components/v3/Cards/StripeCard/StripeCard';
import { COLORS } from 'styles/v3/variables';
import { getDivisionInfo } from 'pages/V3/event/CheckoutAsClubDirector/CheckoutAsClubDirector';
import * as S from './styles';
import { DivisionItemProps } from './types';
import { UpdateQty } from './components/UpdateQty/UpdateQty';

export const DivisionItem = ({ item, price, divisions }: DivisionItemProps) => {
  const subtitle = getDivisionInfo(item);

  return (
    <S.Container>
      <StripeCard
        title={item.name ?? ''}
        subtitle={subtitle}
        description={price ? `$${price}` : ''}
        stripeColor={item.color || COLORS.pink}
        size='small'
        width='100%'
        hasChildren
      >
        <UpdateQty divisions={divisions} divisionId={item.id} />
      </StripeCard>
    </S.Container>
  );
};
