import { useQuery } from '@tanstack/react-query';
import { UserFilter } from 'models/User/UserFilter';
import UserType from 'models/User/UserTypeModel';
import UserService from 'services/User/UserApi';

export const useGetReferees = () => {
  const filter: UserFilter = { typeList: [UserType.REFEREE] };

  return useQuery(['referees'], () =>
    UserService.search(new URLSearchParams(), filter)
  );
};
