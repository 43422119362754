import React, { FC } from 'react';

import { COLORS } from 'styles/v3/variables';
import ConvertUtil from 'util/ConvertUtil';
import { ContainerCircles, CirclesGroup, CircleInfo } from './styles';

interface CircleInformation {
  id: string;
  logo: string;
  name?: string;
}

interface CirclesNestedProps {
  circlesInfos: CircleInformation[];
  maxInformation?: number;
}

export const CirclesNested: FC<CirclesNestedProps> = ({
  circlesInfos,
  maxInformation = 4,
  ...props
}) => {
  return (
    <ContainerCircles>
      <CirclesGroup
        maxCount={maxInformation}
        maxStyle={{
          color: `${COLORS.white}`,
          backgroundColor: `${COLORS.grey800}`,
        }}
      >
        {circlesInfos?.map((circle) => (
          <CircleInfo
            key={circle.id}
            src={ConvertUtil.getMinioUrl(circle.logo)}
          />
        ))}
      </CirclesGroup>
    </ContainerCircles>
  );
};
