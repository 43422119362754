import React from 'react';
import { User } from 'models/User/UserModel';
import ConvertUtil from 'util/ConvertUtil';

import Logo from 'components/v3/Elements/Logo/Logo';
import OutlinedButton from 'components/v3/Buttons/OutlinedButton';

import { BodyM, BodyS, COLORS, TitleH3 } from 'styles/v3/variables';

import { Dot } from '@icon-park/react';
import { Container, UserImage, UserMemberType } from './styles';

interface ModalHeaderMemberInfoProps {
  member: User;
  handleGoToUpgradeMembership: () => void;
}

const ModalHeaderMemberInfo = ({ member, handleGoToUpgradeMembership }: ModalHeaderMemberInfoProps) => {

  const userYear = new Date(member.birthDate as string).getFullYear();


  return (
    <>
      <Container>
        <UserImage>
          <img src={ConvertUtil.getMinioUrl(member.photo)} alt='User Img' />
        </UserImage>
        <TitleH3>
          {member.firstName} {member.lastName}
        </TitleH3>
        <UserMemberType>
          <Logo
            width={20}
            height={20}
            minimal
            color={
              member.membershipType === 'OFFICIAL' ? 'brandPrimary' : 'grey600'
            }
          />
          <BodyM
            $color={member.membershipType === 'OFFICIAL' ? 'grey50' : 'grey300'}
          >
            {member.membershipType === 'OFFICIAL'
              ? 'Official Member'
              : 'Community Member'}
          </BodyM>
        </UserMemberType>
        <BodyS>
            {member.gender} <Dot fill={COLORS.grey800} /> {userYear}
        </BodyS>
        {member.membershipType !== 'OFFICIAL' &&
          <>
            <OutlinedButton
              color='white-dark'
              isUpper
              isBold={false}
              onClick={handleGoToUpgradeMembership}
            >
              Upgrade Membership
            </OutlinedButton>
          </>
      }
      </Container>

    </>
  );
};

export default ModalHeaderMemberInfo;
