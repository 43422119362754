import React from 'react';
import { Attention, Camera, IdCardH, IdCardV, PreviewOpen } from '@icon-park/react';
import ConvertUtil from 'util/ConvertUtil';
import UserType from 'models/User/UserTypeModel';
import { User } from 'models/User/UserModel';

import { BodyXL, BodyM, COLORS, BodyS, ColorsInterface } from 'styles/v3/variables';
import Logo from 'components/v3/Elements/Logo/Logo';
import IconButton from 'components/v3/Buttons/IconButton';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import {
  Container,
  HeaderList,
  BodyList,
  ContainerUser,
  UserInformationContent,
  UserIconName,
  UserImage,
  UserMemberType,
  UserDocumentsAndMore,
  UserDocuments,
  UserDocument,
  UserJerseyContainer,
} from './styles';

interface ListOfUserProps {
  typeOfUser: string;
  handleOpenModal: (title: UserType) => void;
  handleOpenUserInfoModal: (user: User) => void;
  coachesList?: User[];
  playersList?: User[];
}

export const ListOfUser = ({
  typeOfUser,
  handleOpenModal,
  handleOpenUserInfoModal,
  coachesList,
  playersList,
}: ListOfUserProps) => {
  const userType = typeOfUser === 'Coachs' ? UserType.COACH : UserType.PLAYER;
  const listOfUser = typeOfUser === 'Coachs' ? coachesList : playersList;

  const userSS = (user: User): keyof ColorsInterface => {
    if (user.type === 'PLAYER') {
      return `grey800`;
    }

    return user.photoVerified !== 'DECLINED' ? 'grey400' : 'supportError';
  };

  return (
    <Container>
      <HeaderList>
        <BodyXL $color='white'>
          {typeOfUser} ({listOfUser?.length})
        </BodyXL>
        <TextButtonWithIcon
          customIcon='PeoplePlusOne'
          color='primary'
          align='flex-end'
          isUpper
          isBold={false}
          onClick={() => handleOpenModal(userType)}
        >
          ADD {typeOfUser}
        </TextButtonWithIcon>
      </HeaderList>

      <BodyList>
        {listOfUser && (
          <>
            {listOfUser.length === 0 ? (
              <BodyM
                $color='grey400'
                style={{ paddingTop: 50, paddingBottom: 50 }}
              >
                You don&apos;t have{' '}
                {typeOfUser === 'Coachs' ? 'coaches' : 'players'} in this
                roster. Add now
              </BodyM>
            ) : (
              <>
                {listOfUser.map((user) => (
                  <ContainerUser key={`key_member_${user.id}`}>
                    <UserInformationContent>
                      <UserIconName>
                        <UserImage>
                          <img src={ConvertUtil.getMinioUrl(user.photo)} alt='User Profile pic'/>
                        </UserImage>
                        <BodyXL>
                          {user.firstName} {user.lastName}
                        </BodyXL>
                      </UserIconName>
                      <UserMemberType>
                        {user.membershipType !== 'OFFICIAL' && (
                          <Attention size={24} fill={COLORS.supportError} />
                        )}
                        <Logo
                          width={20}
                          height={20}
                          minimal
                          color={
                            user.membershipType === 'OFFICIAL'
                              ? 'brandPrimary'
                              : 'grey600'
                          }
                        />
                        <BodyM
                          $color={
                            user.membershipType === 'OFFICIAL'
                              ? 'grey50'
                              : 'grey300'
                          }
                        >
                          {user.membershipType === 'OFFICIAL'
                            ? 'Official Member'
                            : 'Community Member'}
                        </BodyM>
                      </UserMemberType>
                    </UserInformationContent>

                    <UserDocumentsAndMore>
                      <UserDocuments>
                        <UserDocument>
                          <Camera
                            fill={
                              user.photoVerified !== 'DECLINED'
                                ? COLORS.grey400
                                : COLORS.supportError
                            }
                          />
                          <BodyS
                            $color={
                              user.photoVerified !== 'DECLINED'
                                ? 'grey400'
                                : 'supportError'
                            }
                          >
                            PH
                          </BodyS>
                        </UserDocument>

                        <UserDocument>
                          <IdCardH
                            fill={
                              user.photoVerified !== 'DECLINED'
                                ? COLORS.grey400
                                : COLORS.supportError
                            }
                          />
                          <BodyS
                            $color={
                              user.photoVerified !== 'DECLINED'
                                ? 'grey400'
                                : 'supportError'
                            }
                          >
                            AV
                          </BodyS>
                        </UserDocument>

                        <UserDocument>
                          <IdCardV
                            fill={COLORS[userSS(user)]}
                          />
                          <BodyS $color={userSS(user)}>SS</BodyS>
                        </UserDocument>
                      </UserDocuments>

                    {
                      user.type === 'PLAYER' &&
                      <UserJerseyContainer>
                        <BodyS $color='grey400'>Jersey</BodyS>
                        <BodyM $color='white'>.</BodyM>
                      </UserJerseyContainer>
                    }


                      <IconButton
                        icon={<PreviewOpen size={24} fill={COLORS.grey50}/>}
                        style={{ backgroundColor: COLORS.grey900 }}
                        onClick={() => handleOpenUserInfoModal(user)}
                      />
                    </UserDocumentsAndMore>
                  </ContainerUser>
                ))}
              </>
            )}
          </>
        )}
      </BodyList>
    </Container>
  );
};
