import styled from 'styled-components';

import { Tabs as AntdTabs } from 'antd';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  padding: 0px;
`;

export const Tabs = styled(AntdTabs)`
  .ant-tabs-nav {
    ::before {
      display: none;
    }
    margin: 0;
  }

  .ant-tabs-ink-bar {
    display: block !important;
    visibility: visible !important;
    background: ${COLORS.brandPrimary};
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-nav-list {
    gap: 24px;
  }

  .ant-tabs-nav {
    padding: 0 30px;
  }

  .ant-tabs-tab {
    padding: 0 !important;
    border: none !important;
    margin-bottom: 8px !important;
    background-color: unset !important;
  }

  .ant-tabs-tab:hover {
    color: ${COLORS.brandPrimary} !important;
    .tab-title-large,
    .tab-title-small {
      font-family: 'Boxed Regular Bold';
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${COLORS.brandPrimary} !important;

    .tab-title-large,
    .tab-title-small {
      font-family: 'Boxed Regular Bold';
    }
  }

  .ant-tabs-tab-btn:active {
    color: ${COLORS.brandPrimary};
  }
`;

export const TabPane = styled(AntdTabs.TabPane)``;

export const TabTitle = styled.p`
  font-family: 'Boxed Regular';

  font-size: ${toRem(20)};
  line-height: ${toRem(24)};
  &.tab-title-large {
    font-size: ${toRem(25)};
    line-height: ${toRem(30)};
  }
`;

export const TabPaneTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 8px;

  span {
    margin-top: 2px;

    &::before {
      display: none;
    }
  }
`;
