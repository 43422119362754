import React from 'react';
import { UploadLogs, PreviewOpen } from '@icon-park/react';

import * as S from './styles';
import { BodyM, BodySBold, BodyXL } from 'styles/v3/variables';
import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';
import { DocumentStatusLabel } from 'redux/v3/baseUsers/types';

interface DocumentCardProps {
  title: string;
  caption: string;
  status: DocumentStatusLabel;
  isRequired?: boolean;
  isOptional?: boolean;
  onClick: () => void;
}

const DocumentCard: React.FC<DocumentCardProps> = ({
  title,
  caption,
  status,
  isRequired,
  isOptional,
  onClick,
}) => {
  return (
    <S.DocumentCardWrapper>
      <S.BoxInfo
        $isRequired={isRequired && status === DocumentStatusLabel.EMPTY}
      >
        <S.BoxInfoHeader>
          <BodyXL className='card-title'>{title}</BodyXL>
          {isOptional && status === DocumentStatusLabel.EMPTY && (
            <BodyM $color='grey500' className='title-info'>
              (Optional)
            </BodyM>
          )}
          {status !== DocumentStatusLabel.EMPTY && (
            <S.StatusChip $status={status}>
              <BodySBold
                $isUpper
                $color={
                  status === DocumentStatusLabel.EXPIRED ||
                  status === DocumentStatusLabel.DECLINED
                    ? 'white'
                    : 'grey800'
                }
              >
                {status}
              </BodySBold>
            </S.StatusChip>
          )}
        </S.BoxInfoHeader>
        <BodyM>{caption}</BodyM>
      </S.BoxInfo>
      <S.BoxAction>
        {status === DocumentStatusLabel.EMPTY ? (
          <>
            <OutlinedButtonWithIcon
              color='white-dark'
              customIcon={<UploadLogs size={24} />}
              onClick={onClick}
            >
              UPLOAD
            </OutlinedButtonWithIcon>
          </>
        ) : (
          <S.IconWrapper>
            <PreviewOpen size={24} onClick={onClick} />
          </S.IconWrapper>
        )}
      </S.BoxAction>
    </S.DocumentCardWrapper>
  );
};

export default DocumentCard;
