import * as React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isArray } from 'lodash';

import TabBar from 'components/v3/Elements/TabBar/TabBar';

import { useGetMyClubsByUserType } from 'hooks/v3/clubs/useGetMyClubsByUserType/useGetMyClubsByUserType';
import { useGetMyClubsByUserId } from 'hooks/v3/clubs/useGetMyClubsByUserId/useGetMyClubsByUserId';

import { ClubView } from 'admin/models/club/Club';

import { ApplicationState } from 'redux/store';
import { UserType } from 'redux/user/types';
import { myClubsFetchRequest } from 'redux/club/actions';

import { BodyL, BodyLBold, TitleH2 } from 'styles/v3/variables';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import ClubCard from 'components/v3/Cards/ClubCard/ClubCard';
import Loading from 'components/v3/Loading/Loading';
import * as S from './styles';

const EmptyState = () => (
  <S.EmptyState>
    <BodyL $color='grey400'>
      You are not part of any Club. Try find the perfect club for you in the{' '}
      <BodyLBold>Find Clubs</BodyLBold> tab or create your own Club.
    </BodyL>
  </S.EmptyState>
);

const Clubs = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: userData } = useSelector(
    (state: ApplicationState) => state.currentUser
  );
  const userType: UserType | undefined =
    userData.types && userData.types.length > 0
      ? userData.types[0]
      : userData.type || UserType.PLAYER;
  const { data: myClubsByType } = useGetMyClubsByUserType(userType);
  const { data: myClubsById } = useGetMyClubsByUserId();

  const [clubs, setClubs] = React.useState<ClubView[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const handleGoToDashboard = (clubId: string) => {
    history.push(`/v3/clubs/${clubId}/dashboard`);
  };

  React.useEffect(() => {
    if (myClubsByType && myClubsByType.length > 0) {
      setClubs(myClubsByType);
    } else if (myClubsById && myClubsById.length > 0) {
      setClubs(myClubsById);
    } else {
      // dispatch(myClubsFetchRequest());
    }
    setLoading(false);
  }, [myClubsByType, myClubsById]);

  return (
    <>
      <S.PageTitle $maxWidth={1044}>
        <TitleH2 $isUpper>Clubs</TitleH2>
      </S.PageTitle>

      <S.Wrapper $maxWidth={1044}>
        <S.WrapperHeader>
          <TabBar
            items={[
              {
                name: 'my clubs',
                link: '/v3/clubs',
                path: '/v3/clubs',
              },
              {
                name: 'find clubs',
                link: '/v3/clubs/find',
                path: '/v3/clubs/find',
              },
            ]}
          />
          <TextButtonWithIcon
            align='flex-end'
            color='light'
            customIcon='Plus'
            onClick={() => history.push('/v3/clubs/new')}
          >
            New Club
          </TextButtonWithIcon>
        </S.WrapperHeader>

        {!loading ? (
          <S.WrapperBody $empty={clubs.length === 0} $gridTemplateColumns='5,1'>
            {isArray(clubs) && clubs.length > 0 ? (
              clubs.map((club, index) => (
                <ClubCard
                  key={club.id}
                  isMainClub={index === 0 ?? false}
                  onClick={() => handleGoToDashboard(club.id)}
                  club={club}
                />
              ))
            ) : (
              <EmptyState />
            )}
          </S.WrapperBody>
        ) : (
          <Loading />
        )}
      </S.Wrapper>
    </>
  );
};

export default withRouter(Clubs);
