import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const HeaderInformationsContainer = styled.div``;

export const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;

  gap: ${toRem(16)};
`;

export const AvatarEventInformation = styled.div`
  height: fit-content;
  display: flex;
  padding: ${toRem(8)} ${toRem(16)} ${toRem(8)} ${toRem(8)};
  align-items: center;

  background-color: ${COLORS.grey800};
  border-radius: ${toRem(121)};
  gap: ${toRem(8)};
`;

export const EventImage = styled.div`
  width: ${toRem(24)};
  height: ${toRem(24)};
  border-radius: ${toRem(24)};

  margin: 0 auto;
  overflow: hidden;

  background-color: ${COLORS.brandPrimary};

  img {
    object-fit: cover;
    max-width: 100%;

    width: ${toRem(24)};
    height: ${toRem(24)};
  }
`;

export const RulesInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(10)};
  padding-top: ${toRem(15)};
`;

export const Rules = styled.div`
  display: flex;
  gap: ${toRem(10)};
`;

export const ButtonsHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(16)};
`;

export const LocksContainer = styled.div`
  display: flex;
  padding: ${toRem(16)};
  gap: ${toRem(5)};

  background-color: ${COLORS.grey900};
  border-radius: ${toRem(16)};
`;
