import React, { useState } from 'react';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import { BodyM, BodyMBold, BodyS, TitleH2, TitleH4 } from 'styles/v3/variables';
import InputText from 'components/v3/Forms/InputText/InputText';
import { Dropdown, Menu } from 'antd';
import { Down, Mail } from '@icon-park/react';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { useHistory, useParams } from 'react-router-dom';

import { useGetReferees } from 'hooks/v3/referees/useGetReferees/useGetReferees';
import { useFilterReferees } from 'pages/V3/Invites/hooks';
import { formatUserName } from 'util/formatUserName';
import DateUtil from 'util/DateUtil';
import { useInviteByEvent } from 'hooks/v3/event/useInviteByEvent/useInviteByEvent';
import { notification } from 'components/v3/Notification/notification';
import { EventInvitesTypes } from 'models/v3/EventInvite/EventInvitesModel';
import { Card, SimpleCard } from '../../../components';
import * as S from './styles';

export const InviteByList = () => {
  const history = useHistory();
  const params: { eventId?: string } = useParams();

  const { mutateAsync } = useInviteByEvent();
  const { data: response } = useGetReferees();

  const [refereeName, setRefereeName] = useState('');
  const [selectedAge, setSelectedAge] = useState<number>(0);
  const [selectedCity, setSelectedCity] = useState<string>('');
  const [selectedReferees, setSelectedReferees] = useState<string[]>([]);

  const cities = Array.from(
    new Set(response?.data?.content?.map((item) => item.address?.city))
  );

  const ages = Array.from(
    new Set(
      response?.data?.content?.map((item) =>
        DateUtil.getUserAge(item.birthDate as string)
      )
    )
  );

  const selectedRefereesData = response?.data?.content?.filter((item) =>
    selectedReferees.includes(item.id)
  );

  const filteredReferees = useFilterReferees({
    selectedAge,
    refereeName,
    selectedCity,
    referees: response?.data?.content,
  });

  const handleGoBack = () => {
    history.goBack();
  };

  const handleRemoveCard = (id: string) => {
    setSelectedReferees(selectedReferees?.filter((clubId) => clubId !== id));
  };

  const handleCardSelect = (id: string) => {
    if (selectedReferees.includes(id)) handleRemoveCard(id);
    else setSelectedReferees([...selectedReferees, id]);
  };

  const handleSendInvites = async () => {
    if (!selectedReferees.length) {
      notification.error({
        message: 'An error occurs!',
        description:
          'Please select at least one referee to send the invitation to',
      });
      return;
    }

    if (params.eventId) {
      const payload = {
        eventId: params.eventId,
        receiversIds: selectedReferees,
        type: EventInvitesTypes.EVENT_TO_REFEREE,
      };

      await mutateAsync(payload);

      history.push(`/v3/event/${params.eventId}/dashboard`);
    }
  };

  return (
    <S.Container>
      <S.FindWrapper>
        <TextButtonWithIcon
          reverse
          icon='back'
          color='light'
          align='flex-end'
          onClick={handleGoBack}
        >
          Go back
        </TextButtonWithIcon>
        <TitleH2 $color='brandPrimary'>Find referees</TitleH2>
        <InputText
          id='search'
          icon='Search'
          value={refereeName}
          placeholder='Search by name'
          onChange={({ target }) => setRefereeName(target.value)}
        />
        <S.FilterWrapper>
          <BodyMBold $isUpper $color='grey500'>
            Filter by
          </BodyMBold>
          <Dropdown
            overlay={
              <Menu
                selectable
                onSelect={({ key }) => setSelectedCity(key.toString())}
              >
                {cities?.map((city) => (
                  <Menu.Item key={city}>
                    <BodyM>{city}</BodyM>
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <S.FilterText>
              <BodyM $isUpper>City</BodyM>
              <Down size={28} />
            </S.FilterText>
          </Dropdown>
          <Dropdown
            overlay={
              <Menu
                selectable
                onSelect={({ key }) => setSelectedAge(Number(key))}
              >
                {ages?.map((age) => (
                  <Menu.Item key={age}>
                    <BodyM>{age}</BodyM>
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <S.FilterText>
              <BodyM $isUpper>Age</BodyM>
              <Down size={28} />
            </S.FilterText>
          </Dropdown>
        </S.FilterWrapper>
        {!filteredReferees?.length ? (
          <S.FallbackMessageWrapper>
            <TitleH4 $isUpper $color='grey700'>
              didn&apos;t find the referee you were looking for?
            </TitleH4>
            <TitleH4 $color='grey500'>Invite via email</TitleH4>
          </S.FallbackMessageWrapper>
        ) : (
          filteredReferees?.map((item) => (
            <Card
              id={item.id}
              key={item.id}
              logoSrc={item.photo}
              onSelect={handleCardSelect}
              selected={selectedReferees.includes(item.id)}
              extraBasicInfo={<BodyS $color='grey400'>y.o</BodyS>}
              extraAddressInfo={<BodyM $color='grey400'> xp</BodyM>}
              address={`${item.address?.city} - ${item.address?.state}`}
              name={formatUserName({
                firstName: item.firstName,
                middleName: item.middleName,
                lastName: item.lastName,
              })}
            />
          ))
        )}
      </S.FindWrapper>
      <S.InviteListWrapper>
        <S.InviteListMain>
          <TitleH4 $isUpper>Invite list</TitleH4>
          <BodyM $color='grey400'>
            If you invite referees that have already been invited, they will be
            notified again.
          </BodyM>
          <S.SimpleCardList>
            {selectedRefereesData?.map((item) => (
              <SimpleCard
                id={item.id}
                key={item.id}
                imgSrc={item.photo}
                handleRemoveCard={handleRemoveCard}
                extraAddressInfo={<BodyM $color='grey400'>xp</BodyM>}
                address={`${item.address?.city} - ${item.address?.state}`}
                name={formatUserName({
                  firstName: item.firstName,
                  middleName: item.middleName,
                  lastName: item.lastName,
                })}
              />
            ))}
          </S.SimpleCardList>
          <FilledButton isUpper isBold={false} onClick={handleSendInvites}>
            Send invites
          </FilledButton>
        </S.InviteListMain>
        <S.InviteEmailWrapper>
          <BodyM $isUpper>Invite via email</BodyM>
          <Mail size={24} />
        </S.InviteEmailWrapper>
      </S.InviteListWrapper>
    </S.Container>
  );
};
