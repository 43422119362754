/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { PreviewOpen } from '@icon-park/react';
import { Divider } from 'antd';

import { RequestStatus } from 'models/Request/RequestModel';
import { User } from 'models/User/UserModel';
import UserType from 'models/User/UserTypeModel';

import { BodyLBold, BodyM, BodyS, COLORS, ColorsInterface } from 'styles/v3/variables';
import Select from 'components/v3/Forms/Select/Select';
import InputText from 'components/v3/Forms/InputText/InputText';
import IconButton from 'components/v3/Buttons/IconButton';
import OutlinedButton from 'components/v3/Buttons/OutlinedButton';
import { DocumentStatusViewModal } from 'components/v3/ModalInformations/DocumentStatusViewModal/DocumentStatusViewModal';
import { useGetUserDocuments } from 'hooks/v3/users/useGetUserDocuments/useGetUserDocuments';
import ModalHeaderMemberInfo from '../ModalHeaderMemberInfo';

import {
  Container,
  UserDocumentsInformation,
  UserDocuments,
  BreadInfo,
} from './styles';

interface ModalBodyDivisionProps {
  member: User;
  handleRemoveUser: () => Promise<void>;
  handleGoToUpgradeMembership: () => void;
}

const ModalBodyMemberInfo: React.FC<ModalBodyDivisionProps> = ({ member, handleRemoveUser, handleGoToUpgradeMembership }) => {
  const { data: userInfo } = useGetUserDocuments(member.id);
  const [modalPhotoOpen, setModalPhotoOpen] = useState(false);
  const [modalAgeOpen, setModalAgeOpen] = useState(false);
  const [modalSafeSportOpen, setModalSafeSportOpen] = useState(false);

  console.log('userInfo', userInfo);

  const statusVerifiedColor = (status: RequestStatus): keyof ColorsInterface => {
    if (status === 'APPROVED') {
      return 'brandPrimary';
    }

    if (status === RequestStatus.DECLINED) {
      return 'supportError';
    }

    return 'supportNeutral';
  }

  const safeSportView = (type?: UserType) => {
    if (type === 'PLAYER') {
      return <>
        <BodyM $color='grey700'>Safesport</BodyM>
        <BodyM $color='grey700'>No Documents</BodyM>
      </>
    }

    return <>
      <BodyM $color='grey200'>Safesport</BodyM>
      <BreadInfo color={statusVerifiedColor(member.documentationVerified as RequestStatus)}>
        <BodyS $color={statusVerifiedColor(member.documentationVerified as RequestStatus)}>{member.documentationVerified}</BodyS>
      </BreadInfo>
      <IconButton
        icon={<PreviewOpen size={24} fill={COLORS.grey50} />}
        style={{ backgroundColor: COLORS.grey700 }}
        onClick={() => setModalSafeSportOpen(true)}
      />
    </>
  }
  return (
    <>
      <Container>
        <ModalHeaderMemberInfo member={member} handleGoToUpgradeMembership={handleGoToUpgradeMembership} />
        <>
          <BodyLBold $color='white'>Contact Info</BodyLBold>
          <fieldset>
            <InputText
              disabled
              id='jerseyNumber'
              label='Jersey Number'
              placeholder='Jersey Number'
              // defaultValue={eventData?.data?.eventInfo?.emailContact ?? ''}
              key='key_jersey_number'
              className='general-event-input'
            />
          </fieldset>
          <fieldset>
            <Select
              placeholder='Positions'
              name='positionsPlayer'
              key='key_for_positions'
              defaultValue={null}
            />
          </fieldset>
        </>

        <Divider style={{ margin: 0 }} />

        <>
          <BodyLBold $color='white'>Documents</BodyLBold>
          <UserDocumentsInformation>
            <UserDocuments>
              <BodyM $color='grey200'>Photo</BodyM>
              <BreadInfo color={statusVerifiedColor(member.photoVerified as RequestStatus)}>
                <BodyS $color={statusVerifiedColor(member.photoVerified as RequestStatus)}>{member.photoVerified}</BodyS>
              </BreadInfo>
              <IconButton
                icon={<PreviewOpen size={24} fill={COLORS.grey50} />}
                style={{ backgroundColor: COLORS.grey700 }}
                onClick={() => setModalPhotoOpen(true)}
              />
            </UserDocuments>

            {
              member.type !== 'COACH' && (
                <UserDocuments>
                  <BodyM $color='grey200'>Proof of Age</BodyM>
                  <BreadInfo color={statusVerifiedColor(member.ageVerified as RequestStatus)}>
                    <BodyS $color={statusVerifiedColor(member.ageVerified as RequestStatus)}>{member.ageVerified}</BodyS>
                  </BreadInfo>
                  <IconButton
                    icon={<PreviewOpen size={24} fill={COLORS.grey50} />}
                    style={{ backgroundColor: COLORS.grey700 }}
                    onClick={() => setModalAgeOpen(true)}
                  />
                </UserDocuments>
              )
            }


            <UserDocuments>
              {
                safeSportView(member.type)
              }
            </UserDocuments>
          </UserDocumentsInformation>
        </>

        <Divider style={{ margin: 0 }} />

        <>
          <OutlinedButton
            color='white-dark'
            isUpper
            isBold={false}
            onClick={handleRemoveUser}
          >
            Remove from Roster
          </OutlinedButton>
        </>
      </Container>
      <DocumentStatusViewModal
        isOpen={modalAgeOpen}
        documentName='Proof of Age'
        documentStatus={member.ageVerified as RequestStatus}
        urlImg={userInfo?.ageProofUser as string}
        handleIsOpen={setModalAgeOpen}
      />
      <DocumentStatusViewModal
        isOpen={modalPhotoOpen}
        documentName='Photo'
        documentStatus={member.photoVerified as RequestStatus}
        isPhoto
        urlImg={userInfo?.photoUser as string}
        handleIsOpen={setModalPhotoOpen}
      />
      <DocumentStatusViewModal
        isOpen={modalSafeSportOpen}
        documentName='SafeSport'
        documentStatus={member.documentationVerified as RequestStatus}
        urlImg={userInfo?.safeSSportUser as string}
        handleIsOpen={setModalSafeSportOpen}
      />
    </>
  );
};

export default ModalBodyMemberInfo;
