import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 24px;
  background-color: ${COLORS.grey950};

  border: 1px solid ${COLORS.grey800};
`;
