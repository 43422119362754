import { AxiosRequestConfig } from 'axios';
import {
  Roster,
  RosterCreation,
  RosterMemberpool,
  RosterUpdate,
} from '../../models/Roster/RosterModel';
import { ConfigApi } from '../config.api';
import APIServices from '../util/ApiServices';
import ServiceResponse from '../util/ServiceResponse';

class RosterService {
  // TODO: arange reponse Type instead of any
  public static async create(
    body: RosterCreation
  ): Promise<ServiceResponse<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: '/rosters',
      method: 'POST',
      data: body,
    };
    return APIServices.request<any>(axiosConfig);
  }

  public static async update(
    rosterId: string,
    body: RosterUpdate
  ): Promise<ServiceResponse<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/rosters/${rosterId}`,
      method: 'PUT',
      data: body,
    };
    return APIServices.request<any>(axiosConfig);
  }

  public static async updateStatus(
    rosterId: string,
    body: RosterUpdate
  ): Promise<ServiceResponse<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/rosters/${rosterId}/update/`,
      method: 'PUT',
      data: body,
    };
    return APIServices.request<any>(axiosConfig);
  }

  public static async getRoster(id: string): Promise<ServiceResponse<Roster>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/rosters/${id}`,
      method: 'GET',
    };
    return APIServices.request<Roster>(axiosConfig);
  }

  public static async getRosterMemberpool(
    rosterId: string,
    type: 'coaches' | 'players'
  ): Promise<ServiceResponse<RosterMemberpool[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/rosters/${rosterId}/memberpool/${type}`,
      method: 'GET',
    };
    return APIServices.request<RosterMemberpool[]>(axiosConfig);
  }

  public static async addPlayer(arg: {
    rosterId: string;
    playerId: string;
  }): Promise<ServiceResponse<Roster>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/rosters/${arg.rosterId}/user/${arg.playerId}`,
      method: 'POST',
    };
    return APIServices.request<any>(axiosConfig);
  }

  public static async removePlayer(arg: {
    rosterId: string;
    playerId: string;
  }): Promise<ServiceResponse<Roster>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/rosters/${arg.rosterId}/user/${arg.playerId}`,
      method: 'DELETE',
    };
    return APIServices.request<any>(axiosConfig);
  }

  public static async updatePlayerNumber(arg: {
    rosterId: string;
    playerId: string;
    number: number;
  }): Promise<ServiceResponse<Roster>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/rosters/${arg.rosterId}/user/${arg.playerId}/number/${arg.number}`,
      method: 'PUT',
    };
    return APIServices.request<any>(axiosConfig);
  }

  public static async sendCheckIn(arg: {
    rosterId: string;
    matchId: string;
    userId: string;
    attend: boolean;
  }): Promise<ServiceResponse<Roster>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/rosters/${arg.rosterId}/match/${arg.matchId}/user/${arg.userId}/check-in/${arg.attend}`,
      method: 'PUT',
    };
    return APIServices.request<any>(axiosConfig);
  }

  public static async submitFinalRoster(
    rosterId: string
  ): Promise<ServiceResponse<Roster>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/rosters/${rosterId}/submit`,
      method: 'PUT',
    };
    return APIServices.request<any>(axiosConfig);
  }

  public static async deleteRoster(
    rosterId: string
  ): Promise<ServiceResponse<Roster>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/rosters/${rosterId}`,
      method: 'DELETE',
    };
    return APIServices.request<any>(axiosConfig);
  }
}

export default RosterService;
