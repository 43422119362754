/* eslint-disable no-nested-ternary */
import React from 'react';
import { ListView, Right } from '@icon-park/react';
import { SelectedSideModal } from 'redux/sideModal/types';
import {
  sideModalOpenModal,
  sideModalSetSelected,
  sideModalSetTitle,
} from 'redux/sideModal/actions';
import { useDispatch } from 'react-redux';

import { COLORS } from 'styles/v3/variables';
import {
  InfoContainer,
  InfoIconTitle,
  InfoIcon,
  InfoTitleStatus,
  InfoTitle,
  InfoStatus,
  InfoArrow,
} from './styles';

export interface InfoEventEditProps {
  title: string;
  status?: 'ok' | 'draft' | 'error' | null;
  selectedModal?: SelectedSideModal;
}

export const InfoEventEdit: React.FC<InfoEventEditProps> = ({
  title,
  status = null,
  selectedModal,
}) => {
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    if (selectedModal) {
      dispatch(sideModalSetSelected(selectedModal));
      dispatch(sideModalSetTitle(title));
      dispatch(sideModalOpenModal());
    }
  };

  return (
    <>
      <InfoContainer onClick={handleOpenModal}>
        <InfoIconTitle>
          <InfoIcon status={status}>
            <ListView size='32' fill={COLORS.grey1000} />
          </InfoIcon>
          <InfoTitleStatus>
            <InfoTitle>{title}</InfoTitle>
          </InfoTitleStatus>
        </InfoIconTitle>
        <InfoArrow>
          <Right />
        </InfoArrow>
      </InfoContainer>
    </>
  );
};
