import * as React from 'react';
import { loadStripe, RedirectToCheckoutOptions } from '@stripe/stripe-js';
import { withRouter, useHistory } from 'react-router-dom';
import { message as MessageSnackbar } from 'antd';

import PaymentCard from 'components/v3/Cards/PaymentCard/PaymentCard';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import Avatar from 'components/v3/Elements/Avatares/AvatarAction/AvatarAction';

import DateUtil from 'util/DateUtil';

import PaymentServices from 'services/Payment/PaymentServices';

import { TitleH1, TitleH3, BodyM, BodyS } from 'styles/v3/variables';
import Logo from 'assets/imgs/logos/logo-vault-green.png';
import { useGetProfiles } from 'hooks/v3/users/useGetProfiles/useGetProfiles';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import * as S from './styles';

const UpgradeMembershipPayment: React.FC = () => {
  const [urlParams] = React.useState<URLSearchParams>(
    new URLSearchParams(window.location.search)
  );
  const [urlParamsParsed, setUrlParamsParsed] = React.useState<{
    membership_id?: string;
    success?: boolean;
  }>({});

  const currentUser = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );

  useGetProfiles();

  const history = useHistory();

  function handlePreviousStep() {
    history.goBack();
  }

  const redirectStripe = async (sessionId: RedirectToCheckoutOptions) => {
    const { data } = await PaymentServices.getPaymentKey();
    const stripe = await loadStripe(data.publicKey);
    return stripe?.redirectToCheckout(sessionId);
  };

  const createMembership = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const url = '/v3/profile/upgrade-membership/payment';
    try {
      const { data } = await PaymentServices.fetchCheckoutMembershipSession(
        url
      );
      if (data.message) {
        throw new Error(data.message);
      }
      redirectStripe(data);
    } catch (error) {
      if (error instanceof Error) {
        MessageSnackbar.error({ content: error.message, duration: 5 });
      } else {
        MessageSnackbar.error({
          content: 'An unexpected error occurred',
          duration: 5,
        });
      }
    }
  };

  const currentSeason = DateUtil.formatSeason();
  const firstYear = currentSeason.split('-')[0];
  const lastYear = currentSeason.split('-')[1];

  React.useEffect(() => {
    urlParams.forEach((value, key) => {
      const newValue =
        value === 'true' || value === 'false' ? JSON.parse(value) : value;
      setUrlParamsParsed((obj) => ({ ...obj, [key]: newValue }));
    });
  }, [urlParams]);

  return (
    <S.PaymentWrapper>
      {urlParamsParsed.success ? (
        <PaymentCard
          title='Payment was a success'
          description='You will be redirected soon. If you were not redirected, just click the button below.'
          link='/v3/profile'
          buttonLabel='Go to my profile'
          orientation='row'
          size='large'
          theme='success'
        />
      ) : (
        <>
          <TextButtonWithIcon
            className='back-button'
            color='light'
            align='flex-end'
            reverse
            icon='back'
            onClick={handlePreviousStep}
          >
            Go back
          </TextButtonWithIcon>
          <PaymentCard
            title='something went wrong'
            description='Please make sure your payment information is correct and try again'
            action={createMembership}
            buttonLabel='Continue to payment'
            orientation='row'
            size='large'
            theme='error'
          />
        </>
      )}

      <TitleH1>Order Summary</TitleH1>

      <S.OrderSummaryWrapper>
        <S.SeasonWrapper>
          <img src={Logo} alt='Vault Logo' />
          <S.SeasonDetails>
            <TitleH3>Season {currentSeason}</TitleH3>
            <BodyM $color='grey300'>
              Starts at September 1st, {firstYear}
              <br />
              Ends at August 31th, {lastYear}
            </BodyM>
          </S.SeasonDetails>
        </S.SeasonWrapper>

        <S.MembershipDetailsWrapper>
          <S.MembershipInfo $direction='row'>
            <TitleH3 $color='brandPrimary'>$25</TitleH3>
            <BodyM> Official Membership</BodyM>
          </S.MembershipInfo>

          <S.MembershipInfo $direction='column'>
            <BodyM className='user-status'>User upgraded</BodyM>
            {currentUser?.firstName && <Avatar />}
          </S.MembershipInfo>
        </S.MembershipDetailsWrapper>
      </S.OrderSummaryWrapper>

      <BodyS className='disclaimer'>
        Your receipt will be sent to your email.
      </BodyS>
    </S.PaymentWrapper>
  );
};

export default withRouter(UpgradeMembershipPayment);
