import * as React from 'react';
import { useEffect } from 'react';

import Keycloak from 'keycloak-js';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import ConvertUtil from 'util/ConvertUtil';
import Loading from 'components/v3/Loading/Loading';

// eslint-disable-next-line import/no-cycle
import { routes as v3Routes } from './routes/V3.routes';

interface V3LoginProps {
  auth?: Keycloak.KeycloakInstance | undefined;
  routeFrom?: string;
}
interface LocationState {
  from: {
    pathname: string,
  };
}

const V3Login: React.FC<V3LoginProps> = (props) => {
  const { auth } = props;
  const location = useLocation<LocationState>();
  const history = useHistory();
  const redirectLocation = ConvertUtil.queryParameter(
    'targetPath',
    location.search
  );

  const targetPath = React.useMemo<string>(() => {
    if (!redirectLocation) return '';

    const target = redirectLocation.split('targetPath=');

    let finalTarget = target[target.length - 1];

    if (finalTarget.charAt(0) !== '/') {
      finalTarget = `/${finalTarget}`;
    }
    return finalTarget;
  }, [redirectLocation]);

  useEffect(() => {
    if (targetPath) {
      if (v3Routes.find((r) => r.private === false && r.path === targetPath)) {
        console.log('Redirecionando!');
        history.push(targetPath);
      }
    }

    if (!auth?.authenticated) {
      auth?.login();
    }
  }, [auth, history, targetPath]);

  if (auth?.authenticated) {
    if (targetPath) {
      history.push(targetPath);
    } else {
      return <Redirect to={location.state ? location.state.from : '/V3'} />;
    }
  }

  return <Loading />;
};

export default V3Login;
