import React, { useEffect } from 'react';

import { Avatar } from 'antd';
import { ClubView } from 'admin/models/club/Club';
import clubBanner from 'assets/imgs/club/club-banner.png';
import { LocalTwo } from '@icon-park/react';

import ConvertUtil from 'util/ConvertUtil';
import { BodyLBold, TitleH2 } from 'styles/v3/variables';
import FilledButton from 'components/v3/Buttons/FilledButton';
import useClubPermission from 'hooks/v3/clubs/useClubPermission';
import { useRequestJoin } from 'hooks/v3/clubs/useRequestJoin/useRequestJoin';
import { ApplicationState } from 'redux/store';
import { useSelector } from 'react-redux';
import { useGetUserClubRequest } from 'hooks/v3/clubs/useGetUserClubRequest/useGetUserClubRequest';
import { useHistory } from 'react-router-dom';
import * as S from './styles';

type HeaderProps = {
  club?: ClubView;
  maxWidth?: boolean;
};

export const Header = ({ club, maxWidth = true }: HeaderProps) => {
  const history = useHistory();

  const userId = useSelector(
    (state: ApplicationState) => state.currentUser.data.id
  );

  const { isDirectorOfThisClub, isMemberOfThisClub } = useClubPermission(club);

  const { data: userRequest, isLoading } = useGetUserClubRequest({
    userId,
    clubId: club?.id,
    isMemberOfThisClub,
  });

  const { mutateAsync } = useRequestJoin({ clubId: club?.id, userId });

  const isDisabled = ['APPROVED', 'PENDING', 'DECLINED'].includes(
    userRequest?.status || ''
  );

  useEffect(() => {
    if ((isDirectorOfThisClub || isMemberOfThisClub) && club) {
      history.push(`/v3/clubs/${club.id}/dashboard`);
    }
  }, [isDirectorOfThisClub, isMemberOfThisClub, club]);

  return (
    <S.Container $maxWidth={maxWidth} $image={clubBanner}>
      <S.HeaderContent>
        <S.AvatarWrapper>
          <Avatar
            size={140}
            src={ConvertUtil.getMinioUrl(club?.logo)}
            style={{ backgroundColor: 'white' }}
          />
          <S.TitleWrapper>
            <TitleH2>{club?.name}</TitleH2>
            <S.SubTitleWrapper>
              <LocalTwo />
              <BodyLBold>
                {club?.city}, {club?.country}
              </BodyLBold>
            </S.SubTitleWrapper>
          </S.TitleWrapper>
        </S.AvatarWrapper>
        {isDirectorOfThisClub ? (
          <FilledButton isBold={false}>Edit club info</FilledButton>
        ) : (
          !isMemberOfThisClub && (
            <FilledButton
              isUpper
              isBold={false}
              disabled={isDisabled}
              onClick={() => mutateAsync()}
            >
              {userRequest?.status === 'PENDING'
                ? 'Waiting approval'
                : 'Request to join'}
            </FilledButton>
          )
        )}
      </S.HeaderContent>
    </S.Container>
  );
};
