import * as React from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { Country, State } from 'country-state-city';
import { useSelector, useDispatch } from 'react-redux';
import { message as MessageSnackbar } from 'antd';
import { User } from 'models/User/UserModel';

import { BreadcrumbItemType } from 'components/v3/Breadcrumb/Breadcrumb';
import InputText from 'components/v3/Forms/InputText/InputText';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';

import { useGetUser } from 'hooks/v3/users/useGetUser/useGetUser';
import { baseUserUpdateData } from 'redux/v3/baseUsers/actions';
import { PatchBaseUser } from 'redux/v3/baseUsers/types';
import { ApplicationState } from 'redux/store';

import BaseUserService from 'services/v3/BaseUsers/BaseUsersApi';
import UserServices from 'services/v3/User/UserService';

import { DeepPartial } from 'util/types';
import DateUtil from 'util/DateUtil';

import { BodyXL } from 'styles/v3/variables';
import { setCurrentUser } from 'redux/v3/currentUser/actions';
import { usersFetchSuccess } from 'redux/v3/users/actions';
import breadcrumbItems from './breadcrumb.json';
import breadcrumbAdultItems from './breadcrumb-adult.json';
import CompleteUserTemplate from '../Template/Template';
import * as S from '../Template/styles';
import { useGetBaseUser } from '../../../../../hooks/v3/users/useGetBaseUser/useGetBaseUser';

type Fields = {
  line1: { value: string; error: boolean; required: boolean };
  line2: { value: string; error: boolean; required: boolean };
  city: { value: string; error: boolean; required: boolean };
  state: { value: string; error: boolean; required: boolean };
  zipCode: { value: string; error: boolean; required: boolean };
  country: { value: string; error: boolean; required: boolean };
};
type CountriesAndStatesType = {
  name: string;
  code: string;
  flag?: string;
};

const Address = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const formRef = React.useRef<HTMLFormElement>(null);
  // const { userId, data } = useSelector(
  //   (state: ApplicationState) => state.baseUsers
  // );

  // const userData = data.find((u) => u?.id === userId);

  const { data: currentUser } = useSelector(
    (state: ApplicationState) => state.currentUser
  );

  const isBaseUser = React.useMemo(() => {
    if (!currentUser) return false
    return Array.isArray(currentUser?.types)
  }, [currentUser]);

  // const currentUser?.address = currentUser?.address;


  const stateFields = React.useMemo < Fields>(() => {
    return {
      line1: { value: currentUser?.address?.line1 ?? '', error: false, required: true },
      line2: { value: currentUser?.address?.line2 ?? '', error: false, required: false },
      city: { value: currentUser?.address?.city ?? '', error: false, required: true },
      state: { value: currentUser?.address?.state ?? '', error: false, required: true },
      zipCode: {
        value: currentUser?.address?.zipCode ?? '',
        error: false,
        required: true,
      },
      country: {
        value: currentUser?.address?.country ?? '',
        error: false,
        required: true,
      },
    }
  }, [currentUser])
  // const stateFields: Fields = {
  //   line1: { value: currentUser?.address?.line1 ?? '', error: false, required: true },
  //   line2: { value: currentUser?.address?.line2 ?? '', error: false, required: false },
  //   city: { value: currentUser?.address?.city ?? '', error: false, required: true },
  //   state: { value: currentUser?.address?.state ?? '', error: false, required: true },
  //   zipCode: {
  //     value: currentUser?.address?.zipCode ?? '',
  //     error: false,
  //     required: true,
  //   },
  //   country: {
  //     value: currentUser?.address?.country ?? '',
  //     error: false,
  //     required: true,
  //   },
  // };

  const [fields, setFields] = React.useState<Fields>(stateFields);
  const [validating, setValidating] = React.useState<boolean>(false);
  const [countryOptions, setCountryOptions] = React.useState<OptionsType[]>([]);
  const [statesOptions, setStatesOptions] = React.useState<OptionsType[]>([]);

  const setLabelToCountry = countryOptions.find(
    (s) => s.value === stateFields.country.value
  );

  const setLabelToState = statesOptions.find(
    (s) => s.value === stateFields.state.value
  );

  const params: { userId: string } = useParams();
  const { data: userFromBack, isLoading } = useGetBaseUser(params.userId);
  const [userLogged, setUserLogged] = React.useState<User>();
  const [loggedBreadCrumbs, setLoggedBreadCrumbs] = React.useState<BreadcrumbItemType[]>();

  const handleChangeSelect = async (field: keyof Fields, e: any) => {
    setFields({
      ...fields,
      [field]: {
        ...fields[field],
        value: e.value,
        error: false,
      },
    });
    setValidating(false);
  };
  const handleInputClear = (field: keyof Fields) => {
    setFields({
      ...fields,
      [field]: { ...fields[field], value: '' },
    });
    setValidating(false);
  };
  const handleChangeInputValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof Fields
  ) => {
    const { name, value } = e.target;

    setFields({
      ...fields,
      [name]: {
        required: stateFields[field]?.required ?? true,
        error: false,
        value,
      },
    });
    setValidating(false);
  };

  const validate = () => {
    setValidating(true);
    for (const [key, values] of Object.entries(fields)) {
      if (values?.value === '' && values?.required) {
        setFields((newFields) => ({
          ...newFields,
          [key]: { value: values.value, error: true },
        }));
      }
    }
  };
  const saveUser = async () => {
    try {
      if (formRef.current) {
        const formData: FormData = new FormData(formRef.current);
        const payload: DeepPartial<PatchBaseUser> = {
          address: {
            line1: formData.get('line1') as string,
            line2: formData.get('line2') as string,
            city: formData.get('city') as string,
            state: formData.get('state') as string,
            zipCode: formData.get('zipCode') as string,
            country: formData.get('country') as string,
          },
        };

        if(isBaseUser){
          const idForPatch = `${currentUser.id}`;
          const { data: updatedUser } = await BaseUserService.updateUser(
            idForPatch,
            payload
          );
          if (updatedUser.message) throw new Error(updatedUser.message);

          dispatch(baseUserUpdateData({ ...payload, id: currentUser.id }));
          const { data: updatedProfile } = await UserServices.getProfiles()

          const updatedBaseUser = updatedProfile?.baseUsers?.find(u => u.id === currentUser.id)

          if (updatedBaseUser) {
            dispatch(setCurrentUser(updatedBaseUser));
            const newUsers = [...(updatedProfile.baseUsers || []), ...(updatedProfile.users || [])];
            dispatch(usersFetchSuccess(newUsers));
          }

        } else {
          UserServices.updateUser(`${currentUser.id}`, payload as any, isBaseUser).then(async (res) => {
            if (res.success) {
              dispatch(baseUserUpdateData({ ...payload, id: currentUser.id }));

              const { data: updatedProfile } = await UserServices.getProfiles()

              const myNewBaseUser = updatedProfile?.baseUsers?.find(u => u.userIds?.includes(`${currentUser.id}`))

              if (myNewBaseUser) {
                dispatch(setCurrentUser(myNewBaseUser));
                const newUsers = [...(updatedProfile.baseUsers || []), ...(updatedProfile.users || [])];
                dispatch(usersFetchSuccess(newUsers));
              }

            } 
          });
        }
        
        history.push('/v3/new-user/documents');
      }
    } catch (error) {
      setValidating(false);
      if (error instanceof Error) {
        MessageSnackbar.error({ content: error.message, duration: 5 });
      }
    }
  };

  React.useEffect(() => {
    const allCountries = Country.getAllCountries().map((i) => ({
      label: `${i.name} ${i.flag}`,
      value: i.isoCode,
    }));
    setCountryOptions(allCountries);
  }, []);
  React.useEffect(() => {
    if (validating) {
      const getFields = Object.values(fields);
      const hasError = getFields.find((i) => i?.error);

      if (!hasError) {
        saveUser();
      } else {
        setValidating(false);
      }
    }
  }, [validating, fields]);
  React.useEffect(() => {
    const countryCode = fields.country.value;
    if (countryCode) {
      const statesOfCountry = State.getStatesOfCountry(countryCode).map(
        (i) => ({
          label: i.name,
          value: i.isoCode,
        })
      );
      setStatesOptions(statesOfCountry);
    }
  }, [fields.country.value]);

  React.useEffect(() => {
    if (!isLoading && userFromBack !== undefined) {

      const stateFieldsHasUser: Fields = {
        line1: { value: userFromBack.address?.line1 ?? '', error: false, required: true },
        line2: { value: userFromBack.address?.line2 ?? '', error: false, required: false },
        city: { value: userFromBack.address?.city ?? '', error: false, required: true },
        state: { value: userFromBack.address?.state ?? '', error: false, required: true },
        zipCode: {
          value: userFromBack.address?.zipCode ?? '',
          error: false,
          required: true,
        },
        country: {
          value: userFromBack.address?.country ?? '',
          error: false,
          required: true,
        },
      };

      setFields(stateFieldsHasUser);

      if (DateUtil.isAnAdult(userFromBack?.birthDate)) {
        setLoggedBreadCrumbs([{
          "icon": "CheckSmall",
          "status": "checked",
          "text": "User Info",
          "link": `/v3/new-user/user-info/${params.userId}`,
        },
        {
          "icon": "CheckSmall",
          "status": "checked",
          "text": "Address",
          "link": `/v3/new-user/address/${params.userId}`,
        },
        {
          "icon": "CheckSmall",
          "status": "checked",
          "text": "Documents",
          "link": `/v3/new-user/documents/${params.userId}`,
        }]);

      } else {
        setLoggedBreadCrumbs([{
          "icon": "CheckSmall",
          "status": "active",
          "text": "User Info",
          "link": `/v3/new-user/user-info/${params.userId}`,
        },
        {
          "icon": "CheckSmall",
          "status": "checked",
          "text": "Legal Guardian",
          "link": `/v3/new-user/legal-guardian/${params.userId}`,
        },
        {
          "icon": "CheckSmall",
          "status": "checked",
          "text": "Address",
          "link": `/v3/new-user/address/${params.userId}`,
        },
        {
          "icon": "CheckSmall",
          "status": "checked",
          "text": "Documents",
          "link": `/v3/new-user/documents/${params.userId}`,
        }
        ]);
      }

      setUserLogged(userFromBack);

    }
  }, [isLoading]);

  const valueButton = () => {
    if (validating) {
      return 'Saving'
    }

    if (userLogged) {
      return 'Updated'
    }

    return 'Continue'
  }

  return (
    <CompleteUserTemplate
      breadcrumbItems={loggedBreadCrumbs || (DateUtil.isAnAdult(currentUser?.birthDate ? currentUser?.birthDate as any : '')
        ? breadcrumbAdultItems
        : breadcrumbItems) as BreadcrumbItemType[]
      }
      user={userLogged}
    >
      <S.UserForm ref={formRef} onSubmit={(e) => e.preventDefault()}>


        {userLogged ?
          <>
            <fieldset>
              <legend>
                <BodyXL $color='brandPrimary' $isUpper>
                  Address Edit
                </BodyXL>
              </legend>
              <InputText
                id='line1'
                placeholder='Address line 1'
                label='Address line 1'
                required
                defaultValue={fields.line1.value}
                onClearInput={(id) => handleInputClear(id)}
                error={fields.line1.error}
                onChange={(e) => handleChangeInputValue(e, 'line1')}
                className='user-info-input'
              />
              <InputText
                id='line2'
                placeholder='Address line 2'
                label='Address line 2'
                defaultValue={fields.line2.value}
                onClearInput={(id) => handleInputClear(id)}
                onChange={(e) => handleChangeInputValue(e, 'line2')}
                className='user-info-input'
              />
            </fieldset>

            <S.FormFieldset>
              {(countryOptions.length > 0 && fields.country.value) && (
                <InputText
                  id='country'
                  placeholder='Country'
                  required
                  defaultValue={fields.country.value}
                  onChange={(e) => handleChangeSelect('country', e)}
                  error={fields.country.error}
                  className='user-info-input'
                />
              )}
              {statesOptions.length > 0 && (
                <InputText
                  id='state'
                  placeholder='State'
                  required
                  defaultValue={fields.state.value}
                  onChange={(e) => handleChangeSelect('state', e)}
                  error={fields.state.error}
                  className='user-info-input'
                />
              )}
            </S.FormFieldset>

            <S.FormFieldset>
              <InputText
                id='city'
                placeholder='City'
                label='City'
                required
                defaultValue={fields.city.value}
                onClearInput={(id) => handleInputClear(id)}
                error={fields.city.error}
                onChange={(e) => handleChangeInputValue(e, 'city')}
                className='user-info-input'
              />
              <InputText
                id='zipCode'
                placeholder='Zipcode'
                label='Zipcode'
                required
                defaultValue={fields.zipCode.value}
                onClearInput={(id) => handleInputClear(id)}
                error={fields.zipCode.error}
                onChange={(e) => handleChangeInputValue(e, 'zipCode')}
                className='user-info-input'
              />
            </S.FormFieldset>


          </>
          :
          <>
            <fieldset>
              <legend>
                <BodyXL $color='brandPrimary' $isUpper>
                  Address
                </BodyXL>
              </legend>

              <InputText
                id='line1'
                placeholder='Address line 1'
                label='Address line 1'
                required
                defaultValue={fields.line1.value}
                onClearInput={(id) => handleInputClear(id)}
                error={fields.line1.error}
                onChange={(e) => handleChangeInputValue(e, 'line1')}
                className='user-info-input'
              />
              <InputText
                id='line2'
                placeholder='Address line 2'
                label='Address line 2'
                defaultValue={fields.line2.value}
                onClearInput={(id) => handleInputClear(id)}
                onChange={(e) => handleChangeInputValue(e, 'line2')}
                className='user-info-input'
              />
            </fieldset>

            <S.FormFieldset>
              {countryOptions.length > 0 && (
                <Select
                  name='country'
                  placeholder='Country'
                  required
                  isSearchable
                  options={countryOptions}
                  defaultValue={setLabelToCountry}
                  onChange={(e) => handleChangeSelect('country', e)}
                  error={fields.country.error}
                  className='user-info-input'
                />
              )}
              {statesOptions.length > 0 && (
                <Select
                  name='state'
                  placeholder='State'
                  required
                  isSearchable
                  options={statesOptions}
                  defaultValue={setLabelToState}
                  onChange={(e) => handleChangeSelect('state', e)}
                  error={fields.state.error}
                  className='user-info-input'
                />
              )}
            </S.FormFieldset>

            <S.FormFieldset>
              <InputText
                id='city'
                placeholder='City'
                label='City'
                required
                defaultValue={fields.city.value}
                onClearInput={(id) => handleInputClear(id)}
                error={fields.city.error}
                onChange={(e) => handleChangeInputValue(e, 'city')}
                className='user-info-input'
              />
              <InputText
                id='zipCode'
                placeholder='Zipcode'
                label='Zipcode'
                required
                defaultValue={fields.zipCode.value}
                onClearInput={(id) => handleInputClear(id)}
                error={fields.zipCode.error}
                onChange={(e) => handleChangeInputValue(e, 'zipCode')}
                className='user-info-input'
              />
            </S.FormFieldset>

          </>
        }
        <FilledButtonWithIcon onClick={validate} disabled={userLogged !== undefined}>
          {valueButton()}
        </FilledButtonWithIcon>
      </S.UserForm>
    </CompleteUserTemplate>
  );
};

export default withRouter(Address);
