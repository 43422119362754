import { AxiosRequestConfig } from 'axios';
import { MatchDetailsModel, MatchModel } from '../../models/Match/MatchModel';
import { ConfigApi } from '../config.api';
import APIServices from '../util/ApiServices';
import PageResponse from '../util/PageResponse';
import ServiceResponse from '../util/ServiceResponse';

class MatchService {
  public static async search(
    params: {
      urlParams?: URLSearchParams,
      body?: any
    }
  ): Promise<ServiceResponse<PageResponse<any>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: '/matches/search',
      method: 'POST',
      // TO-DO: add this on function parameters
      params: params.urlParams,
      data: params.body,
    };
    return APIServices.request<PageResponse<MatchModel>>(axiosConfig);
  }

  public static async getDetails(
    matchId: string
  ): Promise<ServiceResponse<MatchDetailsModel>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/matches/${matchId}/detail`,
      method: 'GET',
    };
    return APIServices.request<MatchDetailsModel>(axiosConfig);
  }

  public static async create(
    body: MatchModel
  ): Promise<ServiceResponse<MatchModel>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: '/matches',
      method: 'POST',
      data: body,
    };
    return APIServices.request<MatchModel>(axiosConfig);
  }

  public static async update(arg: {
    matchId: string;
    body: MatchModel;
  }): Promise<ServiceResponse<MatchModel>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/matches/${arg.matchId}`,
      method: 'PUT',
      data: arg.body,
    };
    return APIServices.request<MatchModel>(axiosConfig);
  }

  public static async delete(
    matchId: string
  ): Promise<ServiceResponse<MatchModel>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/matches/${matchId}`,
      method: 'DELETE',
    };
    return APIServices.request<MatchModel>(axiosConfig);
  }

  public static async finishGame(
    matchId: string,
    report?: string
  ): Promise<ServiceResponse<MatchModel>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/matches/${matchId}/finish`,
      method: 'POST',
      data: { report },
    };
    return APIServices.request<MatchModel>(axiosConfig);
  }

  public static async resubmitGame(
    matchId: string,
  ): Promise<ServiceResponse<MatchModel>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/matches/${matchId}/fixing`,
      method: 'GET'
    };
    return APIServices.request<MatchModel>(axiosConfig);
  }
}

export default MatchService;
