import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Icon, { IconType } from '@icon-park/react/es/all';

import { BodyM } from 'styles/v3/variables';
import * as S from './styles';

export type BreadcrumbItemType = {
  text: string,
  status: 'checked' | 'locked' | 'active',
  icon: IconType,
  link?: string,
};

interface BreadcrumbProps {
  items: BreadcrumbItemType[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  const history = useHistory();

  const handleGoTo = (link: string) => {
    history.push(link);
  };

  return (
    <S.BreadcrumbWrapper>
      {items.map((i) => (
        <S.BreadcrumbItem
          key={i.text}
          $status={i.status}
          onClick={() => (i.link ? handleGoTo(i.link) : '')}
        >
          <BodyM $isUpper>{i.text}</BodyM>
          <Icon type={i.icon} size='24' />
        </S.BreadcrumbItem>
      ))}
    </S.BreadcrumbWrapper>
  );
};

export default Breadcrumb;
