import React, { FunctionComponent } from 'react';
import { useLocation, Link } from 'react-router-dom';
import ConvertUtil from 'util/ConvertUtil';

// Components
import { BodyM, TitleH3 } from 'styles/v3/variables';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';

// Styles
import {
  EventImageInformation,
  Information,
  InformationContainer,
  InformationContent,
} from './styles';

export interface InformationScreenProps {
  title: string;
  description: string;
  eventIcon: string;
  eventId: string;
}

const InformationScreen: FunctionComponent = (props) => {
  const { state } = useLocation<InformationScreenProps>();

  const { title, description, eventIcon } = state;
  const link = `/v3/events`;

  return (
    <Information>
      <InformationContainer>
        <EventImageInformation>
          <img src={ConvertUtil.getMinioUrl(eventIcon)} alt='Event Logo' />
        </EventImageInformation>
        <InformationContent>
          <TitleH3>{title}</TitleH3>
          <BodyM $color='grey400'>{description}</BodyM>
          <Link to={link}>
            <FilledButtonWithIcon color='primary' isUpper>
              Go To Event Page
            </FilledButtonWithIcon>
          </Link>
        </InformationContent>
      </InformationContainer>
    </Information>
  );
};

export default InformationScreen;
