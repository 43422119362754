import { action } from 'typesafe-actions';
import { EventListView } from 'admin/models/event/Event';
import { EventsActionTypes } from './types';

export const eventsFetchRequest = () =>
  action(EventsActionTypes.FETCH_EVENTS_REQUEST);

export const eventsFetchSuccess = (events: EventListView[]) =>
  action(EventsActionTypes.FETCH_EVENTS_SUCCESS, events);

export const eventsFetchError = (message: string) =>
  action(EventsActionTypes.FETCH_EVENTS_ERROR, message);
