export enum RequestStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
}

interface RequestModel {
  id: string;
  club: {
    id: string;
    city: string;
    country: string;
    logo: string;
    name: string;
    state: string;
  };
  user: any;
  status: RequestStatus;
}

export default RequestModel;
