/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
/* eslint-disable no-sequences */
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import EventService from 'services/v3/Event/EventService';
import { Divider } from 'antd';

import { DeepPartial } from 'util/types';
import { PatchEventProps } from 'services/v3/B2bEvent/types';

import { NewAgeDivision } from 'admin/models/AgeDivision';
import RightDrawer from 'components/v3/RightDrawer';
import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';
import { DivisionsFilters } from './DivisionsFIlters';
import { DivisionsList } from './DivisionsList';
import ModalBody from './Modal/ModalBody/ModalBody';
import ModalHeader from './Modal/ModalHeader/ModalHeader';

export interface DivisionAndClubsState {
  division: NewAgeDivision;
  divisionOrder: {
    description: string;
    id: string;
    itemPrice: number;
    name: string;
    quantity: number;
    total: number;
    clubId: string;
  }[];
  clubInformation: {
    id: string;
    logo: string;
    name: string;
  }[];
}

export const DivisionsTab = () => {
  const params: { eventId: string } = useParams();

  const { mutateAsync } = useUpdateEvent({
    eventId: params.eventId,
  });

  const [divisionsAndClubs, setDivisionsAndClubs] = useState<
    DivisionAndClubsState[]
  >();
  const [yearForFilter, setYear] = React.useState<number[]>();
  const [filterGender, setFilterGender] = React.useState<string>('');
  const [filterYear, setFilterYear] = React.useState<string>('');
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentDivision, setCurrentDivision] = React.useState<
    DivisionAndClubsState | undefined
  >(undefined);

  const handleEvents = async () => {
    try {
      const {
        data: fetchedClubsAndDivisions,
      } = await EventService.getClubsAndDivisionsPaidForEvent(params.eventId);

      if (fetchedClubsAndDivisions.content) {
        const { content } = fetchedClubsAndDivisions;
        const updateDivisionsAndClubs: DivisionAndClubsState[] = [];

        content.forEach((c) => {
          c.ageDivisions.map((division) => {
            const { id } = division;

            c.orderDetailed.map((orderDetailed) => {
              orderDetailed.cart[0]?.b2bEventRosterFee.divisions.forEach(
                (divisionOrder) => {
                  if (divisionOrder.id === id) {
                    const clubInformation =
                      orderDetailed.cart[0]?.b2bEventRosterFee.club;

                    const divisionOrderPush = [
                      { ...divisionOrder, clubId: clubInformation.id },
                    ];
                    const updateInfo = updateDivisionsAndClubs.find(
                      (info) => info.division.id === id
                    );
                    if (updateInfo) {
                      if (
                        !updateInfo.clubInformation.find(
                          (club) => club.id === clubInformation.id
                        )
                      ) {
                        updateInfo.clubInformation.push(clubInformation);
                      }
                      updateInfo.divisionOrder.push(...divisionOrderPush);
                    } else {
                      const clubs = [clubInformation];

                      updateDivisionsAndClubs.push({
                        division,
                        divisionOrder: divisionOrderPush,
                        clubInformation: clubs,
                      });
                    }
                  }
                }
              );
            });
          });
        });

        setDivisionsAndClubs(updateDivisionsAndClubs);
        const years: number[] = [];
        updateDivisionsAndClubs?.forEach((division) => {
          division.division.years.forEach((yearAccepted) => {
            if (!years.find((yearIn) => yearIn === yearAccepted)) {
              years.push(yearAccepted);
            }
          });
        });

        setYear(years);
      }
    } catch (err) {
      console.log('ERROR: ', err);
    }
  };

  const filterDivision = useMemo(() => {
    if (filterGender !== '' && filterYear !== '') {
      const genderAndYearFiltered = divisionsAndClubs?.filter(
        (divisionAndClub) => {
          if (
            divisionAndClub.division.gender === filterGender &&
            divisionAndClub.division.years.includes(Number(filterYear))
          ) {
            return divisionAndClub.division;
          }
        }
      );
      return genderAndYearFiltered;
    }

    if (filterGender !== '') {
      const genderFiltered = divisionsAndClubs?.filter((divisionAndClub) => {
        if (divisionAndClub.division.gender === filterGender) {
          return divisionAndClub.division;
        }
      });
      return genderFiltered;
    }

    if (filterYear !== '') {
      const yearFiltered = divisionsAndClubs?.filter((divisionAndClub) => {
        if (divisionAndClub.division.years.includes(Number(filterYear))) {
          return divisionAndClub.division;
        }
      });
      return yearFiltered;
    }

    return undefined;
  }, [filterGender, filterYear]);

  const handleSelectGender = (gender: string) => {
    setFilterGender(gender);
  };

  const handleSelectYear = (year: string) => {
    setFilterYear(year);
  };

  const handleOpenModal = (division: DivisionAndClubsState) => {
    setCurrentDivision(division);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setCurrentDivision(undefined);
    setIsOpen(false);
  };

  const handleSave = async (status: boolean) => {
    try {
      if (divisionsAndClubs) {
        const allDivisions: NewAgeDivision[] = divisionsAndClubs.map(
          (divisions) => {
            return {
              ...divisions.division,
              locked: status,
            };
          }
        );

        const payload: DeepPartial<PatchEventProps> = {
          ageDivisions: allDivisions,
        };
        await mutateAsync({ id: params.eventId, data: payload });
        await handleEvents();
      }
    } catch (e) {
      console.error(`Error: ${e}`);
    }
  };

  useEffect(() => {
    handleEvents();
  }, []);

  return (
    <>
      <Divider style={{ margin: 0 }} />
      {yearForFilter && (
        <DivisionsFilters
          setGender={handleSelectGender}
          setYear={handleSelectYear}
          yearsToFilter={yearForFilter}
          handleSaveAll={handleSave}
        />
      )}
      <Divider style={{ margin: 0 }} />
      {divisionsAndClubs && (
        <DivisionsList
          handleOpenModal={handleOpenModal}
          divisionFiltered={filterDivision}
          divisionsAndClubs={divisionsAndClubs}
        />
      )}
      <RightDrawer
        isOpen={isOpen && !!currentDivision}
        handleCloseModal={handleCloseModal}
        headerComponent={
          <ModalHeader title={currentDivision?.division.name as string} />
        }
      >
        {currentDivision && divisionsAndClubs && (
          <ModalBody
            divisionAndClubs={currentDivision}
            allDivisions={divisionsAndClubs.map(
              (divisions) => divisions.division
            )}
          />
        )}
      </RightDrawer>
    </>
  );
};
