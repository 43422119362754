import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const CheckoutAsClubDirectorContainer = styled.div`
  padding: 0 ${toRem(50)};
  display: flex;

  gap: ${toRem(24)};

  align-items: flex-start;

  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DivisionWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: ${toRem(40)};
`;

export const DivisionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(16)};
`;

export const CheckoutWrapper = styled.div`
  display: flex;
  max-width: ${toRem(384)};
  width: 100%;
  padding: ${toRem(30)};
  flex-direction: column;
  align-items: center;
  gap: ${toRem(30)};

  border-radius: 24px;
  background: ${COLORS.grey950};

  .btn-checkout {
    width: 100%;
    display: flex;
    column-gap: ${toRem(8)};
    align-items: center;

    .i-icon svg {
      width: 20px;
      height: 20px;
      display: flex;
      &::before {
        display: none;
      }
    }
  }
`;
