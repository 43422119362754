import { NewAgeDivision } from "admin/models/AgeDivision";
import { CouponView } from "services/v3/Coupons/types";

export enum SignupEventActionTypes {
  SET_CLUB = '@@signupEvent/SET_CLUB',
  VALIDATE_DIVISIONS = '@@signupEvent/VALIDATE_DIVISIONS',
  DIVISION_QTY_INCREASE = '@@signupEvent/DIVISION_QTY_INCREASE',
  DIVISION_QTY_DECREASE = '@@signupEvent/DIVISION_QTY_DECREASE',
  SET_COUPON = '@@signupEvent/SET_COUPON',
}

export interface SignupEventState {
  readonly data: SignupEvent;
}

export interface SignupEvent {
  currentDivisions: NewAgeDivision[];
  clubId: string;
  divisions: SignupDivision[];
  coupon?: CouponView;
}

export interface SignupDivision {
  divisionId: string,
  name: string,
  quantity: number

}