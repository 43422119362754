import React, { FunctionComponent, useState, useMemo, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// HOOKS
import { useGetRosterByIdDetailed } from 'hooks/v3/clubs/useGetRosterByIdDetailed/useGetRosterByIdDetailed';
import { useGetClubMembersPool } from 'hooks/v3/clubs/useGetClubMembersPool/useGetClubMembersPool';

import { HeaderRosterEdit } from './HeaderRosterEdit';
import { ListOfUser } from './ListOfUser';

const RosterDetails: FunctionComponent = () => {
  const params: { rosterId: string } = useParams();
  const { data: rosterDetailed } = useGetRosterByIdDetailed(params.rosterId);
  const { data: members } = useGetClubMembersPool(rosterDetailed?.club?.id);
  const [selectedUsers, setSelectedUsers] = useState<string[]>(['']);

  const history = useHistory();

  const handleGoBack = async () => {
    const id = rosterDetailed?.club?.id ?? '';
    history.push(`/v3/clubs/${id}/dashboard`);
  };

  const coachesSelected = useMemo(() => {
    if (!members?.length) return [];

    return members.filter(
      (member) => member.type === 'COACH' && selectedUsers.includes(member.id)
    );
  }, [members]);

  const playersSelected = useMemo(() => {
    if (!members?.length) return [];

    return members.filter(
      (member) => member.type === 'PLAYER' && selectedUsers.includes(member.id)
    );
  }, [members]);

  useEffect(() => {
    const selectedPlayers = rosterDetailed?.players.map((player) => player.id);
    const selectedCoaches = rosterDetailed?.coaches.map((player) => player.id);

    if (selectedPlayers && selectedPlayers?.length > 0) {
      setSelectedUsers((oldSelected) => [...oldSelected, ...selectedPlayers]);
    }

    if (selectedCoaches && selectedCoaches?.length > 0) {
      setSelectedUsers((oldSelected) => [...oldSelected, ...selectedCoaches]);
    }
  }, [members]);

  return (
    <>
      {rosterDetailed?.ageDivision && (
        <HeaderRosterEdit
          handleGoBack={handleGoBack}
          name={rosterDetailed?.name ?? ''}
          eventName={rosterDetailed?.event?.name ?? ''}
          eventLogo={rosterDetailed?.event?.logo ?? ''}
          division={rosterDetailed.ageDivision}
          locksIn={rosterDetailed?.event?.data.generalRules?.rosterLockDate}
        />
      )}

      {rosterDetailed?.coaches && (
        <ListOfUser typeOfUser='Coachs' coachesList={coachesSelected} />
      )}

      {rosterDetailed?.players && (
        <ListOfUser typeOfUser='Players' playersList={playersSelected} />
      )}
    </>
  );
};

export default RosterDetails;
