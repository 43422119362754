import { Reducer } from 'redux';
import { produce } from 'immer';
import { B2bDraftEventActionTypes, B2bDraftEventState } from './types';
import { B2bBoostersData, B2bEventInfoData } from './data';

export const initialState: B2bDraftEventState = {
  data: {
    selectedEvent: undefined,
    events: [],
    boosters: B2bBoostersData,
    selectedBoosters: [],
    eventInfo: B2bEventInfoData,
  },
  loading: false,
};

const reducer: Reducer<B2bDraftEventState> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // LOAD EVENT PRODUCTS
      case B2bDraftEventActionTypes.FETCH_PRODUCT_REQUEST: {
        draft.loading = true;
        break;
      }
      case B2bDraftEventActionTypes.FETCH_PRODUCT_SUCCESS: {
        draft.loading = false;

        if (Array.isArray(action.payload) && action.payload.length > 0) {
          draft.data.events = action.payload;
          if (state.data.selectedEvent) {
            draft.data.selectedEvent = draft.data.events[0];
          }
        }

        break;
      }
      case B2bDraftEventActionTypes.FETCH_PRODUCT_ERROR: {
        draft.loading = false;
        break;
      }

      // SELECTED EVENT TYPE
      case B2bDraftEventActionTypes.UPDATE_EVENT_TYPE: {
        draft.data.selectedEvent =
          draft.data.events.find((e) => e.id === action.payload) ??
          draft.data.events[0];
        break;
      }
      // BOOSTERS
      case B2bDraftEventActionTypes.ADD_BOOSTER: {
        const boosterToAdd = draft.data.boosters.find(
          (b) => b.id === action.payload
        );
        if (
          boosterToAdd &&
          !draft.data.selectedBoosters.find((b) => b.id !== boosterToAdd.id)
        ) {
          draft.data.selectedBoosters.push(boosterToAdd);
        }
        break;
      }
      case B2bDraftEventActionTypes.REMOVE_BOOSTER: {
        draft.data.selectedBoosters = draft.data.selectedBoosters.filter(
          (b) => b.id !== action.payload
        );
        break;
      }
      case B2bDraftEventActionTypes.ADD_OR_REMOVE_BOOSTER: {
        const boosterToAddOrRemove = state.data.boosters.find(
          (b) => b.id === action.payload
        );

        if (boosterToAddOrRemove) {
          const addBooster = !state.data.selectedBoosters.find(
            (b) => b.id === boosterToAddOrRemove.id
          );

          if (addBooster) {
            draft.data.selectedBoosters.push(boosterToAddOrRemove);
          } else {
            draft.data.selectedBoosters = state.data.selectedBoosters.filter(
              (b) => b.id !== boosterToAddOrRemove.id
            );
          }
        }

        break;
      }
      // EVENT GENERAL INFORMATION
      case B2bDraftEventActionTypes.UPDATE_EVENT_INFO: {
        draft.data.eventInfo = { ...state.data.eventInfo, ...action.payload };
        break;
      }

      case B2bDraftEventActionTypes.CLEAR_EVENT: {
        draft.data = initialState.data;
        draft.loading = initialState.loading;
        break;
      }

      default: {
        break;
      }
    }
  });

export { reducer as b2bDraftEventReducer };
