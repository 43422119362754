import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;

  gap: 24px;

  padding: 16px;
  border-radius: 16px;
  background-color: ${COLORS.grey900};
`;

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 4px;
`;
