import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const PageTitle = styled.div<{ $maxWidth: number }>`
  margin: 0 auto;
  width: 100%;
  padding: 0 ${toRem(24)};
  max-width: ${(props) => `${props.$maxWidth}px`};
`;
export const Wrapper = styled.div<{ $maxWidth: number }>`
  border: 1px solid ${COLORS.grey800};
  border-radius: ${toRem(24)};
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => `${props.$maxWidth}px`};
  margin-top: ${toRem(24)};
  background-color: ${COLORS.grey950};
`;
export const WrapperHeader = styled.div`
  display: flex;
  padding: ${toRem(24)} ${toRem(24)} 0;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.grey800};
`;
export const WrapperSubHeader = styled.div`
  display: flex;
  padding: ${toRem(16)} ${toRem(24)};
  justify-content: space-between;
`;
export const WrapperBody = styled.div<{
  $empty?: boolean;
  $gridTemplateColumns?: string;
  $noMarginTop?: boolean;
}>`
  display: ${(props) => (props.$empty ? 'flex' : 'grid')};
  height: ${(props) => (props.$empty ? '496px' : 'auto')};
  align-items: ${(props) => (props.$empty ? 'center' : 'initial')};
  grid-template-columns: ${(props) =>
    props.$gridTemplateColumns
      ? `repeat(${props.$gridTemplateColumns}fr)`
      : 'repeat(5, 1fr)'};
  grid-auto-columns: minmax(${toRem(180)}, auto);
  grid-gap: ${toRem(24)};
  margin: ${toRem(24)};
  margin-top: ${(props) => (props.$noMarginTop ? 0 : 'inherit')};
`;
export const EmptyState = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 426px;
`;
export const Form = styled.form<{ $noPadding?: boolean }>`
  padding: ${(props) => (props.$noPadding ? 0 : toRem(24))};
`;
export const Fieldset = styled.fieldset`
  &.button-wrapper {
    display: flex;
    flex-direction: column;
  }
  & + fieldset {
    margin-top: ${toRem(24)};
  }
  legend {
    margin-bottom: ${toRem(24)};
  }
  .input-field {
    margin-bottom: ${toRem(24)};
  }
  .half-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${toRem(24)};
  }
  .upload-file-text {
    margin: ${toRem(16)} 0;
  }
`;
export const SwitchWrapper = styled.div`
  display: flex;

  .switch-text {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;

    .switch-title {
      margin-bottom: ${toRem(4)};
    }
  }
`;
export const WrapperPagination = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;
