export const status = [
  {
    key: 'invited',
    name: 'Invited',
  },
  {
    key: 'denied',
    name: 'Denied',
  },
  {
    key: 'signedUp',
    name: 'Signed Up',
  },
];
