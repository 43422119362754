import React, { FunctionComponent, useState, useMemo, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import UserType from 'models/User/UserTypeModel';
import { User } from 'models/User/UserModel';
import RosterService from 'services/v3/Roters/RostersService';

// HOOKS
import { useGetRosterByIdDetailed } from 'hooks/v3/clubs/useGetRosterByIdDetailed/useGetRosterByIdDetailed';
import { useGetClubMembersPool } from 'hooks/v3/clubs/useGetClubMembersPool/useGetClubMembersPool';

import RightDrawer from 'components/v3/RightDrawer';
import { HeaderRosterEdit, ListOfUser } from './components';
import ModalHeader from './components/Modal/ModalHeader/ModalHeader';
import ModalBody from './components/Modal/ModalBody/ModalBody';
import ModalBodyMemberInfo from './components/Modal/ModalBodyMemberInfo';

const RosterEditPage: FunctionComponent = () => {
  const params: { rosterId: string } = useParams();
  const { data: rosterDetailed } = useGetRosterByIdDetailed(params.rosterId);
  const { data: members } = useGetClubMembersPool(rosterDetailed?.club?.id);
  const [selectedUsers, setSelectedUsers] = useState<string[]>(['']);
  // const users = members.filter((user) => user.type === userType);

  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);
  const [userSelected, setUserSelected] = useState<User | false>(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalType, setModalType] = useState<UserType>();

  const handleGoToUpgradeMembership = () => {
    history.push('/v3/profile/upgrade-membership');
  };

  const handleGoBack = async () => {
    const id = rosterDetailed?.club?.id ?? '';
    history.push(`/v3/clubs/${id}/dashboard`);
  };

  const handleOpenModal = (headerModal: UserType) => {
    setModalTitle(`Add ${headerModal}s`);
    setModalType(headerModal);
    setIsOpen(true);
  };

  const handleOpenUserInfoModal = (user: User) => {
    setIsUserInfoOpen(true);
    setUserSelected(user);
  };

  const handleCloseModal = () => {
    setModalTitle('');
    setIsOpen(false);
    setUserSelected(false);
  };

  const handleSaveNewUser = async () => {
    if (selectedUsers.length > 0) {
      await selectedUsers.forEach(async (userId) => {
        try {
          await RosterService.addPlayer(params.rosterId, userId);
        } catch (e) {
          console.log(e);
        }
      });

      handleCloseModal();
    }
  };

  const handleRemoveUser = async () => {
    if (userSelected) {
      try {
        await RosterService.removePlayer(params.rosterId, userSelected.id);
        setSelectedUsers((oldState) => [
          ...oldState.filter((userId) => userId !== userSelected.id),
        ]);
      } catch (e) {
        console.log(e);
      }

      handleCloseModal();
    }
  };

  const coachesData = useMemo(() => {
    if (!members?.length) return [];

    return members.filter((member) => member.type === 'COACH');
  }, [handleSaveNewUser, handleCloseModal]);

  const coachesSelected = useMemo(() => {
    if (!members?.length) return [];

    return members.filter(
      (member) => member.type === 'COACH' && selectedUsers.includes(member.id)
    );
  }, [handleSaveNewUser, handleCloseModal]);

  const playersData = useMemo(() => {
    if (!members?.length) return [];

    return members.filter((member) => member.type === 'PLAYER');
  }, [handleSaveNewUser, handleCloseModal]);

  const playersSelected = useMemo(() => {
    if (!members?.length) return [];

    return members.filter(
      (member) => member.type === 'PLAYER' && selectedUsers.includes(member.id)
    );
  }, [handleSaveNewUser, handleCloseModal]);

  useEffect(() => {
    const selectedPlayers = rosterDetailed?.players.map((player) => player.id);
    const selectedCoaches = rosterDetailed?.coaches.map((player) => player.id);

    if (selectedPlayers && selectedPlayers?.length > 0) {
      setSelectedUsers((oldSelected) => [...oldSelected, ...selectedPlayers]);
    }

    if (selectedCoaches && selectedCoaches?.length > 0) {
      setSelectedUsers((oldSelected) => [...oldSelected, ...selectedCoaches]);
    }
  }, [members]);

  return (
    <>
      {rosterDetailed?.ageDivision && (
        <HeaderRosterEdit
          handleGoBack={handleGoBack}
          name={rosterDetailed?.name ?? ''}
          eventName={rosterDetailed?.event?.name ?? ''}
          eventLogo={rosterDetailed?.event?.logo ?? ''}
          division={rosterDetailed.ageDivision}
          locksIn={rosterDetailed?.event?.data.generalRules?.rosterLockDate}
        />
      )}

      {rosterDetailed?.coaches && (
        <ListOfUser
          typeOfUser='Coachs'
          coachesList={coachesSelected}
          handleOpenModal={handleOpenModal}
          handleOpenUserInfoModal={handleOpenUserInfoModal}
        />
      )}

      {rosterDetailed?.players && (
        <ListOfUser
          typeOfUser='Players'
          playersList={playersSelected}
          handleOpenModal={handleOpenModal}
          handleOpenUserInfoModal={handleOpenUserInfoModal}
        />
      )}

      {isOpen && modalType && (
        <RightDrawer
          isOpen={isOpen}
          handleCloseModal={handleCloseModal}
          headerComponent={<ModalHeader title={modalTitle} />}
        >
          <ModalBody
            userType={modalType}
            members={modalType === 'COACH' ? coachesData : playersData}
            selectedUsers={selectedUsers}
            handleSelectedUsers={setSelectedUsers}
            handleUpdateUsers={handleSaveNewUser}
          />
        </RightDrawer>
      )}

      {isUserInfoOpen && userSelected && (
        <RightDrawer
          isOpen={isUserInfoOpen}
          handleCloseModal={handleCloseModal}
        >
          <ModalBodyMemberInfo
            member={userSelected}
            handleRemoveUser={handleRemoveUser}
            handleGoToUpgradeMembership={handleGoToUpgradeMembership}
          />
        </RightDrawer>
      )}
    </>
  );
};

export default RosterEditPage;
