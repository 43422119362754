import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

// General Event Page
export const EventInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 ${toRem(30)} ${toRem(24)};
`;
export const EventInfoFormWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: ${toRem(48)};
  }
`;
export const EventInfoForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  legend {
    margin: ${toRem(6)} 0 ${toRem(24)};
  }

  .field-description {
    font-weight: 500;
    > span {
      font-weight: 700;
    }
  }

  fieldset {
    button:last-child {
      width: 100%;
    }

    /* & + fieldset {
      margin-top: 1.5rem;
    } */
    .general-event-info {
      margin: ${toRem(4)} 0 ${toRem(24)} ${toRem(16)};
    }
    .general-event-input {
      & + .general-event-input {
        margin-top: 1.5rem;
      }
    }
    .booster-item {
      & + .booster-item {
        margin-top: ${toRem(24)};
      }
    }
  }

  bottom {
    width: 100%;
  }
`;
export const EventInfoFieldset = styled.fieldset`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
`;
export const EventInfoSummary = styled.div`
  width: 100%;
  max-width: 384px;

  .summary-subtitle {
    margin-bottom: 1rem;
  }
  .trophy-image {
    max-width: 50px;
  }
  .general-event-selected {
    margin: ${toRem(14)} 0 ${toRem(30)};
  }
`;
export const EventInfoSummaryHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${toRem(30)};

  .summary-title {
    margin: ${toRem(30)} 0 ${toRem(4)};
  }
`;
export const EventInfoSummaryBody = styled.div`
  display: grid;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${toRem(10)};
`;
export const EventInfoSummaryMapWrapper = styled.div`
  max-width: 324px;
  max-height: 110px;
  display: flex;
  margin-bottom: ${toRem(30)};
  align-items: center;
  justify-content: center;
  position: relative;

  p {
    position: absolute;
    z-index: 1;
  }

  img {
    object-fit: cover;
  }
`;
export const EventInfoSummaryEventSelected = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;

  h4,
  p {
    margin: 0;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50px;
  position: relative;
  font-family: 'Boxed Regular';
  font-weight: 500;
  border: 1px solid ${COLORS.grey400};
  border-radius: ${toRem(12)};
  padding: ${toRem(8)} ${toRem(16)};

  &:hover {
    border-color: ${COLORS.brandPrimary};
  }

  > select {
  }
`;

export const Select = styled.select`
  width: 100%;
  color: ${COLORS.white};
  font-size: 1rem;
  background-color: ${COLORS.grey950};
  border: 0;
  flex: 1;
  padding: 0;
  line-height: 1rem;

  &:focus {
    outline: none;
  }
  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`;
