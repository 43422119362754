/* eslint-disable react/button-has-type */
import * as React from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';

import VenueServices from 'services/Venue/VenueApi';

import { BodyLBold, COLORS } from 'styles/v3/variables';
import { Plus, Delete } from '@icon-park/react';

import FilledButton from 'components/v3/Buttons/FilledButton';
import InputText from 'components/v3/Forms/InputText/InputText';

import { EventVenue, SubVenues } from 'services/v3/B2bEvent/types';
import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';
import {
  CourtsWrapper,
  CourtsFormWrapper,
  CourtsTitleHeader,
  CourtsForm,
  CourtInputs,
  TrashButtonContainer,
} from './styles';

// TYPE COURTS

interface Courts {
  venueId: string;
  venueName: string;
  courtsRecommended: number;
  courts: SubVenues[];
}

const Courts: React.FC = () => {
  const params: { eventId: string } = useParams();
  const eventId: string = params?.eventId || '';

  const { isLoading, mutateAsync } = useUpdateEvent({ eventId });

  const formRef = React.useRef<HTMLFormElement>(null);

  const [courtsEvent, setCourts] = React.useState<Courts[]>([]);

  const { eventData } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );

  const loadVenues = React.useCallback(async () => {
    try {
      const { data } = await VenueServices.listEventVenues(eventId);

      const updateCourts: Courts[] = [];
      data.forEach((v) => {
        const eventHaveThisVenue = eventData?.venues?.find(
          (e) => e?.venueId === v.id
        );

        if (eventHaveThisVenue) {
          updateCourts.push({
            venueId: v.id,
            venueName: v.name,
            courtsRecommended: v.availableCourt,
            courts: (eventHaveThisVenue?.subVenues as SubVenues[]) ?? [],
          });
        }
      });

      setCourts(updateCourts);
    } catch (e) {
      console.log('error loading venues', e);
    }
  }, []);

  React.useEffect(() => {
    loadVenues();
  }, [loadVenues]);

  function handleAddCourt(venueToUpdate: Courts) {
    const updatedVenue = courtsEvent.map((venue) => {
      if (venue.venueId === venueToUpdate.venueId) {
        return {
          ...venue,
          courts: [...venue.courts, { name: '', abbreviation: '' }],
        };
      }

      return venue;
    });
    setCourts(updatedVenue);
  }

  function handleRemoveCourt(
    subVenueIndex: number,
    venueToUpdate: Courts,
    venueId: string
  ) {
    const deleted = courtsEvent.map((v) => {
      if (v.venueId === venueId) {
        return {
          ...v,
          courts: [
            ...v.courts.slice(0, subVenueIndex),
            ...v.courts.slice(subVenueIndex + 1),
          ],
        };
      }

      return v;
    });

    setCourts(deleted);
  }

  const handleSave = async () => {
    try {
      if (formRef.current) {
        const formData = new FormData(formRef.current);
        let courtsUpdate: Partial<EventVenue[]> = [];

        const createSendable = courtsEvent.map((venue) => {
          const subVenues: SubVenues[] = [];
          venue.courts.map((court, index) => {
            const name = formData
              .get(`courtName_${venue.venueId}_${index}`)
              ?.toString();
            const abbr = formData
              .get(`courtAbbreviation_${venue.venueId}_${index}`)
              ?.toString();

            if (name) {
              subVenues.push({
                name,
                abbreviation: abbr ?? '',
              });
            }
          });

          return {
            venueId: venue.venueId,
            subVenues,
          };
        });

        courtsUpdate = createSendable;

        const payload = {
          venues: courtsUpdate,
        };

        await mutateAsync({ id: eventId, data: payload });
      }
    } catch (e) {
      console.log('Error updating event', e);
    }
  };

  return (
    <CourtsWrapper>
      {courtsEvent.length > 0 ? (
        <CourtsForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
          {courtsEvent?.map((venue) => {
            return (
              <CourtsFormWrapper key={`key_venue_${venue?.venueId}`}>
                <CourtsTitleHeader>
                  <BodyLBold $color='white'>{venue.venueName}</BodyLBold>
                  <button onClick={() => handleAddCourt(venue)}>
                    ADD COURT <Plus />
                  </button>
                </CourtsTitleHeader>

                {venue.courts.map((c, index) => {
                  return (
                    <CourtInputs key={`key_${c.name}_`}>
                      <InputText
                        id={`courtName_${venue.venueId}_${index}`}
                        label='Court Name'
                        placeholder='Court Name'
                        defaultValue={c.name}
                        key={`name_${c.name}`}
                      />

                      <InputText
                        id={`courtAbbreviation_${venue.venueId}_${index}`}
                        label='Abbreviation'
                        placeholder='Abbreviation'
                        defaultValue={c.abbreviation}
                        key={`abbreviation_${c.abbreviation}`}
                      />
                      <TrashButtonContainer
                        onClick={() =>
                          handleRemoveCourt(index, venue, venue.venueId)
                        }
                      >
                        <Delete theme='outline' size='24' fill={COLORS.white} />
                      </TrashButtonContainer>
                    </CourtInputs>
                  );
                })}
              </CourtsFormWrapper>
            );
          })}
          <FilledButton
            isUpper
            color='primary'
            onClick={handleSave}
            isLoading={isLoading}
          >
            Save {isLoading && '...'}
          </FilledButton>
        </CourtsForm>
      ) : (
        <p>Please Register some venues before register the Courts</p>
      )}
    </CourtsWrapper>
  );
};

export default withRouter(Courts);
