import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';
import { CONTENT_WIDTH } from '../styles';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  width: 100%;
  height: 70px;
  border-bottom: 1px solid ${COLORS.grey900};
  background: ${COLORS.grey1000};
`;
export const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LogoContainer = styled.div`
  height: 30px;
  svg {
    height: 30px;
  }
  @media (max-width: ${CONTENT_WIDTH}) {
    padding: 0 ${toRem(10)};
  }
`;

export const LocaleContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${CONTENT_WIDTH}) {
    padding: 0 ${toRem(10)};
  }
`;
