import { Col, Row, Select } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import chevronDown from '../../../../assets/imgs/icons/icon_chevron_down.svg';
import Button from '../../../../components/uielements/Button/Button';
import { FilterPaginationUsers } from '../../../../models/Pagination/Pagination';
import UserType from '../../../../models/User/UserTypeModel';
import { ApplicationState } from '../../../../redux/store';
import ClubService from '../../../../services/Club/ClubService';
import { color } from '../../../../styles/styleVariables';
import { ClubView } from '../../../models/club/Club';

interface IAdvancedUserSearch {
  filterParams: FilterPaginationUsers;
  setFilterParams: (param: FilterPaginationUsers) => void;
  onSearch: () => void;
}

const AdvancedUserSearch: React.FunctionComponent<IAdvancedUserSearch> = (
  props
) => {
  const { filterParams, onSearch, setFilterParams } = props;

  const hasFilter = () => {
    return Boolean(
      filterParams.birthYear ||
        filterParams.clubs ||
        filterParams.memberships ||
        filterParams.type
    );
  };

  const [open, setOpen] = useState(hasFilter());

  const [clubList, setClubList] = useState<ClubView[]>([]);
  const memberships = useSelector(
    (state: ApplicationState) => state.memberships
  );
  useEffect(() => {
    ClubService.list(
      new URLSearchParams({
        size: '1000',
      })
    ).then((response) => {
      if (response.success) {
        setClubList(response.data.content);
      }
    });
  }, []);

  const searchFields = () => {
    onSearch();
  };

  return (
    <Wrapper>
      <h4
        onClick={() => {
          setOpen(!open);
        }}
      >
        Advanced Search
      </h4>
      <div className={`form-group ${!open ? 'closed' : ''}`}>
        <Row gutter={12}>
          <Col span={12}>
            <div>Clubs</div>
            <Select
              placeholder='Choose the Clubs'
              showSearch
              optionFilterProp='data-label'
              mode='multiple'
              className='small'
              value={filterParams.clubs}
              onChange={(event) => {
                setFilterParams({
                  ...filterParams,
                  clubs: event,
                  pagination: { ...filterParams.pagination, current: 0 },
                });
              }}
            >
              {clubList.map((item) => {
                return (
                  <Select.Option
                    key={`clubId_${item.id}`}
                    value={item.id}
                    data-label={item.name}
                  >
                    {item.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Col>

          <Col span={12}>
            <div>Memberships</div>
            <Select
              placeholder='Choose the memberships'
              showSearch
              optionFilterProp='data-label'
              mode='multiple'
              className='small'
              value={filterParams.memberships}
              onChange={(event) => {
                setFilterParams({
                  ...filterParams,
                  memberships: event,
                  pagination: { ...filterParams.pagination, current: 0 },
                });
              }}
            >
              {memberships.data.map((item) => {
                return (
                  <Select.Option
                    key={`membershipId_${item.id}`}
                    value={item.id}
                    data-label={item.name}
                  >
                    {item.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={8}>
            <div>Birth Year</div>
            <Select
              mode='multiple'
              className='small'
              value={filterParams.birthYear}
              onChange={(event: any) => {
                setFilterParams({
                  ...filterParams,
                  birthYear: event,
                });
              }}
            >
              {Array.from({ length: 90 }, (_, i) => 2020 - i).map((x, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Select.Option key={`year_${x}_${i}`} value={x.toString()}>
                  {x}
                </Select.Option>
              ))}
            </Select>
          </Col>

          {/* <Col span={8}>
            <div>Gender</div>
            <select>
              <option>Both</option>
              <option>Male</option>
              <option>Female</option>
            </select>
          </Col> */}

          <Col span={8}>
            <div>Type</div>
            <select
              value={filterParams.type}
              onChange={(event) => {
                setFilterParams({
                  ...filterParams,
                  type: event.currentTarget.value,
                  pagination: { ...filterParams.pagination, current: 0 },
                });
              }}
            >
              <option value=''>All</option>
              <option value={UserType.PLAYER}>{UserType.PLAYER}</option>
              <option value={UserType.COACH}>{UserType.COACH}</option>
              <option value={UserType.REFEREE}>{UserType.REFEREE}</option>
              <option value={UserType.CLUB_DIRECTOR}>
                {UserType.CLUB_DIRECTOR}
              </option>
            </select>
          </Col>
        </Row>

        {/* <Row gutter={12}>
          <Col span={24}>
            <div>Creation date</div>
            <DatePicker.RangePicker style={{ width: '70%' }} />
          </Col>
        </Row> */}
        <Button onClick={searchFields}>Search</Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 15px;
  border-radius: 10px;
  background-color: ${color.greyDark};
  margin: 10px 0;
  h4 {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      right: 10px;
      top: 10px;
      width: 15px;
      height: 15px;
      background-size: contain;
      background-image: url(${chevronDown});
    }
  }
  .closed {
    height: 0;
    overflow: hidden;
    margin: 0 !important;
    display: block;
  }
`;

export default AdvancedUserSearch;
