import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-width: 30%;

  padding: ${toRem(24)};
  background-color: ${COLORS.grey900};

  border-radius: ${toRem(12)};
`;

export const UserXPContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${toRem(4)};
`;

export const UserXPInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const UserLevelInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;
