import React, { useCallback, useState } from 'react';
import { Tabs } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { BodyM } from 'styles/v3/variables';
import { Header } from 'components/v3/ClubManagerDashboard/Header/Header';
import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';
import { useGetClubMembersById } from 'hooks/v3/clubs/useGetClubMembersById/useGetClubMembersById';
import { Spinner } from 'components/v3/Spinner/Spinner';
import { TABS_KEYS } from './tab-keys';
import { Members, Staff } from './components';
import * as S from './styles';

export const Club = () => {
  const history = useHistory();
  const location = useLocation();
  const params: { clubId: string } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const searchParams = new URLSearchParams(location.search);

  const activeTab = searchParams.get('tab') ?? TABS_KEYS.STAFF;

  const handleTabChange = useCallback(
    (key: string) => {
      history.push(`${location.pathname}?tab=${key}`);
    },
    [history, location.pathname]
  );

  const { data: club, isLoading: isLoadingGetClub } = useGetClubById(
    params.clubId
  );

  const {
    data: response,
    isLoading: isLoadingGetMembers,
  } = useGetClubMembersById({
    id: params.clubId,
    page: currentPage - 1,
  });

  const isLoading = isLoadingGetClub || isLoadingGetMembers;

  const staff = response?.content?.filter(
    (item) => item.type === 'CLUB_DIRECTOR'
  );

  const members = response?.content?.filter(
    (item) => item.type !== 'CLUB_DIRECTOR'
  );

  return isLoading ? (
    <S.SpinnerWrapper>
      <Spinner />
    </S.SpinnerWrapper>
  ) : (
    <>
      <Header maxWidth={false} club={club} />
      <S.Main>
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          <Tabs.TabPane
            key={TABS_KEYS.STAFF}
            tab={<BodyM $isUpper>Staff</BodyM>}
          >
            <Staff
              staff={staff}
              total={response?.total}
              pageSize={response?.pageSize}
              setCurrentPage={setCurrentPage}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            key={TABS_KEYS.MEMBERS}
            tab={<BodyM $isUpper>Members</BodyM>}
          >
            <Members
              members={members}
              total={response?.total}
              pageSize={response?.pageSize}
              setCurrentPage={setCurrentPage}
            />
          </Tabs.TabPane>
        </Tabs>
      </S.Main>
    </>
  );
};
