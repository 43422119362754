import * as React from 'react';

import * as S from './styles';

interface NotificationsProps {}

const Notifications: React.FC<NotificationsProps> = ({ ...props }) => {
  return (
    <S.Container className='notifications-wrapper'>
      <S.Icon {...props} />
    </S.Container>
  );
};

export default Notifications;
