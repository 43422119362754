import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;

  gap: ${toRem(16)};

  padding: ${toRem(16)};
  border-radius: ${toRem(24)};
  background-color: ${COLORS.grey900};

  border: ${({ isSelected }) =>
    isSelected ? `${toRem(1)} solid ${COLORS.brandPrimary}` : 'none'};
`;

export const Flex = styled.div<{
  gap?: number;
  alignItems?: string;
  isSelected?: boolean;
}>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems};

  .i-icon {
    margin-top: ${toRem(4)};
    color: ${COLORS.brandPrimary};

    &::before {
      display: none;
    }
  }

  gap: ${({ gap }) => gap && toRem(gap)};
`;

export const BasicInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;
`;

export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const InfoWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: ${toRem(64)};
`;
