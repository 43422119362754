import { Divider } from 'antd';
import React from 'react';
import RightDrawer from 'components/v3/RightDrawer';
import { SimpleOrderView } from 'services/v3/Order/types';
import { useParams } from 'react-router-dom';
import { useGetRosterEventOrdersForMyEvent } from 'hooks/v3/orders/useGetRosterEventOrdersForMyEvent/useGetRosterEventOrdersForMyEvent';
import { OrderFilters } from '../OrderFilters/OrderFilters';
import { OrderList } from './components/OrderList/OrderList';
import ModalHeader from './components/Modal/ModalHeader/ModalHeader';
import ModalBody from './components/Modal/ModalBody/ModalBody';

export const OrderTab = () => {
  const params: { eventId: string } = useParams();
  const { data: orders } = useGetRosterEventOrdersForMyEvent(
    params.eventId,
    'PAID'
  );

  const [filterDivisionId, setFilterDivisionId] = React.useState<string>('');
  const [filterClubId, setFilterClubId] = React.useState<string>('');
  const [orderBy, setOrderBy] = React.useState<string>('last');

  const filteredOrders = React.useMemo(() => {
    if (!orders) return orders;

    const myOrders = orders.filter((order) => {
      const divisionFilter = !filterDivisionId
        ? true
        : order?.item?.b2bEventRosterFee?.divisions?.findIndex(
            (division) => division.id === filterDivisionId
          ) !== -1;

      const clubFilter = !filterClubId
        ? true
        : order?.item?.b2bEventRosterFee?.club?.id === filterClubId;

      return clubFilter && divisionFilter;
    });

    myOrders.sort((a, b) => {
      if (orderBy === 'last') {
        return (
          new Date(b.paidAt || b.createdAt).getTime() -
          new Date(a.paidAt || a.createdAt).getTime()
        );
      }

      return (
        new Date(a.paidAt || a.createdAt).getTime() -
        new Date(b.paidAt || b.createdAt).getTime()
      );
    });
    return myOrders;
  }, [orders, filterDivisionId, filterClubId, orderBy]);

  const [isOpen, setIsOpen] = React.useState(false);
  const [currentOrder, setCurrentOrder] = React.useState<
    SimpleOrderView | undefined
  >(undefined);

  const handleCloseModal = () => {
    setCurrentOrder(undefined);
    setIsOpen(false);
  };

  const handleOpenModal = (order: SimpleOrderView) => {
    setCurrentOrder(order);
    setIsOpen(true);
  };

  const handleSelectDivision = (divisionId: string) => {
    setFilterDivisionId(divisionId);
  };

  const handleSelectClub = (clubId: string) => {
    setFilterClubId(clubId);
  };

  const handleSelectOrderBy = (selectedOrderBy: string) => {
    setOrderBy(selectedOrderBy);
  };

  return (
    <>
      <Divider style={{ margin: 0 }} />
      <OrderFilters
        orders={orders || []}
        setDivision={handleSelectDivision}
        setClub={handleSelectClub}
        setOrderBy={handleSelectOrderBy}
      />
      <Divider style={{ margin: 0 }} />
      <OrderList
        orders={filteredOrders || []}
        handleOpenModal={handleOpenModal}
      />
      <RightDrawer
        isOpen={isOpen && !!currentOrder}
        handleCloseModal={handleCloseModal}
        headerComponent={
          <ModalHeader
            title='ORDER DETAILS'
            subtitleCategory='Number'
            subtitleValue={currentOrder?.id || ''}
          />
        }
      >
        {currentOrder && <ModalBody order={currentOrder} />}
      </RightDrawer>
    </>
  );
};
