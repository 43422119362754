import React, { useMemo } from 'react';
import { Calendar, LocalTwo } from '@icon-park/react';

import { BodyL } from 'styles/v3/variables';
import moment from 'moment';
import { useGetVenueById } from 'hooks/v3/venue/useGetVenueById/useGetVenueById';
import { CheckoutGeneralInfoProps } from './types';
import * as S from './styles';
import { InfoItem } from './components/InfoItem/InfoItem';

export const CheckoutGeneralInfo = ({ event }: CheckoutGeneralInfoProps) => {
  const formattedStartDate = moment(new Date(event.startDate)).format(
    'MMM D YYYY'
  );
  const formattedEndDate = moment(new Date(event.endDate)).format('MMM D YYYY');

  const { data: venue } = useGetVenueById((event.venues[0] as any)?.venueId);

  const eventAddress = useMemo(() => {
    if (!venue) return '';

    return `${venue.address.line1}, ${venue.address.city}, ${venue.address.state}`;
  }, [venue]);

  return (
    <S.Container>
      <BodyL>GENERAL INFO</BodyL>
      <InfoItem
        icon={<Calendar />}
        text={`${formattedStartDate} - ${formattedEndDate}`}
      />
      <InfoItem icon={<LocalTwo />} text={`${eventAddress}`} />
    </S.Container>
  );
};
