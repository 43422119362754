import { useQuery } from '@tanstack/react-query';
import ServiceResponse from 'services/util/ServiceResponse';
import { AxiosRequestConfig } from 'axios';
import { ConfigApi } from 'services/config.api';
import { StripeConnectedAccount } from 'models/Stripe/StripeConnectedAccount';
import APIServices from 'services/util/ApiServices';

async function fetchGetStripeAccount(
  id?: string
): Promise<ServiceResponse<StripeConnectedAccount | undefined>> {
  if (!id) {
    return ServiceResponse.success(undefined, '');
  }
  const axiosConfig: AxiosRequestConfig = {
    ...ConfigApi.getNoAccountConfig(),
    url: `/stripe/connected-accounts/${id}`,
    method: 'GET',
  };
  return APIServices.request<StripeConnectedAccount>(axiosConfig);
}

export function useGetStripeAccount(id?: string) {
  console.log('pegando conta id', id);
  const { data, isLoading, error } = useQuery(
    ['/stripe-connected-account', id],
    // eslint-disable-next-line no-return-await
    async () => await fetchGetStripeAccount(id),
    {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: data?.data ? data.data : undefined,
    isLoading,
    error,
  };
}
