import React from 'react';
import {
  RadioChangeEvent,
  RadioGroupProps as AntdRadioGroupProps,
} from 'antd/lib/radio';

import { BodyM } from 'styles/v3/variables';
import * as S from './styles';

interface RadioGroupProps extends AntdRadioGroupProps {
  label?: string;
  onChange: (e: RadioChangeEvent) => void;
  options: {
    value: any;
    label: string;
  }[];
}

export const RadioGroup = ({
  label,
  options,
  onChange,
  ...rest
}: RadioGroupProps) => {
  return (
    <S.Container>
      {label && <BodyM $isUpper>{label}</BodyM>}
      <S.Group onChange={onChange} {...rest}>
        {options?.map((option) => (
          <S.Button key={option.value} value={option.value}>
            {option.label}
          </S.Button>
        ))}
      </S.Group>
    </S.Container>
  );
};
