import styled, { keyframes } from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

import NotificationsIcon from 'assets/imgs/icons/notification.svg';

const shakeIcon = keyframes`
  0% {
    transform: rotate(0deg);
  }
  20%, 60% {
    transform: rotate(-10deg);
  }
  40%, 80% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.grey800};
  border-radius: 50%;
  transition: 0.2s;
  cursor: pointer;
  border: 1px solid ${COLORS.grey800};
  width: 48px;
  height: 48px;

  &:hover {
    border-color: ${COLORS.grey100};
    & > div {
      animation: ${shakeIcon} 0.4s ease-in-out;
    }
  }
`;

export const Icon = styled.div`
  background: url(${NotificationsIcon}) no-repeat center;
  width: 24px;
  height: 24px;
`;
