import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Button = styled.button<{
  $isBold: boolean;
  $isUpper: boolean;
  $isLoading: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ $isBold }) =>
    $isBold ? 'Boxed Regular Bold' : 'Boxed Regular'};
  padding: ${toRem(8)} ${toRem(24)};
  border-radius: ${toRem(12)};
  min-height: 50px;
  border: 1px solid;
  cursor: pointer;
  transition: 0.5s;
  font-size: ${(props) => (props.$isUpper ? '1rem' : toRem(16))};
  letter-spacing: ${(props) => (props.$isUpper ? '0.48px' : '0.6px')};
  text-transform: ${(props) => (props.$isUpper ? 'uppercase' : 'none')};

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  div {
    span {
      opacity: 0.3;
      font-size: ${toRem(26)};
      color: ${COLORS.grey50};
    }
  }
`;
