import { action } from 'typesafe-actions';
import {
  B2bEventInfo,
  B2bEventProduct,
  B2bDraftEventActionTypes,
} from './types';

export const b2bDraftEventSetEventType = (eventId: string) =>
  action(B2bDraftEventActionTypes.UPDATE_EVENT_TYPE, eventId);

export const b2bDraftEventAddBooster = (boosterId: string) =>
  action(B2bDraftEventActionTypes.ADD_BOOSTER, boosterId);

export const b2bDraftEventRemoveBooster = (boosterId: string) =>
  action(B2bDraftEventActionTypes.REMOVE_BOOSTER, boosterId);

export const b2bDraftEventAddOrRemoveBooster = (boosterId: string) =>
  action(B2bDraftEventActionTypes.ADD_OR_REMOVE_BOOSTER, boosterId);

export const b2bDraftEventUpdateEventInfo = (
  partialEventInfo: Partial<B2bEventInfo>
) => action(B2bDraftEventActionTypes.UPDATE_EVENT_INFO, partialEventInfo);

export const b2bDraftEventFetchProductRequest = () =>
  action(B2bDraftEventActionTypes.FETCH_PRODUCT_REQUEST);

export const b2bDraftEventFetchProductSuccess = (data: B2bEventProduct[]) =>
  action(B2bDraftEventActionTypes.FETCH_PRODUCT_SUCCESS, data);

export const b2bDraftEventFetchProductError = (message: string) =>
  action(B2bDraftEventActionTypes.FETCH_PRODUCT_ERROR, message);
export const b2bDraftEventClearEvent = () =>
  action(B2bDraftEventActionTypes.CLEAR_EVENT);
