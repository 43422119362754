import { AxiosRequestConfig } from 'axios';

import APIServices from 'services/util/ApiServices';
import { EventListView, EventView } from 'admin/models/event/Event';
import PageResponse from 'services/util/PageResponse';
import ServiceResponse from 'services/util/ServiceResponse';
import EventInvitesModel, {
  EventInvitesTypes,
} from 'models/v3/EventInvite/EventInvitesModel';
import { ConfigApi } from '../../config.api';
import {
  AllEventInvites,
  ClubsAndDivisionsProps,
  FindSubscribedClubs,
  FindSubscribedReferees,
  InviteClubsByEvent,
  PagedEventListWithRosterUsers,
} from './types';

export default class EventService {
  public static async getActiveEvents(): Promise<
    ServiceResponse<PageResponse<EventListView>>
  > {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `events/active`,
      method: 'GET',
    };
    return APIServices.request<PageResponse<EventListView>>(axiosConfig);
  }

  public static async find(id: string): Promise<ServiceResponse<EventView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/events/${id}/with-detailed-venues`,
      method: 'GET',
    };
    return APIServices.request<EventView>(axiosConfig);
  }

  public static async getClubsAndDivisionsPaidForEvent(
    id: string
  ): Promise<ServiceResponse<ClubsAndDivisionsProps>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/events/${id}/divisions`,
      method: 'GET',
    };

    return APIServices.request(axiosConfig);
  }

  public static async findSubscribedClubs(
    id: string
  ): Promise<ServiceResponse<FindSubscribedClubs>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/events/${id}/subscribed/clubs`,
      method: 'GET',
    };
    return APIServices.request<FindSubscribedClubs>(axiosConfig);
  }

  public static async findSubscribedReferees(
    id: string
  ): Promise<ServiceResponse<FindSubscribedReferees>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/events/${id}/subscribed/referees`,
      method: 'GET',
    };
    return APIServices.request<FindSubscribedReferees>(axiosConfig);
  }

  public static async getJoinedEvents(
    pageSize = 50
  ): Promise<ServiceResponse<PagedEventListWithRosterUsers>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: '/events/joined',
      method: 'GET',
      params: {
        size: pageSize,
      },
    };
    return APIServices.request<PagedEventListWithRosterUsers>(axiosConfig);
  }

  public static async inviteByEvent(
    body: InviteClubsByEvent
  ): Promise<ServiceResponse<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/event-invites`,
      method: 'POST',
      data: body,
    };
    return APIServices.request<any>(axiosConfig);
  }

  public static async getEventInvites(
    id: string,
    type?: EventInvitesTypes
  ): Promise<ServiceResponse<AllEventInvites[]>> {
    let url = `/event-invites/all/${id}`;

    if (type) url += `?type=${type}`;

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url,
      method: 'GET',
    };
    return APIServices.request<AllEventInvites[]>(axiosConfig);
  }


  public static async denyInvite(
    id: string,
  ): Promise<ServiceResponse<AllEventInvites[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/event-invites/${id}/deny`,
      method: 'POST',
    };
    return APIServices.request<AllEventInvites[]>(axiosConfig);
  }

}
