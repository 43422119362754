import React from 'react';

import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { BodyXL } from 'styles/v3/variables';

import { useGetProfiles } from 'hooks/v3/users/useGetProfiles/useGetProfiles';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import ConvertUtil from 'util/ConvertUtil';
import UserType from 'models/User/UserTypeModel';
import { SettingTwo } from '@icon-park/react';
import { useHistory , Link } from 'react-router-dom';
import { formatUserName } from 'util/formatUserName';
import { AccountTag } from './components/AccountTag/AccountTag';
import { AccountAccordion } from './components/AccountAccordion/AccountAccordion';


import * as S from './styles';

type AccountModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AccountModal = ({
  isModalOpen,
  setIsModalOpen,
}: AccountModalProps) => {
  useGetProfiles();

  const history = useHistory();

  const currentUser = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );

  const isMember = currentUser?.membershipType === 'OFFICIAL';

  const types = [
    currentUser?.type,
    ...(Array.isArray(currentUser?.types) ? currentUser.types : []),
  ].filter(Boolean) as Array<UserType>;

  const handleGoToUpgradeMembership = () => {
    history.push('/v3/profile/upgrade-membership');
  };

  return (
    <S.StyledModal
      width={460}
      mask={false}
      maskClosable
      footer={false}
      closable={false}
      visible={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      style={{ top: 100, right: 0, position: 'absolute' }}
    >
      <S.ModalWrapper>
        <S.ButtonWrapper>
          <Link to={`/v3/new-user/user-info/${currentUser.id}`}>
          <S.SettingsButton>
            <SettingTwo size='24px' />
          </S.SettingsButton>
          </Link>
        </S.ButtonWrapper>
        <S.HeaderWrapper>
          <Avatar
            size={118}
            icon={<UserOutlined />}
            src={ConvertUtil.getMinioUrl(currentUser.photo)}
          />
          <BodyXL $color='grey50' $fontWeight='700'>
            {formatUserName({
              lastName: currentUser.lastName,
              firstName: currentUser.firstName,
              middleName: currentUser.middleName,
            })}
          </BodyXL>
          <S.TagsWrapper>
            {types?.map((type) => (
              <AccountTag key={type} type={type} />
            ))}
          </S.TagsWrapper>
        </S.HeaderWrapper>
        {!isMember && (
          <S.UpgradeButton onClick={handleGoToUpgradeMembership}>
            Upgrade Membership
          </S.UpgradeButton>
        )}
        <AccountAccordion />
      </S.ModalWrapper>
    </S.StyledModal>
  );
};
