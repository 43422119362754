import React, { useMemo } from 'react';

import { useGetJoinedEvents } from 'hooks/v3/event/useGetJoinedEvents/useGetJoinedEvents';
import MyEventCard from 'components/v3/Cards/MyEventCard/MyEventCard';
import * as S from './styles';

export const MyEvents = () => {
  const { data: response } = useGetJoinedEvents();

  console.log('response', response);

  const permission = useMemo(() => {
    const isPlayer =
      response?.data?.content?.some((item) =>
        item.playersIds?.some((i) => item.myIds.includes(i))
      ) || false;

    const isCoach =
      response?.data?.content?.some((item) =>
        item.coachesIds?.some((i) => item.myIds.includes(i))
      ) || false;

    const isReferee =
      response?.data?.content?.some((item) =>
        item.refereesIds?.some((i) => item.myIds.includes(i))
      ) || false;

    return {
      isPlayer,
      isCoach,
      isReferee,
    };
  }, [response]);

  return (
    <S.EventsList>
      {response?.data?.content?.map((item) => {
        const eventStatus = item.draft ? 'draft' : 'published';
        return (
          <MyEventCard
            size='small'
            key={item.id}
            id={item.id}
            logo={item.logo}
            title={item.name}
            orientation='column'
            actionText='See event'
            eventStatus={eventStatus}
            description={item.description}
            startDate={item.startDate}
            finalDate={item.endDate}
            link={`/v3/event/${item.id}`}
            permission={permission}
          />
        );
      })}
    </S.EventsList>
  );
};
