import React from 'react';

import { EventManagerDashboard } from 'components/v3/EventManagerDashboard/EventManagerDashboard';
import { useParams } from 'react-router-dom';
import { TABS_KEYS } from './tab-keys';
import { Invites } from './components';
import Sales from '../setupEvent/PublishedEvent/components/Sales/Sales';

const EventManager = () => {
  const params: { eventId: string } = useParams();

  return (
    <EventManagerDashboard
      tabs={[
        {
          title: 'Invites',
          children: <Invites id={params.eventId} />,
          key: TABS_KEYS.INVITES,
        },
        {
          title: 'Sales',
          children: <Sales />,
          key: TABS_KEYS.SALES,
        },
      ]}
    />
  );
};

export default EventManager;
