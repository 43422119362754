import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';
import useClubPermission from 'hooks/v3/clubs/useClubPermission';
import { NonMemberView } from 'pages/V3/ClubDashboard/NonMemberView/NonMemberView';
import { Header } from './Header/Header';
import { Tabs, TabsProps } from './Tabs/Tabs';
import * as S from './styles';
import Loading from '../Loading/Loading';

export const ClubManagerDashboard = ({ tabs }: TabsProps) => {
  const params: { clubId: string } = useParams();
  const { data: currentClub } = useGetClubById(params.clubId);

  const { isMemberOfThisClub } = useClubPermission(currentClub);

  if (!currentClub) return <Loading />;
  return (
    <S.Container>
      <Header club={currentClub} />
      {isMemberOfThisClub ? <Tabs tabs={tabs} /> : <NonMemberView />}
    </S.Container>
  );
};
