import styled from 'styled-components';
import { Modal } from 'antd';

import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: 468px;
    border-radius: 24px;
    background-color: ${COLORS.grey900};
  }

  .ant-modal-header {
    border-radius: 24px;
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: 24px;
    padding: 6px 12px 6px 12px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CloseWrapper = styled.div`
  cursor: pointer;
  padding: ${toRem(5)};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${toRem(8)};
  padding-top: ${toRem(16)};
`;

export const TextInformation = styled.div`
  margin-bottom: ${toRem(8)};
`;

export const InformationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${toRem(16)};
  padding: ${toRem(16)};

  background-color: ${COLORS.grey950};
  border-radius: ${toRem(8)};
`;
